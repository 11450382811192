export default class Price {
  constructor(value, type) {
    this.value = value;
    this.type = type;
  }

  static get SalesPriceType () {
    return 'sales';
  }

  static get PriceType () {
    return 'price';
  }

  get isSalesPrice() {
    return this.type === Price.SalesPriceType;
  }
}
