<template>
  <!-- Desktop -->
  <ion-grid class="search-bar">
    <ion-row class="">
      <ion-col
        v-click-outside="clickOutside"
        size="12"
        class="ion-text-end ion-float-right ion-no-padding"
      >
        <ion-row>
          <ion-col size="12">
            <ion-item
              class="search-item"
              lines="none"
            >
              <ion-input
                id="desktop-keyword"
                v-model="keyword"
                placeholder="Search by keyword"
                aria-label="Input Search by Keyword"
                :clear-input="true"
                :disabled="loading"
                debounce="1000"
                @click="clickOnSearchInput"
                @keyup.enter="goToPLP"
              />
            </ion-item>
            <div
              class="search-icon-field"
            >
              <ion-icon
                class="search-icon"
                :icon="searchOutline"
                @click="goToPLP"
              />
            </div>
            <ion-list
              v-if="showAutocomplete && !loading"
              class="result-list"
            >
              <ion-item
                v-if="results.length !== 0"
                class="result-item"
                lines="none"
                button
                @click="goToPLP"
              >
                <ion-thumbnail
                  slot="start"
                  class="thumbnail-item"
                >
                  <img
                    alt="Search results icon"
                    src="@/assets/search-results-icon.webp"
                  >
                </ion-thumbnail>
                <ion-label>
                  Click for all results
                </ion-label>
              </ion-item>
              <ion-item
                v-for="(item, index) in results"
                :key="index"
                class="result-item"
                lines="none"
                button
                @click="goToProduct(item)"
              >
                <ion-thumbnail
                  slot="start"
                  class="thumbnail-item"
                >
                  <img
                    :alt="altText(item)"
                    :src="item.image"
                  >
                </ion-thumbnail>
                <ion-label>
                  {{ item.title }}
                </ion-label>
              </ion-item>
            </ion-list>
            <template v-if="showAutocomplete">
              <template v-if="loading">
                <ion-list class="result-list">
                  <ion-item lines="none">
                    Searching for "{{ trimmedKeyword }}", please wait...
                  </ion-item>
                </ion-list>
              </template>
              <template v-else>
                <ion-list
                  v-if="results.length === 0"
                  class="result-list"
                >
                  <ion-item
                    lines="none"
                  >
                    <ion-label>No Results Found</ion-label>
                  </ion-item>
                </ion-list>
              </template>
            </template>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
  <!-- Mobile -->
  <ion-grid class="search-bar-mobile">
    <ion-row class="">
      <ion-col
        v-click-outside="clickOutside"
        size="12"
        class="ion-text-end ion-float-right ion-no-padding"
      >
        <ion-row>
          <ion-col size="12">
            <ion-item
              class="search-item"
              lines="none"
            >
              <ion-input
                id="mobile-keyword"
                v-model="keyword"
                placeholder="Search by keyword"
                aria-label="Input Search by keyword"
                :clear-input="true"
                :disabled="loading"
                debounce="1000"
                @click="clickOnSearchInput"
                @keyup.enter="goToPLP"
              />
            </ion-item>
            <div
              class="search-icon-field"
            >
              <ion-icon
                class="search-icon"
                :icon="searchOutline"
                @click="goToPLP"
              />
            </div>
            <ion-list
              v-if="showAutocomplete && !loading"
              class="result-list"
            >
              <ion-item
                v-if="results.length !== 0"
                class="result-item"
                lines="none"
                button
                @click="goToPLP"
              >
                <ion-thumbnail
                  slot="start"
                  class="thumbnail-item"
                >
                  <img
                    alt="Search results icon"
                    src="@/assets/search-results-icon.webp"
                  >
                </ion-thumbnail>
                <ion-label>
                  Click for all results
                </ion-label>
              </ion-item>
              <ion-item
                v-for="(item, index) in results"
                :key="index"
                class="result-item"
                lines="none"
                button
                @click="goToProduct(item)"
              >
                <ion-thumbnail
                  slot="start"
                  class="thumbnail-item"
                >
                  <img
                    :alt="altText(item)"
                    :src="item.image"
                  >
                </ion-thumbnail>
                <ion-label>
                  {{ item.title }}
                </ion-label>
              </ion-item>
            </ion-list>
            <template v-if="showAutocomplete">
              <template v-if="loading">
                <ion-list class="result-list">
                  <ion-item lines="none">
                    Searching, please wait...
                  </ion-item>
                </ion-list>
              </template>
              <template v-else>
                <ion-list
                  v-if="results.length === 0"
                  class="result-list"
                >
                  <ion-item
                    lines="none"
                  >
                    <ion-label>No Results Found</ion-label>
                  </ion-item>
                </ion-list>
              </template>
            </template>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonIcon, IonInput, IonItem, IonLabel, IonList, IonThumbnail } from "@ionic/vue";
import { searchOutline } from "ionicons/icons";
import { mapActions } from "vuex";
import { ProductsLoader } from "@/services/ProductsService";
import { urlTransFormations } from "@/mixins";
import { LogRocketService } from "@/services/LogRocketService";

export default {
  name: "SearchBar",
  components: {
    IonCol,
    IonGrid,
    IonRow,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonThumbnail,
  },
  mixins: [urlTransFormations],
  data() {
    return {
      searchOutline,
      keyword: "",
      results: [],
      showAutocomplete: false,
      loading: false,
      productsLoader: new ProductsLoader(),
      keyDownEventListener: null,
    };
  },
  computed: {
    trimmedKeyword() {
      return this.keyword.trim();
    },
  },
  watch: {
    keyword: async function(newVal) {
      const trimmedKeyword = newVal.trim();

      if (trimmedKeyword.length > 2) {
        this.showAutocomplete = true;
        await this.loadProducts(this.composeParams({ search: trimmedKeyword }));
      } else {
        this.showAutocomplete = false;
        this.results = [];
      }
    },
  },
  mounted() {
    this.keyDownEventListener = (e) => {
      // Prevent scroll behavior when pressing space if the search is loading
      if (e.key === " " && e.target === document.body) {
        if (this.loading) {
          e.preventDefault();
        }
      }
    };

    window?.addEventListener("keydown", this.keyDownEventListener);
  },
  unmounted() {
    window?.removeEventListener("keydown", this.keyDownEventListener);
  },
  methods: {
    ...mapActions(["addSearchFilter", "clearAllSearchFilter"]),
    clickOutside(e) {
      if (!e.target) {
        return;
      }

      const elDesktop = document.getElementsByClassName("search-bar")?.[0];
      const elMobile = document.getElementsByClassName("search-bar-mobile")?.[0];

      if (elDesktop === undefined || elMobile === undefined) {
        return;
      }

      if (typeof elDesktop.contains !== 'function' || typeof elMobile.contains !== 'function') {
        return;
      }

      if (!elDesktop.contains(e.target) && !elMobile.contains(e.target)) {
        this.showAutocomplete = false;
      }
    },
    clickOnSearchInput() {
      if (this.trimmedKeyword.length > 2) {
        this.showAutocomplete = true;
      }
    },
    goToPLP() {
      if (this.trimmedKeyword.length === 0) {
        return;
      }

      if(this.loading) {
        return;
      }

      this.clearAllSearchFilter();

      LogRocketService.track(LogRocketService.events.homepage_search_enter_pressed, {
        keyword: this.keyword,
      });

      if (this.trimmedKeyword.length > 2) {
        this.$router.push({
          name: "ProductList",
          query: {
            type_id: "all",
            search: this.keyword,
            sort: this.$store.getters.sortOption,
          },
        });
      }
    },
    composeParams(optionalParams) {
      const { userGeoLocation, distance, location, searchApiFields } = this.$store.state;
      const { sortOption } = this.$store.getters;

      if (searchApiFields.type_id === "all") {
        return {
          ...this.loadAllTrailers(searchApiFields),
          ...optionalParams,
          lat: userGeoLocation[0],
          lon: userGeoLocation[1],
          location,
          distance,
          sort: sortOption,
          per_page: 10,
          page: 1,
          search_page: "HP",
        };
      }

      return {
        ...searchApiFields,
        ...optionalParams,
        lat: userGeoLocation[0],
        lon: userGeoLocation[1],
        location,
        distance,
        sort: sortOption,
        per_page: 10,
        page: 1,
        search_page: "HP",
      };
    },
    loadAllTrailers(apiFields) {
      const newApiFields = { ...apiFields };
      delete newApiFields.type_id;
      delete newApiFields.category;
      return newApiFields;
    },
    async loadProducts(params) {
      this.loading = true;

      try {
        LogRocketService.track(LogRocketService.events.homepage_search_searching, {
          keyword: params.search,
        });
        this.results = await this.productsLoader.getProducts(params, false, true);
        if (this.results.length > 0) {
          LogRocketService.track(LogRocketService.events.homepage_search_success_with_results, {
            keyword: params.search,
          });
        } else {
          LogRocketService.track(LogRocketService.events.homepage_search_success_empty, {
            keyword: params.search,
          });
        }
        this.loading = false;

        await this.$nextTick();

        setTimeout(() => {
          const ionInputId = this.isMobile ? "mobile-keyword" : "desktop-keyword";

          document.querySelector(`#${ionInputId} > input`).focus();
        }, 100);
      } catch (error) {
        LogRocketService.track(LogRocketService.events.homepage_search_error, {
          keyword: params.search,
          error,
        });

        console.log("Search Error: ", error);
      }
    },
    goToProduct(product, $event) {
      if ($event !== undefined) {
        $event.preventDefault();
      }
      this.singleProduct(product.id);
      let url = this.superSanitize(product.title);
      url = url + "-" + this.shortenNumber(product.id) + ".html";
      this.trackInventoryClicked(product);
      LogRocketService.track(LogRocketService.events.homepage_search_item_selected, {
        inventory_id: product.id,
        inventory_title: product.title,
      });
      this.$router.push({
        name: "ProductDetails",
        params: {
          url: url,
        },
      });
      localStorage.setItem("manufacturer", product?.manufacturer);
      localStorage.setItem("dealer_location_id", product?.dealer_location_id);
    },
    singleProduct(id) {
      this.$store.commit(
        "SET_INVENTORY_PRODUCT",
        this.results.find((item) => item.id == id),
      );
    },
    trackInventoryClicked(inventory) {
      window.tt_track?.inventoryClicked({
        meta: {
          dealer_id: parseInt(inventory.dealer_id),
          inventory_id: parseInt(inventory.id),
          stock: inventory.stock,
          category: inventory.category,
          category_label: inventory.category_label,
          type_id: inventory.type_id,
          type_label: inventory.type_label,
          title: inventory.title,
          is_private: inventory.dealer?.is_private,
        },
      });
    },
  },
};
</script>
