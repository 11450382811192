import GroupItemFilter from "./GroupItemFilter";

export default class AxlesItemFilter extends GroupItemFilter {
  constructor({value}) {
    if(value === AxlesItemFilter.AXLES_3_MORE_VALUE) {
      super({name: AxlesItemFilter.AXLES_GROUP, label:AxlesItemFilter.AXLES_3_MORE_LABEL, value});
    } else {
      super({name: AxlesItemFilter.AXLES_GROUP, value});
    }
  }
}

const self = AxlesItemFilter;
AxlesItemFilter.fromApiField = function (field) {
  const filters = [];
  let valueList = field.split(';');
  const newValueList = valueList.filter((value) => value < 3);
  if(newValueList.length < valueList.length) {
    filters.push(new self({
      label: self.AXLES_3_MORE_LABEL,
      value: self.AXLES_3_MORE_VALUE,
    }));
    valueList = newValueList;
  }
  for (const value of valueList) {
    filters.push(new self({
      value,
    }));
  }
  return filters;
}

AxlesItemFilter.AXLES_GROUP = 'axles';
AxlesItemFilter.AXLES_3_MORE_LABEL='3 or more';
AxlesItemFilter.AXLES_3_MORE_VALUE='3;4;5;6;7;8';