export default (context, middleware, index) => {
  const nextMiddleWare = middleware[index];
  if (!nextMiddleWare) {
    return context.next;
  }

  return () => {
    const nextPipeLine = (context, middleware, index + 1);

    nextPipeLine({ ...context, next: nextPipeLine });
  };
};
