import RangeFilter from "./RangeFilter";

export default class PriceFilter extends RangeFilter {
  constructor({ name, min, max }) {
    const label = PriceFilter.defaultLabel(name, min, max);
    const rangeLabels = PriceFilter.rangeLabelsList(name, min, max);
    super({ name, min, max, label, rangeLabels });
    this.min = min;
    this.max = max;
  }
}

PriceFilter.valueFormatter = function (value) {
  return `$${value}`;
};

PriceFilter.defaultLabel = function (name, min, max) {
  const minFormatted = PriceFilter.valueFormatter(min);
  const maxFormatted = PriceFilter.valueFormatter(max);

  return `${minFormatted}<${name}<${maxFormatted}`;
};

PriceFilter.rangeLabelsList = function (name, min, max) {
  const ranges = [];
  if (min) {
    ranges.push({
      key: "min",
      value: `${name} > ${PriceFilter.valueFormatter(min)}`,
    });
  }
  if (max) {
    ranges.push({
      key: "max",
      value: `${name} < ${PriceFilter.valueFormatter(max)}`,
    });
  }

  return ranges;
};
