import axios from "axios";
import { appBaseUrl } from "@/config";
import { isSSR } from "@/utils/SSRUtil";
import { refreshToken } from "@/services/UserService";
import { $store } from "@/services/StoreService";
import { $router } from "@/services/RouterService";
import { cacheAdapterEnhancer } from "axios-extensions";

const fetchClient = () => {
  // Default config options
  let baseUrl = appBaseUrl + "/api/";
  let defaultOptions = {
    baseURL: baseUrl,
    adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false }),
  };

  // In SSR mode, we want to set user agent so the request can go through
  if (isSSR()) {
    // This will be set by the entry-server.js file
    defaultOptions.headers = {
      "User-Agent": "",
      "TT-SSR-Original-IP": "",
    };
  }

  // Create instance
  const instance = axios.create(defaultOptions);
  instance.interceptors.request.use(function (config) {
    const token = isSSR() ? null : localStorage.getItem("token");
    if (token !== null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        return refreshToken()
          .then((res) => {
            originalRequest.headers.Authorization = `Bearer ${res.data.token}`;
            localStorage.setItem("token", res.data.token);
            return axios(originalRequest);
          })
          .catch(() => {
            $store.commit("REMOVE_AUTH_TOKEN", "");
            $store.commit("SET_IS_AUTHENTICATED", false);
            $store.commit("SET_AUTHENTICATED_ROUTE", false);
            $store.dispatch("setToastNotification", {
              open: true,
              text: "Session Expired. Please login again.",
              type: "success",
            });
            $router.push({
              name: "Home",
              replace: true,
            });
          });
      }

      if (error?.response?.data?.errors !== undefined) {
        return Promise.reject({
          data: error?.response?.data,
        });
      }

      return Promise.reject(error?.response?.data?.message || error);
    },
  );
  return instance;
};
export default fetchClient();
