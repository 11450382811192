<template>
  <div
    id="TestA_pdp_mapcontainer"
    ref="sellerLocationMap"
    class="map-box"
  />
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import { closeSharp } from "ionicons/icons";
import { mapsApiKey } from "@/config";
// import { geocode } from "@/services/LocationService";

export default {
  name: "SellerLocationMap",
  components: {},
  props: ["lat", "lng", "zoomLevel", "address","geoCode"],
  data() {
    return {
      closeSharp,
      google: null,
      googleMapApi: null,
      map: null,
      marker: null,
      apiKey: mapsApiKey,
      getGeoCode: {},
    };
  },
  computed: {},
  watch: {
    zoomLevel: function () {
      if(Object.keys(this.geoCode).length > 0)
      this.getSellerGeoCode();
    },
    geoCode: function (){
      this.getSellerGeoCode();
    },
  },
  async mounted() {
    const googleMapApi = new Loader({
      apiKey: this.apiKey,
    });
    this.google = await googleMapApi.load();
  },
  methods: {
    async getSellerGeoCode() {
      const sellerGeoCode = {
        lat: parseFloat(this.geoCode.lat),
        lng: parseFloat(this.geoCode.lng),
      };
      if (this.google !== null) {
        this.initializeMap(sellerGeoCode);
      } else {
        const googleMapApi = new Loader({
          apiKey: this.apiKey,
        });
        this.google = await googleMapApi.load();
        this.initializeMap(sellerGeoCode);
      }
    },
    initializeMap(sellerGeoCode) {
      const mapContainer = this.$refs.sellerLocationMap;
      this.map = new this.google.maps.Map(mapContainer, {
        zoom: this.zoomLevel,
        maxZoom: 13,
        center: sellerGeoCode,
        disableDefaultUI: true,
        zoomControl: true,
      });
      this.marker = new this.google.maps.Marker({
        position: sellerGeoCode,
        icon: "/assets/sellerMap.webp",
        map: this.map,
      });
    },
  },
};
</script>

<style scoped>
.map-box {
  width: 100%;
  height: 100%;
}
</style>
