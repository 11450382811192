import GroupItemFilter from "./group/GroupItemFilter";
import ValueFilter from "./value/ValueFilter";
import RangeFilter from "./range/RangeFilter";
import AxlesItemFilter from "./group/AxlesItemFilter";
import SizeFilter from "./range/SizeFilter";
import WeightFilter from "./range/WeightFilter";
import PriceFilter from "./range/PriceFilter";
import SkirtedBedItemFilter from "./group/SkirtedBedItemFilter";
const filterDefs = {
  groupFilters: {
    category: GroupItemFilter,
    axles: AxlesItemFilter,
    manufacturer: GroupItemFilter,
    brand: GroupItemFilter,
    pull_type: GroupItemFilter,
    year: GroupItemFilter,
    construction: GroupItemFilter,
    condition: GroupItemFilter,
    availability: GroupItemFilter,
    cab_to_axle_length: GroupItemFilter,
    number_of_truck_axles: GroupItemFilter,
    skirted_bed: SkirtedBedItemFilter,
    body_type: GroupItemFilter,
    location_country: GroupItemFilter,
    color: GroupItemFilter,
  },
  valueFilters: {
    sale: ValueFilter,
    search: ValueFilter,
  },
  rangeFilters: {
    length: SizeFilter,
    width: SizeFilter,
    height: SizeFilter,
    price: PriceFilter,
    gvwr: WeightFilter,
    payload_capacity: WeightFilter,
  },
};


const toApiFields = function (filters) {
  const queryDict = {};
  for (const filter of Object.values(filters)) {
    if (filter instanceof GroupItemFilter || filter.group === "category") {
      if (queryDict[filter.group]) {
        queryDict[filter.group] += ";" + filter.value;
      } else {
        queryDict[filter.group] = filter.value;
      }
    } else if (filter instanceof RangeFilter) {
      queryDict[filter.name + "_min"] = filter.min;
      queryDict[filter.name + "_max"] = filter.max;
    } else if (filter instanceof ValueFilter) {
      queryDict[filter.name] = filter.value;
    }
  }
  return queryDict;
};

const fromApiFields = function (params) {
  let filters = [];
  const {groupFilters, valueFilters, rangeFilters} = filterDefs;
  for (const group in groupFilters) {
    if (params[group]) {
      filters = filters.concat(groupFilters[group].fromApiField(params[group], group));
    }
  }

  for (const filter in valueFilters) {
    const value = params[filter];
    if (value) {
      filters.push(new valueFilters[filter]({
        name: filter,
        value,
        label: filter === 'search' ? `Keyword - ${value}` : undefined,
      }));
    }
  }
  for (const filter in rangeFilters) {
    let paramValue = params[filter + "_min"];
    if (paramValue !== "" && paramValue !== undefined) {
      filters.push(
          new rangeFilters[filter]({
            name: filter,
            min: +params[filter + "_min"],
          }),
      );
    }
    paramValue = params[filter + "_max"];
    if (paramValue !== "" && paramValue !== undefined) {
      filters.push(
          new rangeFilters[filter]({
            name: filter,
            max: +params[filter + "_max"],
          }),
      );
    }
  }
  return filters;
};

const parseRangeFilters = (searchFilter, name) => {
  name = String(name);
  const filterName = name.replace("_min", "").replace("_max", "");
  const filter = searchFilter.find((f) => f.name === filterName)
  const isRangeFilter = filter instanceof RangeFilter;
  let rangeLimit = "";

  if (isRangeFilter && name.includes("_min")) {
    rangeLimit = "min";
  } else if (isRangeFilter && name.includes("_max")) {
    rangeLimit = "max";
  }

  return { rangeLimit, filterName };
};

export { fromApiFields, toApiFields, parseRangeFilters };
