export const POPULAR_URL = [
  [
  {
    name: "Gooseneck Flatbed",
    url: "/trailers-for-sale/gooseneck-flatbed-trailers-for-sale",
    query: {
      type_id: "1",
      category: "Equipment / Flatbed Trailers",
      pull_type: "gooseneck",
    },
    description: "Access a list of equipment or flatbed trailers that use a hitch for a gooseneck as pull type. Trucks are usually equipped with those gooseneck hitches.",
  },
  {
    name: "Gooseneck Cargo",
    url: "/trailers-for-sale/gooseneck-enclosed-trailers-for-sale",
    query: {
      type_id: "1",
      category: "Cargo (Enclosed) Trailers",
      pull_type: "gooseneck",
    },
    description: "Access a list of enclosed or cargo trailers that protect the load carried and that use a hitch for a gooseneck as pull type. Trucks are usually equipped with those gooseneck hitches.",
  },
  {
    name: "Trailers Near Me",
    url: "/trailers-for-sale/trailers-for-sale",
    query: { type_id: "1" },
    description: "Access a list of trailers across all categories that are located near you. You’ll want to share your browser location to have the optimal results. ",
  },
  {
    name: "Enclosed Trailers",
    url: "/trailers-for-sale/enclosed-trailers-for-sale",
    query: { type_id: "1", category: "Cargo (Enclosed) Trailers" },
    description: " Access all the enclosed trailers listed on TrailerTrader",

  },
],
[
  {
    name: "Gooseneck Horse Trailers",
    url: "/trailers-for-sale/gooseneck-horse-trailers-for-sale",
    query: { type_id: "2", category: "Horse Trailers", pull_type: "gooseneck" },
    description: "Access a list of large trailers that can carry multiple horses and optionally have living quarters requiring a truck with a gooseneck hitch. ",
  },
  {
    name: "Used Trailers",
    url: "/trailers-for-sale/all-used-trailers-for-sale",
    query: { condition: "used" },
    description: "Access all the used trailers listed on TrailerTrader",
  },
  {
    name: "Bumper Horse Trailers",
    url: "/trailers-for-sale/bumper-horse-trailers-for-sale",
    query: { type_id: "2", category: "Horse Trailers", pull_type: "bumper" },
    description: "Access a list of trailers that carry one or two horses and can be attached to a car. ",
  },
],
[
  {
    name: "Utility Trailers",
    url: "/trailers-for-sale/utility-trailers-for-sale",
    query: { type_id: "1", category: "Utility Trailers" },
    description: "Access a list of flatbed trailers with side panels to protect the load carried. The list contains utility trailers with various pull types.",
  },
  {
    name: "Dump Trailers",
    url: "/trailers-for-sale/dump-trailers-for-sale",
    query: { type_id: "1", category: "Dump Trailers" },
    description: "Access a list of large utility trailers with closed side panels that can tilt to offload. The list contains dump trailers with various pull types.",
  },
  {
    name: "RV - Recreational Vehicles",
    url: "/trailers-for-sale/camper-for-sale",
    query: { type_id: "3", category: "Camper / RV" },
    description: "Access a list of motorized recreational vehicles.",
  },
  {
    name: "Snowmobile Trailers",
    url: "/trailers-for-sale/snowmobile-trailers-for-sale",
    query: { type_id: "1", category: "Snowmobile Trailers" },
    description: "Access a list of specialized trailers dedicated to carrying snowmobiles with front panels to protect the snowmobiles from snow and projections. ",
  },
],
[
  {
    name: "Bumper Cargo",
    url: "/trailers-for-sale/bumper-enclosed-trailers-for-sale",
    query: {
      type_id: "1",
      category: "Cargo (Enclosed) Trailers",
      pull_type: "bumper",
    },
    description: "Access a list of enclosed or cargo trailers with a regular bumper hitch",
  },
  {
    name: "Travel Trailers",
    url: "/trailers-for-sale/travel-trailers-for-sale",
    query: { type_id: "3" },
    description: "Access a list of trailers with living quarters with regular vehicle hitches. ",
  },
  {
    name: "Gooseneck Car Hauler",
    url: "/trailers-for-sale/gooseneck-racing-trailers-for-sale",
    query: {
      type_id: "1",
      pull_type: "gooseneck",
    },
    description: "Access a list of large sports car enclosed trailers or flatbed car haulers requiring a gooseneck hitch.",
  },
  {
    name: "Bumper Equipment",
    url: "/trailers-for-sale/bumper-flatbed-trailers-for-sale",
    query: {
      type_id: "1",
      category: "Equipment / Flatbed Trailers",
      pull_type: "bumper",
    },
    description: "Access a list of smaller equipment or flatbed trailers that attach to a car’s hitch. ",
  },
],
[
  {
    name: "Gooseneck Toy Hauler",
    url: "/trailers-for-sale/gooseneck-toy-haulers-for-sale",
    query: { type_id: "3", category: "Toy Haulers", pull_type: "gooseneck" },
    description: "Access a list of large travel trailers with both living quarters and capacity to carry ATVs, motorcycles or any other “toy” requiring a truck with a gooseneck hitch. ",
  },
  {
    name: "Bumper Dump Trailers",
    url: "/trailers-for-sale/bumper-dump-trailers-for-sale",
    query: { type_id: "1", category: "Dump Trailers", pull_type: "bumper" },
    description: "Access a list of smaller dump trailers that attach to a car’s hitch. ",
  },
  {
    name: "Toy Haulers",
    url: "/trailers-for-sale/toy-haulers-for-sale",
    query: { type_id: "3", category: "Toy Haulers" },
    description: "Access a list of travel trailers with both living quarters and capacity to carry an ATV, motorcycle or light “toys”.",
  },
],
];
