<template>
  <div />
</template>
<script>
export default {
  name: "Tracker",
  watch: {
    $route() {
      this.trackPageVisit();
    },
  },
  mounted() {
    this.setBearerTokenToTtTrack();

    // Add timeout, so we don't run the tracking logic so fast
    // otherwise, we might send out the request before the visitor_id
    // is set to the object.
    setTimeout(() => {
      this.trackPageVisit();
    }, 100);
  },
  methods: {
    setBearerTokenToTtTrack() {
      const token = localStorage.getItem("token");

      if (token) {
        window.tt_track?.setBearerToken(token);
      }
    },
    trackPageVisit() {
      console.log(window.tt_track)
      window.tt_track?.pageView();
    },
  },
};
</script>
