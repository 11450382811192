<template>
  <ion-list>
    <div class="filter-panel">
      <ion-item lines="none">
        <h2 class="ion-text-uppercase title">
          Types
        </h2>
      </ion-item>
      <div
        v-for="(item, index) in categories"
        :key="index"
      >
        <ion-list>
          <div class="type">
            {{ TrailerCategories[item[0].type_id - 1].category }}
          </div>
          <div
            v-for="(cat, i) in item"
            :key="i"
            :class="cat.category === category.category ? 'category active' : 'category'"
            @click="selectCategory(index, i)"
          >
            {{ `${cat.category}(${cat.count})` }}
          </div>
        </ion-list>
      </div>
    </div>
  </ion-list>
</template>

<script>
import { defineComponent } from "vue";
import { IonList, IonItem } from "@ionic/vue";
import { trailerTypes } from "@/consts";

export default defineComponent({
  name: "CategorySideBar",
  components: {
    IonList,
    IonItem,
  },
  props: [ "categories", "category", "selectCategory" ],
  data() {
    return {
      TrailerCategories: trailerTypes,
    };
  },
});
</script>
