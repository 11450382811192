import RangeFilter from "./RangeFilter";

export default class SizeFilter extends RangeFilter {
  constructor({name, min, max}) {
    const label = SizeFilter.defaultLabel(name, min, max);
    const rangeLabels = SizeFilter.rangeLabelsList(name, min, max)
    super({name, min, max, label, rangeLabels});
  }
}

SizeFilter.valueFormatter = function (value) {
  const feet = Math.floor(Number(value));
  const inch = Number(String(value).split(".")?.[1] || 0);
  return `${feet}' ${inch}''`;
}

SizeFilter.defaultLabel = function (name, min, max) {
  const minFormatted = SizeFilter.valueFormatter(min);
  const maxFormatted = SizeFilter.valueFormatter(max);

  return `${minFormatted}<${name}<${maxFormatted}`;
}

SizeFilter.rangeLabelsList = function (name, min, max) {
  const ranges = [];
  if (min) {
    ranges.push({
      key: "min",
      value: `${name} > ${SizeFilter.valueFormatter(min)}`,
    });
  }
  if (max) {
    ranges.push({
      key: "max",
      value: `${name} < ${SizeFilter.valueFormatter(max)}`,
    });
  }

  return ranges;
};