<template>
  <!-- Start of AxlesFilter Accordion Setup -->
  <ion-row
    v-if="(axles || []).length > 0"
    class="collapsable-menu-custom"
  >
    <ion-col>
      <ion-list>
        <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
          <ion-item
            id="TestA_plp_filter_axles"
            lines="none"
            class="filter-item-head"
            :class="{ active }"
            @click="handleClick"
          >
            <div class="text-icon">
              <div class="ion-text-uppercase parent-text">
                Axles
              </div>
              <div class="d-flex">
                <span class="ion-text-uppercase selected-search ion-padding-end">
                  {{ selectedListString }}
                </span>
                <span :class="!active ? 'arrow-drop  rotate-caret' : 'arrow-drop'">
                  <img
                    alt="Trailers Near Me"
                    src="@/assets/icons/caretDownBlue.svg"
                  >
                </span>
              </div>
            </div>
          </ion-item>
          <template
            v-for="(item, index) in axles"
            :key="index"
          >
            <ion-item
              v-if="active"
              lines="none"
              :href="item.href"
              class="filter-collapse"
              @click="toggleFilter($event, 'axles', index)"
            >
              <ion-checkbox
                slot="start"
                ref="axles"
                :value="item.value"
                :checked="!!hasFilter('axles', item.value)"
                class="filter-checkbox"
              />
              <ion-label class="ion-text-uppercase child-text">
                {{ item.label }}
              </ion-label>
            </ion-item>
          </template>
        </div>
      </ion-list>
    </ion-col>
  </ion-row>
</template>

<script>
import { mapState } from "vuex";
import { ellipsizeList } from "@/utils/FilterListUtil";
import { IonCheckbox, IonCol, IonItem, IonList, IonRow, IonLabel } from "@ionic/vue";
import AxlesItemFilter from "../../models/filters/group/AxlesItemFilter";
import { filterActions } from "@/mixins";

export default {
  name: "AxlesFilter",
  components: {
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonCheckbox,
    IonLabel,
  },
  mixins: [filterActions],
  props: {
    active: Boolean,
  },
  emits: ["toggle"],
  computed: {
    ...mapState({
      searchFilter: (state) => state.searchFilter,
      searchApiFields: (state) => state.searchApiFields,
      aggregations: (state) => state.categoryAggregations,
    }),
    selectedList() {
      return this.searchFilter
        .filter((item) => item.name === "axles")
        .map((item) => item.label);
    },
    selectedListString() {
      return ellipsizeList(this.selectedList, 14);
    },
    axles() {
      const axles_values = [
        { name: "axles", value: "1", label: "1" },
        { name: "axles", value: "2", label: "2" },
        { name: "axles", value: AxlesItemFilter.AXLES_3_MORE_VALUE, label: AxlesItemFilter.AXLES_3_MORE_LABEL },
      ];
      return this.aggregations.filter_aggregations?.axles?.buckets?.map((a) => {
        return {
          ...axles_values[a.key - 1],
          href: this.generateHrefFromField(axles_values[a.key - 1].value, "axles"),
        };
      });
    },
  },
  methods: {
    handleClick() {
      this.$emit("toggle");
    },
  },
};
</script>
