export const ACTIVE_MENU = Object.freeze({
  MapViewFilters: "mapViewFilters",
  Navigation: "navigation",
  Filters: "filters",
  Categories: "categories",
});

export const MENU_CONTROLLER = Object.freeze({
  AppContent: "appContent",
  FilterContent: "filterContent",
  MapViewFilterContent: "mapViewFilterContent",
  CategoryContent: "categoryContent",
});
