<template>
  <ion-header class="ion-no-border">
    <ion-grid class="modal-header-save-search">
      <div class="save-search-modal">
        <div class="save-search-header">
          <span class="close-save-search-modal">
            <ion-icon
              :icon="closeSharp"
              aria-label="close modal"
              @click="closeModal"
            />
          </span>
        </div>
        <div class="save-search-content">
          <div class="save-search-logo">
            <img
              src="@/assets/saveSearchTrailer.webp"
              alt="@/assets/saveSearchTrailer.webp"
            >
          </div>
          <div class="save-search-description">
            <div class="save-this-search ion-text-uppercase">
              save this search
            </div>
            <span
              v-if="!isAuthenticated"
              class="save-this-search-description"
            >
              Register or login to TrailerTrader to save your search to the dashboard.
            </span>
            <span
              v-if="isAuthenticated"
              class="save-this-search-description"
            >
              Your search has been saved, in the future, you can access it from your dashboard.
            </span>
            <div
              class="buttons-group"
            >
              <ion-button
                v-if="!isAuthenticated"
                @click="openUserForm"
              >
                Register / Login
              </ion-button>
              <ion-button
                v-if="isAuthenticated"
                @click="goToSavedSearches"
              >
                See Saved Searches
              </ion-button>
              <ion-button
                v-if="isAuthenticated"
                @click="closeModal"
              >
                Close
              </ion-button>
            </div>
          </div>
        </div>
      </div>
    </ion-grid>
  </ion-header>
</template>

<script>
import {
  IonHeader,
  IonGrid,
  IonIcon,
  modalController,
  IonButton,
} from "@ionic/vue";
import { closeSharp } from "ionicons/icons";
import { mapState } from "vuex";
import UserBaseForm from "@/components/user/UserBaseForm.vue";
import { modal } from "@/mixins";
import { generateQueryStringFromObj } from "@/utils/UrlParamUtil";
import { saveSearch } from "@/services/ProductsService";
import { utagLink } from "@/services/TealiumService";

export default {
  name: "SaveSearch",
  components: {
    IonHeader,
    IonGrid,
    IonIcon,
    IonButton,
  },
  mixins: [modal],
  data() {
    return {
      closeSharp,
    };
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.isAuthenticated,
    }),
  },
  watch: {
    isAuthenticated(newVal) {
      if(newVal) {
        this.saveSearch();
      }
    },
  },
  mounted() {
    if(this.isAuthenticated) {
      this.saveSearch();
    }
  },
  methods: {
    async closeModal() {
      this.$store.commit("SET_REDIRECT_URL", null);
      await modalController.dismiss();
    },
    async openUserForm() {
      this.$store.commit("SET_REDIRECT_URL", "search");
      await this.openModal(UserBaseForm, {
        cssClass: "modal-user-base-form",
        backdropDismiss: true,
      });
    },
    async goToSavedSearches() {
      await this.closeModal();
      this.$router.push("/saved-searches");
    },
    async saveSearch() {
      const filtersObj = {
        ...this.$store.state.searchApiFields,
        location: this.$store.state.location,
        distance: this.$store.state.distance,
      };
      const search_query = `${window.location.origin}/trailers-for-sale?${generateQueryStringFromObj(filtersObj)}`;
      const search_result_count = this.$store.state.totalProducts;
      let search_name = this.$store.state.seo.head.title;
      search_name = search_name.slice(0, search_name.indexOf("For Sale")).trim() + (filtersObj.location ? `, ${filtersObj.location}` : '');

      await saveSearch({
        search_name,
        search_result_count,
        search_query,
      });

      utagLink(this.$route, {
        "tealium_event": "form_submit",
        "form_id": "save_search_form",
        "form_submission_email": event.email,
      });
    },
  },
};
</script>

<style scoped>
ion-button {
  margin-left: -10px;
  margin-top: 10px;
}
</style>
