import FilterBase from "./FilterBase";

export default class GeoLocationFilter extends FilterBase {
  constructor({ name, location, lat, lon, distance, label }) {
    super({ name, label });
    this.lat = lat;
    this.lon = lon;
    this.location = location;
    this.distance = distance;
  }
}
