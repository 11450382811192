const ellipsizeList = (list, maxChars) => {
  let prefixString = "";
  let suffixCount = 0;

  list.forEach((category) => {
    const updatedPrefixString = `${prefixString}${
      prefixString ? ", " : ""
    }${category}`;
    if (
      String(prefixString).length < maxChars &&
      String(updatedPrefixString).length <= maxChars
    ) {
      prefixString = updatedPrefixString;
    } else {
      suffixCount += 1;
    }
  });

  const suffixString = suffixCount ? `+${suffixCount}` : "";

  return `${prefixString} ${suffixString}`;
};

export { ellipsizeList };