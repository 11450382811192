<template>
  <ion-row
    v-if="isVisible"
    class="collapsable-menu-custom"
  >
    <ion-col>
      <ion-list>
        <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
          <ion-item
            id="TestA_plp_filter_gvwr"
            lines="none"
            class="filter-item-head"
            :class="{ active: isShown }"
            @click="toggle()"
          >
            <div class="text-icon">
              <div class="ion-text-uppercase parent-text">
                {{ name }}
                <Popper
                  :content="gvwrGlossary"
                  hover
                  arrow
                  placement="top"
                >
                  <span><img
                    style="margin-left: 6px"
                    src="@/assets/infobubble.webp"
                    alt="@/assets/infobubble.webp"
                  ></span>
                </Popper>
              </div>
              <div>
                <span
                  :class="!isShown ? 'arrow-drop  rotate-caret' : 'arrow-drop'"
                >
                  <img
                    alt="Trailers Near Me"
                    src="@/assets/icons/caretDownBlue.svg"
                  >
                </span>
              </div>
            </div>
          </ion-item>
          <div
            v-if="isShown"
            lines="none"
          >
            <range-filter
              :label="gvwrInfo.label"
              :name="gvwrInfo.name"
              :min="gvwrInfo.min"
              :max="gvwrInfo.max"
              :min-span="gvwrInfo.minSpan"
              :filter-class="WeightFilterModel"
              @submitted="handleValueSubmitted()"
            />
          </div>
        </div>
      </ion-list>
    </ion-col>
  </ion-row>
</template>

<script>
import { IonRow, IonCol, IonItem, IonList } from "@ionic/vue";
import { informationCircle } from "ionicons/icons";
import WeightFilterModel from "../../models/filters/range/WeightFilter";
import RangeFilter from "./RangeFilter.vue";
import { scrollPlpToTop } from "@/utils/ProductListingPageUtil";

export default {
  name: "GvwrFilter",
  components: {
    IonCol,
    IonRow,
    RangeFilter,
    IonList,
    IonItem,
  },
  props: {
    glossaryData: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      informationCircle,
      name: "GVWR",
      isShown: false,
      WeightFilterModel,
      lower: 0,
      upper: 0,
    };
  },
  computed: {
    isVisible() {
      const {
        filterLimits,
        searchApiFields: { type_id: activeTypeId },
      } = this.$store.state;
      return filterLimits?.gvwr && filterLimits.gvwr[activeTypeId];
    },
    gvwrInfo() {
      const {
        filterLimits,
        searchApiFields: { type_id: activeTypeId },
      } = this.$store.state;
      const min =
        (filterLimits?.gvwr && filterLimits.gvwr[activeTypeId]?.min) || 0;
      const max =
        (filterLimits?.gvwr && filterLimits.gvwr[activeTypeId]?.max) || 40000;
      return {
        name: "gvwr",
        label: "GVWR",
        min,
        max,
        minSpan: 1500,
      };
    },
    gvwrGlossary() {
      let shortDescription = "";
      shortDescription = this.glossaryData.filter(
        (item) => item.denomination === "GVWR",
      )[0]?.short_description;
      return shortDescription;
    },
  },
  methods: {
    toggle() {
      this.isShown = !this.isShown;
    },
    handleValueSubmitted() {
      scrollPlpToTop();
    },
  },
};
</script>

<style scoped>
:deep(.popper) {
  transform: translate(25px, -33px) !important;
  font-family: "Object Sans Heavy";
}

:deep(#arrow) {
  transform: translate(30px, 0) !important;
}
ion-list {
  contain: unset !important;
}
ion-item {
  overflow: visible;
}
</style>
