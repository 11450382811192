<template>
  <div
    :class="{
      'page-overlay mega-menu ion-hide-lg-down': showMegaMenuOverlay,
      'page-overlay': showOverlay || showProfileOverlay,
    }"
  ></div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      showOverlay: (state) => state.showOverlay,
      showProfileOverlay: (state) => state.showProfileOverlay,
      showMegaMenuOverlay: (state) => state.showMegaMenuOverlay,
      filterCount: (state) => state.searchFilter.length,
    }),
  },
};
</script>
