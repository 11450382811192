<template>
  <div class="hide-tablet-mobile filter-side-bar">
    <filter-list>
      <template #types>
        <simple-filter-list />
      </template>
    </filter-list>
    <ion-row
      class="results-button-row collapsable-menu-custom sticky-bottom"
      :style="!isDesktop ? resultsButtonOffset : ''"
    >
      <ion-col>
        <div class="results-button-parent">
          <ion-button 
            class="results-button btn-inactive"
            disabled
          >
            Now listing {{ totalProducts > 0 ? totalProducts : "0" }} Results
            <ion-icon :icon="chevronForwardOutline" />
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { IonButton, IonCol, IonRow, IonIcon } from "@ionic/vue";
import { closeSharp, chevronForwardOutline } from "ionicons/icons";
import FilterList from "./FilterList.vue";
import SimpleFilterList from "./SimpleFilterList.vue";
import { mapState } from "vuex";
export default defineComponent({
  name: "AllCategoriesFilters",
  components: {
    FilterList,
    SimpleFilterList,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
  },
  props: {
    height: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: true,
      closeSharp,
      chevronForwardOutline,
    };
  },
  computed: {
    ...mapState({
      totalProducts: (state) => state.totalProducts,
      searchFilter: (state) => state.searchFilter,
    }),
    resultsButtonOffset() {
      return this.$store.getters.resultsButtonOffset;
    },
    isSearchFilterEmpty() {
      return Object.keys(this.searchFilter).length === 0;
    },
  },
  methods: {
  },
});
</script>
