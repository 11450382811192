import FilterBase from "../FilterBase";

export default class GroupItemFilter extends FilterBase {
  constructor({name, value, label}) {
    if(!label) {
      label = GroupItemFilter.defaultLabel(value);
    }
    super({name, label});
    this.value = value;
    this.isGroup = true;
  }
}

const self = GroupItemFilter;

GroupItemFilter.fromApiField = function (field, group) {
  const filters = [];
  let valueList = field.split(';');
  for (const value of valueList) {
    filters.push(new self({
      name: group,
      value,
    }))
  }
  return filters;
}

GroupItemFilter.defaultLabel = (value) => {
  return value.replaceAll("_", " ");
}