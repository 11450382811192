import { isSSR } from "@/utils/SSRUtil";
import { websiteBaseUrl } from "@/config";

const state = () => ({
  head: {},
});

const mutations = {
  SET_SEO_HEAD(state, value) {
    state.head = value;
  },
};

const actions = {
  /**
   * This method takes in the payload object and set appropriate tags using the given values.
   *
   * The developer can override the object that will be set in the SET_SEO_HEAD method by sending
   * it in the `extras` field.
   *
   * @typedef {Object} SetMetaTagsPayload
   * @property {string} title - Title for tab, crawler, and social media
   * @property {string} description - Description for crawler and social media
   * @property {string} keywords - Keyword for crawler
   * @property {string} image - Image URL to show on social media
   * @property {object|undefined} extras - Any extra properties to put in the head object
   *
   * @param commit
   * @param {SetMetaTagsPayload} payload
   */
  setMetaTags({ commit }, payload) {
    // Extras key allows the action caller to send  things like `link` for canonical and anything else
    const extras = payload.extras ?? {};

    let meta = [
      {
        name: "description",
        content: payload.description ?? "",
      },
      {
        name: "keywords",
        content: payload.keywords ?? "",
      },
      {
        name: "og:title",
        content: payload.title ?? "",
      },
      {
        property: "og:description",
        content: payload.description ?? "",
      },
      {
        property: "og:image",
        content: payload.image ?? "",
      },
      {
        property: "og:image:alt",
        content: payload.title ?? "",
      },
      {
        property: "og:url",
        content: (isSSR() ? (payload.url ?? websiteBaseUrl) : window?.location?.href) ?? "",
      },
      {
        name: "twitter:title",
        content: payload.title ?? "",
      },
      {
        property: "twitter:description",
        content: payload.description ?? "",
      },
      {
        property: "twitter:image",
        content: payload.image ?? "",
      },
      {
        property: "og:type",
        content: payload.type ?? "website",
      },
    ];

    // If noindex key is in the payload, then we'll set it properly
    if (payload.noindex) {
      meta.push({
        property: "robots",
        content: "noindex",
      });
    }

    commit("SET_SEO_HEAD", {
      meta,
      title: payload.title,
      ...extras,
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
