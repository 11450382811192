import FilterBase from "../FilterBase";

export default class ValueFilter extends FilterBase {
  constructor({name, value, label}) {
    if(!label) {
      label = ValueFilter.defaultLabel(name);
    }
    super({name, label});
    this.value = value;
  }
}

ValueFilter.defaultLabel = (value) => {
  return value;
}