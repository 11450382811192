<template>
  <ion-header class="ion-no-border">
    <ion-grid class="modal-user-base-form ion-no-padding">
      <span class="close-modal">
        <ion-icon
          :icon="closeSharp"
          aria-label="close dialog"
          @click="closeModal"
        />
      </span>
      <ion-row class="ion-no-padding">
        <!-- Sign In -->
        <ion-col
          v-if="onToggle"
          size="6"
          class="btn-inactive"
          @click="onToggle = !onToggle"
        >
          <ion-text class="txt">
            Sign In
          </ion-text>
        </ion-col>
        <ion-col
          v-else
          size="6"
          class="btn-active"
        >
          <ion-text class="txt">
            Sign In
          </ion-text>
        </ion-col>
        <!-- register -->
        <ion-col
          v-if="!onToggle"
          size="6"
          class="btn-inactive"
          @click="onToggle = !onToggle"
        >
          <ion-text class="txt">
            Register
          </ion-text>
        </ion-col>
        <ion-col
          v-else
          size="6"
          class="btn-active"
        >
          <ion-text class="txt">
            Register
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="limit-height">
        <ion-col
          v-if="!onToggle"
          size="12"
          class="ion-padding-bottom ion-padding-horizontal login-form-container"
        >
          <!-- Sign In component goes here -->
          <LoginForm />
        </ion-col>
        <ion-col
          v-else
          size="12"
          class=""
        >
          <!-- Register component goes here -->
          <RegistrationForm />
          <div class="registration-form-wrapper">
            <div class="registration-form">
              <SocialAuth />
            </div>
          </div>
        </ion-col>
        <ion-col
          v-if="onToggle"
          size="12"
          class=""
          style="text-align: center"
        >
          <!-- Social login component goes here -->
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-header>
</template>

<script>
import { IonGrid, IonHeader, IonRow, IonCol, IonText, modalController, IonIcon } from "@ionic/vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";
import LoginForm from "@/components/user/login/LoginForm.vue";
import RegistrationForm from "@/components/user/registration/RegistrationForm.vue";
import { closeSharp } from "ionicons/icons";
import SocialAuth from "@/components/user/SocialAuth.vue";

export default defineComponent({
  name: "UserBaseForm",
  components: { IonGrid, SocialAuth, LoginForm, IonHeader, IonRow, IonCol, IonText, RegistrationForm, IonIcon },
  // eslint-disable-next-line vue/require-prop-types
  props: ["isPrivate"],
  data() {
    return {
      closeSharp,
      onToggle: !!this.isPrivate,
    };
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.isAuthenticated,
    }),
  },
  watch: {
    "$store.state.userFormToggle": function () {
      this.onToggle = !this.onToggle;
    },
  },
  methods: {
    closeModal() {
      modalController.dismiss();
    },
  },
});
</script>
