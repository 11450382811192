/**
 * Add static header contents here. For dynamic header contents, please move to each page component
 * @param route
 * @returns {{meta: [{property: string, content: string},{property: string, content: string},{property: string, content: string}], title: string}}
 */
import { websiteBaseUrl } from "@/config";

export default (route) => {
  if (route.meta.title) {
    return {
      title: route.meta.title,
      meta: [
        {
          property: "title",
          content: route.meta.title,
        },
        {
          property: "description",
          content:
            "Find used and new trailers for sale near you on America’s largest trailer and RV marketplace. Trusted by sellers and buyers since 2003",
        },
        {
          property: "og:title",
          content: "Largest selection of trailers and recreational vehicles in America | TrailerTrader | Select from 100,000s of new and used listings.",
        },
        {
          property: "og:description",
          content:
            "Find trailers and RVs for sale near you. Select from over 150,000 trailers and recreational vehicles from Airstream, Jayco, Load Trail, PJ Trailers, Forest River, Coachmen, Travel Lite, Keystone, Big Tex, Aluma, Dutchmen, and hundreds of more brands.",
        },
        {
          property: "og:image",
          content: `${websiteBaseUrl}/assets/TrailerTraderLogo.webp`,
        },
        {
          property: "og:url",
          content: `${websiteBaseUrl}${route?.path ?? ''}`,
        },
        {
          property: "twitter:title",
          content: route.meta.title,
        },
        {
          property: "twitter:description",
          content:
            "Find trailers and RVs for sale near you. Select from over 150,000 trailers and recreational vehicles from Airstream, Jayco, Load Trail, PJ Trailers, Forest River, Coachmen, Travel Lite, Keystone, Big Tex, Aluma, Dutchmen, and hundreds of more brands.",
        },
        {
          property: "twitter:image",
          content: `${websiteBaseUrl}/assets/TrailerTraderLogo.webp`,
        },
      ],
    };
  }
};
