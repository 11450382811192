<template>
  <!-- Desktop -->
  <ion-grid class="location-bar location-bar-desktop">
    <ion-row class="content-container lr-padding">
      <ion-col
        v-click-outside="clickConfig"
        size="3"
        push="9"
        style="background: #ffffff; padding: 10px; border: 2px solid #bccbdb"
        class="ion-text-end ion-float-right ion-no-padding"
      >
        <ion-row>
          <!-- city input -->
          <ion-col size="12">
            <ion-item
              lines="none"
              class="location-input"
              :class="{ 'item-has-value': !!location }"
            >
              <ion-label
                class="location-label"
                position="floating"
              >
                City or Zip, then scroll to select
              </ion-label>
              <ion-input
                v-model="location"
                debounce="300"
                aria-label="Input City or Zip, then scroll to select"
                @click="locationClick"
              />
              <img
                slot="end"
                style="margin-left: 10px; margin-top: 16px"
                alt="caretDown"
                src="@/assets/icons/location-point.svg"
                @click="getBrowserLocation"
              >
            </ion-item>
            <ion-list v-if="showAutocomplete">
              <ion-item
                v-for="(item, index) in address"
                :key="index"
                class="location-list"
                lines="none"
                @click="selectLocation(item.address)"
              >
                <ion-label>{{ item.address.label }}</ion-label>
              </ion-item>
            </ion-list>
            <ion-list
              v-if="emptyData"
              class="empty-list"
            >
              <ion-item
                lines="none"
                class="location-list-empty"
              >
                <ion-label>No Results Found</ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <!-- distance input -->
          <ion-col size="12">
            <ion-item
              lines="none"
              class="location-input"
              @click="showDistanceList"
            >
              <IonLabel
                class="location-label"
                position="floating"
              >
                Search Distance
              </IonLabel>
              <ion-input
                v-if="distance === nationwideDistance"
                aria-label="Input Search Distance"
                readonly
                :value="nationwide"
              />
              <ion-input
                v-else
                readonly
                aria-label="Input Search Distance"
                :value="`Within ${formatDistance(distance)}`"
              />
              <span
                slot="end"
                :class="!showDistance ? '' : 'rotate-caret'"
              >
                <img
                  style="margin-top: 14px; margin-right: 5px"
                  alt="caretDown"
                  src="@/assets/icons/caretBlue.svg"
                ></span>
            </ion-item>
            <ion-list v-if="showDistance">
              <ion-item
                v-for="val in distanceList"
                :key="val"
                class="location-list"
                lines="none"
                @click="selectDistance(val)"
              >
                <ion-label v-if="val === nationwideDistance">
                  {{ nationwide }}
                </ion-label>
                <ion-label v-else>
                  Within {{ formatDistance(val) }}
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <!-- buttons -->
          <ion-col
            size="12"
            class="ion-no-padding"
          >
            <ion-row>
              <ion-col size="6">
                <ion-button
                  class="btn btn-cancel"
                  @click="clearLocation"
                >
                  Clear
                </ion-button>
              </ion-col>
              <ion-col size="6">
                <ion-button
                  v-if="isValid"
                  class="btn btn-submit"
                  @click="saveForm"
                >
                  save
                  <ion-icon :icon="chevronForwardOutline" />
                </ion-button>
                <ion-button
                  v-else
                  class="btn btn-inactive"
                >
                  save
                  <ion-icon :icon="chevronForwardOutline" />
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
  <!-- Mobile -->
  <ion-grid class="location-bar location-bar-mobile">
    <div
      v-click-outside="mobileClickConfig"
      class="formBoxMobile"
    >
      <ion-row>
        <ion-col
          size="12"
          class="ion-text-start"
        >
          <div class="location-txt">
            Location
          </div>
        </ion-col>
        <ion-col
          size="12"
          class=""
        >
          <div class="ion-text-nowrap location-input">
            <ion-grid class="ion-no-padding">
              <ion-row>
                <ion-col
                  size-xs="10"
                  size="11"
                  class=""
                >
                  <div class="ios-margin">
                    <ion-item
                      lines="none"
                      class="ion-text-nowrap floating-input"
                    >
                      <ion-label
                        class="location-label"
                        position="floating"
                      >
                        City or Zip, then scroll to select
                      </ion-label>
                      <ion-input
                        v-model="location"
                        debounce="300"
                        aria-label="Input City or Zip, then scroll to select"
                        @click="locationClick"
                      />
                    </ion-item>
                  </div>
                </ion-col>
                <ion-col
                  size-xs="2"
                  size="auto"
                  class="ion-text-center"
                >
                  <img
                    class="img-location"
                    alt="caretDown"
                    src="@/assets/icons/location-point.svg"
                    @click="getBrowserLocation"
                  >
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <ion-list v-if="showAutocomplete">
            <ion-item
              v-for="(item, index) in address"
              :key="index"
              class="location-list"
              lines="none"
              @click="selectLocation(item.address)"
            >
              <ion-label>{{ item.address.label }}</ion-label>
            </ion-item>
          </ion-list>
          <ion-list
            v-if="emptyData"
            class="empty-list"
          >
            <ion-item
              lines="none"
              class="location-list-empty"
            >
              <ion-label>No Results Found</ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
        <!-- distance input -->
        <ion-col size="12">
          <div class="ion-text-nowrap location-input">
            <ion-grid class="ion-no-padding">
              <ion-row>
                <ion-col
                  size-xs="10"
                  size="11"
                  class=""
                >
                  <div class="ios-margin">
                    <ion-item
                      lines="none"
                      class="floating-input"
                      @click="showDistanceList"
                    >
                      <ion-label
                        class="location-label"
                        position="floating"
                      >
                        Search Distance
                      </ion-label>
                      <ion-input
                        v-if="distance === nationwideDistance"
                        readonly
                        aria-label="Input Search Distance"
                        :value="nationwide"
                      />
                      <ion-input
                        v-else
                        readonly
                        aria-label="Input Search Distance"
                        :value="formatDistance(distance)"
                      />
                    </ion-item>
                  </div>
                </ion-col>
                <ion-col
                  size-xs="2"
                  size="auto"
                  class="ion-text-center"
                >
                  <span
                    :class="!showDistance ? '' : 'rotate-caret'"
                    @click="showDistanceList"
                  >
                    <img
                      class="img-caret"
                      alt="caretDown"
                      src="@/assets/icons/caretBlue.svg"
                    >
                  </span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <ion-list v-if="showDistance">
            <ion-item
              v-for="val in distanceList"
              :key="val"
              class="location-list"
              lines="none"
              @click="selectDistance(val)"
            >
              <ion-label v-if="val === nationwideDistance">
                {{ nationwide }}
              </ion-label>
              <ion-label v-else>
                Within {{ formatDistance(val) }}
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
        <ion-col
          size="12"
          class="ion-no-padding"
        >
          <ion-row>
            <ion-col size="6">
              <ion-button
                class="btn btn-cancel"
                @click="clearLocation"
              >
                Clear
              </ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button
                v-if="isValid"
                class="btn btn-submit"
                @click="saveForm"
              >
                save
                <ion-icon :icon="chevronForwardOutline" />
              </ion-button>
              <ion-button
                v-else
                class="btn btn-inactive"
              >
                save
                <ion-icon :icon="chevronForwardOutline" />
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList } from "@ionic/vue";
import { chevronForwardOutline, closeSharp } from "ionicons/icons";
import { autocomplete, reverseGeocode } from "@/services/LocationService";
import { mapActions } from "vuex";
import GeoLocationFilter from "../models/filters/GeoLocationFilter";
import { formatDistance } from "@/utils/DistanceUtil";
import { DISTANCE_LIST, NATIONWIDE_DISTANCE, NATIONWIDE } from "@/consts/distance";
import { scrollPlpToTop } from "@/utils/ProductListingPageUtil";

export default {
  name: "LocationBarForm",
  components: {
    IonCol,
    IonGrid,
    IonRow,
    IonIcon,
    IonButton,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
  },
  emits: ["is-open", "is-save", "is-outside-click"],
  data() {
    return {
      chevronForwardOutline,
      closeSharp,
      location: "",
      distance: "100mi",
      nationwideDistance: NATIONWIDE_DISTANCE,
      nationwide: NATIONWIDE,
      distanceList: DISTANCE_LIST,
      setLocation: false,
      showAutocomplete: false,
      gpsClick: false,
      showDistance: false,
      address: [],
      isValid: false,
      emptyData: false,
      geoLatLng: [],
      mobileClickConfig: {
        events: ['click'],
        capture: true,
        handler: this.clickOutsideMobile,
      },
      clickConfig: {
        events: ['click'],
        capture: true,
        handler: this.clickOutside,
      },
    };
  },
  computed: {},
  watch: {
    location: async function (newVal) {
      if (newVal?.length > 2 && this.setLocation === false && this.gpsClick === false) {
        //make request to api server
        const { data } = await autocomplete({
          q: newVal,
        });
        this.address = this.sanitizeData(data.data);
        this.emptyData = true; // hide empty results
        //handle empty data
        if (this.address.length === 0) {
          this.showAutocomplete = false;
          this.emptyData = true;
          this.isValid = false;
          return;
        }
        this.showAutocomplete = true;
        this.emptyData = false;
        this.gpsClick = false;
        this.isValid = false;
      } else {
        this.gpsClick = false;
        this.setLocation = false;
        this.showAutocomplete = false;
      }
    },
  },
  mounted() {
    const { location, distance, userGeoLocation } = this.$store.state;

    if (location !== "") {
      this.location = location;
      this.setLocation = true;
      this.isValid = true;
    }

    if (distance !== "") {
      this.distance = distance;
    }

    if (userGeoLocation?.length !== 0) {
      this.geoLatLng = userGeoLocation;
    }

    if (this.$route.params?.location) {
      this.location = this.$route.params?.location;
      this.saveForm();
    }
  },
  methods: {
    ...mapActions(["addSearchFilter", "clearSearchFilter"]),
    sanitizeData(data) {
      //remove null values from data
      let arr = [];
      if (data.length === 0) return arr;

      for (const element of data) {
        if (element.address.label !== null && element.address.label.split(",").length > 2) {
          arr.push(element);
        }
      }

      return arr;
    },
    clearLocation() {
      /**
       * The dispatch method doesn't work here for some reason
       * so I need to use removeItem and commit functions directly
       */
      localStorage.removeItem("location");
      localStorage.removeItem("userGeolocation");

      this.$store.commit("SET_LOCATION", null);
      this.$store.commit("SET_USER_GEOLOCATION", null);

      this.clearSearchFilter("location");
      this.clearSearchFilter("lat");
      this.clearSearchFilter("lon");

      this.location = "";
      this.geoLatLng = [];
      this.saveForm();
    },
    updateSearchFilter() {
      this.addSearchFilter(
        new GeoLocationFilter({
          name: "geoLocation",
          label: "GeoLocation",
          lat: this.geoLatLng[0],
          lon: this.geoLatLng[1],
          location: this.location,
          distance: this.distance,
        }),
      );
    },
    saveForm() {
      this.$store.dispatch("setLocation", this.location);
      this.$store.commit("SET_DISTANCE", this.distance);
      this.$store.dispatch("setUserGeolocation", this.geoLatLng);
      this.$emit("is-save", {
        location: this.location,
        distance: this.distance,
      });
      this.$emit("is-open", false);
      this.updateSearchFilter();

      scrollPlpToTop();
    },
    clickOutside(event) {
      if (this.$store.state.showOverlay && this.isDesktop) {
        event.stopPropagation();
        this.$emit("is-open", false);
      }
    },
    clickOutsideMobile(event) {
      if (this.$store.state.showOverlay && !this.isDesktop) {
        event.stopPropagation();
        this.$emit("is-open", false);
      }
    },
    selectLocation(i) {
      this.setLocation = true;
      this.location = `${i.label.split(",")[0].trim()}, ${i.label.split(",")[1].trim()}`;
      this.isValid = true;
      this.geoLatLng = [parseFloat(i.latitude), parseFloat(i.longitude)];
    },
    selectDistance(i) {
      this.showDistance = false;
      this.distance = `${i}`;
    },
    showDistanceList() {
      this.showAutocomplete = false;
      this.emptyData = false;
      this.showDistance = !this.showDistance;
    },
    formatDistance: formatDistance,
    locationClick() {
      this.showDistance = false;
    },
    getBrowserLocation() {
      this.gpsClick = true;

      if (!navigator.geolocation) {
        return;
      }

      navigator.geolocation.getCurrentPosition(async (position) => {
        const { data: geocodeResponse } = await reverseGeocode({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });

        const geocodeLocation = geocodeResponse.data[0];

        const countryCode = geocodeLocation.address.countryCode.trim();

        // We won't process if the country is not US and Canada
        if (!["US", "CA"].includes(countryCode)) {
          return;
        }

        const postalCode = geocodeLocation.address.postalCode;

        // If we get the postal code back from the geocode API, we'll use this postal code
        // to fetch latitude and longitude from the autocomplete API
        if (postalCode !== null) {
          const { data: autocompleteResponse } = await autocomplete({
            q: postalCode,
          });

          // Do nothing if the autocomplete response is empty
          if (autocompleteResponse.data.length === 0) {
            return;
          }

          const address = autocompleteResponse.data[0].address;

          this.location = `${address.city.trim()}, ${address.state.trim()}`;
          this.geoLatLng = [address.latitude, address.longitude];
        } else {
          // Some locations don't have the postal code provided back, in this case
          // we need to use the latitude and longitude provided by the geocode API

          this.location = `${geocodeLocation.address.city.trim()}, ${geocodeLocation.address.stateCode}`;
          this.geoLatLng = [geocodeLocation.position.lat, geocodeLocation.position.lng];
        }

        this.showAutocomplete = false;
        this.isValid = true;
      });
    },
  },
};
</script>
