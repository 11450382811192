<template>
  <ion-grid class="modal-header-wrong-password">
    <div
      v-if="location"
      class="wrong-password-modal"
    >
      <div class="wrong-password-info">
        Your email or password is incorrect
      </div>
      <ion-col
        size-md="4"
        size="12"
      >
        <ion-button
          class="btn btn-submit custom-button"
          @click="closeModal"
        >
          ok
        </ion-button>
      </ion-col>
    </div>
    <div
      v-else
      class="wrong-password-modal"
    >
      <div class="wrong-password-info long-txt">
        You have entered the wrong password, please enter the password that you used for registration.
      </div>
      <div class="ion-padding forgot-password-link">
        <span
          class="ion-text-uppercase ion-text-center d-block"
          @click="openForgotPasswordModal"
        >forgot
          password?</span>
      </div>
      <ion-col
        size-md="4"
        size="12"
      >
        <ion-button
          class="btn btn-submit custom-button"
          @click="closeModal"
        >
          ok
        </ion-button>
      </ion-col>
    </div>
  </ion-grid>
</template>

<script>
import { IonGrid, IonCol, IonButton, modalController } from "@ionic/vue";
import ForgotPassword from "@/components/user/ForgotPassword.vue";
import { modal } from "@/mixins";

export default {
  components: {
    IonGrid,
    IonCol,
    IonButton,
  },
   mixins: [modal],
  props: {
    location: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    async closeModal() {
      await modalController.dismiss();
    },
    openForgotPasswordModal() {
      this.openModal(ForgotPassword, {
        cssClass: "modal-header-forgot-password",
        backdropDismiss: true,
      });
    },
  },
};
</script>
