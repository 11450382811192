import http from "./http-client";
import Price from "../models/Price";
import { isSSR } from "@/utils/SSRUtil";
import { $store } from "@/services/StoreService";

const sortMappings = {
  in_distance: "distance",
  "-createdAt": "-createdAt",
};

const PRIVATE_SELLER = 8410;

const trackListInventoryImpression = async (products) => {
  if (!isSSR()) {
    const meta = products?.map((product) => ({
      dealer_id: parseInt(product.dealer_id),
      inventory_id: parseInt(product.id),
      stock: product.stock,
      category: product.category,
      category_label: product.category_label,
      type_id: product.type_id,
      type_label: product.type_label,
      title: product.title,
      is_private: product.dealer?.is_private,
    }));

    await window.tt_track?.impression({
      meta,
    });
  }
};

export class ProductsLoader {
  constructor() {
    this.shuffleSize = 60;
    this._shuffledStocks = [];
    this._params = null;
    this._products = [];
    this._pendingRequest = null;
    this._categoryRetryCount = 0;
    this._total = 0;
    this._totalPages = 0;
    this._tracked = 0;
    this._didReadAll = false;
  }

  clearCache() {
    this._shuffledStocks = [];
    this._products = [];
    this._params = null;
	  this._total = 0;
    this._totalPages = 0;
    this._tracked = 0;
    this._didReadAll = false;
  }

  willNewSearch(params) {
    const searchParams = this._extractSearchParams(params);
    return this._isNewSearch(searchParams);
  }

  _isNewSearch(searchParams) {
    return !this._params || JSON.stringify(searchParams, Object.keys(searchParams).sort()) !==
        JSON.stringify(this._params, Object.keys(this._params).sort());
  }

  async getProducts(params, searchByLocation = false, cache = false) {
    const numProd = params.page * params.per_page;
    const searchParams = this._extractSearchParams(params);
    if (this._isNewSearch(searchParams)) {
      this.clearCache();
    }
    this._params = searchParams;

    try {
      if (this._pendingRequest) {
        this._pendingRequest.abort();
      }

      if (!this._didReadAll && this._products.length < numProd) {
        if (!isSSR()) {
          this._pendingRequest = new AbortController();
        }
        await this.fetchProducts(params, this._pendingRequest?.signal, searchByLocation, cache);
        this._pendingRequest = null;
      }

      this._currentPage = params.page;
      if (isSSR()) {
        return [...this._products];
      } else {
        return this._getCachedProducts(0, numProd);
      }
    } catch (err) {
      if (this._categoryRetryCount > 0) {
        this._categoryRetryCount = 0;
        return this._getCachedProducts(0, numProd);
      }
      if (err.response && err.response?.data?.message === "No category was selected" && params.category) {
        this._categoryRetryCount += 1;
        throw {
          invalidCategory: true,
        };
      }
      return this._getCachedProducts(0, numProd);
    }
  }

  async getPageProducts(params, searchByLocation = false, cache = false) {
    try {
      const products = await this.getProducts(params, searchByLocation, cache);
      const to = params.page * params.per_page;
      return products.slice(to - params.per_page, to);
    } catch (err) {
      console.log(err);
    }
  }

  _trackCachedProducts(to) {
    if(this._tracked < to) {
      trackListInventoryImpression(this._products.slice(this._tracked, to));
      this._tracked = to;
    }
  }

  _getCachedProducts(from, to) {
    this._trackCachedProducts(to);

    return this._products.slice(from, to);
  }

  sortNeedShuffle(sort) {
    if(isSSR()) {
      return false;
    } else {
      return ["in_distance", "-createdAt"].includes(sort);
    }
  }

  _extractSearchParams(params) {
    const result = { ...params, page: undefined};
    for (let key in result) {
      if (result[key] === undefined) {
        delete result[key];
      }
    }
    return result;
  }

  async fetchProducts(params, signal, searchByLocation, cache = false) {
    let apiParams;
    let isShuffleLoading = false;
    if (this.sortNeedShuffle(params.sort)) {
      apiParams = {
        ...params,
        sort: sortMappings[params.sort ? params.sort : '-createdAt'],
      };
      if (this._products.length === 0) {
        apiParams.page = 1;
        apiParams.per_page = this.shuffleSize;

        // If the sort is in_distance, we'll not sort it by distance in the first 4 page
        apiParams.sort = params.sort === "in_distance"
            ? "-hasImage"
            : `-hasImage;${apiParams.sort}`;
        isShuffleLoading = true;
      } else {
        const numShufflePages = Math.ceil(this._shuffledStocks.length / params.per_page);
        apiParams.exclude_stocks = this._shuffledStocks;
        apiParams.page -= numShufflePages;
      }
    } else {
      apiParams = {...params};

      // If the sort is in_distance, we'll not sort it by distance in the first 4 page
      apiParams.sort = params.sort === "in_distance"
        ? "-hasImage"
        : `-hasImage;${apiParams.sort ? apiParams.sort : '-createdAt'}`;
    }

    if(!this.isProgressivePage(params.page, params.per_page) && !isSSR()) {
      isShuffleLoading = false;
      apiParams.page = 1;
      apiParams.per_page = params.page * params.per_page;
    }

    if (cache) {
      apiParams.cache = "1";
    }

    const numLoaded = await this.loadProductsAndUpdate(searchByLocation, apiParams, isShuffleLoading, signal)
    this._didReadAll = this._products.length === this._total || numLoaded < apiParams.per_page;

    if(isShuffleLoading) {
      this._shuffledStocks = this.getProductStocks(this._products);
    }
  }

  isProgressivePage(page, perPage) {
    return this._products.length >= (page - 1) * perPage;
  }

  async loadProductsAndUpdate(searchByLocation, apiParams, shouldShuffle, signal) {
    const url = searchByLocation ? "/inventory/list-by-location" : "/inventory";
    
    let payload = await http.get(url, { params: apiParams, signal });
    const site_url = window.location.pathname;
    while(payload.data?.status_code) {
      $store.dispatch("setToastNotification", {
        open: true,
        text: "Slow network speed inhibits the access of our inventory database. We’ll try again in 5 seconds.",
        type: "danger",
      });
      if(site_url !== window.location.pathname) {
        return 0;
      }
      payload = await http.get(url, { params: apiParams, signal });
    }

    let countOfSales = 0;
    if(!apiParams?.sales) {
      const payloadForSales = await http.get(url, { params: { ...apiParams, sale: 1, per_page: 1 }, signal });
      countOfSales = payloadForSales.data.data.aggregations.filter_aggregations.doc_count;
    }

    const inventories = payload.data.data.inventories;
    trackListInventoryImpression(inventories).then(() => {});

    inventories.forEach((product) => {
      return Object.assign(product, {
        visible_price: getProductPrice(product),
      });
    });

    this.updateProductCache(payload.data, shouldShuffle, countOfSales);
    return inventories.length
  }

  updateProductCache(payload, shouldShuffle, countOfSales) {
    const products = payload.data?.inventories;

    // If it's the call that has stocks excluded, we can ignore the total from it
    // and use only the total from the original call
    if (this._shuffledStocks.length === 0) {
      this._total = payload.data.meta?.pagination.total;
    }

    if(products.length > 0) {
      this._aggregations = { ...payload.data.aggregations, count_of_sales: countOfSales };
    }
    if (shouldShuffle) {
      /* Shuffle Products */
      const shuffledProduct = [];

      // Aggregate by dealers
      const dealerProducts = {
        [PRIVATE_SELLER]: [],
      };
      products.forEach((product) => {
        const entity = { ...product, updated_at: new Date(product.updated_at) };
        if(product.dealer.is_private) {
          dealerProducts[PRIVATE_SELLER].push(entity);
        } else {
          if (!(product.dealer_id in dealerProducts)) {
            dealerProducts[product.dealer_id] = [];
          }
          dealerProducts[product.dealer_id].push(entity);
        }
      });
      if(dealerProducts[PRIVATE_SELLER].length === 0) {
        delete dealerProducts[PRIVATE_SELLER];
      }

      // Sorting
      for (const dealer_id in dealerProducts) {
        dealerProducts[dealer_id].sort((p1, p2) => {
          if (p1 > p2) return 1;
          else if (p1 < p2) return -1;
          return 0;
        });
      }

      // Random picking per Francois' diagram
      // @see https://docs.google.com/presentation/d/16qA8mHeqZB-fDkV_rbij_Z_EdGIgEIEsXio5xMMYcNs/edit#slide=id.p
      for (let i = 0; i < products.length; i++) {
        // Get array of dealer ID, index 1 - N per diagram
        const dealers = Object.keys(dealerProducts);

        // Random one index from 1 - N
        const randIndex = Math.floor(Math.random() * dealers.length);

        // Get dealer ID from that randomized index
        const dealerId = dealers[randIndex];

        // Remove the first inventory from the list of inventories that belong to that dealer
        // and keep the product object in the 'product' variable
        const product = dealerProducts[dealerId].shift();

        // Add the product into the finalized product list
        shuffledProduct.push(product);

        // If there is no more product under that dealer, we delete it from the object,
        // so it's not found in the next loop
        if (dealerProducts[dealerId].length === 0) {
          delete dealerProducts[dealerId];
        }
      }

      // Caching by page
      this._products = [...shuffledProduct, ...products.slice(shuffledProduct.length)];
    } else {
      this._products = [...this._products, ...products];
    }
  }

  getProductStocks(products) {
    return products.map((product) => product.stock);
  }

  get total() {
    return this._total;
  }

  get totalPages() {
    if(this._params && this._total)
      return Math.ceil(this._total / this._params.per_page);
    else
      return 0;
  }

  get currentPage() {
    return this._currentPage;
  }

  get aggregations() {
    return this._aggregations;
  }
}

const getProducts = (params, signal = null) => {
  return http.get("/inventory", { params, signal }).then((payload) => {
    const inventories = payload.data.data.inventories;

    inventories.forEach((product) => {
      return Object.assign(product, {
        visible_price: getProductPrice(product),
      });
    });

    return payload;
  });
};

const getProductByID = (params, cache = false) => {
  let path = `/inventory/${params}`;

  if (cache) {
    path += `?cache=1`;
  }

  try {
    return http.get(path).then((payload) => {
      const product = payload.data.data;

      return Object.assign(product, {
        visible_price: getProductPrice(product),
      });
    });
  } catch (err) {
    console.log(err);
  }
};

const getProductByIDs = (inventoryIds, includeArchived = false) => {
  const idsString = inventoryIds.join(",");

  let query = `ids=${idsString}&include_archived=${Number(includeArchived)}`;

  try {
    return http.get(`/inventory/get-by-ids?${query}`).then((payload) => {
      return payload.data.data;
    });
  } catch (err) {
    console.log(err);
  }
};

const contactSeller = (payload, sendEmail = true) => {
  if(typeof payload.phone_number === "string") {
    payload.phone_number = payload.phone_number.trim()
      // Remove all non digit characters
      .replace(/\D+/g, '')
      // Make sure we get only the first 10 characters
      .slice(0, 10)
      // If the phone is less than 10 characters, we pad it with 0, so it doesn't throw validation error
      .padEnd(10, '0');
  }

  return http.put(
    sendEmail ? "/leads" : "/leads/create",
    payload,
  ).then(() => {
    // The first HTTP request was successful
    // Call the register potential buyer endpoint
    return http.put("/leads/potentialbuyer", payload); 
  }).catch(error => {
    console.error("An error occurred", error);
  });
};

const getGlossary = () => {
  return http.get(`/glossary`).then((res) => res.data.data);
};

const getProductPrice = (product) => {
  if (!product) new Price(0, Price.PriceType);
  const price = +product.website_price >= 1 ? +product.website_price : +product.basic_price;
  if (0 < product.sales_price && product.sales_price < price) {
    return new Price(+product.sales_price, Price.SalesPriceType);
  } else {
    return new Price(price, Price.PriceType);
  }
};

const createViewedDealers = (payload) => {
  return http.post(`/viewed-dealers`, {
    viewed_dealers: payload,
  });
};

const getViewedDealerId = (params) => {
  return http.get(`/viewed-dealers`, { params });
};

const getContentBlocks = async () => {
  const res = await http.get('/articles');
  return res.data.data;
}

const saveSearch = async (payload) => {
  return await http.put( '/inventory/saved-search', payload);
}

const getSavedSearchList = async () => {
  const res = await http.get(`/inventory/saved-search`);
  return res.data.data;
}

const deleteSavedSearch = async (id) => {
  return await http.delete(`/inventory/saved-search/${id}`);
}

export {
  getProducts,
  getProductByID,
  contactSeller,
  getProductPrice,
  getGlossary,
  createViewedDealers,
  getViewedDealerId,
  getProductByIDs,
  getContentBlocks,
  saveSearch,
  getSavedSearchList,
  deleteSavedSearch,
};
