<template>
  <!-- Start of YearFilter Accordion Setup -->
  <ion-row class="collapsable-menu-custom">
    <ion-col>
      <ion-list>
        <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
          <ion-item
            id="TestA_plp_filter_year"
            lines="none"
            class="filter-item-head"
            :class="{ active }"
            @click="handleClick"
          >
            <div class="text-icon">
              <div class="ion-text-uppercase parent-text">
                Year
              </div>
              <div class="d-flex">
                <span class="ion-text-uppercase selected-search ion-padding-end">
                  {{ selectedListString }}
                </span>
                <span :class="!active ? 'arrow-drop  rotate-caret' : 'arrow-drop'">
                  <img
                    alt="Trailers Near Me"
                    src="@/assets/icons/caretDownBlue.svg"
                  >
                </span>
              </div>
            </div>
          </ion-item>
          <div
            v-if="active"
            class="filter-attribute"
          >
            <input
              v-model="searchYear"
              type="text"
              class="form-textbox"
              placeholder="Search Year..."
              aria-label="Input Search Year"
            >
          </div>
          <template
            v-for="(item, index) in years"
            :key="item.key"
          >
            <ion-item
              v-if="active && (index < moreLimit || seeMore)"
              lines="none"
              :href="item.href"
              class="filter-collapse"
              @click="toggleSelected($event, item.key, index)"
            >
              <ion-checkbox
                slot="start"
                ref="year"
                :value="item.key"
                :checked="!!hasFilter('year', item.key)"
                class="filter-checkbox"
              />
              <ion-label class="ion-text-uppercase child-text">
                {{ item.key }}
              </ion-label>
            </ion-item>
          </template>
          <div
            v-if="active && years.length > moreLimit"
            class="see-more-less"
          >
            <div>
              <ion-label
                v-if="active && !seeMore"
                class="see-more ion-margin-top"
                @click="toggleSeeMore(true)"
              >
                See {{ years.length - moreLimit }} more
                <img
                  alt="caretRight"
                  class="caret-right"
                  src="@/assets/icons/caretRight.svg"
                >
              </ion-label>
            </div>
            <div class="see-less">
              <ion-label
                v-if="active && seeMore"
                class="see-more ion-margin-top"
                @click="toggleSeeMore(false)"
              >
                See less
                <img
                  alt="caretUp"
                  src="@/assets/icons/caretUp.svg"
                >
              </ion-label>
            </div>
          </div>
        </div>
      </ion-list>
    </ion-col>
  </ion-row>
</template>

<script>
import GroupItemFilter from "../../models/filters/group/GroupItemFilter";
import { mapActions, mapState } from "vuex";
import { ellipsizeList } from "@/utils/FilterListUtil";
import { IonCheckbox, IonCol, IonItem, IonList, IonRow, IonLabel } from "@ionic/vue";
import { filterActions } from "@/mixins";
import { scrollPlpToTop } from "@/utils/ProductListingPageUtil";

export default {
  name: "YearFilter",
  components: {
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonCheckbox,
    IonLabel,
  },
  mixins: [filterActions],
  props: {
    active: Boolean,
  },
  emits: ["toggle"],
  data() {
    return {
      moreLimit: 6,
      seeMore: false,
      searchYear: '',
    };
  },
  computed: {
    ...mapState({
      searchFilter: (state) => state.searchFilter,
      searchApiFields: (state) => state.searchApiFields,
      aggregations: (state) => state.categoryAggregations,
    }),
    years() {
      return (
        this.aggregations?.filter_aggregations?.year?.buckets
          .map((item) => +item.key)
          .filter((item) => 1900 < item && item < 2050)
          .filter((item) => String(item).includes(this.searchYear))
          .sort((a, b) => b - a) || []
      ).map((year) => {
        return { key: String(year), href: this.generateHrefFromField(year, "year") };
      });
    },
    selectedList() {
      return this.searchFilter
        .filter((item) => item.name === "year")
        .map((item) => item.value);
    },
    selectedListString() {
      return ellipsizeList(this.selectedList, 14);
    },
  },
  methods: {
    ...mapActions(["addSearchFilter", "clearSearchFilter"]),
    toggleSelected($event, value, index) {
      $event.preventDefault();
      const checked = this.$refs.year[index].checked;
      if (!checked) {
        this.addSearchFilter(
          new GroupItemFilter({
            name: "year",
            value,
          }),
        );
      } else if (checked) {
        this.clearSearchFilter({
          name: "year",
          value,
        });
      }

      scrollPlpToTop();
    },
    toggleSeeMore(bSeeMore) {
      this.seeMore = bSeeMore;
    },
    handleClick() {
      this.$emit("toggle");
    },
  },
};
</script>
