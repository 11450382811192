import GroupItemFilter from "@/models/filters/group/GroupItemFilter";

export default class CabToAxleLengthItemFilter extends GroupItemFilter {
    constructor({value}) {
        super({
            name: CabToAxleLengthItemFilter.NAME,
            label: CabToAxleLengthItemFilter.defaultLabel(value),
            value: value,
        });
    }
}

CabToAxleLengthItemFilter.defaultLabel = function (value) {
    return value + '"';
}
CabToAxleLengthItemFilter.fromApiField = GroupItemFilter.fromApiField;
CabToAxleLengthItemFilter.NAME = 'cab_to_axle_length';