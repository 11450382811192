import smoothscroll from "smoothscroll-polyfill";
import { isSSR } from "@/utils/SSRUtil";
if (!isSSR()) {
  smoothscroll.polyfill();
}

export default {
  mounted() {
    if (!isSSR()) {
      setTimeout(() => {
        if (this.$route.hash) {
          document.querySelector(this.$route.hash)?.scrollIntoView();
        }
      }, 1500);
    }
  },
};
