<template>
  <div class="registration-form-wrapper">
    <Form @submit="registerUser">
      <div class="registration-form">
        <ion-col>
          <p>
            Register to access the TrailerTrader Dashboard from which you can create and manage listings.
          </p>
        </ion-col>
        <ion-col
          size-md="12"
          size="12"
          class=""
        >
          <Field
            v-slot="{ field, errors }"
            rules="required"
            name="firstName"
            label="FIRST NAME"
          >
            <ion-item
              lines="none"
              :class="errors.length !== 0 ? 'error-field' : ''"
              class="search-trailer-location-input error-registration-form"
            >
              <IonLabel
                class="location-label custom-color"
                position="floating"
              >
                first name
              </IonLabel>
              <ion-input
                name="firstName"
                v-bind="field"
                aria-label="Input your first name"
                @keyup="checkIfFill()"
              />
            </ion-item>
          </Field>
          <ErrorMessage
            class=""
            name="firstName"
          />
        </ion-col>
        <ion-col
          size-md="12"
          size="12"
          class=""
        >
          <Field
            v-slot="{ field, errors }"
            rules="required"
            name="lastName"
            label="LAST NAME"
          >
            <ion-item
              lines="none"
              :class="errors.length !== 0 ? 'error-field' : ''"
              class="search-trailer-location-input error-registration-form"
            >
              <IonLabel
                class="location-label custom-color"
                position="floating"
              >
                last name
              </IonLabel>
              <ion-input
                name="lastName"
                v-bind="field"
                aria-label="Input your last name"
                @keyup="checkIfFill()"
              />
            </ion-item>
          </Field>
          <ErrorMessage name="lastName" />
        </ion-col>
        <ion-col
          size-md="12"
          size="12"
          class=""
        >
          <Field
            v-slot="{ field, errors }"
            rules="required|email"
            name="email"
            label="EMAIL"
          >
            <ion-item
              lines="none"
              :class="errors.length != 0 ? 'error-field' : ''"
              class="search-trailer-location-input error-registration-form"
            >
              <IonLabel
                class="location-label custom-color"
                position="floating"
              >
                Email
              </IonLabel>
              <ion-input
                name="email"
                v-bind="field"
                aria-label="input your email"
                @keyup="checkIfFill()"
              />
              <Popper
                slot="end"
                class="email-popper"
                :content="emailPolicy"
                hover
                arrow
                placement="top"
              >
                <span><img
                  style="float: right; margin-top: 22px"
                  src="@/assets/infobubble.webp"
                  alt="@/assets/infobubble.webp"
                ></span>
              </Popper>
            </ion-item>
          </Field>

          <ErrorMessage name="email" />
        </ion-col>
        <ion-col
          size-md="12"
          size="12"
          class=""
        >
          <Field
            v-slot="{ field, errors }"
            rules="required|phone_number"
            name="phoneNumber"
            label="PHONE NUMBER"
          >
            <ion-item
              lines="none"
              :class="errors.length !== 0 ? 'error-field' : ''"
              class="search-trailer-location-input error-registration-form"
            >
              <IonLabel
                class="location-label custom-color"
                position="floating"
              >
                phone number
              </IonLabel>
              <ion-input
                name="phoneNumber"
                v-bind="field"
                aria-label="input your phone number"
                @keyup="checkIfFill()"
              />
              <Popper
                slot="end"
                class="phone-popper"
                :content="phoneInfo"
                hover
                arrow
                placement="top"
              >
                <span><img
                  style="float: right; margin-top: 22px"
                  src="@/assets/infobubble.webp"
                  alt="@/assets/infobubble.webp"
                ></span>
              </Popper>
            </ion-item>
          </Field>
          <ErrorMessage name="phoneNumber" />
        </ion-col>
        <ion-row>
          <ion-col
            size-md="6"
            size="12"
            class="password"
          >
            <Field
              v-slot="{ field, errors }"
              ref="password"
              rules="required|min:8|max:16"
              name="password"
              label="PASSWORD"
            >
              <ion-item
                :class="errors.length !== 0 ? 'error-field' : ''"
                class="search-trailer-location-input error-save-search"
                lines="none"
              >
                <IonLabel
                  class="location-label custom-color"
                  position="floating"
                >
                  Password
                </IonLabel>
                <ion-input
                  type="password"
                  name="password"
                  aria-label="Input password"
                  v-bind="field"
                  @keyup="checkIfFill"
                />
              </ion-item>
            </Field>
            <ErrorMessage name="password" />
          </ion-col>
          <ion-col
            size-md="6"
            size="12"
            class="confirm-password"
          >
            <Field
              v-slot="{ field, errors }"
              rules="required|min:8|max:16|confirmed:password"
              name="confirmPassword"
              label="Confirm Password"
            >
              <ion-item
                :class="errors.length !== 0 ? 'error-field' : ''"
                class="search-trailer-location-input error-save-search"
                lines="none"
              >
                <IonLabel
                  class="location-label custom-color"
                  position="floating"
                >
                  Confirm Password
                </IonLabel>
                <ion-input
                  type="password"
                  name="confirmPassword"
                  aria-label="Input confirm password"
                  v-bind="field"
                  @keyup="checkIfFill"
                />
              </ion-item>
            </Field>
            <ErrorMessage
              class="ion-float-left"
              name="confirmPassword"
            />
          </ion-col>
        </ion-row>
      </div>
      <div class="login-form-button registration-form-button">
        <div class="d-flex">
          <ion-col
            size-md="6"
            size="12"
          >
            <ion-button
              class="btn btn-cancel custom-button"
              @click="closeModal"
            >
              Cancel
            </ion-button>
          </ion-col>
          <ion-col
            size-md="6"
            size="12"
          >
            <ion-button
              class="btn btn-submit custom-button"
              type="submit"
              :class="signingUp ? 'btn-inactive' : ''"
              :disabled="signingUp"
            >
              <span v-if="signingUp">Signing Up...</span>
              <span v-else>Submit</span>
              <ion-icon :icon="chevronForwardOutline" />
            </ion-button>
          </ion-col>
        </div>
        <div>
          <RecaptchaRemark />
        </div>
      </div>
    </Form>
    <div />
    <div class="confirm-terms">
      <ion-text class="msg">
        {{ conditionsText }}
      </ion-text> <a
        href="/termsofuse"
        target="_blank"
      > terms of use</a>
    </div>
  </div>
  <div class="divider-register" />
</template>

<script>
import { IonIcon, IonLabel, IonCol, IonInput, IonItem, IonButton, IonText, modalController, IonRow } from "@ionic/vue";
import { Field, ErrorMessage, Form } from "vee-validate";
import { closeSharp, chevronForwardOutline } from "ionicons/icons";
import { registerUser, privateStats } from "@/services/UserService";
import { informationCircle } from "ionicons/icons";
import EmailExists from "@/components/user/EmailExists.vue";
import { modal } from "@/mixins";
import { getRecaptchaToken } from "@/services/RecaptchaService";
import RecaptchaRemark from "@/components/google/RecaptchaRemark.vue";
import { LogRocketService } from "@/services/LogRocketService";
import { utagLink } from "@/services/TealiumService";

export default {
  components: {
    RecaptchaRemark,
    IonIcon,
    IonLabel,
    IonCol,
    IonInput,
    IonItem,
    IonButton,
    Field,
    Form,
    ErrorMessage,
    IonText,
    IonRow,
  },
  mixins: [modal],
  data() {
    return {
      closeSharp,
      chevronForwardOutline,
      conditionsText: "By submitting, I accept Trailertrader's",
      emailPolicy: `You will receive a confirmation link at this email address. Please make sure that you have typed the address correctly.
TrailerTrader abides by GDPR rules and will not be sharing or selling your email address to third parties.`,
      informationCircle,
      phoneInfo: "We use your telephone number for confirmation purposes if you lose your password.",
      signingUp: false,
    };
  },
  mounted() {},
  methods: {
    async closeModal() {
      await modalController.dismiss();
    },
    async registerUser(event) {
      this.signingUp = true;

      const payLoad = {
        first_name: event.firstName,
        last_name: event.lastName,
        email: event.email?.toLowerCase(),
        password: event.password,
        phone_number: event.phoneNumber,
        password_confirmation: event.confirmPassword,
        captcha: await getRecaptchaToken("sign_up"),
      };

      const logRocketPayload = { ...payLoad };
      delete logRocketPayload.password;
      delete logRocketPayload.password_confirmation;
      delete logRocketPayload.captcha;

      try {

        LogRocketService.track(LogRocketService.events.register_submitted, logRocketPayload);

        const { data } = await registerUser(payLoad);
        this.signingUp = false;
        if (data !== undefined) {
          await this.closeModal();
          // update store with email
          await this.$router.push({
            path: "/registrationconfirmation",
            query: { email: event.email },
          });
          LogRocketService.track(LogRocketService.events.register_success, logRocketPayload);
          this.$store.dispatch("setToastNotification", {
            open: true,
            text: "Registration has been successful. Please check your email to verify it.",
            type: "success",
          });

          // add inventory published event to private seller stats
          await privateStats({
            website_user_id: data.id,
            event: 'account_creation',
          });
        }
        
        utagLink(this.$route, {
          "tealium_event": "form_submit",
          "form_id": "registration_form",
          "form_submission_email": event.email?.toLowerCase(),
          "form_submission_first_name": event.firstName,
          "form_submission_last_name": event.lastName,
        });
      } catch (e) {
        LogRocketService.track(LogRocketService.events.register_failed, logRocketPayload);
        this.signingUp = false;
        const data = e.data;
        if (e.data.status_code === 500) {
          this.$store.dispatch("setToastNotification", {
            open: true,
            text: "Unable to register. Please try again later.",
            type: "danger",
          });
        } else {
          if (data?.errors?.email[0].includes("TrailerCentral")) {
            LogRocketService.track(LogRocketService.events.register_failed_duplicate_emails_with_tc, logRocketPayload);
            await this.openModal(EmailExists, {
              cssClass: "modal-header-email-exists",
              componentProps: {
                message: `This email is registered to access the TrailerCentral dashboard. 
                            In order to avoid interactions with your account on Trailer Central, we ask that you 
                            register with another email. Thank you for your understanding. `,
              },
              backdropDismiss: true,
            });
          }
          if (!data?.errors?.email[0].includes("TrailerCentral")) {
            LogRocketService.track(LogRocketService.events.register_failed_account_exists_in_tt, logRocketPayload);
            await this.openModal(EmailExists, {
              cssClass: "modal-header-email-exists",
              componentProps: {
                message: `You can not use this email address as it is already associated to an account.
                            You may log into TrailerTrader with that email otherwise please enter another email address. `,
              },
              backdropDismiss: true,
            });
          }
        }
      }
    },
    checkIfFill() {
      this.formPartiallyFilled = true;
    },
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  :deep(.email-popper .popper) {
    transform: translate(-8px, -134px) !important;
    font-family: "Object Sans Heavy";
  }
  :deep(.phone-popper .popper) {
    transform: translate(-8px, -41px) !important;
    font-family: "Object Sans Heavy";
  }

  :deep(#arrow) {
    display: none;
  }
}

@media (min-width: 767px) {
  :deep(.popper) {
    transform: translate(9px, -38px) !important;
    font-family: "Object Sans Heavy";
    max-height: 100px;
    overflow-y: auto;
  }

  :deep(#arrow) {
    display: none;
    /* transform: translate(340px, 0) !important; */
  }
}

ion-list {
  contain: unset !important;
}

ion-item {
  overflow: visible;
}

ion-button {
  margin: -10px;
  margin-top: 5px;
}
</style>
