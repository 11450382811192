<template>
  <ion-grid>
    <div class="recaptcha-failed-modal">
      <div class="recaptcha-failed-info">
        Recaptcha validation unsuccessful. Please refresh the page and try again.
      </div>
      <ion-col
        size-md="4"
        size="12"
      >
        <ion-button
          class="btn btn-submit custom-button"
          @click="closeModal"
        >
          OK
        </ion-button>
      </ion-col>
    </div>
  </ion-grid>
</template>

<script>
import { IonGrid, IonCol, IonButton, modalController } from "@ionic/vue";

export default {
  components: {
    IonGrid,
    IonCol,
    IonButton,
  },
  methods: {
    async closeModal() {
      await modalController.dismiss();
    },
  },
};
</script>

