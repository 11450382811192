<template>
  <div class="dealer-name-and-link">
    <template v-if="typeof websiteDomain === 'string' && websiteDomain.trim().length > 0">
      <a
        :href="`https://${websiteDomain}`"
        class="dealer-name"
        @click="trackDealerWebsiteVisit"
      >
        <span>
          {{ dealerName }}
        </span>
        <span>
          <img
            alt="External Link"
            src="@/assets/icons/external-link.webp"
          >
        </span>
      </a>
    </template>
    <template v-else>
      <div class="dealer-name">
        {{ dealerName }}
      </div>
    </template>
  </div>
</template>

<script>
import {
  generateDWNavigationLeadPayload,
} from "@/utils/LeadUtil";
import urlTransformations from "@/mixins/urlTransformations";
import { LogRocketService } from "@/services/LogRocketService";
import { contactSeller } from "@/services/ProductsService";
import { modal } from "@/mixins";

export default {
  name: "DealerNameAndLink",
  mixins: [urlTransformations, modal],
  props: {
    websiteDomain: {
      type: String,
      required: true,
    },
    dealerName: {
      type: String,
      required: true,
    },
    dealerId: {
      type: [String, Number],
      required: true,
    },
    dealerLocationId: {
      type: [String, Number],
      required: true,
    },
    inventoryId: {
      type: [String, Number],
      required: true,
    },
    inventoryTitle: {
      type: String,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
  },
  methods: {
    async trackDealerWebsiteVisit(event) {
      event.preventDefault();
      //a Check first if the user is logged in
      const { isAuthenticated } = this.$store.state;

      const userData = JSON.parse(localStorage.getItem("user")) || this.$store?.state?.userData;

      // If the user is authenticated, we can execute the share and lead
      // submission logic using their info saved in the database
      if (isAuthenticated) {
        await this.submitLeadData({
          name: `${userData.first_name} ${userData.last_name}`,
          email: userData.email,
          phone: userData.phone_number ?? undefined,
        });

        return;
      }

      // If not authenticated, we'll simply use default values
      await this.submitLeadData({
        name: "",
        email: "",
        phone: undefined,
      });
    },
    async submitLeadData({ name, email, phone }) {
      let [firstName, ...lastNameArr] = name.split(" ");

      let lastName = lastNameArr.join(" ").trim();

      const pdpUrl = this.productionPDPUrl(this.inventoryId, this.inventoryTitle);

      // Generate lead generation payload
      const dwNavigationPayload = {
        dealer_id: Number(this.dealerId),
        dealer_location_id: Number(this.dealerLocationId),
        inventory: {
          inventory_id: Number(this.inventoryId),
        },
        pdp_url: pdpUrl,
        title: this.inventoryTitle,
        referral: `/${this.productHrefPath(this.inventoryId, this.inventoryTitle)}`,
        website_domain: this.websiteDomain,
      };

      if (firstName?.length > 0) {
        dwNavigationPayload.first_name = firstName;
      }

      if (lastName?.length > 0) {
        dwNavigationPayload.last_name = lastName;
      }

      if (email?.length > 0) {
        dwNavigationPayload.email_address = email;
      }

      if (phone?.length > 0) {
        dwNavigationPayload.phone_number = phone;
      }

      window.open(`https://${this.websiteDomain}`);

      // Acquire the lead payload
      const payload = await generateDWNavigationLeadPayload(dwNavigationPayload);

      let contactSellerOk = false;

      try {
        // Send the lead to TC API
        await contactSeller(payload);
        contactSellerOk = true;
      } catch (error) {
        LogRocketService.track(LogRocketService.events.dw_navigation_lead_errors, {
          inventory_id: this.inventoryId,
          dealer_id: this.dealerId,
          location: this.location,
        });
      }

      // Only send success state to LogRocket if the lead submission is successful
      if (contactSellerOk) {
        LogRocketService.track(LogRocketService.events.dw_navigation_lead_success, {
          inventory_id: this.inventoryId,
          dealer_id: this.dealerId,
          location: this.location,
          website_domain: this.websiteDomain,
        });
      }
    },
  },
};
</script>
