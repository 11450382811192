<template>
  <ion-header class="ion-no-border">
    <ion-grid class="modal-header-email-exists">
      <div class="email-exists-modal">
        <div class="email-exists-title">Email Already Exists</div>
        <div class="email-exits-description">{{message}}
        </div>
        <div class="email-exists-button">
          <ion-col size-md="4" size="12">
            <ion-button @click="openUserModal" class="btn btn-submit custom-button" type="submit">
              Go to login
            </ion-button>
          </ion-col>
          <ion-col size-md="4" size="12">
            <ion-button @click="closeModal" class="btn btn-cancel custom-button">Use another email</ion-button>
          </ion-col>
        </div>
      </div>
    </ion-grid>
  </ion-header>
</template>

<script>
import {
  IonHeader,
  IonGrid,
  IonCol,
  IonButton,
  modalController,
} from "@ionic/vue";
import { modal } from "@/mixins"

export default {
  name: "EmailExists",
  mixins: [modal],
  props:{
    message: {
      type: String,
      required: true,
    }
  },
  emits: ["onGoToLogin"],
  components: {
    IonHeader,
    IonGrid,
    IonCol,
    IonButton,
    
  },
  methods: {
    async closeModal() {
      await modalController.dismiss();
    },
    openUserModal() {
      this.closeModal();
      this.$store.commit("SET_USER_FORM_TOGGLE", !this.$store.state.userFormToggle);
    }
  }

};
</script>
