<template>
  <ion-header class="ion-no-border">
    <ion-grid class="modal-header-logout-confirmation">
      <div class="logout-confirmation-modal">
        <div 
          class="logout-confirmation-title ion-text-uppercase" 
          style="text-align:center"
        >
          {{ heading }}
        </div>
        <div class="logout-confirmation-description">
          {{ message }}
        </div>
        <div 
          v-if="type === 'confirm'" 
          class="logout-confirmation-button"
        >
          <ion-col 
            size-md="4" 
            size="12"
          >
            <ion-button 
              class="btn btn-cancel custom-button" 
              @click="closeModal"
            >
              Cancel
            </ion-button>
          </ion-col>
          <ion-col 
            size-md="4" 
            size="12"
          >
            <ion-button
              class="btn btn-submit custom-button" 
              type="submit"
              @click="confirm" 
            >
              Remove
            </ion-button>
          </ion-col>
        </div>
        <div v-else>
          <ion-col 
            size-md="10" 
            size="10"
          >
            <ion-button 
              class="btn btn-submit"
              @click="closeModal"
            >
              close
            </ion-button>
          </ion-col>
        </div>
      </div>
    </ion-grid>
  </ion-header>
</template>

<script>
import { IonHeader, IonGrid, modalController, IonCol, IonButton } from "@ionic/vue";
export default {
  name: "RemoveAllModal",
  components: {
    IonHeader,
    IonGrid,
    IonCol,
    IonButton,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
  },
  methods: {
    async closeModal() {
      await modalController.dismiss();
    },
    async confirm() {
      await modalController.dismiss("YES");
    },
  },
};
</script>
