const TYPE_EQUIPMENT_ID = 1;
const TYPE_HORSE_LIVESTOCK_ID = 2;
const TYPE_TRAVEL_TRAILERS_ID = 3;
const TYPE_SEMI_TRAILERS_ID = 4;
const TYPE_TRUCK_BED_ID = 5;
export {TYPE_EQUIPMENT_ID, TYPE_HORSE_LIVESTOCK_ID, TYPE_TRAVEL_TRAILERS_ID, TYPE_SEMI_TRAILERS_ID, TYPE_TRUCK_BED_ID};

export const TYPE_TITLES = {
  [TYPE_EQUIPMENT_ID]: "General Trailers",
  [TYPE_HORSE_LIVESTOCK_ID]: "Horse & Livestock",
  [TYPE_TRAVEL_TRAILERS_ID]: "Travel Trailers",
  [TYPE_SEMI_TRAILERS_ID]: "Semi Trailers",
  [TYPE_TRUCK_BED_ID]: "Truck Beds",
};

export const SEO_FRIENDLY_URLS_CATEGORIES = [
  'Motorcycle / Cycle Trailers',
  'Tow Dollys',
  'Snowmobile Trailers',
  'snowmobile',
  'tow-dollys',
  'motorcycle',
];

export const CONTENT_BLOCK_CATEGORIES = [
  'Dump Trailers',
  'Snowmobile Trailers',
  'Cargo (Enclosed) Trailers',
  'Vending / Concession Trailers',
];

export const CONTENTS_FOR_CATEGORIES = {
  'Dump Trailers': {
    headline: "Dump Trailer Buyers Guide",
    author: "TrailerTrader",
    datePublished: "2024-01-01",
    articleBody: "Dump trailers are designed for transporting and unloading loose materials, such as sand, gravel, dirt, or construction debris. ...",
  },
  'Snowmobile Trailers': {
    headline: "Snowmobile Trailer Buyers Guide",
    author: "TrailerTrader",
    datePublished: "2024-01-01",
    articleBody: "You’ll likely need a snowmobile trailer to haul your sleds to where the best snow is - but the variety of available options mean you’ll have some decisions to make. ...",
  },
  'Cargo (Enclosed) Trailers': {
    headline: "Cargo-Enclosed Trailer Buyers Guide",
    author: "TrailerTrader",
    datePublished: "2024-01-01",
    articleBody: "Cargo trailers, or enclosed trailers, are a common type of trailer used to transport goods, equipment or other cargo. ...",
  },
  'Vending / Concession Trailers': {
    headline: "Food/Concession/Vending Trailers for Sale",
    author: "TrailerTrader",
    datePublished: "2024-01-01",
    articleBody: "Concession trailers (as well as food trailers and vending trailers) are mobile units designed for selling goods, food, or services in locations where people gather. ...",
  },
};
