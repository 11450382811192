<template>
  <ion-grid
    v-if="isDesktop"
    id="productlist-header-desktop"
    class="product-list-header-grid"
  >
    <ion-row>
      <ion-col
        size="12"
        class="content-box"
      >
        <div id="plp-page-top-anchor-desktop" />
        <ion-row class="ion-no-padding">
          <template v-if="!fetchingProducts">
            <ion-col
              size="12"
            >
              <h1
                id="TestA_plp_pagetitle_desktop"
                class="page-title"
              >
                {{ pageTitle }} for Sale
                {{ isSeoFriendlyPage ? " near me" : pageLocation ? ` near ${pageLocation}` : "near me" }}
              </h1>
            </ion-col>
            <ion-col size="10">
              <template v-if="totalProducts > 0">
                <template v-if="isSeoFriendlyPage">
                  <h2 class="page-subtitle">
                    TrailerTrader has a huge selection of {{ pageTitle }} for Sale.
                  </h2>
                  <h4
                    id="TestA_plp_pagedescription_desktop"
                    class="page-description"
                  >
                    You can find your trailer or recreational vehicle near your location. To select the location 
                    <a
                      id="selectlocation_plp"
                      href="javascript:void(0)"
                      @click="showLocationBar('mobile')"
                    >click here</a>.
                  </h4>
                </template>
                <template v-else>
                  <h4
                    id="TestA_plp_pagedescription_desktop"
                    class="page-description"
                  >
                    TrailerTrader.com has a huge selection of {{ pageTitle }} for Sale{{
                      pageLocation ? ` near ${pageLocation}` : ""
                    }}.
                    <template v-if="pageLocation">
                      With over 150,000 trailers for sale, you are likely to find the trailer you need close to
                      {{ pageLocation }}. To change the location of your results
                      <a
                        id="selectlocation_plp"
                        href="javascript:void(0)"
                        @click="showLocationBar('desktop')"
                      >click here</a>.
                    </template>
                    <template v-else>
                      With over 150,000 trailers for sale, you can find the trailer you need close to your location. To
                      select the location
                      <a
                        id="selectlocation_plp"
                        href="javascript:void(0)"
                        @click="showLocationBar('desktop')"
                      >click here</a>.
                    </template>
                  </h4>
                </template>
              </template>
              <template v-else>
                <h4
                  id="TestA_plp_pagedescription_desktop"
                  class="page-description"
                >
                  Your search is too restrictive and did not generate results. 
                  If you have centered your search around a location, we invite you to increase the range. 
                  You can also remove some of the filters applied in the Filter column.
                </h4>
              </template>
            </ion-col>
            <ion-col
              size="12"
              class="page-filters"
            >
              <ion-row class="ion-no-padding">
                <ion-col
                  size="10"
                  class="ion-no-padding"
                >
                  <!-- <span :id="search+`_plp`" v-for="(search, index) in searchFilter" :key="index">

                    <QuickFilter :title="search" :activeFilter="index" />
                  </span> -->
                </ion-col>
                <!-- This Feature will be uncommented in the future -->
                <!-- <ion-col
                  size="2"
                  class="ion-no-padding map-btn"
                  id="Map_view_shift_PLP"
                >
                  <ion-chip
                    @click="showMapView"
                    class="quick-filter-chip ion-float-left ion-no-margin"
                    v-if="pageLocation"
                  >
                    <img
                      alt="mapView"
                      class="map-img"
                      src="@/assets/icons/mapview.svg"
                    />
                    <ion-label>Map View</ion-label>
                  </ion-chip>
                </ion-col> -->
                <!-- This Feature will be uncommented in the future -->
              </ion-row>
            </ion-col>
          </template>

          <!--When the product is being fetched-->
          <template
            v-if="fetchingProducts"
            v-cloak
          >
            <SkeletonLoader style="height: 250px" />
          </template>
        </ion-row>
        <ion-row class="ion-padding d-flex ion-justify-content-evenly">
          <a
            id="TestA_plp_all_inventories_desktop"
            class="ion-text-uppercase ion-margin-right primary-hyperlink"
            :href="`${domainOrigin}/trailers-for-sale/all-trailers-for-sale`"
            @click="redirect('/trailers-for-sale/all-trailers-for-sale', $event)"
          >
            All Inventory
          </a>
          |
          <a
            id="TestA_plp_general_trailers_desktop"
            class="ion-text-uppercase ion-margin-left ion-margin-right primary-hyperlink"
            :href="`${domainOrigin}/trailers-for-sale/trailers-for-sale`"
            @click="redirect('/trailers-for-sale/trailers-for-sale', $event)"
          >
            general trailers
          </a>
          |
          <a
            id="TestA_plp_horse_livestock_desktop"
            class="ion-text-uppercase ion-margin-left ion-margin-right primary-hyperlink"
            :href="`${domainOrigin}/trailers-for-sale/horse-and-livestock-trailers-for-sale`"
            @click="redirect('/trailers-for-sale/horse-and-livestock-trailers-for-sale', $event)"
          >
            horse & livestock
          </a>
          |
          <a
            id="TestA_plp_travel_rv_desktop"
            class="ion-text-uppercase ion-margin-left ion-margin-right primary-hyperlink"
            :href="`${domainOrigin}/trailers-for-sale/all-travel-trailers-for-sale`"
            @click="redirect('/trailers-for-sale/all-travel-trailers-for-sale', $event)"
          >
            travel & rv
          </a>
          |
          <a
            id="TestA_plp_semi_trailers_desktop"
            class="ion-text-uppercase ion-margin-left ion-margin-right primary-hyperlink"
            :href="`${domainOrigin}/trailers-for-sale/semi-trailers-for-sale`"
            @click="redirect('/trailers-for-sale/semi-trailers-for-sale', $event)"
          >
            semi trailers
          </a>
          |
          <a
            id="TestA_plp_truck_beds_desktop"
            class="ion-text-uppercase ion-margin-left ion-margin-right primary-hyperlink"
            :href="`${domainOrigin}/trailers-for-sale/truck-beds-for-sale`"
            @click="redirect('/trailers-for-sale/truck-beds-for-sale', $event)"
          >
            truck beds
          </a>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid
    v-if="!isDesktop"
    id="productlist-header-mobile"
    class="product-list-header-grid"
  >
    <ion-row class="">
      <ion-col
        size="12"
        class="ion-no-padding content-box-mobile"
      >
        <div id="plp-page-top-anchor-mobile" />
        <ion-row>
          <template v-if="!fetchingProducts">
            <ion-col
              size="12"
            >
              <div
                id="TestA_plp_pagetitle_mobile"
                class="h1 page-title"
              >
                {{ pageTitle }} for Sale
                {{ isSeoFriendlyPage ? " near me" : pageLocation ? ` near ${pageLocation}` : "near me" }}
              </div>
            </ion-col>
            <ion-col size="12">
              <template v-if="totalProducts > 0">
                <template v-if="isSeoFriendlyPage">
                  <h2
                    class="page-subtitle"
                  >
                    TrailerTrader has a huge selection of {{ pageTitle }} for Sale.
                  </h2>
                  <h4
                    id="TestA_plp_pagedescription_mobile"
                    class="page-description"
                  >
                    You can find your trailer or recreational vehicle near your location. To select the location 
                    <a
                      id="selectlocation_plp"
                      href="javascript:void(0)"
                      @click="showLocationBar('mobile')"
                    >click here</a>.
                  </h4>
                </template>
                <template v-else>
                  <h4
                    id="TestA_plp_pagedescription_mobile"
                    class="page-description"
                  >
                    TrailerTrader.com has a huge selection of {{ pageTitle }} for Sale{{
                      pageLocation ? ` near ${pageLocation}` : ""
                    }}.
                    <template v-if="pageLocation">
                      With over 150,000 trailers for sale, you are likely to find the trailer you need close to
                      {{ pageLocation }}. To change the location of your results
                      <a
                        id="selectlocation_plp"
                        href="javascript:void(0)"
                        @click="showLocationBar('mobile')"
                      >click here</a>.
                    </template>
                    <template v-else>
                      With over 150,000 trailers for sale, you can find the trailer you need close to your location. To
                      select the location
                      <a
                        id="selectlocation_plp"
                        href="javascript:void(0)"
                        @click="showLocationBar('mobile')"
                      >click here</a>.
                    </template>
                  </h4>
                </template>
              </template>
              <template v-else>
                <h4
                  id="TestA_plp_pagedescription_mobile"
                  class="page-description"
                >
                  Your search is too restrictive and did not generate results. 
                  If you have centered your search around a location, we invite you to increase the range. 
                  You can also remove some of the filters applied in the Filter column.
                </h4>
              </template>
            </ion-col>
          </template>

          <template
            v-if="fetchingProducts"
            v-cloak
          >
            <SkeletonLoader style="height: 210px" />
          </template>

          <ion-col
            size="12"
            class="page-filters"
          >
            <ion-row>
              <ion-col
                size="12"
                class=""
              >
                <div
                  v-if="filterCount === 0"
                  id="TestA_plp_allfilter_btn"
                  class="ion-float-left ion-no-padding filters-btn"
                >
                  <ion-chip
                    class="filters-btn-chip"
                    @click="showFilterMenu"
                  >
                    <img
                      alt="caretDown"
                      style="margin-right: 8px"
                      src="@/assets/icons/iconfilter.svg"
                    >
                    <ion-label>All filters</ion-label>
                  </ion-chip>
                </div>
                <div
                  v-else
                  id="TestA_plp_filter_btn"
                  class="ion-float-left ion-no-padding filters-btn"
                >
                  <ion-chip
                    class="filters-btn-chip"
                    @click="showFilterMenu"
                  >
                    <ion-badge class="badge">
                      {{ filterCount }}
                    </ion-badge>
                    <ion-label>filters</ion-label>
                  </ion-chip>
                </div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row class="ion-padding d-flex ion-justify-content-evenly">
          <a
            id="TestA_plp_all_inventories_mobile"
            class="ion-text-uppercase ion-margin-right primary-hyperlink"
            :href="`${domainOrigin}/trailers-for-sale/all-trailers-for-sale`"
            @click="redirect('/trailers-for-sale/all-trailers-for-sale', $event)"
          >
            All Inventory
          </a>
          |
          <a
            id="TestA_plp_general_trailers_mobile"
            class="ion-text-uppercase ion-margin-left ion-margin-right primary-hyperlink"
            :href="`${domainOrigin}/trailers-for-sale/trailers-for-sale`"
            @click="redirect('/trailers-for-sale/trailers-for-sale', $event)"
          >
            general trailers
          </a>
          |
          <a
            id="TestA_plp_horse_livestock_mobile"
            class="ion-text-uppercase ion-margin-left ion-margin-right primary-hyperlink"
            :href="`${domainOrigin}/trailers-for-sale/horse-and-livestock-trailers-for-sale`"
            @click="redirect('/trailers-for-sale/horse-and-livestock-trailers-for-sale', $event)"
          >
            horse & livestock
          </a>
          |
          <a
            id="TestA_plp_travel_rv_mobile"
            class="ion-text-uppercase ion-margin-left ion-margin-right primary-hyperlink"
            :href="`${domainOrigin}/trailers-for-sale/all-travel-trailers-for-sale`"
            @click="redirect('/trailers-for-sale/all-travel-trailers-for-sale', $event)"
          >
            travel & rv
          </a>
          |
          <a
            id="TestA_plp_semi_trailers_mobile"
            class="ion-text-uppercase ion-margin-left ion-margin-right primary-hyperlink"
            :href="`${domainOrigin}/trailers-for-sale/semi-trailers-for-sale`"
            @click="redirect('/trailers-for-sale/semi-trailers-for-sale', $event)"
          >
            semi trailers
          </a>
          |
          <a
            id="TestA_plp_truck_beds_mobile"
            class="ion-text-uppercase ion-margin-left ion-margin-right primary-hyperlink"
            :href="`${domainOrigin}/trailers-for-sale/truck-beds-for-sale`"
            @click="redirect('/trailers-for-sale/truck-beds-for-sale', $event)"
          >
            truck beds
          </a>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonChip, IonLabel, IonBadge } from "@ionic/vue";
import GroupItemFilter from "@/models/filters/group/GroupItemFilter";
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { QUICK_FILTERS, RANGE_FILTERS } from "@/consts/filters";
import { useRoute } from "vue-router";
import { mapState, mapActions } from "vuex";
import { domain } from "../../mixins";
import SkeletonLoader from "@/components/content/SkeletonLoader.vue";
import { SEO_FRIENDLY_URLS_CATEGORIES } from "../../consts/category";

export default defineComponent({
  name: "ProductListHeader",
  components: {
    SkeletonLoader,
    IonCol,
    IonGrid,
    IonRow,
    // QuickFilter,
    IonChip,
    IonLabel,
    IonBadge,
  },
  mixins: [domain],
  props: ["bottomFilter"],
  emits: ["onShowFilter", "onShowMapView"],
  setup() {
    const location = ref("Select Location");
    const distance = ref("within 100 miles");

    const store = useStore();
    const route = useRoute();

    const searchFilter = QUICK_FILTERS;
    const filterCount = computed(() => store.state.searchFilter.length);
    const pageLocation = computed(() => store.state.location);
    const updateDistance = computed(() => store.state.distance || distance.value);
    const totalProducts = computed(() => store.state.totalProducts);
    const fetchingProducts = computed(() => store.state.fetchingProducts);
    const { searchApiFields } = store.state;
    const { condition, pull_type, category, manufacturer } = searchApiFields;
    const pageTitle = computed(() => {
      const query = { ...route.query };

      const titleParts = [];

      if (condition) {
        const conditions = condition.split(";");
        if (conditions.length <= 2) {
          titleParts.push(conditions.map((item) => GroupItemFilter.defaultLabel(item)).join(" and "));
        }
      }

      if (manufacturer) {
        const manufacturers = manufacturer.split(";");
        titleParts.push(manufacturers.map((item) => GroupItemFilter.defaultLabel(item)).join(", "));
      }
      if (pull_type) {
        const pullTypes = pull_type.split(";");
        titleParts.push(pullTypes.map((item) => GroupItemFilter.defaultLabel(item)).join(","));
      }

      const revertCategoryName = (name) => {
        if(name.includes("Vending")) {
          return "Concession / Food / Vending Trailers";
        }
        return name;
      }
      if (category) {
        const categories = category.split(";");
        titleParts.push(categories.map((item) => revertCategoryName(GroupItemFilter.defaultLabel(item))).join(" and "));
      } else {
        var categoryType = store.state.megaMenuList.find((type) => type.id == store.state.searchApiFields.type_id);
        var title = "";
        if (categoryType) {
          if (categoryType.name === "General Trailers") {
            title = "Trailers";
          } else {
            title = revertCategoryName(categoryType.name);
          }
        }
        if (title) {
          titleParts.push(title);
        }
      }

      if (query.type_id === "all" || searchApiFields.type_id === "all") {
        titleParts.unshift("All");
        titleParts.push("Trailers");
      }
      const isSeoFriendlyCategory = SEO_FRIENDLY_URLS_CATEGORIES.some(seoCategory => titleParts.includes(seoCategory));

      if (isSeoFriendlyCategory) {
        return searchApiFields.category?.split(";")[0];
      }

      return titleParts.join(" ");
    });

    return {
      location,
      distance,
      totalProducts,
      searchFilter,
      filterCount,
      pageLocation,
      updateDistance,
      pageTitle,
      fetchingProducts,
    };
  },
  computed: {
    ...mapState({
      removedSeoFilters: (state) =>
        state.removedSeoFilters.filter((item, pos) => state.removedSeoFilters.indexOf(item) === pos),
    }),
    listingSelectedFilter() {
      let values = [];
      this.$store.state.selectedFilter.forEach((value) => {
        if (RANGE_FILTERS.includes(value.name)) {
          values.push(value.label);
        } else {
          values.push(value.value);
        }
      });
      values = values
        .concat(this.removedSeoFilters.filter((item) => values.indexOf(item) < 0))
        .filter((item) => !RANGE_FILTERS.includes(item.replace("_max", "").replace("_min", "")));
      return values;
    },
    isSeoFriendlyPage() {
      const { searchApiFields } = this.$store.state;
      const { category } = searchApiFields;
      return SEO_FRIENDLY_URLS_CATEGORIES.includes(category);
    },
  },
  watch: {
    updateDistance(newval) {
      if (this.$store.state.distance !== "") {
        this.distance = newval;
      }
    },
  },
  mounted() {
    this.$store.commit("SET_FETCHING_PRODUCTS", true);
    this.location = this.$store.state.location || this.location;
    this.distance = this.$store.state.distance || this.distance;
  },
  methods: {
    ...mapActions(["clearAllSearchFilter"]),
    showFilterMenu() {
      this.$emit("onShowFilter");
    },
    showLocationBar(platform) {
      if (platform === "desktop") {
        this.$store.commit("SET_SHOW_LOCATION_DESKTOP", true);
      } else if (platform === "mobile") {
        this.$store.commit("SET_SHOW_LOCATION_MOBILE", true);
      }
    },
    showMapView() {
      this.$emit("onShowMapView");
    },
    redirect(url, $event) {
      $event.preventDefault();
      this.$router.push({
        path: url,
      });
    },
  },
});
</script>
