<template>
  <ion-header class="ion-no-border">
    <ion-grid class="modal-header-no-inventory">
      <div class="no-inventory-modal">
        <span style="float: right;">
          <ion-icon
            :icon="closeSharp"
            aria-label="close modal"
            @click="closeModal"
          />
        </span>
        <div class="no-inventory-content">
          <div class="no-inventory-description">
            <div class="trailer-img">
              <img
                :src="defaultLogo"
                alt="logo"
              >
            </div>
            <div class="title">
              Item has been removed
            </div>
            <div class="description">
              The seller recently removed this item,<br>
              It is no longer possible to access the contact form
            </div>
          </div>
          <div class="no-inventory-button">
            <ion-button
              class="btn btn-submit"
              @click="closeModal"
            >
              close
            </ion-button>
          </div>
        </div>
      </div>
    </ion-grid>
  </ion-header>
</template>

<script>
import { IonHeader, IonGrid, modalController,IonButton, IonIcon } from "@ionic/vue";
import { closeSharp } from "ionicons/icons";
import defaultLogo from "@/assets/defaultLogo.webp";
export default {
  name: "NoInventory",
  components: {
    IonHeader,
    IonGrid,
    IonButton,
    IonIcon,
  },
  data() {
    return {
      closeSharp,
      defaultLogo,
    }
  },
  computed: {
  },
  methods: {
    async closeModal() {
      await modalController.dismiss();
    },
  },
};
</script>
