import { modalController } from "@ionic/vue";
import { LogRocketService } from "@/services/LogRocketService";
import InfoModal from "@/components/trailers/InfoModal.vue";
export default {
  methods: {
    async openModal(component, options) {
      const modal = await modalController.create({
        component,
        animated: true,
        backdropDismiss: false,
        backdropBreakpoint: 1,
        ...options,
      });

      await modal?.present();

      const overlay = modal?.shadowRoot?.querySelector('.ion-overlay-wrapper');

      if (overlay) {
        overlay.style.opacity = '1';
      }

      return Promise.resolve(modal);
    },
    async closeModal(ref = null, partiallyFilled = false) {
      if (ref === "contactSellerForm") {
        LogRocketService.track(LogRocketService.events.contact_form_abandoned);
        if (partiallyFilled) {
          LogRocketService.track(LogRocketService.events.contact_form_partially_filled_in);
        }
      }
      if (ref) {
        this.$refs[ref]?.resetForm();
      }
      await modalController.dismiss();
    },
    openReminderModal(title) {
      this.openModal(InfoModal, {
        cssClass: "modal-header-email-exists",
        componentProps: {
          title: title !== 'NULL' ? 'Reminder removed' : 'Alert',
          message: title !== 'NULL' ? `We have deactivated reminders for "${title}".` : 'The selected inventory is not exists.',
        },
        backdropDismiss: true,
      });
    },
  },
};
