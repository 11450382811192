<template>
  <ion-row class="collapsable-menu-custom">
    <ion-col>
      <ion-list>
        <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
          <ion-item
            id="TestA_plp_filter_size"
            lines="none"
            class="filter-item-head"
            :class="{ active: isShown }"
            @click="toggle()"
          >
            <div class="text-icon">
              <div class="ion-text-uppercase parent-text">
                {{ name }}
              </div>
              <div>
                <span
                  class="ion-text-uppercase selected-search ion-padding-end"
                >
                  {{ activeFilters }}
                </span>
                <span
                  :class="!isShown ? 'arrow-drop  rotate-caret' : 'arrow-drop'"
                >
                  <img
                    alt="Trailers Near Me"
                    src="@/assets/icons/caretDownBlue.svg"
                  >
                </span>
              </div>
            </div>
          </ion-item>

          <template
            v-for="filter in filters"
            :key="filter"
          >
            <ion-item
              v-if="isShown"
              :id="`TestA_plp_filter_${filter.label}`"
              lines="none"
              class="filter-collapse"
            >
              <ion-checkbox
                slot="start"
                class="filter-checkbox"
                :value="filter.name"
                :checked="visibility[filter.name]"
                @ionChange="toggleSelectedSizes($event, filter.name)"
              />
              <ion-label class="ion-text-uppercase child-text">
                {{ filter.label }}
              </ion-label>
            </ion-item>
            <range-filter
              v-if="isShown && visibility[filter.name]"
              :name="filter.name"
              :label="filter.label"
              :min="filter.min"
              :max="filter.max"
              :step="filter.step"
              :min-span="filter.minSpan"
              :is-active="visibility[filter.name]"
              :filter-class="SizeFilterModel"
              @remove="onRemoveFilter($event)"
              @add="onAddFilter($event)"
              @submitted="handleValueSubmitted()"
            />
          </template>
        </div>
      </ion-list>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonLabel,
  IonItem,
  IonCheckbox,
  IonList,
} from "@ionic/vue";
import RangeFilter from "./RangeFilter.vue";
import {mapGetters, mapState} from "vuex";
import SizeFilterModel from "../../models/filters/range/SizeFilter";
import { scrollPlpToTop } from "@/utils/ProductListingPageUtil";

export default {
  name: "SizeFilter",
  components: {
    RangeFilter,
    IonCol,
    IonRow,
    IonLabel,
    IonList,
    IonCheckbox,
    IonItem,
  },
  data: function () {
    const { hasFilter } = this.$store.getters;
    return {
      name: "SIZE",
      isShown: false,
      visibility: {
        width: !!hasFilter('width'),
        height: !!hasFilter('height'),
        length: !!hasFilter('length'),
      },
      SizeFilterModel,
    };
  },
  computed: {
    ...mapState(["searchFilter"]),
    ...mapGetters(['hasFilter']),
    filters() {
      const { filterLimits } = this.$store.state;
      const length_min = filterLimits?.size?.length.min || 3;
      const length_max = filterLimits?.size?.length.max || 100;
      const width_min = filterLimits?.size?.width.min || 3;
      const width_max = filterLimits?.size?.width.max || 100;
      const height_min = filterLimits?.size?.height.min || 3;
      const height_max = filterLimits?.size?.height.max || 100;

      return [
        {
          name: "length",
          label: "LENGTH",
          min: length_min,
          max: length_max,
        },
        {
          name: "width",
          label: "WIDTH",
          min: width_min,
          max: width_max,
        },
        {
          name: "height",
          label: "HEIGHT",
          min: height_min,
          max: height_max,
        },
      ];
    },
    activeFilters() {
      const existingOptions = [];
      for (let option of this.filters) {
        if (this.existsInFilter(option)) {
          existingOptions.push(option.name);
        }
      }
      return existingOptions.join(", ");
    },
  },
  methods: {
    toggleSelectedSizes($event, key) {
      this.visibility[key] = $event.detail.checked;
    },
    toggle() {
      this.isShown = !this.isShown;
    },
    existsInFilter(filter) {
      return !!this.hasFilter(filter.name, null);
    },
    onRemoveFilter($event) {
      this.visibility[$event.name] = false;
    },
    onAddFilter($event) {
      this.visibility[$event.name] = true;
    },
    handleValueSubmitted() {
      scrollPlpToTop();
    },
  },
};
</script>
