<template>
  <ion-grid
    v-if="!isMobile"
    class="about-seller-desktop"
  >
    <ion-row>
      <ion-col
        v-if="!isDesktop"
        size-xl="3"
        style="margin-bottom: 24px"
        size-md="12"
        class="ion-text-start"
      />
      <ion-col
        v-if="isDesktop"
        size-xl="2"
        class="ion-text-start"
      >
        <ion-text
          id="TestA_pdp_title_desktop"
          class="title"
        >
          About The Seller
        </ion-text>
      </ion-col>
      <ion-col
        size-xl="8"
        size-md="12"
        class="ion-no-padding content-box"
      >
        <div :class="Object.keys(productData).length === 0 ? 'custom-skeleton-loader' : ''" />
        <ion-row>
          <ion-col
            size="5"
            size-xl="5"
          >
            <ion-row>
              <ion-col size="12">
                <DealerNameAndLink
                  :dealer-name="productData?.dealer?.tt_username ?? productData?.dealer?.name ?? ''"
                  :website-domain="productData?.dealer?.website?.domain ?? ''"
                  :dealer-id="productData?.dealer?.id ?? 0"
                  :dealer-location-id="productData?.dealer_location?.id ?? 0"
                  :inventory-id="productData?.id ?? 0"
                  :inventory-title="productData?.inventory_title ?? ''"
                  location="PDP_ABOUT_SELLER_DESKTOP"
                />
              </ion-col>
              <ion-col
                size="12"
                style="margin-top: -5px"
              >
                <ion-text
                  v-if="productData?.dealer?.is_private"
                  id="TestA_pdp_aboutselleraddress_desktop"
                  class="address"
                >
                  {{ dealerAddress }}
                </ion-text>
                <ion-text
                  v-else
                  id="TestA_pdp_aboutselleraddress_desktop"
                  class="address"
                >
                  {{ address }}
                </ion-text>
              </ion-col>
              <ion-col
                size="12"
                style="margin-top: -11px"
              >
                <span
                  v-if="isLocationActive"
                  class="distance"
                >{{ setDistance }} miles</span>
                <span
                  v-else
                  class="distance location"
                  @click="isTablet ? showLocationBarMobile() : showLocationBar()"
                >SELECT LOCATION</span>
                <!-- <ion-text class="view-map">
                  view map >
                  <ion-icon class="map" :icon="chevronForwardOutline"></ion-icon>
                </ion-text> -->
              </ion-col>
              <ion-col
                class="d-flex"
                size="12"
                style="margin-left: -4px"
              >
                <CallSellerButton
                  id="callseller_pdp"
                  :dealer-location-phone="productData?.dealer_location?.phone"
                  :inventory-id="productData?.id"
                  :inventory-title="productData?.inventory_title"
                  :dealer-location-id="productData?.dealer_location?.id"
                  :dealer-id="productData?.dealer?.id"
                  :disabled="productData?.availability === 'sold' || productData?.is_archived === 1"
                  style="margin-top: 0"
                  class="btn btn-call"
                  location="PDP_ABOUT_SELLER"
                  :is-dealer-private="productData?.dealer?.is_private || false"
                >
                  Call
                  <ion-icon :icon="chevronForwardOutline" />
                </CallSellerButton>
                <ion-button
                  id="contactseller_pdp"
                  :disabled="productData?.availability === 'sold' || productData?.is_archived === 1"
                  class="btn btn-seller-email"
                  @click="openContactSeller"
                >
                  Email
                  <ion-icon
                    class="email"
                    :icon="chevronForwardOutline"
                  />
                </ion-button>
                <ion-button
                  id="seller_directions_pdp"
                  class="btn btn-seller-direction"
                  :class="{ active: isBuyerLocationPresent() }"
                  :disabled="!isBuyerLocationPresent()"
                  @click="openDirectionsOnMap"
                >
                  Directions 
                  <ion-icon
                    class="email"
                    :icon="chevronForwardOutline"
                  />
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-col
            size="7"
            size-xl="7"
            class="ion-no-padding map-box"
          >
            <SellerLocationMap
              :lat="lat"
              :lng="lng"
              :zoom-level="zoomLevel"
              :address="address"
              :geo-code="geoCode"
            />
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid
    v-if="isMobile"
    class="about-seller-mobile"
  >
    <ion-row>
      <ion-col
        size="12"
        class="ion-text-start"
        style="margin-bottom: 10px"
      >
        <ion-text
          id="TestA_pdp_title_mobile"
          class="title"
        >
          About The Seller
        </ion-text>
      </ion-col>
      <ion-col
        size="12"
        class="ion-no-padding map-box"
      >
        <SellerLocationMap
          :lat="lat"
          :lng="lng"
          :zoom-level="zoomLevel"
          :address="address"
          :geo-code="geoCode"
        />
      </ion-col>
      <ion-col size="12">
        <ion-row>
          <ion-col size="12">
            <DealerNameAndLink
              :dealer-name="productData?.dealer?.tt_username ?? productData?.dealer?.name ?? ''"
              :website-domain="productData?.dealer?.website?.domain ?? ''"
              :dealer-id="productData?.dealer?.id ?? 0"
              :dealer-location-id="productData?.dealer_location?.id ?? 0"
              :inventory-id="productData?.id ?? 0"
              :inventory-title="productData?.inventory_title ?? ''"
              location="PDP_ABOUT_SELLER_MOBILE"
            />
          </ion-col>
          <ion-col
            size="12"
            style="margin-top: -5px"
          >
            <ion-text
              v-if="productData?.dealer?.is_private"
              id="TestA_pdp_aboutselleraddress_mobile"
              class="address"
            >
              {{ dealerAddress }}
            </ion-text>
            <ion-text
              v-else
              id="TestA_pdp_aboutselleraddress_mobile"
              class="address"
            >
              {{ address }}
            </ion-text>
          </ion-col>
          <ion-col
            size="12"
            style="margin-top: -11px"
          >
            <span
              v-if="isLocationActive"
              class="distance"
            >{{ setDistance }} miles</span>
            <span
              v-else
              class="distance location"
              @click="showLocationBarMobile"
            >SELECT LOCATION</span>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col
        size="12"
        style="margin-left: -4px"
      >
        <ion-row>
          <ion-col size="6">
            <CallSellerButton
              id="callseller_pdp"
              :dealer-location-phone="productData?.dealer_location?.phone"
              :inventory-id="productData?.id"
              :inventory-title="productData?.inventory_title"
              :dealer-location-id="productData?.dealer_location?.id"
              :dealer-id="productData?.dealer?.id"
              :stock="productData?.stock"
              :disabled="productData?.availability === 'sold' || productData?.is_archived === 1"
              style="margin-top: 0"
              class="btn btn-call"
              location="PDP_ABOUT_SELLER"
              :is-dealer-private="productData?.dealer?.is_private || false"
            >
              Call
              <ion-icon :icon="chevronForwardOutline" />
            </CallSellerButton>
          </ion-col>
          <ion-col size="6">
            <ion-button
              id="contactseller_pdp"
              :disabled="productData?.availability === 'sold' || productData?.is_archived === 1"
              class="btn btn-seller-email"
              @click="openContactSeller"
            >
              Email
              <ion-icon
                class="email"
                :icon="chevronForwardOutline"
              />
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button
              id="seller_directions_pdp"
              class="btn btn-seller-direction"
              :class="{ active: isBuyerLocationPresent() }"
              :disabled="!isBuyerLocationPresent()"
              @click="openDirectionsOnMap"
            >
              Directions
              <ion-icon
                class="email"
                :icon="chevronForwardOutline"
              />
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonText, IonIcon, IonButton } from "@ionic/vue";
import { chevronForwardOutline } from "ionicons/icons";
import SellerLocationMap from "@/components/SellerLocationMap.vue";
import ContactSellerForm from "../components/ContactSellerForm.vue";
import { modal, dealerAddress } from "../mixins";
import { mapState } from "vuex";
import defaultLogo from "@/assets/icons/sellerLogoDefault.svg";
import CallSellerButton from "@/components/leads/CallSellerButton.vue";
import DealerNameAndLink from "@/components/productdetails/DealerNameAndLink.vue";
export default {
  name: "AboutSeller",
  components: {
    DealerNameAndLink,
    CallSellerButton,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonIcon,
    SellerLocationMap,
    IonButton,
  },
  mixins: [modal, dealerAddress],
  props: ["productData", "geoCode"],
  data() {
    return {
      chevronForwardOutline,
      isLocationActive: false,
      setDistance: 0,
      name: "",
      lat: 0,
      lng: 0,
      zoomLevel: 5,
      ContactSellerForm,
      disableCallModal: false,
      defaultLogo,
    };
  },
  computed: {
    ...mapState({
      userGeolocation: (state) => state.userGeoLocation,
      distance: (state) => state.distance,
    }),
    sellerName() {
      return this.productData?.dealer?.name;
    },
  },
  watch: {
    geoCode: function () {
      const { userGeoLocation } = this.$store.state;
      const distance = this.calculateDistance(
        parseFloat(userGeoLocation[0]),
        parseFloat(userGeoLocation[1]),
        this.geoCode.lat,
        this.geoCode.lng,
      );
      this.setDistance = isNaN(Math.ceil(distance)) ? 0 : Math.ceil(distance);
    },
    productData: function (newVal) {
      if (Object.keys(newVal).length !== 0) {
        this.getAddress(newVal?.dealer_location);
      }
    },
    userGeolocation: function (newVal, oldVal) {
      if (newVal.length === oldVal.length || newVal.length !== 0) {
        //calculateDistance
        const distance = this.calculateDistance(
          parseFloat(newVal[0]),
          parseFloat(newVal[1]),
          this.geoCode.lat,
          this.geoCode.lng,
        );
        this.setDistance = isNaN(Math.ceil(distance)) ? 0 : Math.ceil(distance);
      } else {
        this.isLocationActive = false;
      }
    },
    distance: function () {
      this.calculateZoomLevel();
    },
  },
  mounted() {
    this.getAddress(this.productData?.dealer_location);
    this.getDealerAddress(this.productData?.dealer_location);
    this.calculateZoomLevel();
    //calculateDistance
    const { userGeoLocation } = this.$store.state;
    if (Object.keys(this.geoCode).length > 0) {
      if (userGeoLocation.length !== 0) {
        const distance = this.calculateDistance(
          parseFloat(userGeoLocation[0]),
          parseFloat(userGeoLocation[1]),
          this.geoCode.lat,
          this.geoCode.lng,
        );
        this.setDistance = isNaN(Math.ceil(distance)) ? 0 : Math.ceil(distance);
      }
    }
  },
  methods: {
    openContactSeller() {
      if (!this.isMobile) {
        window.dispatchEvent(new CustomEvent("goToContactSellerForm", {
          detail: {
            duration: 1000,
          },
        }));
        return;
      }

      this.openModal(ContactSellerForm, {
        cssClass: "modal-header-contact-seller",
        componentProps: {
          modalData: this.productData,
          location: "PDP_ABOUT_SELLER",
        },
        backdropDismiss: true,
      });
    },
    calculateZoomLevel() {
      //convert miles to km
      let km = parseFloat(this.distance);
      if (this.distance.endsWith("mi")) {
        km /= 0.621371;
      }

      //get zoomlevel
      const t = 40000 / (km / 2);
      const zoom = Math.log2(t);
      this.zoomLevel = Math.ceil(zoom);
    },
    isBuyerLocationPresent(){
      return this.isLocationActive;
    },
    openDirectionsOnMap(){
      const dealerLatitude = this.productData?.dealer_location?.latitude;
      const dealerLongitude = this.productData?.dealer_location?.longitude;
      const destination = `${dealerLatitude},${dealerLongitude}`;
      let url = `https://www.google.com/maps/dir/?api=1&destination=${destination}&travelmode=driving`;
      if(this.isLocationActive){
        const { userGeoLocation } = this.$store.state;
        const userLatitude = parseFloat(userGeoLocation[0]);
        const userLongitude = parseFloat(userGeoLocation[1]);
        const start = `${userLatitude},${userLongitude}`;
        url = `https://www.google.com/maps/dir/?api=1&origin=${start}&destination=${destination}&travelmode=driving`;
      }
      window.open(url, '_blank');
    },
    showLocationBar() {
      this.$store.commit("SET_SHOW_LOCATION_DESKTOP", true);
    },
    showLocationBarMobile() {
      this.$store.commit("SET_SHOW_LOCATION_MOBILE", true);
    },
    // Haversine formula
    calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371;
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      const m = d * 0.621371; //converted to miles
      if (!isNaN(m)) this.isLocationActive = true;
      return m;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
  },
};
</script>
