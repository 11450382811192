<template>
  <ion-header class="ion-no-border">
    <ion-grid class="modal-header-response-message">
      <div class="container-response-message">
        <div class="response-message-header">
          <span
            id="contact_seller_confirmation"
            class="close-response-message-modal"
          ><ion-icon
            :icon="closeSharp"
            aria-label="close dialog"
            @click="closeModal"
          /></span>
        </div>
        <div class="success-response">
          <div>
            <img src="@/assets/icons/successResponse.svg">
          </div>
          <div class="message-status">
            Message was sent
          </div>
          <div class="greeting-response">
            Thank you for your interest in this item! Your seller will be in
            touch with you shortly.
          </div>
          <div class="seller-contact-detailer">
            <span class="seller ion-text-uppercase">seller's phone number</span>
            <span class="seller-phone-number">{{ formatPhoneNumber }}</span>
          </div>
        </div>
      </div>
    </ion-grid>
  </ion-header>
</template>

<script>
import { IonIcon, IonGrid, IonHeader, modalController } from "@ionic/vue";

import { closeSharp } from "ionicons/icons";

export default {
  name: "ContactSellerForm",
  components: {
    IonGrid,
    IonHeader,
    IonIcon,
  },
  props: {
    phoneNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      closeSharp,
    };
  },
  computed: {
    formatPhoneNumber() {
      const x = this.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      return !x[2]
        ? `+1 (${x[1]})`
        : `+1 (${x[1]})-${x[2]}${x[3] ? `${"-" + x[3]}` : ""}`;
    },
  },
  methods: {
    async closeModal() {
      await modalController.dismiss();
    },
  },
};
</script>
