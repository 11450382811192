<template>
  <ion-grid class="product-list-sort-desktop">
    <ion-row>
      <ion-col
        size-sm="3"
        size-xs="4"
        size-xl="8"
        size="8"
        class="ion-text-start"
      >
        <template v-if="fetchingProducts">
          <div style="width: 120px;">
            <SkeletonLoader style="height: 20px;" />
          </div>
        </template>
        <template v-else>
          <ion-text
            id="TestA_plp_result_count"
            class="results"
          >
            {{ totalProducts }} results
          </ion-text>
        </template>
      </ion-col>
      <ion-col
        v-click-outside="hideList"
        size-sm="9"
        size-xs="8"
        size-xl="4"
        size="4"
        class="ion-text-end"
      >
        <ion-text class="sort-txt">
          Sort By
        </ion-text>
        <ion-text
          id="sort_plp"
          class="value"
          @click="showSortList = !showSortList"
        >
          {{
            sortOptions[selectedSortIndex]?.name
          }}
        </ion-text>
        <span
          :class="!showSortList ? '' : 'rotate-caret'"
          style="cursor:pointer"
          @click="showSortList = !showSortList"
        >
          <img
            style=""
            alt="caretDown"
            src="@/assets/icons/caretBlue.svg"
          ></span>
        <ion-list v-if="showSortList">
          <template
            v-for="(val, index) in sortOptions"
            :key="index"
          >
            <ion-item
              id="sort_plp"
              class="sort-list"
              lines="none"
              @click="selectSortItem(index)"
            >
              <ion-label>{{ val.name }}</ion-label>
            </ion-item>
          </template>
        </ion-list>
        <Popper
          v-if="sortOptions[selectedSortIndex]?.name === 'Within range'"
          class="infoBubble"
          :content="showInfo"
          hover
          placement="top"
        >
          <span><img
            style="margin-left: 6px;"
            src="@/assets/infobubble.webp"
            alt="@/assets/infobubble.webp"
          ></span>
        </Popper>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>
<script>
import { IonGrid, IonCol, IonRow, IonText, IonLabel, IonItem, IonList } from "@ionic/vue";
import { mapState, mapActions, useStore } from "vuex";
import SortFilter from "../../models/filters/SortFilter";
import { LOCATION_SORT_LIST, NO_LOCATION_SORT_LIST, DEFAULT_LOCATION_SORT, DEFAULT_NO_LOCATION_SORT } from "@/consts/sorts";
import { useRoute } from "vue-router";
import { computed } from "vue";
import SkeletonLoader from "@/components/content/SkeletonLoader.vue";

export default {
  name: "ProductListSort",
  components: {
    SkeletonLoader,
    IonGrid,
    IonCol,
    IonRow,
    IonText,
    IonLabel,
    IonItem,
    IonList,
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const fetchingProducts = computed(() => store.state.fetchingProducts);

    return {
      route,
      fetchingProducts,
    };
  },
  data() {
    return {
      showSortList: false,
      selectedSortItem: 0,
      showInfo:"GIVES YOU THE BEST WAY TO SEE A VARIETY OF OPTIONS WITHIN THE SELECTED RANGE",
    };
  },
  watch: {
    userLocation: function () {
      this.$forceUpdate();
    },
  },
  computed: {
    ...mapState({
      location: (state) => state.location,
      userLocation: (state) => state.userGeoLocation,
      totalProducts: (state) =>
        state.totalProducts?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      type_id: (state) => state.searchApiFields.type_id,
    }),
    sortOptions() {
      if(this.location) {
        return this.type_id === 5 ? LOCATION_SORT_LIST.slice(0, -2) : LOCATION_SORT_LIST;
      } else {
        return this.type_id === 5 ? NO_LOCATION_SORT_LIST.slice(0, -2) : NO_LOCATION_SORT_LIST;
      }
    },
    selectedSortIndex() {
      const index = this.sortOptions.findIndex((x) => x.apiVal === this.$store.getters.sortOption);
      if(index === -1) {
        if(this.location) {
          this.$store.commit("SET_SORTOPTION", DEFAULT_LOCATION_SORT);
          return this.sortOptions.findIndex((x) => x.apiVal === DEFAULT_LOCATION_SORT);
        } else {
          this.$store.commit("SET_SORTOPTION", DEFAULT_NO_LOCATION_SORT);
          return this.sortOptions.findIndex((x) => x.apiVal === DEFAULT_NO_LOCATION_SORT);
        }
      }
      return index;
    },
  },
  methods: {
    ...mapActions([
      "addSearchFilter",
      "clearSearchFilter",
      "clearAllSearchFilter",
    ]),
    selectSortItem(val) {
      this.showSortList = false;
      const sortApiVal = this.sortOptions[val].apiVal;
      this.$store.commit("SET_SORTOPTION", sortApiVal);

      this.addSearchFilter(
        new SortFilter({
          name: "sort",
          sort: sortApiVal,
        }),
      );
    },
    hideList() {
      this.showSortList = false;
    },
  },
};
</script>
<style scoped>
  :deep(.inline-block) {
    display: none;
  }
  :deep(.popper) {
    font-family: "Object Sans Heavy",sans-serif;
    text-align: left;
    font-size:13px;
    @media (max-width: 767px) {
      transform: translate(255px, 0) !important;
    }
  }

  :deep(#arrow) {
    transform: translate(30px, 0) !important;
  }

  </style>
