<template>
  <div class="social-auth-container">
    <!--<div-->
    <!--  class="btn-facebook"-->
    <!--  @click="facebookLogin"-->
    <!--&gt;-->
    <!--  <div class="wrapper">-->
    <!--    <img-->
    <!--      class="img-logo"-->
    <!--      src="@/assets/icons/facebook-login.svg"-->
    <!--      alt="img"-->
    <!--    >-->
    <!--  </div>-->
    <!--  <div class="wrapper">-->
    <!--    <div class="txt-facebook">-->
    <!--      Continue with Facebook-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</div>-->
    <div
      class="btn-google"
      @click="googleLogin"
    >
      <div class="wrapper">
        <img
          class="img-logo"
          src="@/assets/icons/google-login.svg"
          alt="img"
        >
        <div class="txt-facebook">
          Login with Google
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { appBaseUrl } from "@/config";
import { LogRocketService } from "@/services/LogRocketService";

export default defineComponent({
  name: "SocialAuth",
  components: {},
  props: {
    callback: {
      type: String,
      default: "",
    },
    location: {
      type: String,
      default: "SIGN_IN_MODAL",
    },
  },
  methods: {
    googleLogin() {
      LogRocketService.track(LogRocketService.events.login_google, {
        location: this.location,
      });

      // Wait for a second for LogRocket to track the event before redirect
      setTimeout(() => {
        window.location = `${appBaseUrl}/api/user/auth/google?callback=${this.callbackUrl()}`;
      }, 1000);
    },
    facebookLogin() {
      LogRocketService.track(LogRocketService.events.login_facebook, {
        location: this.location,
      });

      // Wait for a second for LogRocket to track the event before redirect
      setTimeout(() => {
        window.location = `${appBaseUrl}/api/user/auth/facebook?callback=${this.callbackUrl()}`;
      }, 1000);
    },
    callbackUrl() {
      const callback = this.callback.trim();

      if (callback.length === 0) {
        return window.location.origin;
      }

      return callback;
    },
  },
});
</script>
