import { getRecaptchaToken } from "@/services/RecaptchaService";

export const defaultCallSellerLeadFirstName = "TrailerTrader";

export const defaultCallSellerLeadLastName = "";

export const defaultContactSellerLeadFirstName = "TrailerTrader";

export const defaultContactSellerLeadLastName = "ContactLead";

export const defaultDWNavigationLeadFirstName = "TrailerTrader lead";

export const defaultDWNavigationLeadLastName = "A buyer has visited your website";

export const defaultInventoryShareLeadFirstName = "TrailerTrader lead";

export const defaultInventoryShareLeadLastName = "A buyer has shared your listing";

export const defaultCallSellerEmail = "this_is_a_call_lead@trailertrader.com";

export async function generateCallSellerLeadPayload(overridePayload = {}) {
  let comments = "A customer has tried to contact you Via the Call button on TrailerTrader. You will find the name and telephone number they provided under the Customer section.";

  // If pdp_url prop exists in the payload, then we change the comment message.
  if (typeof overridePayload.pdp_url === "string") {
    comments = `A customer has contacted you directly via the Call button on TrailerTrader. That customer is interested in ${overridePayload.pdp_url}.`;
  }

  const defaultPayload = {
    dealer_id: null,
    first_name: defaultCallSellerLeadFirstName,
    last_name: defaultCallSellerLeadLastName,
    phone_number: "0000000000",
    comments: comments,
    email_address: defaultCallSellerEmail,
    inquiry_type: "inventory",
    dealer_location_id: null,
    lead_types: [
      "nonlead",
    ],
    inventory: {
      inventory_id: null,
    },
    captcha: await getRecaptchaToken("call_seller"),
  };

  return {
    ...defaultPayload,
    ...overridePayload,
  };
}

export async function generateDWNavigationLeadPayload(overridePayload = {}) {
  let comments = `A buyer has clicked to access your website (${overridePayload.website_domain}) after seeing your listing.`;

  if (typeof overridePayload.pdp_url === "string") {
    comments = `A buyer has clicked to access your dealer website (${overridePayload.website_domain}) after seeing your listing at ${overridePayload.pdp_url}.`;
  }

  const defaultPayload = {
    comments,
    dealer_id: null,
    first_name: defaultDWNavigationLeadFirstName,
    last_name: defaultDWNavigationLeadLastName,
    phone_number: "0000000000",
    email_address: "this_is_a_dw_navigation_lead@trailertrader.com",
    inquiry_type: "inventory",
    dealer_location_id: null,
    lead_types: [
      "nonlead",
    ],
    inventory: {
      inventory_id: null,
    },
    captcha: await getRecaptchaToken("dw_navigation"),
  };

  return {
    ...defaultPayload,
    ...overridePayload,
  };
}

export async function generateListingShareLeadPayload(overridePayload = {}) {
  let comments = `A buyer has shared your listing.`;

  if (typeof overridePayload.pdp_url === "string") {
    comments = `A buyer has shared your listing at ${overridePayload.pdp_url}.`;
  }

  const defaultPayload = {
    comments,
    dealer_id: null,
    first_name: defaultInventoryShareLeadFirstName,
    last_name: defaultInventoryShareLeadLastName,
    phone_number: "0000000000",
    email_address: "this_is_a_listing_share_lead@trailertrader.com",
    inquiry_type: "inventory",
    dealer_location_id: null,
    lead_types: [
      "nonlead",
    ],
    inventory: {
      inventory_id: null,
    },
    captcha: await getRecaptchaToken("listing_share"),
  };

  return {
    ...defaultPayload,
    ...overridePayload,
  };
}

