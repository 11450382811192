import { FacebookService } from "@/services/FacebookService";
import { LogRocketService } from "@/services/LogRocketService";

LogRocketService.init();
FacebookService.init();

import { createSSRApp, createApp } from "vue";
import App from "./App.vue";
import createRouter from "./router";
import createStore from "./store";
import "./polyfill";

import { IonicVue } from "@ionic/vue";

/* Import directives here */
import vClickOutside from "click-outside-vue3";

import { VueWindowSizePlugin } from "vue-window-size/option-api";

/* Import global mixin */
import { platforms, domain } from "./mixins";

/* Import Global component */
import { Footer, MegaMenuOverlay } from "@/components";

/* Import global plugins */
import { createHead } from "@vueuse/head";
import VueGtag from "vue-gtag";
import { createGtm } from "@gtm-support/vue-gtm";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./scss/global.scss";

/* Vee Validate */
import { defineRule, configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import { required, numeric, digits, email, min, max, alpha_num, max_value, regex, between } from "@vee-validate/rules";

/* Vue virtual scroller */
import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

/* Tooltip */
import Popper from "vue3-popper";
import { isSSR } from "@/utils/SSRUtil";
import { setStore } from "./services/StoreService";
import { setRouter } from "./services/RouterService";

export default function () {
  const store = createStore();
  const router = createRouter({ store });

  defineRule("required", required);
  defineRule("numeric", numeric);
  defineRule("digits", digits);
  defineRule("email", email);
  defineRule("min", min);
  defineRule("max", max);
  defineRule("between", between);
  defineRule("regex", regex);
  defineRule("confirmed", (value, [target], ctx) => {
    if (value === ctx.form[target]) {
      return true;
    }
    return "Both Passwords must match";
  });
  defineRule("length_num", (value) => {
    if (!value || /^[0-9]+\u0027?[0-9]*\u0022?$/.test(value) || /^[0-9]+[.]?[0-9]*$/.test(value)) {
      return true;
    }
    return configure({
      generateMessage: localize("en", {
        messages: {
          length_num: `The {field} must have only the numbers, dot, single quote and double quotes`,
        },
      }),
    });
  });
  defineRule("valid_number", (value) => {
    if (String(value).length <= 22) {
      return true;
    }

    return configure({
      generateMessage: localize("en", {
        messages: {
          valid_number: `The value cannot exceed 22 digits`,
        },
      }),
    });
  });
  defineRule("exact_num", (value) => {
    let typeCheck = Number(value);
    if (value === undefined || (String(value).length === 0 || String(value).length === String(typeCheck).length)) {
      return true;
    }
    return configure({
      generateMessage: localize("en", {
        messages: {
          exact_num: `Please remove unnecessary zeros`,
        },
      }),
    });
  });
  defineRule("alpha_num", alpha_num);
  defineRule("max_value", max_value);
  defineRule("decimal", (value) => {
    let typeCheck = Number(value);
    if (!isNaN(typeCheck) && (typeCheck % 1 === 0 || typeCheck % 1 !== 0)) {
      return true;
    }
    return configure({
      generateMessage: localize("en", {
        messages: {
          decimal: `The {field} must be number`,
        },
      }),
    });
  });
  defineRule("max_vin", (value) => {
    if (value?.length <= 17) {
      return true;
    }
    return configure({
      generateMessage: localize("en", {
        messages: {
          max_vin: `The {field} must be less than 17`,
        },
      }),
    });
  });

  defineRule("phone_number", (value) => {
    if (!value || /^[0-9]{10}$/.test(value)) {
      return true;
    }
    return configure({
      generateMessage: localize("en", {
        messages: {
          phone_number: `The {field} must contain 10 digits. Only numbers,  no signs, spaces, parenthesis`,
        },
      }),
    });
  });

  defineRule("trim", (value) => {
    if ((String(value).trim() === String(value)) || ((String(value).length > 1) && (String(value).length === String(value).trim().length + 1) && (String(value).charAt(String(value).length -1) === ' '))) {
      return true;
    }
    return configure({
      generateMessage: localize("en", {
        messages: {
          trim: `Please remove unnecessary spaces`,
        },
      }),
    });
  });

  defineRule("description_length", (value) => {
    //Remove enter value
    const newValue = (value || "").replaceAll("\n", "").replaceAll("<br>", "");
    if (newValue.length <= 1500) {
      return true;
    }
    return configure({
      generateMessage: localize("en", {
        messages: {
          description: `The description should be less than 1500 letters`,
        },
      }),
    });
  });

  configure({
    validateOnBlur: false, // controls if `blur` events should trigger validation with `handleChange` handler
    validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
    validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
    validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    generateMessage: localize("en", {
      messages: {
        required: `The {field} is required`,
        email: `The {field} must be a valid email`,
        numeric: `The {field} must be a positive number`,
        digits: `The {field} must be of length 0:{length}`,
        min: `The {field} must be 0:{length} or more Characters`,
        max: `The {field} must be 0:{length} or less Characters`,
        between: `Your input should be within 0:{min} and 1:{max}`,
        alpha_num: `The {field} must have only an alphabet and a number`,
        max_value: `The {field} must not exceed 0:{max}`,
        regex: `The {field} must contain Special, UpperCase, LowerCase, Numeric Characters`,
      },
    }),
  });

  const app = (isSSR() ? createSSRApp : createApp)(App).use(IonicVue, {
    mode: "md",
  });

  app.use(router);

  app.use(VueWindowSizePlugin);

  app.use(VueGtag, {
    bootstrap: false,
    config: { id: "G-0R5D9CLHWN" },
  });

  let enableGtm = false;

  if (import.meta.env.VITE_APP_ENABLE_GTM) {
    enableGtm = parseInt(import.meta.env.VITE_APP_ENABLE_GTM) === 1;
  }

  app.use(
    createGtm({
      id: "GTM-55HTZBK", // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
      defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
      compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      enabled: enableGtm, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      ignoredViews: ["listingconfirmation"], // Don't trigger events for specified router names (case insensitive) (optional)
    }),
  );
  app.component("RecycleScroller", RecycleScroller);
  app.component("Footer", Footer);
  app.component("MegaMenuOverlay", MegaMenuOverlay);

  app.mixin(platforms);
  app.mixin(domain);

  app.use(store);

  setStore(store);

  setRouter(router);

  app.use(vClickOutside);

  app.component("Popper", Popper);

  const head = createHead();
  app.use(head);

  return {
    app,
    head,
    router,
    store,
  };
}
