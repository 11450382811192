import { createStore } from "vuex";
import GroupItemFilter from "@/models/filters/group/GroupItemFilter";
import RangeFilter from "@/models/filters/range/RangeFilter";
import { fromApiFields, parseRangeFilters } from "@/models/filters/FilterManager";
import GeoLocationFilter from "@/models/filters/GeoLocationFilter";
import SortFilter from "@/models/filters/SortFilter";
import { TYPE_EQUIPMENT_ID, TYPE_TITLES } from "@/consts/category";
import { reverseGeocode } from "@/services/LocationService";
import { getSysConfigs } from "@/services/SysConfigService";
import { ACTIVE_MENU } from "@/consts/menus";
import { isSSR } from "@/utils/SSRUtil";
import { pathFromQueryParams } from "@/utils/UrlParamUtil";
import { DEFAULT_LOCATION_SORT, DEFAULT_NO_LOCATION_SORT, LOCATION_SORT_LIST, NO_LOCATION_SORT_LIST } from "@/consts/sorts";
import notifications from "./modules/notifications.js";
import trailers from "./modules/trailers.js";
import dealers from "./modules/dealers.js";
import seo from "./modules/seo";
import ads from "./modules/ads";
import { PRODUCT_LISTING_SEO } from "@/consts/seo";
import { websiteBaseUrl } from "@/config";
import { scrollPlpToTop } from "@/utils/ProductListingPageUtil";

const debug = process.env.NODE_ENV !== "production";

export default function () {
  return createStore({
    state: {
      showOverlay: false,
      showProfileOverlay: false,
      showMegaMenuOverlay: false,
      showLocationMessage: false,
      locationMessage: "",
      location: "",
      userGeoLocation: [],
      distance: "100mi",
      activeMenu: null,
      toggleView: false,

      //productList parameters
      showLocationDesktop: false,
      showLocationMobile: false,
      pageTitle: "",
      searchFilter: [],
      totalProducts: 0,
      fetchingProducts: false,
      typeAggregations: {},
      categoryAggregations: {},
      filterLimits: null,
      bannerList: null,
      searchApiFields: {},
      limitingFilters: [],
      selectedFilter: [],
      showSelectedFilter: false,
      removedSeoFilters: [],
      //contactseller parameters
      email: "",
      message: "Hi, I'm interested in your trailer, could you please contact me?",
      megaMenuList: [],
      inventoryProduct: {},
      sortOption: null,
      activeCategory: "General Trailers",

      //user Authorization / Authentication
      isAuthenticated: false,
      userRegistrationEmail: "",
      userData: {},
      userFormToggle: true,
      // authToken: !isSSR() ? localStorage.getItem('token') : ''
      authToken: null,
      showProfile: false,
      isUserAuthenticated: false,
      redirectUrl: null,
    },
    mutations: {
      SET_PROFILE_OVERLAY(state, value) {
        state.showProfileOverlay = value;
      },
      SET_OVERLAY(state, value) {
        state.showOverlay = value;
      },
      SET_LOCATIONMESSAGE(state, value) {
        state.locationMessage = value;
      },
      SET_SHOWLOCATIONMESSAGE(state, value) {
        state.showLocationMessage = value;
      },
      SET_LOCATION(state, value) {
        state.location = value;
      },
      SET_DISTANCE(state, value) {
        state.distance = value;
      },
      SET_ACTIVE_MENU(state, value = ACTIVE_MENU.Navigation) {
        state.activeMenu = value;
      },

      //productList mutations
      SET_PAGE_TITLE(state, value) {
        state.pageTitle = value;
      },
      SET_SHOW_LOCATION_DESKTOP(state, value) {
        state.showLocationDesktop = value;
      },
      SET_SHOW_LOCATION_MOBILE(state, value) {
        state.showLocationMobile = value;
      },
      UPDATE_SEARCH_FILTER(state, value) {
        state.searchFilter = value.filter((v) => v.name !== 'page');
      },
      SET_MEGAMENU_OVERLAY(state, value) {
        state.showMegaMenuOverlay = value;
      },
      SET_TOTAL_PRODUCTS(state, value) {
        state.totalProducts = value;
      },
      SET_FETCHING_PRODUCTS(state, value) {
        state.fetchingProducts = value;
      },
      SET_TYPE_AGGREGATIONS(state, value) {
        state.typeAggregations = value;
      },
      SET_CATEGORY_AGGREGATIONS(state, value) {
        state.categoryAggregations = value;
      },
      SET_FILTER_LIMITS(state, value) {
        state.filterLimits = value;
      },
      SET_BANNER_LIST(state, value) {
        state.bannerList = value;
      },
      PATCH_SEARCH_API_FIELDS(state, value) {
        state.searchApiFields = { ...state.searchApiFields, ...value };
      },
      SET_SEARCH_API_FIELDS(state, value) {
        state.searchApiFields = { ...value, page: value?.page || 1 };
      },
      SET_TYPE_CATEGORY_MAP(state, value) {
        state.typeCategoryMap = value;
      },
      // Locations Mutations
      SET_USER_GEOLOCATION(state, value) {
        if(value?.length) {
          state.userGeoLocation = [+value[0], +value[1]];
        } else {
          state.userGeoLocation = [];
        }
      },
      SET_EMAIL(state, value) {
        state.email = value;
      },
      SET_MESSAGE(state, value) {
        state.message = value;
      },
      UPDATE_MEGAMENULIST(state, value) {
        state.megaMenuList = value;
      },
      SET_INVENTORY_PRODUCT(state, value) {
        state.inventoryProduct = value;
      },
      SET_SORTOPTION(state, value) {
        state.sortOption = value;
      },
      SET_IS_AUTHENTICATED(state, value) {
        state.isAuthenticated = value;
      },
      SET_USER_DATA(state, value) {
        state.userData = value;
        if (!isSSR()) {
          localStorage.setItem("user", JSON.stringify(value));
        }
      },
      SET_USER_FORM_TOGGLE(state, value) {
        state.userFormToggle = value;
      },
      SET_AUTH_TOKEN(state, value) {
        state.authToken = value;
        if (!isSSR()) {
          localStorage.setItem("token", value);
          window.tt_track?.setBearerToken(value);
        }
      },
      GET_AUTH_TOKEN(state, value) {
        state.authToken = value;
      },
      REMOVE_AUTH_TOKEN(state, value = "") {
        state.authToken = value;
        if (!isSSR()) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
      },
      SET_REDIRECT_URL(state, value) {
        state.redirectUrl = value;
      },
      SET_SHOW_PROFILE(state, value) {
        state.showProfile = value;
      },
      SET_ACTIVE_CATEGORY(state, value) {
        state.activeCategory = value;
      },
      SET_MAP_VIEW(state, value) {
        state.toggleView = value;
      },
      SET_AUTHENTICATED_ROUTE(state, value) {
        state.isUserAuthenticated = value;
      },
      SET_SEO_SEARCH_FILTER(state, value) {
        state.limitingFilters.push(value);
      },
      UPDATE_SEO_SEARCH_FILTER(state, value) {
        state.limitingFilters = value;
      },
      SET_SELECTED_FILTER(state, value) {
        state.selectedFilter = value;
      },
      SHOW_SELECTED_FILTER(state, value) {
        state.showSelectedFilter = value;
      },
      REMOVED_SEO_FILTER(state, value) {
        state.removedSeoFilters.push(value);
      },
      UPDATE_REMOVED_SEO_FILTER(state, value) {
        state.removedSeoFilters = value;
      },
    },
    actions: {
      async loadApiFields({ commit, dispatch }, apiFields) {
        const newApiFields = apiFields.fromQuery;
        dispatch("setLocation", newApiFields?.location);
        if (newApiFields?.lat && newApiFields?.lon) {
          dispatch("setUserGeolocation", [newApiFields.lat, newApiFields.lon]);
          if (!newApiFields.location) {
            const { data } = await reverseGeocode({
              lat: newApiFields.lat,
              lng: newApiFields.lon,
            });
            if (data.data?.[0]?.address) {
              newApiFields.location = `${data.data[0].address.city.trim()}, ${data.data[0].address.stateCode}`;
            }
          }
        } else {
          dispatch("setUserGeolocation", null);
        }
        dispatch("setLocation", newApiFields.location);

        if (newApiFields.sort) {
          // Validate sort option
          const sortOptions = newApiFields.location ? LOCATION_SORT_LIST : NO_LOCATION_SORT_LIST;
          const sortIndex = sortOptions.findIndex((s) => s.apiVal === newApiFields.sort);
          if(sortIndex !== -1) {
            commit("SET_SORTOPTION", newApiFields.sort);
          }
        }

        if (newApiFields.distance) {
          commit("SET_DISTANCE", newApiFields.distance);
        }
        if (!newApiFields.type_id) {
          newApiFields.type_id = TYPE_EQUIPMENT_ID;
        }

        commit("SET_PAGE_TITLE", TYPE_TITLES[newApiFields.type_id]);
        commit("SET_SEARCH_API_FIELDS", {
          ...newApiFields,
        });

        commit("UPDATE_SEARCH_FILTER", fromApiFields(newApiFields));
      },
      selectCategory({ commit, state }, categorySearch) {
        let newSearchFields;
        let newSearchFilter;
        const { category, typeId: type_id } = categorySearch;
        if (type_id !== state.searchApiFields.type_id) {
          newSearchFields = { category, type_id };
          newSearchFilter = [
            new GroupItemFilter({
              name: "category",
              value: category,
            }),
          ];
        } else {
          newSearchFields = { ...state.searchApiFields, category, type_id };
          delete newSearchFields.manufacturer;
          newSearchFilter = state.searchFilter.filter((f) => !["manufacturer", "category"].includes(f.name));
          newSearchFilter.push(new GroupItemFilter({
            name: "category",
            value: category,
          }));
        }
        commit("SET_PAGE_TITLE", TYPE_TITLES[type_id]);
        commit("SET_SEARCH_API_FIELDS", newSearchFields);
        commit("UPDATE_SEARCH_FILTER", newSearchFilter);
        commit("SET_SEO_SEARCH_FILTER", newSearchFilter);
        commit("SHOW_SELECTED_FILTER", false);
        commit("UPDATE_REMOVED_SEO_FILTER", []);
      },
      selectManufacturer({ commit }, manufacturerSearch) {
        const { manufacturer, typeId: type_id } = manufacturerSearch;
        const newSearchFields = { manufacturer, type_id };
        const newSearchFilter = [
          new GroupItemFilter({
            name: "manufacturer",
            value: manufacturer,
          }),
        ];
        commit("SET_PAGE_TITLE", TYPE_TITLES[type_id]);
        commit("SET_SEARCH_API_FIELDS", newSearchFields);
        commit("UPDATE_SEARCH_FILTER", newSearchFilter);
      },
      addSearchFilter({ commit, state }, filter) {
        let newSearchFilter = state.searchFilter.filter(
            (f) => f.name !== filter.name || (f.value && f.value !== filter.value),
        );
        newSearchFilter.push(filter);

        commit("SET_SEO_SEARCH_FILTER", { [filter.name]: filter });
        commit("SHOW_SELECTED_FILTER", false);
        commit("UPDATE_REMOVED_SEO_FILTER", []);
        if (filter instanceof GroupItemFilter) {
          const groupQuery = newSearchFilter
            .filter((v) => v.name === filter.name)
            .map((v) => v.value)
            .join(";");

          if (filter.name === "category") {
            const newSearchFields = {
              ...state.searchApiFields,
              [filter.name]: groupQuery,
            };
            delete newSearchFields.manufacturer;
            newSearchFilter = newSearchFilter.filter((f) => f.name !== "manufacturer")
            commit("SET_SEARCH_API_FIELDS", newSearchFields);
          } else if (filter.name === "location_country") {
            commit("PATCH_SEARCH_API_FIELDS", { [filter.name]: groupQuery.includes(";") ? "" : groupQuery, page: 1 });
          } else {
            commit("PATCH_SEARCH_API_FIELDS", { [filter.name]: groupQuery, page: 1 });
          }
        } else if (filter instanceof RangeFilter) {
          commit("PATCH_SEARCH_API_FIELDS", {
            [filter.name + "_min"]: filter.min,
            [filter.name + "_max"]: filter.max,
            page: 1,
          });
        } else if (filter instanceof GeoLocationFilter) {
          commit("PATCH_SEARCH_API_FIELDS", {
            lat: +filter.lat,
            lon: +filter.lon,
            location: filter.location,
            distance: filter.distance,
            page: 1,
          });
          return;
        } else if (filter instanceof SortFilter) {
          commit("PATCH_SEARCH_API_FIELDS", {
            sort: filter.sort,
            page: 1,
          });
          return;
        } else {
          if(filter.name === 'page') {
            commit("PATCH_SEARCH_API_FIELDS", { [filter.name]: filter.value });
          } else {
            commit("PATCH_SEARCH_API_FIELDS", { [filter.name]: filter.value, page: 1 });
          }
        }
        commit("UPDATE_SEARCH_FILTER", newSearchFilter);
      },
      clearSearchFilter({ commit, state }, payload) {
        let retainManufacturer;
        let {name, value} = payload;
        if (typeof name === "object") {
          ({ name, retainManufacturer } = name);
        }
        let newSearchFilter = [ ...state.searchFilter ];
        const { rangeLimit, filterName } = parseRangeFilters(newSearchFilter, name);

        const filter = state.searchFilter.find((f) => f.name === filterName && f.value === value);
        const newSearchFields = { ...state.searchApiFields };

        if(!filter) return;

        newSearchFilter = newSearchFilter.filter((f) => f !== filter);
        if (filter.isGroup) {
          newSearchFields[filter.name] = newSearchFilter
            .filter((v) => v.name === filter.name)
            .map((v) => v.value)
            .join(";");
          if (filter.name === "category" && !retainManufacturer) {
            delete newSearchFields.manufacturer;
            newSearchFilter = newSearchFilter.filter((f) => f.name !== "manufacturer");
          }
        } else if (rangeLimit) {
          /*
           * Modify the filter fields and search labels list for range filters
           */

          //-------------We are filtering the list of labels here because of the existing class prototype, which cannot be disturbed ----
          let ranges = filter.rangeLabels.filter((range) => range.key !== rangeLimit);
          delete newSearchFields[`${filter.name}_${rangeLimit}`];

          if (
            (rangeLimit === "max" && filter.min) || (rangeLimit === "min" && filter.max)
          ) {
            //-----------------If the either of the limits (min or max) do exist, retain the filter object -----------------------------
            newSearchFilter.push(
              {
                ...filter,
                [rangeLimit]: undefined,
                rangeLabels: ranges,
              });
          }
        } else if (newSearchFields[`${filterName}_min`] || newSearchFields[`${filterName}_max`]) {
          /* This is for cases where only the field name is mentioned, but without the suffix of min or max.
            In such cases, the API fields should be completely removed if they exist
           */
          delete newSearchFields[`${filterName}_min`];
          delete newSearchFields[`${filterName}_max`];
        } else {
          delete newSearchFields[filterName];
        }
        commit("SET_SEARCH_API_FIELDS", newSearchFields);
        commit("UPDATE_SEARCH_FILTER", newSearchFilter);

        if (state.totalProducts !== 0) {
          commit("SHOW_SELECTED_FILTER", false);
          commit("UPDATE_REMOVED_SEO_FILTER", []);
        }

        const updatedSeoFilter = [...state.limitingFilters];
        const seoFilters = updatedSeoFilter.filter((item) => Object.keys(item).indexOf(filterName));
        commit("UPDATE_SEO_SEARCH_FILTER", seoFilters);

        scrollPlpToTop();
      },
      clearAllSearchFilter({ commit, state }) {
        commit("UPDATE_SEARCH_FILTER", []);
        commit("UPDATE_SEO_SEARCH_FILTER", []);
        commit("SET_SEARCH_API_FIELDS", {
          type_id: state.searchApiFields.type_id,
          location: state.searchApiFields.location,
          lat: state.searchApiFields.lat,
          lon: state.searchApiFields.lon,
          distance: state.searchApiFields.distance,
        });
        commit("SHOW_SELECTED_FILTER", false);
        commit("UPDATE_REMOVED_SEO_FILTER", []);
      },
      async loadConfig({ commit }) {
        const config = await getSysConfigs();
        commit("SET_FILTER_LIMITS", config.filter);
        commit("SET_BANNER_LIST", config.banner);
      },
      async getAuthToken({ commit }) {
        commit("GET_AUTH_TOKEN", !isSSR() ? localStorage.getItem("token") : null);
      },
      async loadLocalStorage({ commit }) {
        if (!isSSR()) {
          const geolocation = localStorage.getItem("userGeolocation");
          if (geolocation) {
            commit("SET_USER_GEOLOCATION", geolocation.split(","));
          }

          const location = localStorage.getItem("location");
          if (location) {
            commit("SET_LOCATION", location);
          }

          const distance = localStorage.getItem("distance");
          if (distance) {
            commit("SET_DISTANCE", distance);
          }
        }
      },
      setUserGeolocation({ commit }, location) {
        if (!isSSR()) {
          if (location) {
            localStorage.setItem("userGeolocation", location);
          } else {
            localStorage.removeItem("userGeolocation");
          }
        }
        commit("SET_USER_GEOLOCATION", location);
      },
      setLocation({ state, commit }, location) {
        if (!isSSR()) {
          if (location) {
            localStorage.setItem("location", location);
          } else {
            localStorage.removeItem("location");
          }
        }
        commit("SET_LOCATION", location || state.location);
      },
      setProductListPageHead({ state, dispatch }) {
        const pageLocation = state.location;
        const totalProducts = state.totalProducts;
        const { condition, pull_type, category, manufacturer, type_id } = state.searchApiFields;
        const titleParts = [];
        const capFirstCharacter = (s) => {
          return s.length === 0 ? s : s[0].toUpperCase() + s.slice(1);
        }

        if (condition) {
          const conditions = condition.split(";");
          if (conditions.length <= 2) {
            titleParts.push(conditions.map((item) => GroupItemFilter.defaultLabel(item)).join(" and "));
          }
        }

        if (manufacturer) {
          const manufacturers = manufacturer.split(";");
          titleParts.push(manufacturers.map((item) => GroupItemFilter.defaultLabel(item)).join(", "));
        }
        if (pull_type) {
          const pullTypes = pull_type.split(";");
          titleParts.push(pullTypes.map((item) => GroupItemFilter.defaultLabel(item)).join(","));
        }

        let typeID;
        let categories = [];

        const revertCategoryName = (name) => {
          if(name.includes("Vending")) {
            return "Concession / Food / Vending Trailers";
          }
          return name;
        }
        if (category) {
          const categories = category.split(";");
          titleParts.push(categories.map((item) => revertCategoryName(GroupItemFilter.defaultLabel(item))).join(" and "));
        } else {
          const categoryType = state.megaMenuList.filter((type) => +type.id === +type_id);

          if (categoryType[0]) {
            titleParts.push(categoryType[0].name);
            categoryType[0].categories.forEach((category) => {
              categories.push(category.name);
            });
          } else {
            titleParts.push("");
          }
        }

        if (type_id === "all") {
          titleParts.unshift("TrailerTrader | All");
          titleParts.push("trailers");
        }

        if (type_id === "all") {
          typeID = "All trailers";
        }

        if (Number(type_id) === 1 || Number(type_id) === 2) {
          typeID = "trailers";
        }
        if (Number(type_id) === 3) {
          typeID = "RVs and travel trailers";
        }
        if (Number(type_id) === 4) {
          typeID = "semi-trailers";
        }
        if (Number(type_id) === 5) {
          typeID = "truck beds";
        }

        const pageTitle = titleParts.join(" ");

        const pageLocationString = pageLocation ? `near ${pageLocation}` : "";

        let descriptionText = '';
        if (PRODUCT_LISTING_SEO.hasCategory(category)) {
          descriptionText = PRODUCT_LISTING_SEO.listingDescription(category);
        } else {
          descriptionText = `Browse thousands of new and used ${manufacturer || ""} ${category || ""} for Sale ${
            pageLocationString === "" ? "near you now" : "in"
          } ${pageLocationString}. TrailerTrader is the Number One Source for ${
            category || ""
          } Classifieds across the United States and Canada `;
        }

        const siteData = {
          title:
            totalProducts < 0
              ? "TrailerTrader"
              : `${pageTitle} For Sale ${PRODUCT_LISTING_SEO.hasCategory(category) ? 'Near Me' : capFirstCharacter(pageLocationString)}`.trim() + " - TrailerTrader",
          seoMetaDescription: descriptionText,
          category: `All the category names of the ${typeID} ${categories}`,
        };

        const params = {distance: state.distance, ...state.searchApiFields};

        const formattedPath =
          type_id === "all" ? "" : pathFromQueryParams(params, true);

        /** @type {SetMetaTagsPayload} */
        const metaTags = {
          title: siteData.title,
          description: siteData.seoMetaDescription,
          keywords: siteData.title,
          image: `${websiteBaseUrl}/assets/TrailerTraderLogo.webp`,
          extras: {
            link: {
              rel: "canonical",
              href: `https://trailertrader.com/trailers-for-sale/${formattedPath}`,
            },
          },
        };

        dispatch("setMetaTags", metaTags);
      },
    },
    getters: {
      sortOption(state) {
        if (state.sortOption) {
          return state.sortOption;
        } else if (state.location) {
          return DEFAULT_LOCATION_SORT;
        } else {
          return DEFAULT_NO_LOCATION_SORT;
        }
      },
      hasFilter: (state) => (name, value) => {
        return !!state.searchFilter.find((f) => f.name === name && f.value === value);
      },
      getFirstFilter: (state) => (name) => {
        return state.searchFilter.filter((f) => f.name === name)[0];
      },
    },
    modules: {
      notifications,
      trailers,
      seo,
      dealers,
      ads,
    },
    strict: debug,
    // plugins: debug ? [createLogger()] : [],
  });
}
