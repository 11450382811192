<template>
  <ion-header class="ion-no-border">
    <ion-grid class="modal-header-forgot-password">
      <div class="forgot-password-modal">
        <div class="d-flex forgot-password-header">
          <span class="forgot-password-title ion-text-uppercase">recover your password</span>
          <span class="close-forgot-password-modal"><ion-icon
            :icon="closeSharp"
            aria-label="close dialog"
            @click="closeModal"
          /></span>
        </div>
        <div class="forgot-password-content">
          <div class="forgot-password-info">
            If you have forgotten your password, use the following field to enter the email you use to log into
            TrailerTrader.com . If this email address is known to us, you’ll shortly receive a link to reset your password
            by email.
          </div>
          <Form
            ref="recoverPasswordForm"
            v-slot="{ meta }"
            @submit="forgotPassword"
          >
            <ion-col
              size-md="4"
              size="12"
            >
              <Field
                v-slot="{ field, errors }"
                rules="required|email"
                name="email"
                label="EMAIL"
              >
                <ion-item
                  :class="errors.length !== 0 ? 'error-field' : ''"
                  class="search-trailer-location-input error-save-search"
                  lines="none"
                >
                  <IonLabel
                    class="location-label custom-color"
                    position="floating"
                  >
                    Email Address
                  </IonLabel>
                  <ion-input
                    name="email"
                    v-bind="field"
                    aria-label="Input Email Address"
                    @keyup="checkIfFill()"
                  />
                </ion-item>
              </Field>
              <ErrorMessage name="email" />
            </ion-col>
            <div class="forgot-password-button">
              <ion-col
                size-md="4"
                size="12"
              >
                <ion-button
                  class="btn btn-cancel custom-button"
                  @click="closeModal"
                >
                  Cancel
                </ion-button>
              </ion-col>
              <ion-col
                size-md="4"
                size="12"
              >
                <ion-button
                  class="btn btn-submit custom-button"
                  type="submit"
                  :class="submitting || !(meta.valid && meta.dirty) ? 'btn-inactive' : ''"
                  :disabled="submitting || !(meta.valid && meta.dirty)"
                >
                  <span v-if="submitting">Submitting...</span>
                  <span v-else>Submit</span>
                </ion-button>
              </ion-col>
            </div>
            <RecaptchaRemark />
          </Form>
        </div>
      </div>
    </ion-grid>
  </ion-header>
</template>

<script>
import {
  IonHeader,
  IonGrid,
  IonIcon,
  modalController,
  IonLabel,
  IonCol,
  IonButton,
  IonItem,
  IonInput,
} from "@ionic/vue";
import { closeSharp, chevronForwardOutline } from "ionicons/icons";
import { Field, ErrorMessage, Form } from "vee-validate";
import { modal } from "@/mixins";
import ForgotPasswordResponse from "../user/ForgotPasswordResponse.vue";
import { forgotPassword } from "@/services/UserService";
import { getRecaptchaToken } from "@/services/RecaptchaService";
import RecaptchaRemark from "@/components/google/RecaptchaRemark.vue";
import { utagLink } from "@/services/TealiumService";

export default {
  components: {
    RecaptchaRemark,
    IonHeader,
    IonGrid,
    IonIcon,
    Field,
    ErrorMessage,
    Form,
    IonLabel,
    IonButton,
    IonCol,
    IonItem,
    IonInput,
  },
  mixins: [modal],
  data() {
    return {
      formPartiallyFilled: false,
      submitting: false,
      closeSharp,
      chevronForwardOutline,
    };
  },
  methods: {
    async closeModal() {
      await modalController.dismiss();
    },
    checkIfFill() {
      this.formPartiallyFilled = true;
    },
    async forgotPassword(event) {
      this.submitting = true;

      const payload = {
        email: event.email?.toLowerCase(),
        callback: window.location.origin + "/resetpassword",
        captcha: await getRecaptchaToken("forgot_password"),
      };
      try {
        await forgotPassword(payload);
        
        utagLink(this.$route, {
          "tealium_event": "form_submit",
          "form_id": "recover_password_form",
          "form_submission_email": event.email?.toLowerCase(),
        });
      } finally {
        this.submitting = false;
        await this.closeModal();
        await this.openModal(ForgotPasswordResponse, {
          cssClass: "modal-header-success-submission",
          backdropDismiss: true,
        });
      }
    },
  },
};
</script>
