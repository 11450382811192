import { SEO_FRIENDLY_URLS_CATEGORIES } from "../consts/category";

export const types = ["trailers", "horse-and-livestock-trailers", "all-travel-trailers", "semi-trailers", "truck-beds"];

const typeCategories = [
  new Map([
    ["Cargo (Enclosed) Trailers", "enclosed-trailers"],
    ["Equipment / Flatbed Trailers", "flatbed-trailers"],
    ["Car Haulers / Racing Trailers", "racing-trailers"],
    ["Tow Dollys", "tow-dollys"],
    ["Tilt Trailers", "tilt-trailers"],
    ["Motorcycle / Cycle Trailers", "motorcycle-trailers"],
    ["ATV Trailers", "atv-trailers"],
    ["Watercraft Trailers", "watercraft-trailers"],
    ["Snowmobile Trailers", "snowmobile-trailers"],
    ["Utility Trailers", "utility-trailers"],
    ["Dump Trailers", "dump-trailers"],
    ["Vending / Concession Trailers", "concession-trailers"],
    ["Office / Fiber Optic Trailers", "fiber-optic-trailers"],
    ["Other Trailers", "other-trailers"],
  ]),
  new Map([
    ["Horse Trailers", "horse-trailers"],
    ["Stock / Stock Combo Trailers", "livestock-trailers"],
  ]),
  new Map([
    ["Travel Trailers", "travel-trailers"],
    ["Fifth Wheel Trailers", "fifth-wheel-trailers"],
    ["Toy Haulers", "toy-haulers"],
    ["Camper / RV", "camper"],
    ["Truck Bed Campers", "truck-bed-campers"],
    ["Overland Trailers", "overland-trailers"],
  ]),
  new Map([
    ["Low Boy / Drop Deck Semi Trailers", "low-boy-semi-trailers"],
    ["Dry Van Semi Trailers", "dry-van-semi-trailers"],
    ["Flatbed Semi Trailers", "flatbed-semi-trailers"],
    ["Grain Semi Trailers", "grain-semi-trailers"],
    ["Reefer Semi Trailers", "reefer-semi-trailers"],
    ["Livestock Semi Trailers", "livestock-semi-trailers"],
    ["Tank / Bulk Semi Trailers", "tank-semi-trailers"],
    ["Dump Semi Trailers", "dump-semi-trailers"],
    ["Semi Car Hauler", "semi-car-hauler"],
    ["Other Semi Trailers", "other-semi-trailers"],
    ["Other Trucks", "other-trucks"],
    ["Standard Trucks", "standard-trucks"],
  ]),
  new Map([
    ["Truck Beds Category", "truck-beds-category"],
    ["Flat Decks", "flat-decks"],
    ["Stake Bodies", "stake-bodies"],
    ["Service Bodies", "service-bodies"],
    ["Goose Bodies", "goose-bodies"],
    ["Dump Bodies", "dump-bodies"],
  ]),
];

const conditions = ["new", "used", "remfg"];

const pullTypes = ["bumper", "gooseneck", "pintle", "fifth_wheel", "tractor_hookup"];

const usedParamKeys = [
  "category",
  "manufacturer",
  "location",
  "sort",
  "page",
  "type_id",
  "axles",
  "pull_type",
  "year",
  "availability",
  "condition",
  "construction",
  "length_min",
  "length_max",
  "width_min",
  "width_max",
  "height_min",
  "height_max",
  "price_min",
  "price_max",
  "gvwr_min",
  "gvwr_max",
  "payload_capacity_min",
  "payload_capacity_max",
  "body_type",
  "number_of_truck_axles",
  "cab_to_axle_length",
  "skirted_bed",
  "sale",
  "lat",
  "lon",
  "distance",
  "search",
  "location_country",
  "color",
];

export const extractUsefulParams = (params) => {
  const usedParams = {};
  usedParamKeys.forEach((key) => {
    if (params[key]) usedParams[key] = params[key];
  });

  return usedParams;
};

const trimEmptyParams = (params) => {
  const newParams = { ...params };
  for (let key in newParams) {
    if (!newParams[key]) {
      delete newParams[key];
    }
  }
  return newParams;
};

const findTypeInPathFromBack = (path) => {
  if (path === "") {
    return {
      path: path,
    };
  }

  let index = -1;
  let matchLength = 0;
  for (let i = 0; i < types.length; i++) {
    if (path.endsWith(types[i]) && matchLength < types[i].length) {
      index = i;
    }
  }

  if (index !== -1) {
    const type = types[index];
    return {
      matchLength: type.length,
      type_id: index + 1,
      path: path === types[index] ? "" : path.substring(0, path.length - type.length - 1),
    };
  } else {
    return {
      path: path,
    };
  }
};

const findConditionInPath = (path) => {
  if (!path) {
    return {
      path: path,
    };
  }

  const condition = conditions.find((_condition) => path.startsWith(_condition));
  if (condition) {
    return {
      condition,
      path: path === condition ? "" : path.substring(condition.length + 1),
    };
  } else {
    return {
      path: path,
    };
  }
};

const findCategoryInPathFromBack = (path) => {
  let category;
  let typeId;

  if (path === "") {
    return {
      path: path,
    };
  }

  // Find maximum length match
  let match = null;
  let matchLength = 0;
  for (let i = 0; i < typeCategories.length; i++) {
    for (const [key, value] of typeCategories[i]) {
      if (path.endsWith(value) && value.length > matchLength) {
        match = [key, value, i];
        matchLength = value.length;
      }
    }
  }

  // If match was found,
  if (match) {
    const [key, value, i] = match;
    category = key;
    typeId = i + 1;
    if (path === value) {
      path = "";
    } else {
      path = path.substr(0, path.length - value.length - 1);
    }
  }
  return {
    matchLength,
    category,
    type_id: typeId,
    path,
  };
};

const findPullTypeInPath = (path) => {
  if (!path) {
    return {
      path,
    };
  }
  const pullType = pullTypes.find((type) => path.startsWith(`${type}`));

  if (pullType) {
    return {
      pull_type: pullType,
      path: path.substring(pullType.length + 1),
    };
  }
  return { path };
};

export const queryParamsFromPath = (path) => {
  const substrings = path.split("-for-sale");
  let location = "";
  if (substrings.length === 2) {
    let params = {};

    const pagePath = substrings[1].split("-showing-");
    location = pagePath?.[0];
    const pageOffset = +pagePath?.[1] || 0;

    let prefix = substrings[0];
    if (!location.startsWith("-near-me")) {
      if (location.startsWith("-in-")) {
        location = location.substr(4);
      } else if (location.startsWith("-near-me")) {
        location = null;
      } else if (location.startsWith("-near-")) {
        location = location.substring(6);
      } else if (location.length > 0) {
        location = "";
      }
      params.location = location?.replace(/-/g, " ")?.replace(/,/g, ", ");
    }



    if (prefix.startsWith("all") && prefix.endsWith("trailers") && !prefix.startsWith("all-travel-trailers")) {
      params.type_id = "all";
      prefix = prefix.replace("all-", "").replace(/(-)?trailers$/, "");
    } else if (["truck-beds-for-sale", "truck-bed-campers-for-sale"].includes(prefix)) {
      params.type_id = 5;
    } else {
      const foundType = findTypeInPathFromBack(prefix);
      const foundCategory = findCategoryInPathFromBack(prefix);

      if (
        foundCategory.type_id === undefined ||
        (foundType.type_id && foundType.matchLength > foundCategory.matchLength)
      ) {
        params = { ...params, ...foundType };
      } else {
        params = { ...params, ...foundCategory };
      }
    }

    // Read condition from path and remove it from params.path
    prefix = params.path || (params.type_id === "all" ? prefix : "");
    params = { ...params, ...findConditionInPath(prefix) };

    // Read pull type from path and remove it from the params.path
    if (typeof params.path === "string" && params.path.length > 0) {
      prefix = params.path || (params.type_id === "all" ? prefix : "");
      params = { ...params, ...findPullTypeInPath(prefix) };
    }

    if (typeof params.path === "string" && params.path.length > 0) {
      params.manufacturer = params.path;
    }

    params.page = (pageOffset / 15) || 1;

    delete params.matchLength;
    delete params.path;

    return params;
  }
};

export const pathFromQueryParams = (params, isForCanonical = false) => {
  let { category: categoryTitle, type_id, location } = params;

  if (!type_id) return;

  const { first, left } = splitFirstParams(params);
  const { category, manufacturer, condition, pull_type, page } = trimEmptyParams(first);
 
  let prefix = "";
  let path = "";
  let type = "";

  if(typeof type_id === 'number'){
    type = types[type_id - 1];
  }

  if (type) {
    prefix = `${type}-`;
  }

  if (type_id !== "all" && category) {
    prefix = `${category}-`;
  }

  if (manufacturer) {
    prefix = `${manufacturer}-${prefix}`;
  }

  if (pull_type) {
    prefix = `${pull_type}-${prefix}`;
  }
  if (condition) {
    prefix = `${condition}-${prefix}`;
  }
  if (type_id === "all") {
    prefix = prefix ? `all-${prefix}trailers-` : "all-trailers-";
  }
  if(SEO_FRIENDLY_URLS_CATEGORIES.includes(categoryTitle)) {
    path = `${prefix}for-sale-near-me`;
  } else if (location) {
    path = `${prefix}for-sale-in-${location?.replace(/ /g, "-")?.replace(/,-/g, ",")}`;
  } else {
    path = `${prefix}for-sale`;
  }
  if (page > 1) {
    path = `${path}-showing-${page * 15}`;
  }

  const queryString = generateQueryStringFromObj(isForCanonical ? { distance: params.distance } : left);
  if (queryString !== "" && !SEO_FRIENDLY_URLS_CATEGORIES.includes(categoryTitle)) {
    path = `${path}?${queryString}`;
  }
  return path;
};

const splitFirstParams = (params) => {
  const leftParams = { ...params };
  const firstParams = {};

  const categoryList = params.category?.split(";");
  const manufacturerList = params.manufacturer?.split(";")?.filter((m) => !m.includes("car-and"));
  const conditionList = params.condition?.split(";");
  const pullTypeList = params.pull_type?.split(";");
  const type_id = params.type_id;

  conditionList?.sort((c1, c2) => {
    const ci1 = conditions.indexOf(c1);
    const ci2 = conditions.indexOf(c2);
    if(ci1 === -1) {
      return 1;
    } else if(ci2 === -1) {
      return -1;
    } else {
      return ci1 - ci2;
    }
  });

  const firstCategoryIndex = categoryList?.findIndex((category) => typeCategories[type_id - 1]?.get(category));
  const firstPullTypeIndex = pullTypeList?.findIndex((pullType) => pullTypes.includes(pullType));
  const firstCondition = conditionList?.shift();
  const firstManufacturer = manufacturerList?.shift();

  firstParams.type_id = type_id;
  delete leftParams.type_id;

  firstParams.location = params.location;
  delete leftParams.location;

  firstParams.page = params.page;
  delete leftParams.page;

  if (firstCategoryIndex !== undefined && firstCategoryIndex !== -1) {
    firstParams.category = typeCategories[type_id - 1].get(categoryList[firstCategoryIndex]);
    categoryList.splice(firstCategoryIndex, 1);
    leftParams.category = categoryList.join(";");
  }

  if (firstPullTypeIndex !== undefined && firstPullTypeIndex !== -1) {
    firstParams.pull_type = pullTypeList[firstPullTypeIndex];
    pullTypeList.splice(firstPullTypeIndex, 1);
    leftParams.pull_type = pullTypeList.join(";");
  }

  if (firstCondition !== undefined) {
    firstParams.condition = firstCondition;
    leftParams.condition = conditionList.join(";");
  }

  if (firstManufacturer !== undefined) {
    firstParams.manufacturer = firstManufacturer;
    leftParams.manufacturer = manufacturerList.join(";");
  }

  return {
    first: firstParams,
    left: trimEmptyParams(leftParams),
  };
};

export const prependParams = (firstParams, params) => {
  const newParams = { ...params };
  const { type_id = null, location, page, category, pull_type, condition, manufacturer } = { ...firstParams };

  if (type_id) {
    newParams.type_id = type_id;
  }

  if (location) {
    newParams.location = location;
  }

  if (page) {
    newParams.page = page;
  }

  if (category) {
    newParams.category = newParams.category ? `${category};${newParams.category}` : category;
  }

  if (pull_type) {
    newParams.pull_type = newParams.pull_type ? `${pull_type};${newParams.pull_type}` : pull_type;
  }

  if (condition) {
    newParams.condition = newParams.condition ? `${condition};${newParams.condition}` : condition;
  }

  if (manufacturer) {
    newParams.manufacturer = newParams.manufacturer ? `${manufacturer};${newParams.manufacturer}` : manufacturer;
  }

  return newParams;
};

export const generateQueryStringFromObj = (paramsObj) => {
  return Object.keys(paramsObj)
    .filter((key) => paramsObj[key] !== undefined)
    .map((key) => `${key}=${encodeURIComponent(paramsObj[key])}`)
    .join("&");
};

export function fetchFilterParams(routeTo) {
  if (Object.keys(routeTo.query).length && Object.keys(routeTo.meta).length) {
    return {
      ...routeTo.meta.queryParams,
      ...routeTo.query,
    };
  }
  return Object.keys(routeTo.meta).length
    ? { ...routeTo.meta.queryParams, location: routeTo.params?.location || "" }
    : { ...routeTo.query, location: routeTo.params?.location || "" };
}

export function getTypeNameFromId(id) {
  return types[id];
}

export function getTypeIdFromName(name) {
  const typeIdx = types.indexOf(name);
  return typeIdx !== -1 ? typeIdx + 1 : 0;
}
