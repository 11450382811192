import GroupItemFilter from "./GroupItemFilter";

export default class ConstructionItemFilter extends GroupItemFilter {
  constructor({value}) {
    super({
      name: ConstructionItemFilter.CONSTRUCTION_GROUP,
      label: ConstructionItemFilter.defaultLabel(value),
      value: value,
    });
  }
}

ConstructionItemFilter.defaultLabel = function (value) {
  if(value === 'steel_frame_aluminum') {
    return 'STEEL FRAME / ALUMINIUM SKIN';
  } else {
    return GroupItemFilter.defaultLabel(value);
  }
}
ConstructionItemFilter.fromApiField = GroupItemFilter.fromApiField;
ConstructionItemFilter.CONSTRUCTION_GROUP = 'construction';