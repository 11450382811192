export const QUICK_FILTERS = ["new", "used"];

export const RANGE_FILTERS = [
  "payload_capacity",
  "gvwr",
  "length",
  "width",
  "height",
  "price",
];
