<template>
  <!-- Start of Pull Type Accordion Setup -->
  <ion-row
    v-if="isVisibleInCurrentRoute && (pullTypes || []).length > 0"
    class="collapsable-menu-custom"
  >
    <ion-col>
      <ion-list>
        <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
          <ion-item
            id="TestA_plp_filter_pulltype"
            lines="none"
            class="filter-item-head"
            :class="{ active }"
            @click="handleClick"
          >
            <div class="text-icon">
              <div class="ion-text-uppercase parent-text">
                Pull Type
              </div>
              <div class="d-flex">
                <span class="ion-text-uppercase selected-search ion-padding-end">
                  {{ selectedList }}
                </span>
                <span :class="!active ? 'arrow-drop  rotate-caret' : 'arrow-drop'">
                  <img
                    alt="Trailers Near Me"
                    src="@/assets/icons/caretDownBlue.svg"
                  >
                </span>
              </div>
            </div>
          </ion-item>
          <!-- <div> -->
          <template
            v-for="(item, index) in pullTypes"
            :key="item.key"
          >
            <ion-item
              v-if="active"
              lines="none"
              :href="item.href"
              class="filter-collapse"
              @click="toggleSelected($event, index, item.key)"
            >
              <ion-checkbox
                slot="start"
                ref="pull_type"
                :value="item.key"
                :checked="!!hasFilter('pull_type', item.key)"
                class="filter-checkbox"
              />
              <ion-label class="ion-text-uppercase child-text">
                {{ toItemLabel(item.key) }}
              </ion-label>
            </ion-item>
          </template>
        </div>
      </ion-list>
    </ion-col>
  </ion-row>
</template>

<script>
import GroupItemFilter from "../../models/filters/group/GroupItemFilter";
import { mapActions, mapState } from "vuex";
import { ellipsizeList } from "@/utils/FilterListUtil";
import { IonCheckbox, IonCol, IonItem, IonList, IonRow, IonLabel } from "@ionic/vue";
import { TYPE_TRUCK_BED_ID, TYPE_SEMI_TRAILERS_ID } from "@/consts/category";
import { filterActions } from "@/mixins";
import { scrollPlpToTop } from "@/utils/ProductListingPageUtil";

export default {
  name: "PullType",
  components: {
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonCheckbox,
    IonLabel,
  },
  mixins: [filterActions],
  props: {
    active: Boolean,
  },
  emits: ["toggle"],
  data() {
    return {
      TYPE_TRUCK_BED_ID,
      TYPE_SEMI_TRAILERS_ID,
    };
  },
  computed: {
    ...mapState({
      searchFilter: (state) => state.searchFilter,
      searchApiFields: (state) => state.searchApiFields,
      aggregations: (state) => state.categoryAggregations,
    }),
    pullTypes() {
      return this.aggregations.filter_aggregations?.pull_type?.buckets?.map((p) => {
        return {
          ...p,
          href: this.generateHrefFromField(p.key, "pull_type"),
        };
      });
    },
    selectedList() {
      return ellipsizeList(
        this.searchFilter
          .filter((item) => item.name === "pull_type")
          .map((item) => item.value.replaceAll("_", " ")),
        14,
      );
    },
    isVisibleInCurrentRoute() {
      const typeId = +this.$route?.query?.type_id;
      return typeId !== TYPE_TRUCK_BED_ID && typeId !== TYPE_SEMI_TRAILERS_ID;
    },
  },
  methods: {
    ...mapActions(["addSearchFilter", "clearSearchFilter"]),
    toItemLabel: GroupItemFilter.defaultLabel,
    toggleSelected($event, index, key) {
      $event.preventDefault();

      const { checked, value } = this.$refs.pull_type.find(obj => obj.value === key);

      if (!checked) {
        this.addSearchFilter(
          new GroupItemFilter({
            name: "pull_type",
            value: value,
          }),
        );
      } else if (checked) {
        this.clearSearchFilter({name: "pull_type", value});
      }

      scrollPlpToTop();
    },
    handleClick() {
      this.$emit("toggle");
    },
  },
};
</script>
