<template>
  <div 
    v-if="showCompareBanner"
    class="compare-banner"
  >
    <ion-grid
      v-if="isDesktop"
    >
      <ion-row
        class="compare-banner-top-bar"
      >
        <ion-col
          class="ion-text-center"  
        >
          <div
            @click="toggleBannerCollapse"
          >
            <ion-text
              class="compare-banner-top-bar-text"
            >
              Compare
            </ion-text>
            <ion-label class="compare-product-count">
              {{ inventoryCount }}
            </ion-label>
            <span>
              <img
                v-if="isCollapsedBanner"
                alt="caretRight"
                src="@/assets/icons/caretDownBlue.svg"
                class="compare-banner-caret-right rotate-compare-caret"
              >
              <img
                v-else
                alt="caretDown"
                src="@/assets/icons/caretDownBlue.svg"
                class="compare-banner-caret-down"
              >
            </span>
          </div>
        </ion-col>
      </ion-row>
      <ion-row
        v-if="!isCollapsedBanner"
        class="compare-banner-card-list"
      >
        <ion-col
          class="ion-text-center"  
        >
          <div
            v-for="(product, index) in comparisons"
            :key="index"
            :ref="`compareList${index}`"
            class="compare-product-card"
            @click="goToProduct(product, $event)"
          >
            <div class="compare-image-box">
              <img
                :src="product.primary_image.original_url"
                class="compare-card-image"
                alt="product.primary_image.original_url"
              >
              <img 
                class="close-img" 
                src="@/assets/crossIcon.webp" 
                alt="remove-photo"
                @click="removeTrailerFromComparison($event, product)"
              >
              <div
                v-if="
                  product.availability !== 'available' &&
                    product.availability !== 'sold' &&
                    product.availability_label &&
                    product.availability
                "
                :id="`TestA_compare_status_${product.availability_label?.replace(/ /g, '_')}_mobile`"
                :class="{
                  pending: product.availability === 'pending_sale',
                  on_order: product.availability === 'on_order',
                  special_order: product.availability === 'special_order',
                }"
                class="status ion-text-uppercase"
              >
                {{ product.availability_label }}
              </div>
            </div>
            <div class="card-trailer-info">
              <ion-text class="compare-card-manufacturer">
                {{ product?.manufacturer }}
              </ion-text>
              <!-- <br> -->
              <ion-text class="compare-card-title">
                {{ product?.inventory_title }}
              </ion-text>
              <!-- <br> -->
              <ion-text
                v-if="product.visible_price?.value"
                class="compare-card-price"
              >
                {{ formatPrice(product.visible_price?.value, product?.currency) }}
              </ion-text>
              <ion-text
                v-else
                class="compare-card-call-for-price"
              >
                CALL FOR <b>PRICE</b>
              </ion-text><br>
              <ion-text 
                v-if="isLocationActive"
                class="compare-card-distance"
              >
                <img 
                  class="location-sharp"
                  src="@/assets/location-sharp.svg"
                  alt="@/assets/location-sharp.svg"
                > 
                {{ calculateMiles(product?.dealer_location?.latitude,product?.dealer_location?.longitude ) }} miles
              </ion-text>            
            </div>
          </div>
          <div
            class="compare-product-cta-card"
          >
            <ion-text
              v-if="inventoryCount > 1"
              class="text-note"
            >
              Use this tool to compare features and prices of up to (5) trailers.
            </ion-text>
            <ion-text
              v-else
              class="text-note"
            >
              Please select more than (1) trailer-and up to (5)- to compare
            </ion-text>
            <br>
            <ion-button
              id="compare_cta_desktop"
              class="compareCTA"
              :disabled="inventoryCount < 2"
              @click="goToComparePage"
            >
              Compare
              <ion-icon
                class="email"
                :icon="chevronForwardOutline"
              />
            </ion-button>
            <span
              v-if="inventoryCount >= 2"
              class="remove-all"
              @click="removeAll($event)"
            >
              Remove All
            </span>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid
      v-else
    >
      <ion-row
        class="compare-banner-bar-mobile"
      >
        <ion-col
          id="compare_cta_mobile"
          class="ion-text-center"
          @click="goToComparePage"
        >
          <div
            v-if="inventoryCount >=2"
          >
            <ion-text
              class="compare-banner-top-bar-text"
            >
              Compare
            </ion-text>
            <ion-label class="compare-product-count">
              {{ inventoryCount }}
            </ion-label>
            <span>
              <img
                alt="caretRight"
                src="@/assets/icons/caretBlueRight.svg"
                class="compare-banner-caret-right"
              >
            </span>
          </div>
          <div
            v-else
            class="text-note"
          >
            Please select more than (1)<br> trailer-and up to (5)- to compare.
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>
<script>
import { IonGrid, IonRow, IonCol,IonText,IonButton,IonIcon,IonLabel,modalController  } from "@ionic/vue";
import { chevronForwardOutline } from "ionicons/icons";
import { modal, urlTransFormations } from "@/mixins";
import { formatPrice } from "@/utils/CurrencyUtil";
import { calculateDistance } from "@/utils/DistanceUtil";
import { mapState } from "vuex";
import RemoveAllModal from "@/components/compare/RemoveAllModal.vue";

export default {
  name: "CompareBanner",
  components: {
    IonCol,
    IonRow,
    IonText,
    IonGrid,
    IonLabel,
    IonIcon,
    IonButton,
  },
  mixins: [modal, urlTransFormations ],
  data() {
    return {
      chevronForwardOutline,
      isCollapsedBanner:true,
    };
  },
  computed: {
    inventoryCount(){
      return this.comparisons.length;
    },
    isLocationActive(){
      const { userGeoLocation } = this.$store.state;
      if (userGeoLocation.length !== 0) {
        return true;
      }
      return false;
    },
    userLocation(){
      const { userGeoLocation } = this.$store.state;
      return userGeoLocation;
    },
    showCompareBanner() {
      return this.inventoryCount > 0 && this.$route.path !== '/compare';
    },
    ...mapState({
      comparisons: (state) => state.trailers.comparisons,
    }),
  },
  methods:{
    toggleBannerCollapse(){
      this.isCollapsedBanner = !this.isCollapsedBanner;
    },
    formatPrice,
    removeAll(e){
      e.preventDefault();
      this.showRemoveAllModal();
    },
    removeTrailerFromComparison(event, product){
      if (event !== undefined) {
        event.preventDefault();
      }
      const products = this.comparisons.filter((item) => item.id !== product.id);
      this.$store.dispatch("setComparisons", products);
    },
    goToComparePage(ev){
      if (ev) {
        ev.preventDefault();
      }
      if (this.$route.path !== "/compare") {
        this.$router.push({
          path: "/compare",
        });
      }
    },
    goToProduct(product, event) {
      if (event !== undefined) {
        event.preventDefault();
      }
      if(event.target.classList.contains('close-img')){
        return;
      }
      this.singleProduct(product);
      let url = this.superSanitize(product.inventory_title);
      url = url + "-" + this.shortenNumber(product.id) + ".html";
      this.trackInventoryClicked(product);
      this.$router.push({
        name: "ProductDetails",
        params: {
          url: url,
        },
      });
      localStorage.setItem("manufacturer", product?.manufacturer);
      localStorage.setItem("dealer_location_id", product?.dealer_location_id);
    },
    singleProduct(product) {
      this.$store.commit(
        "SET_INVENTORY_PRODUCT",
        product,
      );
    },
    trackInventoryClicked(inventory) {
      window.tt_track?.inventoryClicked({
        meta: {
          dealer_id: parseInt(inventory.dealer_id),
          inventory_id: parseInt(inventory.id),
          stock: inventory.stock,
          category: inventory.category,
          category_label: inventory.category_label,
          type_id: inventory.type_id,
          type_label: inventory.type_label,
          title: inventory.title,
          is_private: inventory.dealer?.is_private,
        },
      });
    },
    async showRemoveAllModal() {
      const modal = await modalController.create({
        component: RemoveAllModal,
        cssClass: "modal-header-email-exists",
        componentProps: {
          type: "confirm",
          heading: "Remove All",
          message: `Please confirm you want to remove all trailers from the list ?`,
        },
      });
      modal.onDidDismiss().then((data) => {
        if (data.data === "YES") {
         
          const products = [];
          this.$store.dispatch("setComparisons", products);
          this.$store.dispatch("setToastNotification", {
            open: true,
            text: "You removed all selected trailers.",
            type: "success",
            });
        }
      });
      return await modal.present();
    },
    calculateMiles(lat, lng){
      if(this.isLocationActive){
        const distance = calculateDistance(
        parseFloat(this.userLocation[0]),
        parseFloat(this.userLocation[1]),
        parseFloat(lat),
        parseFloat(lng),
      );
      return isNaN(Math.ceil(distance)) ? 0 : Math.ceil(distance);
      }
    },
  },
};
</script>
