<template>
  <!-- Start of Mobile and Tablet View Your Search -->
  <ion-menu
    class="filters-responsive-layout"
    menu-id="filterContent"
    side="end"
    content-id="main-content"
  >
    <div>
      <span>
        <ion-button
          color="transparent"
          class="close-btn ion-no-padding"
          @click="closeFilter"
        ><ion-icon
          class="close-side-toggle"
          aria-label="close side toggle"
          :icon="closeSharp"
        /></ion-button>
      </span>
      <ion-list>
        <ion-item lines="none">
          <ion-label class="ion-text-uppercase header-text">
            Filters
          </ion-label>
        </ion-item>
      </ion-list>
    </div>
    <filter-list>
      <template #types>
        <simple-filter-list v-if="isAllTrailers" />
      </template>
    </filter-list>
    <ion-row
      class="results-button-row collapsable-menu-custom"
      :style="!isDesktop ? resultsButtonOffset : ''"
    >
      <ion-col>
        <div class="results-button-parent">
          <ion-button
            class="results-button"
            @click="closeFilter"
          >
            Now listing {{ totalProducts > 0 ? totalProducts : "0" }} Results
            <ion-icon :icon="chevronForwardOutline" />
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-menu>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonLabel,
  IonIcon,
  menuController,
  IonButton,
  IonItem,
  IonList,
  IonMenu,
  IonRow,
  IonCol,
} from "@ionic/vue";
import { closeSharp, chevronForwardOutline } from "ionicons/icons";
import { MENU_CONTROLLER } from "@/consts/menus";
import SimpleFilterList from "./SimpleFilterList.vue";
import FilterList from "./FilterList.vue";
import { mapState } from "vuex";
import { scrollPlpToTop } from "@/utils/ProductListingPageUtil";
export default defineComponent({
  name: "FiltersResponsiveLayout",
  components: {
    SimpleFilterList,
    FilterList,
    IonLabel,
    IonIcon,
    IonButton,
    IonMenu,
    IonItem,
    IonList,
    IonRow,
    IonCol,
  },
  data() {
    return {
      chevronForwardOutline,
      data: true,
      closeSharp,
    };
  },
  computed: {
    ...mapState({
      totalProducts: (state) => state.totalProducts,
      searchFilter: (state) => state.searchFilter,
    }),
    resultsButtonOffset() {
      return this.$store.getters.resultsButtonOffset;
    },
    isSearchFilterEmpty() {
      return this.searchFilter.length === 0;
    },
    isAllTrailers() {
      return this.$store.state.searchApiFields?.type_id === "all";
    },
  },
  methods: {
    closeFilter() {
      menuController.close(MENU_CONTROLLER.FilterContent);

      scrollPlpToTop();
    },
    openFilter() {
      menuController.enable(true, MENU_CONTROLLER.FilterContent);
      menuController.open(MENU_CONTROLLER.FilterContent);
    },
  },
});
</script>
