export default {
  data() {
    return {
      typeIDMapping: "",
    };
  },
  mounted() {
    this.typeIdMapping();
  },
  methods: {
    typeIdMapping() {
      if (
        Number(this.$store.state.searchApiFields.type_id) === 1 ||
        Number(this.$store.state.searchApiFields.type_id) === 2
      ) {
        this.typeIDMapping = "trailers";
      } else if (Number(this.$store.state.searchApiFields.type_id) === 3) {
        this.typeIDMapping = "RVs and travel trailers";
      } else if (Number(this.$store.state.searchApiFields.type_id) === 4) {
        this.typeIDMapping = "semi-trailers";
      } else if (Number(this.$store.state.searchApiFields.type_id) === 5) {
        this.typeIDMapping = "truck beds";
      }
    },
  },
};
