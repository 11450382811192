<template>
  <ProductListSort :class="isMapView && (isMobile || isTablet) ? 'sort-margin' : ''" />
  <ion-grid
    class="desktop-view"
    :class="isMapView ? 'ion-no-padding' : ''"
  >
    <div
      v-if="isMapView && (isMobile || isTablet)"
      class="map-mobile-view"
    >
      <ProductListMap
        :seller-locations="sellerLocations"
        :active-seller-address="activeSellerAddress"
      />
      <div class="wrapper">
        <swiper
          :loop="false"
          class="mobile-swiper"
          :slides-per-view="1.17"
          @touchEnd="onTouchEnd"
          @slideChange="onSlideChange"
        >
          <swiper-slide
            v-for="product in productsDisplayList"
            :key="product.id"
            @click="goToProductDetail(product)"
          >
            <ion-grid class="mobile-swipe-item">
              <ion-row class="item ion-no-padding ion-no-margin">
                <ion-col
                  size="4"
                  size-md="2"
                  class="image ion-no-padding"
                >
                  <img
                    :src="product.original_images?.length > 0 ? product.original_images[0] : `${defaultLogo}`"
                    alt="Logo"
                    @ionError="onImageLoadError"
                  >
                </ion-col>
                <ion-col
                  size="8"
                  size-md="10"
                  class="info-wrapper"
                >
                  <ion-row>
                    <ion-col
                      size="12"
                      class="ion-no-padding title"
                    >
                      {{ product?.title }}
                    </ion-col>
                    <ion-col
                      size="12"
                      class="manufacturer ion-no-padding"
                    >
                      {{ product?.manufacturer }}
                    </ion-col>
                    <ion-col
                      v-if="!fetchingProducts"
                      size="12"
                      class="price ion-no-padding"
                    >
                      <span
                        v-if="product.visible_price?.value"
                        class="total-price"
                      >{{
                        formatPrice(product.visible_price?.value, product?.currency)
                      }}</span>
                      <span
                        v-else
                        class="product-contact-price"
                      > CONTACT SELLER FOR <b>PRICE</b> </span>
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
            </ion-grid>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <template v-else>
      <div v-if="isDesktop">
        <div
          v-for="(product, index) in productsDisplayList"
          :key="product.id"
          :ref="`productList${index}`"
          class="d-flex desktop-cards"
          :class="[isMapView ? `desktop-mapview-cards productList${index}` : `productList${index}`]"
          @click="goToProductDetail(product)"
          @mouseenter="showActiveSellerMap(product)"
        >
          <div>
            <div
              :id="`TestA_plp_image_${index}_desktop`"
              class="desktop-image"
            >
              <div :class="product?.isLoading ? 'custom-skeleton-loader' : ''" />
              <NoOverlayIonImg
                :href="productHref(product.id, product.title)"
                :src="product.original_images?.length > 0 ? product.original_images[0] : `${defaultLogo}`"
                :alt="altText(product)"
                :title="`${product?.title}`"
                @click="goToProduct(product, $event)"
              />
            </div>
            <div
              v-if="product.availability !== 'available' &&
                product.availability !== 'sold' &&
                product.availability_label &&
                product.availability
              "
              :id="`TestA_plp_status_${product.availability_label?.replace(/ /g, '_')}_desktop`"
              :class="{
                pending: product.availability === 'pending_sale',
                on_order: product.availability === 'on_order',
                special_order: product.availability === 'special_order',
              }"
              class="status ion-text-uppercase"
            >
              {{ product.availability_label }}
            </div>
          </div>
          <div
            class="content-left-right"
            :class="[isMapView ? 'content-left-right-map' : '']"
          >
            <div class="left-right-content">
              <div class="content-width">
                <div
                  :key="product.id"
                  :class="product?.isLoading ? 'custom-skeleton-loader' : ''"
                />
                <div class="custom-height-loader">
                  <a
                    id="TestA_plp_manufacturer"
                    :href="manufacturerHref(product.manufacturer)"
                    class="subtitle"
                    target="_blank"
                    @click="moreFromManufacturer(product)"
                  >{{ product?.manufacturer }}</a>
                  <a
                    id="TestA_plp_title_desktop"
                    :title="product?.title"
                    :href="productHref(product.id, product.title)"
                    class="title"
                    @mousedown="newTab"
                    @click="goToProduct(product, $event)"
                  >{{ product?.title }}</a>
                  <p
                    v-if="!!product?.short_description"
                    class="description"
                  >
                    {{ product?.short_description || '' }}
                  </p>
                </div>
              </div>
            </div>
            <div class="content-width">
              <div
                :class="product?.isLoading ? 'custom-skeleton-loader' : ''"
                style="top: 5px"
              />
              <div
                id="TestA_plp_features"
                :class="product?.isLoading ? 'custom-height-loader' : ''"
              >
                <template v-if="product?.feature_list?.length == 0">
                  <ion-chip
                    v-if="product?.manufacturer !== null && !(isTruckBedCategory(product?.category))"
                    class="content-chip"
                  >
                    <ion-label class="title">
                      Manufacturer
                    </ion-label>
                    <ion-label class="content">
                      {{ product?.manufacturer }}
                    </ion-label>
                  </ion-chip>
                  <ion-chip
                    v-if="product?.cab_to_axle_length !== null"
                    class="content-chip"
                  >
                    <ion-label class="title">
                      Cab to Axle
                    </ion-label>
                    <ion-label class="content">
                      {{ formatInches(product?.cab_to_axle_length) }}
                    </ion-label>
                  </ion-chip>
                  <ion-chip
                    v-if="product?.condition !== null"
                    class="content-chip"
                  >
                    <ion-label class="title">
                      Condition
                    </ion-label>
                    <ion-label class="content">
                      {{ product?.condition }}
                    </ion-label>
                  </ion-chip>
                  <ion-chip
                    v-if="product?.pull_type !== null && !(isTruckBedCategory(product?.category))"
                    class="content-chip"
                  >
                    <ion-label class="title">
                      Pull Type
                    </ion-label>
                    <ion-label class="content">
                      {{ product?.pull_type }}
                    </ion-label>
                  </ion-chip>
                  <ion-chip
                    v-if="parseInt(product?.payload_capacity) !== 0 && product?.payload_capacity !== null && !(isTruckBedCategory(product?.category))"
                    style="width: fit-content"
                    class="content-chip"
                  >
                    <ion-label class="title">
                      Payload Capacity
                    </ion-label>
                    <ion-label class="content">
                      {{ parseInt(product?.payload_capacity) }} lbs
                    </ion-label>
                  </ion-chip>
                  <ion-chip
                    v-if="parseInt(product?.weight) !== 0 && product?.weight !== null && !(isTruckBedCategory(product?.category))"
                    style="width: fit-content"
                    class="content-chip"
                  >
                    <ion-label class="title">
                      Weight
                    </ion-label>
                    <ion-label class="content">
                      {{ parseInt(product?.weight) }} lbs
                    </ion-label>
                  </ion-chip>
                  <ion-chip
                    v-if="parseInt(product?.length) !== 0 && product?.length !== null"
                    style="width: fit-content"
                    class="content-chip"
                  >
                    <ion-label class="title">
                      Length
                    </ion-label>
                    <ion-label class="content title">
                      {{ toFeetandInch(product?.length) }}
                    </ion-label>
                  </ion-chip>
                </template>
              </div>
            </div>
            <div class="content-width">
              <div
                v-if="product?.feature_list?.length > 0"
                id="TestA_plp_features"
                style="margin-left: -1px"
              >
                <ion-chip
                  v-for="(feature, i) in product.feature_list.slice(0, 5)"
                  :key="i"
                  class="content-chip"
                >
                  <ion-label class="more-features-title">
                    {{ feature }}
                  </ion-label>
                </ion-chip>
                <ion-chip
                  v-if="sortOption.includes('payload') && parseInt(product?.payload_capacity) !== 0 && product?.payload_capacity !== null && !(isTruckBedCategory(product?.category))"
                  style="width: fit-content"
                  class="content-chip"
                >
                  <ion-label class="title">
                    Payload Capacity
                  </ion-label>
                  <ion-label class="content">
                    {{ parseInt(product?.payload_capacity) }} lbs
                  </ion-label>
                </ion-chip>
                <div :class="product?.isLoading ? 'custom-skeleton-loader' : ''" />
              </div>
            </div>
            <div class="content-width d-flex">
              <div class="rating-location">
                <span class="d-flex"><span
                  id="TestA_plp_time"
                  class="product-dealer"
                  :style="{
                    'font-weight': 'bold',
                    color: product.displayColor,
                  }"
                >{{ product.displayTime }}</span>
                </span>
                <div :class="product?.isLoading ? 'custom-skeleton-loader' : ''" />
              </div>
              <div class="rating-location">
                <span
                  v-if="product.dealer?.tt_username"
                  class="d-flex"
                ><a
                  id="TestA_plp_dealername_desktop"
                  :title="`${product.dealer?.tt_username || ''} ${product?.location?.city || ''} ${product?.location?.region || ''
                  }`"
                  class="product-dealer disabled"
                >{{ product.dealer?.tt_username }}</a>
                </span>
                <span
                  id="TestA_plp_dealeraddress_desktop"
                  class="location-address"
                >{{ product?.location?.city }}, {{
                  product?.location?.region }}</span>
                <div :class="product?.isLoading ? 'custom-skeleton-loader' : ''" />
              </div>
            </div>
          </div>
          <div
            class="content-right"
            :class="[isMapView ? 'content-right-map' : '']"
          >
            <div
              class="container-value-money"
              :class="[isMapView ? 'container-value-money-map' : '']"
            >
              <div :class="product?.isLoading ? 'custom-skeleton-loader' : ''" />
              <div
                class="setIpad-view"
                :class="[isMapView ? 'setIpad-view-map' : '']"
              >
                <div>
                  <span
                    v-if="product?.displayTime?.includes('30 min')"
                    id="TestA_plp_nearme"
                    class="value-for-money"
                    style="background-color: #16badf"
                  >NEAR ME</span>
                  <span
                    v-if="showSalesPrice(product)"
                    id="TestA_plp_sale_desktop"
                    class="value-for-money"
                  >SALE</span>
                </div>
                <template v-if="!fetchingProducts">
                  <span
                    v-if="product.visible_price?.value"
                    id="TestA_plp_price_desktop"
                    class="total-price"
                  >{{
                    formatPrice(product.visible_price?.value, product?.currency)
                  }}</span>
                  <span
                    v-else
                    id="TestA_plp_contact_seller_desktop"
                    class="product-contact-price"
                  >
                    CONTACT SELLER FOR <b>PRICE</b>
                  </span>
                </template>
              </div>
              <span
                class="monthly-price ion-hide-sm-up"
                style="margin-right: 5px"
              >Est. <strong>${{ product.monthly_price
              }}</strong>/Mo*
              </span>
              <div
                class="contact-seller-parent"
                :class="[isMapView ? 'contact-seller-parent-map' : '']"
              >
                <div :class="product?.isLoading ? 'custom-skeleton-loader' : ''" />
                <ion-button
                  id="contactseller_plp"
                  :disabled="disableContactSeller || product?.availability === 'sold'"
                  :class="[isMapView ? 'contact-seller-map' : '']"
                  class="contact-seller"
                  @click="getProductByID(product.id)"
                >
                  Contact Seller
                </ion-button>
                <ion-button
                  id="viewdetails_plp"
                  :href="productHref(product.id, product.title)"
                  :disabled="product?.availability === 'sold'"
                  :class="[isMapView ? 'contact-seller-map' : '']"
                  class="contact-seller view-details"
                  @click="goToProduct(product, $event)"
                >
                  View details
                </ion-button>
                <ion-item
                  lines="none"
                  class="comparison-item"
                >
                  <ion-label
                    class="ion-text-uppercase child-text"
                    :class="{strikethrough: comparisons.length >= 5 && !isInComparison(product.id)}"
                  >
                    Compare
                  </ion-label>
                  <ion-checkbox
                    v-if="comparisons.length < 5 || isInComparison(product.id)"
                    id="'compare_selector"
                    :value="isInComparison(product.id)"
                    :checked="isInComparison(product.id)"
                    class="filter-checkbox"
                    @ionChange="addToCompare($event, product)"
                  />
                </ion-item>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- mobile view -->
      <div
        v-if="!isDesktop"
        class="w-100"
      >
        <ion-grid>
          <ion-row>
            <ion-col
              v-for="(product, index) in productsDisplayList"
              :key="product.id"
              :ref="`productList${index}`"
              class="mobile-card-view"
              :class="`productList${index}`"
              size="12"
              size-md="6"
              @mouseenter="showActiveSellerMap(product)"
            >
              <div class="custom-card">
                <div
                  :key="product.id"
                  :class="product?.isLoading ? 'custom-skeleton-loader' : ''"
                />
                <a
                  id="TestA_plp_manufacturer"
                  :href="manufacturerHref(product.manufacturer)"
                  class="subtitle"
                  target="_blank"
                  @click="moreFromManufacturer(product)"
                >{{ product?.manufacturer }}</a>
                <a
                  id="TestA_plp_title_mobile"
                  class="title-font"
                  :href="productHref(product.id, product.title)"
                  @touchstart="newTabMobile"
                  @click="goToProduct(product, $event)"
                >
                  {{ product?.title }}
                </a>
                <p class="description">
                  {{ product?.short_description || '' }}
                </p>
                <div>
                  <div
                    :id="`TestA_plp_image_${index}_mobile`"
                    class="mobile-img"
                  >
                    <NoOverlayIonImg
                      :href="productHref(product.id, product.title)"
                      :src="product.original_images?.length > 0 ? product.original_images[0] : `${defaultLogo}`"
                      :alt="altText(product)"
                      :title="`${product?.title}`"
                      @click="goToProduct(product, $event)"
                    />
                    <div class="view-details">
                      <CallSellerButton
                        v-if="product && product.stock !== undefined"
                        id="callseller_plp"
                        :dealer-location-phone="product.location?.phone ?? ''"
                        :inventory-id="product.id"
                        :inventory-title="product.title"
                        :dealer-location-id="product.dealer_location_id"
                        :dealer-id="product.dealer_id"
                        :stock="product?.stock"
                        :disabled="product?.availability === 'sold'"
                        :class="[isMapView ? 'contact-seller-map' : '']"
                        class="call-seller-plp"
                        location="PLP"
                        :is-dealer-private="product?.dealer?.is_private || false"
                      >
                        Call Seller
                      </CallSellerButton>
                      <ion-button
                        id="viewdetails_plp"
                        :href="productHref(product.id, product.title)"
                        :disabled="product?.availability === 'sold'"
                        :class="[isMapView ? 'contact-seller-map' : '']"
                        class="contact-seller"
                        @click="goToProduct(product, $event)"
                      >
                        View details
                      </ion-button>
                    </div>
                  </div>
                </div>
                <div
                  v-if="product.availability !== 'available' &&
                    product.availability !== 'sold' &&
                    product.availability_label &&
                    product.availability
                  "
                  :id="`TestA_plp_status_${product.availability_label?.replace(/ /g, '_')}_mobile`"
                  :class="{
                    pending: product.availability === 'pending_sale',
                    on_order: product.availability === 'on_order',
                    special_order: product.availability === 'special_order',
                  }"
                  class="status ion-text-uppercase"
                >
                  {{ product.availability_label }}
                </div>
                <div class="d-flex ion-flex-column ion-justify-content-between content-box-custom">
                  <div class="d-flex ion-justify-content-between price-title">
                    <template v-if="!fetchingProducts">
                      <div
                        v-if="product.visible_price?.value"
                        id="TestA_plp_price_mobile"
                      >
                        <strong>{{ formatPrice(product.visible_price?.value, product?.currency) }}</strong>
                      </div>
                      <div
                        v-else
                        id="TestA_plp_contact_seller_mobile"
                      >
                        CONTACT SELLER FOR PRICE
                      </div>
                    </template>
                    <div class="ion-text-right">
                      <ion-button
                        v-if="product?.displayTime?.includes('30 min')"
                        id="TestA_plp_nearme_mobile"
                        class="near-me"
                        size="small"
                      >
                        NEAR ME
                      </ion-button>
                      <ion-button
                        v-if="showSalesPrice(product)"
                        id="TestA_plp_sale_mobile"
                        class="sale-btn"
                        size="small"
                      >
                        Sale
                      </ion-button>
                    </div>
                  </div>
                  <ion-item
                    lines="none"
                    class="comparison-item filter-checkbox-mobile"
                  >
                    <ion-label
                      class="ion-text-uppercase child-text"
                      :class="{strikethrough: comparisons.length >= 5 && !isInComparison(product.id)}"
                    >
                      Compare
                    </ion-label>
                    <ion-checkbox
                      v-if="comparisons.length < 5 || isInComparison(product.id)"
                      id="compare_selector"
                      :ref="'comparisons' + product.id"
                      :value="isInComparison(product.id)"
                      :checked="isInComparison(product.id)"
                      @ionChange="addToCompare($event, product)"
                    />
                  </ion-item>
                  <div class="d-flex ion-justify-content-between dealer-name">
                    <a
                      v-if="product.dealer?.tt_username"
                      id="TestA_plp_dealername_mobile"
                      :title="`${product.dealer?.tt_username || ''} ${product?.location?.city || ''} ${product?.location?.region || ''
                      }`"
                      class="disabled"
                    >{{ product.dealer?.tt_username }}</a>
                    <div
                      id="TestA_plp_dealeraddress_mobile"
                      class="w-100 ion-text-right"
                    >
                      {{ product?.location?.city }}, {{ product?.location?.region }}
                    </div>
                  </div>
                  <div class="d-flex ion-justify-content-between dealer-name">
                    <span class="d-flex"><span
                      class="product-dealer"
                      :style="{
                        'font-weight': 'bold',
                        color: product?.displayColor,
                      }"
                    >{{ product?.displayTime }}</span>
                    </span>
                  </div>
                  <div class="dealer-address">
                    <div class="ion-text-right">
                      <ion-button
                        class="compare-text ion-hide"
                        size="small"
                      >
                        Compare
                      </ion-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mobile-bottom-border ion-hide-md-up" />
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <!-- end mobile view -->
      <div
        v-if="count !== 0 && total !== 0"
        id="showingloadButtons"
        ref="showLoadButton"
        class="ion-text-center bottom-counter"
      >
        Showing <strong>{{ paginationLabel }}</strong> of <strong>{{ total }}</strong>
        <ion-progress-bar
          color="primary"
          class="progress-bar-pagination"
          :value="progressLength"
        />
        <div
          :class="{
            'floating-buttons': isMobile & !isContentBlockVisible,
          }"
          :style="isMobile & !isContentBlockVisible ? loadMoreButtonOffset : ''"
        >
          <!--Load Less button-->
          <a
            v-if="currentPage > 1 && currentPage <= totalPages"
            id="loadless_plp"
            :href="loadLessLink"
            :class="{
              'float-left': currentPage > 1 && currentPage < totalPages,
              'load-inactive': loading,
            }"
            class="load-less"
            @click="loadLess($event)"
          >
            Show Less
          </a>

          <!--Load More button-->
          <a
            v-if="currentPage < totalPages"
            id="loadmore_plp"
            :href="loadMoreLink"
            :class="{
              'load-inactive': loading,
            }"
            class="load-more"
            @click="loadMore($event)"
          >
            Show More
          </a>
        </div>
      </div>
    </template>
  </ion-grid>
</template>

<script>
import {
  IonChip,
  IonLabel,
  IonGrid,
  IonButton,
  IonProgressBar,
  IonCol,
  IonRow,
  IonCheckbox,
  IonItem,
} from "@ionic/vue";
import { ProductsLoader, getProductByID, createViewedDealers } from "@/services/ProductsService";
import { defineAsyncComponent } from 'vue';
import ContactSellerForm from "@/components/ContactSellerForm.vue";
import ProductListSort from "@/components/productlist/ProductListSort.vue";
import { modal, handleImageError, typeIdMapping, urlTransFormations, domain, dealerAddress } from "@/mixins";
import { Swiper, SwiperSlide } from "swiper/swiper-vue.esm";
import defaultLogo from "@/assets/defaultLogo.webp";
import NoOverlayIonImg from "@/components/img/NoOverlayIonImg.vue";
import { mapState, mapActions, useStore } from "vuex";
import { formatPrice } from "@/utils/CurrencyUtil";
import { calculateDistance } from "@/utils/DistanceUtil";
import { isSSR } from "@/utils/SSRUtil";
import NoInventory from "@/components/productlist/NoInventoryModal.vue";
import { trailerTypes } from "@/consts";
import CallSellerButton from "@/components/leads/CallSellerButton.vue";
import { computed, defineComponent } from "vue";
import { tcCategoryMapping } from "@/consts";
import { CONTENT_BLOCK_CATEGORIES, CONTENTS_FOR_CATEGORIES } from "@/consts/category";
import { appendSchema } from "@/utils/SchemaUtil";
import { DEFAULT_LOCATION_SORT, DEFAULT_NO_LOCATION_SORT } from "@/consts/sorts";
import AxlesItemFilter from "@/models/filters/group/AxlesItemFilter";
import SkirtedBedItemFilter from "@/models/filters/group/SkirtedBedItemFilter";
import GroupItemFilter from "@/models/filters/group/GroupItemFilter";

export default defineComponent({
  name: "ProductListContent",
  components: {
    CallSellerButton,
    IonChip,
    IonLabel,
    IonGrid,
    IonButton,
    IonProgressBar,
    IonRow,
    IonCol,
    ProductListSort,
    Swiper,
    SwiperSlide,
    ProductListMap: defineAsyncComponent(() => import("@/components/productlist/ProductListMap.vue")),
    NoOverlayIonImg,
    IonCheckbox,
    IonItem,
  },
  mixins: [modal, handleImageError, typeIdMapping, urlTransFormations, domain, dealerAddress],
  props: {
    isMapView: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    bottomLoadingCTA: {
      type: Boolean,
      default: false,
    },
    isContentBlockVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onShowSellerMap", "onShowActiveSellerMap", "loadedProductCards", "scrollToTop"],
  setup() {
    const store = useStore();

    const fetchingProducts = computed(() => store.state.fetchingProducts);
    const category = computed(() => store.state.searchApiFields?.category);

    return {
      fetchingProducts,
      category,
    };
  },
  data() {
    return {
      defaultLogo,
      pendingRequest: null,
      productsLoader: new ProductsLoader(),
      currentPage: 1,
      perPage: 15,
      total: 0,
      totalPages: 0,
      count: 0,
      productsDisplayList: [],
      productDetail: [],
      ContactSellerForm,
      disableContactSeller: false,
      loading: false,
      geoCode: {},
      markerList: [],
      sellerLocations: [],
      activeSellerAddress: null,
      sellerAddressList: [],
      trailerTypes,
      path: "",
      isEffectFromLoadMore: false,
      CONTENT_BLOCK_CATEGORIES,
      CONTENTS_FOR_CATEGORIES,
    };
  },
  computed: {
    ...mapState({
      totalProducts: (state) => state.totalProducts,
      userGeolocation: (state) => state.userGeoLocation,
      distance: (state) => state.distance,
      location: (state) => state.location,
      comparisons: (state) => state.trailers.comparisons,
    }),
    loadMoreButtonOffset() {
      return this.$store.getters.loadMoreButtonOffset;
    },
    progressLength() {
      let progress = (this.currentPage * this.perPage < this.total ? this.currentPage * this.perPage : this.total) / this.total;
      if (progress < 0.006) {
        progress = 0.006;
      }
      return progress;
    },
    showMoreButton() {
      return (!this.bottomLoadingCTA && this.isMobile) || this.isTablet || this.isDesktop;
    },
    loadLessLink() {
      return this.getNewPageLink(this.currentPage - 1);
    },
    loadMoreLink() {
      return this.getNewPageLink(this.currentPage + 1);
    },
    paginationLabel() {
      return ((this.currentPage - 1) * this.perPage + 1) + "-" + (this.currentPage * this.perPage < this.total ? this.currentPage * this.perPage : this.total);
    },
    truckBedCategories(){
      return this.fetchTruckBedCategories(tcCategoryMapping);
    },
    isInComparison() {
      return (id) => this.comparisons.some((item) => Number(item.id) === Number(id));
    },
    sortOption() {
      return this.$store.getters.sortOption;
    },
  },
  async serverPrefetch() {
    try {
      const { typeAggregations, categoryAggregations, searchApiFields } = this.$store.state;
      const params = this.composeParams();
      if (params.type_id === "all") {
        delete params.type_id;
      }
      await this.loadProducts(params, true, {
        newType: searchApiFields.type_id !== typeAggregations.type_id,
        newCategory: searchApiFields.category !== categoryAggregations.category,
      });
    } catch (ex) {
      console.error(ex);
    }
  },
  watch: {
    isMapView: {
      immediate: true,
      handler(newVal) {
        if (newVal === true) {
          this.showSellerMap();
        }
      },
    },
    "$store.state.searchApiFields": {
      immediate: true,
      handler(newVal, oldVal) {
        if (isSSR()) return;
        // This is dirty hack to wait isActive is ready
        setTimeout(async () => {
          if (!this.isActive) return;
          const { typeAggregations, categoryAggregations } = this.$store.state;

          if (localStorage.getItem("fromPDP")) {
            localStorage.removeItem("fromPDP");
            if (oldVal?.page > newVal?.page) {
              this.addSearchFilter({
                name: "page",
                value: oldVal.page,
              });
              return;
            }
            for(let key in oldVal) {
              if(newVal[key] === undefined) {
                const filterClasses = {
                  'axles': AxlesItemFilter,
                  'skirted_bed': SkirtedBedItemFilter,
                }
                const filterFields = {
                  name: key,
                  value: oldVal[key],
                };
                if (filterClasses[key]) {
                  this.addSearchFilter(
                    new filterClasses[key]({
                      value: oldVal[key],
                    }),
                  );
                } else {
                  this.addSearchFilter(new GroupItemFilter(filterFields));
                }
              }
            }
          }

          let changedCount = 0;
          for(let key in (oldVal || {})) {
            if(oldVal && (newVal[key] !== oldVal[key]) && (!["page", "lat", "lon"].includes(key))) {
              changedCount ++;
            }
          }
          if(changedCount > 0) {
            this.addSearchFilter({
              name: "page",
              value: 1,
            });
          }
          const params = this.composeParams({
            page: newVal?.page !== oldVal?.page ? newVal?.page : 1,
            per_page: this.perPage,
          });
          if (params.type_id === "all") {
            delete params.type_id;
          }
          const status = await this.loadProducts(params, {
            newType: newVal.type_id !== typeAggregations.type_id,
            newCategory: newVal.category !== categoryAggregations.category,
          });
          if (this.total === 0 || status?.requestRetry) {
            this.seoFilter();
          }
        });
      },
    },
    productsDisplayList: async function (newVal) {
      if(newVal.length > this.perPage) {
        this.productsDisplayList = newVal.slice(newVal.length % this.perPage === 0 ? -this.perPage : - (newVal.length % this.perPage));
      }
      for (const item in newVal) {
        const { message, color } = await this.displayTime(newVal[item]);
        if (this.productsDisplayList[item]) {
          this.productsDisplayList[item]["displayTime"] = message;
          this.productsDisplayList[item]["displayColor"] = color;
        }
      }
      this.scrollToTop();
      this.setStructuredSchema();
    },
  },
  methods: {
    ...mapActions(["addSearchFilter", "clearSearchFilter"]),
    scrollToTop() {
      const desktopContent = "div[style*='z-index: 101'] #productlist-header-desktop";
      const mobileContent = "div[style*='z-index: 101'] #productlist-header-mobile";
      document.querySelector(desktopContent)?.scrollIntoView();
      document.querySelector(mobileContent)?.scrollIntoView();
    },
    async displayTime(product) {
      let distance = 0;
      const address = `${product?.location?.address}, ${product?.location?.city} ${product?.location?.region} ${product?.location?.country}`;
      const { userGeoLocation } = this.$store.state;
      if (!address.includes("undefined")) {
        const index = this.sellerAddressList.findIndex((item) => item.location === address);
        if (index === -1) {
          let geoPoints;

          if (product.location.geo) {
            const { lat, lon: lng } = product.location.geo;
            geoPoints = {
              lat,
              lng,
            };

            distance = calculateDistance(parseFloat(userGeoLocation[0]), parseFloat(userGeoLocation[1]), lat, lng);
          } else {
            geoPoints = await this.getSellerGeoCode(address);
            distance = calculateDistance(
              parseFloat(userGeoLocation[0]),
              parseFloat(userGeoLocation[1]),
              geoPoints.lat,
              geoPoints.lng,
            );
          }
          this.sellerAddressList.push({ location: address, geoPoints });
          return this.distanceToTime(distance);
        } else {
          const { geoPoints } = this.sellerAddressList[index];
          distance = calculateDistance(
            parseFloat(userGeoLocation[0]),
            parseFloat(userGeoLocation[1]),
            geoPoints.lat,
            geoPoints.lng,
          );
          return this.distanceToTime(distance);
        }
      }
      return { message: "", color: "#15AE93" };
    },
    distanceToTime(distance) {
      const toTime = distance / 55;
      if (toTime <= 0.5) {
        return { message: "Under 30 min from you", color: "#15AE93" };
      } else if (toTime > 0.5 && toTime <= 1) {
        return { message: "Under 1 hour from you", color: "#F6D422" };
      } else if (toTime > 1 && toTime <= 2) {
        return { message: "Under 2 hours from you", color: "#E8AF5A" };
      } else {
        return { message: "", color: "#15AE93" };
      }
    },
    async loadMore(ev = null) {
      if (ev) {
        ev.preventDefault();
      }
      this.productsDisplayList = [];

      if (this.loading) {
        return;
      }

      this.addSearchFilter({
        name: "page",
        value: this.currentPage + 1,
      });
    },
    async loadLess(ev = null) {
      if (ev) {
        ev.preventDefault();
      }
      this.productsDisplayList = [];

      if (this.loading) {
        return;
      }

      this.addSearchFilter({
        name: "page",
        value: this.currentPage - 1,
      });
      await this.loadProducts(this.composeParams({ page: this.currentPage - 1 }));
    },
    loadAllTrailers(apiFields) {
      const newApiFields = { ...apiFields };
      delete newApiFields.type_id;
      delete newApiFields.category;
      return newApiFields;
    },
    composeParams(optionalParams) {
      const { userGeoLocation, distance, location, searchApiFields } = this.$store.state;
      const { sortOption } = this.$store.getters;
      if (this.$route.query.type_id === "all") {
        return {
          ...optionalParams,
          ...this.loadAllTrailers(searchApiFields),
          lat: userGeoLocation[0],
          lon: userGeoLocation[1],
          location,
          distance,
          sort: sortOption,
          per_page: this.perPage,
        };
      }

      const sortValue = searchApiFields.type_id !== 5 || searchApiFields.type_id === 5
        ? sortOption
        : (location ? DEFAULT_LOCATION_SORT : DEFAULT_NO_LOCATION_SORT);

      return {
        ...searchApiFields,
        ...optionalParams,
        lat: userGeoLocation[0],
        lon: userGeoLocation[1],
        location,
        distance,
        sort: sortValue,
        per_page: this.perPage,
      };
    },
    showSalesPrice(product) {
      if (parseFloat(product?.sales_price) !== 0) {
        return parseFloat(product?.sales_price) < parseFloat(product?.website_price);
      }
      return false;
    },
    formatPrice,
    async loadProducts(params, aggregationFlags = {}) {
      if (!isSSR()) {
        localStorage.setItem("filter", JSON.stringify(params));
      }
      this.loading = true;
      this.addingCustomLoadingKey();

      try {
        this.$store.commit("SET_FETCHING_PRODUCTS", true);

        if (!this.isMapView) {
          this.productsDisplayList = await this.productsLoader.getProducts(params, false, true);
        } else {
          this.productsDisplayList = await this.productsLoader.getPageProducts(params, false, true);
          const getContent = document.querySelector("#pageContent");
          getContent?.scrollToTop();
          this.showSellerMap();
        }
      } catch (error) {
        if (error.invalidCategory) {
          this.clearSearchFilter({
            name: params.category,
            retainManufacturer: true,
          });
          return Promise.resolve({ requestRetry: true });
        }
      }

      let viewed_dealers = [];
      this.productsDisplayList.forEach((product) => {
        const { dealer_id, dealer, id } = product;
        const { name } = dealer;
        let inventory_id = id;
        viewed_dealers = [...viewed_dealers, { dealer_id, name, inventory_id }];
      });

      let uniqueViewedDealers = this.getUniqueViewedDealers(viewed_dealers, "dealer_id");

      try {
        if (!isSSR()) {
          await createViewedDealers(uniqueViewedDealers);
        }
      } catch (error) {
        console.log(error);
      }

      this.typeIdMapping();
      this.total = this.productsLoader.total;
      this.totalPages = this.productsLoader.totalPages;
      this.currentPage = this.productsLoader.currentPage;
      this.count = this.isMapView ? this.calculateCount() : this.productsDisplayList.length;

      this.$store.commit("SET_TOTAL_PRODUCTS", this.total);

      if (aggregationFlags.newType) {
        this.$store.commit("SET_TYPE_AGGREGATIONS", {
          type_id: params.type_id,
          ...this.productsLoader.aggregations,
        });
      }
      if (aggregationFlags.newType || aggregationFlags.newCategory) {
        this.$store.commit("SET_CATEGORY_AGGREGATIONS", {
          category: params.category,
          ...this.productsLoader.aggregations,
        });
      }
      this.loading = false;
      this.$store.commit("SET_FETCHING_PRODUCTS", false);
      return Promise.resolve();
    },
    calculateCount() {
      if (this.currentPage === this.totalPages) {
        return this.total;
      } else {
        return this.productsDisplayList.length * this.currentPage;
      }
    },
    newTab($event) {
      if ($event.which === 3) {
        localStorage.setItem("newtab", "active");
      }
    },
    newTabMobile() {
      localStorage.setItem("newtab", "active");
    },
    goToProduct(product, $event) {
      if ($event !== undefined) {
        $event.preventDefault();
      }
      this.singleProduct(product.id);
      let url = this.superSanitize(product.title);
      url = url + "-" + this.shortenNumber(product.id) + ".html";
      this.trackInventoryClicked(product);
      this.$router.push({
        name: "ProductDetails",
        params: {
          url: url,
        },
      });
      localStorage.setItem("manufacturer", product?.manufacturer);
      localStorage.setItem("dealer_location_id", product?.dealer_location_id);
    },
    singleProduct(id) {
      this.$store.commit(
        "SET_INVENTORY_PRODUCT",
        this.productsDisplayList.find((item) => item.id == id),
      );
    },
    async getProductByID(id) {
      this.disableContactSeller = true;
      try {
        this.productDetail = await getProductByID(id);
        await this.openModal(ContactSellerForm, {
          cssClass: "modal-header-contact-seller",
          componentProps: {
            modalData: this.productDetail,
            location: "PLP",
          },
          backdropDismiss: true,
        });
      } catch (error) {
        await this.openModal(NoInventory, {
          cssClass: "modal-header-no-inventory",
          backdropDismiss: true,
        });
      }

      this.disableContactSeller = false;
    },
    toFeetandInch(feet) {
      const parsedFeet = parseInt(feet);
      const parsedInches = feet - parsedFeet;

      return parsedFeet
        ? `${parsedFeet}' ${Math.round(parsedInches * 12)}" or ${parsedFeet * 12 + Math.round(parsedInches * 12)}"`
        : "";
    },
    formatInches(inches) {
      const parsedInches = parseInt(inches);
      return parsedInches ? `${parsedInches}"` : "";
    },
    fetchTruckBedCategories(categoryMappings){
      return Object.keys(categoryMappings).filter(key => categoryMappings[key].type_id === 5);
    },
    isTruckBedCategory(category){
      return this.truckBedCategories.includes(category);
    },
    goToProductDetail(product) {
      if (!product.id || !product.title) return;
      if (window.innerWidth < 1200) {
        this.goToProduct(product);
      }
    },
    addingCustomLoadingKey() {
      for (let i = 0; i < this.perPage; i++) {
        const obj = {
          isLoading: true,
        };
        this.productsDisplayList.push(obj);
      }
    },
    onSlideChange(swiper) {
      this.showActiveSellerMap(this.productsDisplayList[swiper.realIndex]);
      if (swiper.realIndex === this.perPage - 1) {
        this.loadMore();
        swiper.slideTo(0);
      }
    },
    onTouchEnd(swiper) {
      if (swiper.realIndex === 0 && swiper.touches.diff > 0 && this.currentPage > 1) {
        this.showActiveSellerMap(this.productsDisplayList[swiper.realIndex]);
        this.loadLess();
        swiper.slideTo(0);
      }
    },
    showActiveSellerMap(product) {
      try {
        if (product?.isLoading !== true) {
          const address = `${product?.location.address}, ${product?.location.city} ${product?.location.region} ${product?.location.country}`;
          this.activeSellerAddress = address;
          this.$emit("onShowActiveSellerMap", address);
        }
      } catch (error) {
        console.log(error);
      }
    },
    showSellerMap() {
      try {
        const address = [
          ...new Set(
            this.productsDisplayList.map((item) => {
              return `${item?.location.address}, ${item?.location.city} ${item?.location.region} ${item?.location.country}`;
            }),
          ),
        ];
        this.sellerLocations = address;
        this.$emit("onShowSellerMap", address);
      } catch (error) {
        console.log(error);
      }
    },
    seoFilter() {
      if (
        this.$router.options.history.state.back === null &&
        this.$router.options.history.state.forward === null &&
        this.totalProducts === 0
      ) {
        if (this.$store.state.distance === "300mi") {
          this.$store.commit("SET_DISTANCE", "9999mi");
        } else if (
          this.$store.state.distance === "50mi" ||
          this.$store.state.distance === "100mi" ||
          this.$store.state.distance === "150mi" ||
          this.$store.state.distance === "200mi"
        ) {
          this.$store.commit("SET_DISTANCE", "300mi");
        }
      }

      this.$store.commit("SHOW_SELECTED_FILTER", true);
      this.$store.commit("SET_SELECTED_FILTER", this.$store.state.searchFilter);
    },
    async moreFromSeller(product) {
      if (!isSSR()) {
        localStorage.setItem("inventoryId", product?.id);
        localStorage.setItem("dealerId", product?.dealer_id);
        localStorage.setItem("dealer_location_id", product?.dealer_location_id);
      }
    },
    async moreFromManufacturer(product) {
      if (!isSSR()) {
        localStorage.setItem("inventoryId", product?.id);
        localStorage.setItem("manufacturer", product?.manufacturer);
      }
    },
    getUniqueViewedDealers(duplicateArr, key) {
      return [...new Map(duplicateArr.map((item) => [item[key], item])).values()];
    },
    trackInventoryClicked(inventory) {
      window.tt_track?.inventoryClicked({
        meta: {
          dealer_id: parseInt(inventory.dealer_id),
          inventory_id: parseInt(inventory.id),
          stock: inventory.stock,
          category: inventory.category,
          category_label: inventory.category_label,
          type_id: inventory.type_id,
          type_label: inventory.type_label,
          title: inventory.title,
          is_private: inventory.dealer?.is_private,
        },
      });
    },
    manufacturerHref(manufacturerName) {
      let manufacturer = manufacturerName?.toLowerCase();
      const containTrailerSuffix =
        manufacturer?.split(" ")?.includes("trailer") || manufacturer?.split(" ")?.includes("trailers");
      let url;
      if (containTrailerSuffix) {
        url = `${manufacturer}-for-sale`;
      } else {
        url = `${manufacturer}-trailers-for-sale`;
      }
      return `/${url}`;
    },
    getNewPageLink(page) {
      const [path, query] = this.$route.fullPath.split(`?`);
      const basePath = path.split(`-showing`)[0];

      let url = `${basePath}-showing-${page * this.perPage}`;

      if (query) {
        url += `?${query}`;
      }

      return url;
    },
    async addToCompare(e, product) {
      e.preventDefault();
      const {checked} = e.detail;
      if (checked) {
        if (this.isInComparison(product.id)) {
          return;
        }
        const productDetails = await getProductByID(product.id);
        this.$store.dispatch("setComparisons", [
          ...this.comparisons,
          productDetails,
        ]);
      } else {
        const products = this.comparisons.filter((item) => Number(item.id) !== Number(product.id));
        this.$store.dispatch("setComparisons", products);
      }
    },
    appendSchema,
    setStructuredSchema() {
      const itemList = this.productsDisplayList.map((item, index) => ({
        "@type": "ListItem",
        "position": index + 1,
        "item": {
          "@type": "Vehicle",
          "name": item.title,
          "url": this.productHref(item.id, item.title),
          "image": item.image,
          "description": item.schema_description,
          "offers": {
            "@type": "Offer",
            "price": item.visible_price.value || 0,
            "priceCurrency": "USD",
          },
        },
      }));
      let richTextSchema = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "mainEntity": {
          "@type": "ItemList",
          "itemListElement": itemList,
        },
      };
      const contentCategory = this.CONTENT_BLOCK_CATEGORIES.find(cat => this.category?.split(';').includes(cat));
      if (contentCategory) {
        const article = this.CONTENTS_FOR_CATEGORIES[contentCategory];
        richTextSchema = {
          ...richTextSchema,
          "about": {
            "@type": "Article",
            "headline": article.headline,
            "author": {
              "@type": "Person",
              "name": article.author,
            },
            "datePublished": article.datePublished,
            "articleBody": article.articleBody,
          },
        };
      }

      this.appendSchema(richTextSchema, true);

      if (this.location) {
        const areaServedSchema = {
          "@context": "https://schema.org",
          "areaServed": {
            "@type": "GeoCircle",
            "geoMidpoint": {
              "@type": "GeoCoordinates",
              "latitude": this.userGeolocation[0],
              "longitude": this.userGeolocation[1],
            },
            "geoRadius": 50000,
          },
          "url": !isSSR() ? window.location.href : "",
        };
        this.appendSchema(areaServedSchema, true, false);
      }
    },
  },
});
</script>

<style scoped>
.status {
  font-size: 13px;
}
</style>
