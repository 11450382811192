const defaultCurrency = {
  code : "USD",
  symbol : "$",
}

const formatPrice = (value, currency = defaultCurrency) => {
if(!value) return 0;
if(typeof value === 'string' && value.includes('$')){
  return value;
}

if(!currency){
  currency = defaultCurrency;
}
const price = Math.round(+value);
const symbol = currency.symbol;

return `${symbol}${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;;
};

export { formatPrice };