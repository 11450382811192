<template>
  <!-- Start of Status Accordion Setup -->
  <ion-row class="collapsable-menu-custom">
    <ion-col>
      <ion-list>
        <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
          <ion-item
            id="TestA_plp_filter_status"
            lines="none"
            class="filter-item-head"
            :class="{ active }"
            @click="handleClick"
          >
            <div class="text-icon">
              <div class="ion-text-uppercase parent-text">
                Status
              </div>
              <div class="d-flex">
                <span class="ion-text-uppercase selected-search ion-padding-end">
                  {{ selectedListString }}
                </span>
                <span :class="!active ? 'arrow-drop  rotate-caret' : 'arrow-drop'">
                  <img
                    alt="Trailers Near Me"
                    src="@/assets/icons/caretDownBlue.svg"
                  >
                </span>
              </div>
            </div>
          </ion-item>
          <!-- <div> -->
          <template
            v-for="item in statusOptions"
            :key="item.name"
          >
            <ion-item
              v-if="active"
              lines="none"
              :href="item.href"
              class="filter-collapse"
              @click="toggleSelected($event, item)"
            >
              <ion-checkbox
                slot="start"
                :ref="'status_' + item.name"
                :value="item"
                :checked="!!hasFilter('availability', item.name)"
                class="filter-checkbox"
              />
              <ion-label class="ion-text-uppercase child-text">
                {{ item.label }}
              </ion-label>
            </ion-item>
          </template>
        </div>
      </ion-list>
    </ion-col>
  </ion-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ellipsizeList } from "@/utils/FilterListUtil";
import { IonCheckbox, IonCol, IonItem, IonList, IonRow, IonLabel } from "@ionic/vue";
import GroupItemFilter from "@/models/filters/group/GroupItemFilter";
import { filterActions } from "@/mixins";
import { scrollPlpToTop } from "@/utils/ProductListingPageUtil";

export default {
  name: "StatusFilter",
  components: {
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonCheckbox,
    IonLabel,
  },
  mixins: [filterActions],
  props: {
    active: Boolean,
  },
  emits: ["toggle"],
  computed: {
    ...mapState({
      searchFilter: (state) => state.searchFilter,
      searchApiFields: (state) => state.searchApiFields,
      aggregations: (state) => state.categoryAggregations,
    }),
    statusOptions() {
      const statusValues = {
        "1": { name: "available", label: "Available" },
        "3": { name: "on_order", label: "On Order" },
        "5": { name: "special_order", label: "Special Order" },
        "4": { name: "pending_sale", label: "Pending Sale" },
      };
      return this.aggregations?.filter_aggregations?.status?.buckets?.filter((status) => status.key !== "0").map((status) => {
        return {
          ...statusValues[status.key],
          href: this.generateHrefFromField(statusValues[status.key].name, "availability"),
        };
      });
    },
    selectedList() {
      return this.searchFilter
        .filter((item) => item.name === "availability")
        .map((item) => item.label);
    },
    selectedListString() {
      return ellipsizeList(this.selectedList, 14);
    },
  },
  methods: {
    ...mapActions(["addSearchFilter", "clearSearchFilter"]),
    toggleSelected($event, item) {
      $event.preventDefault();
      const { checked, value } = this.$refs['status_' + item.name][0];
      if (!checked) {
        this.addSearchFilter(
          new GroupItemFilter({
            value: value.name,
            label: value.label,
            name: "availability",
          }),
        );
      } else if (checked) {
        this.clearSearchFilter({
          name: "availability",
          value: value.name,
        });
      }

      scrollPlpToTop();
    },
    handleClick() {
      this.$emit("toggle");
    },
  },
};
</script>
