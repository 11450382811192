<template>
  <ion-header class="ion-no-border">
    <ion-grid style="height:200px">
      <span
        class="info-modal"
        @click="goToListing()"
      >
        <span>
          {{ title }}
        </span>
        <ion-icon 
          :icon="closeSharp"
          aria-label="close modal"
        />
      </span>
      <div class="info-content">
        <div class="info-message">
          {{ message }}
        </div>
      </div>
    </ion-grid>
  </ion-header>
</template>

<script>
import {
  IonHeader,
  IonGrid,
  IonIcon,
  modalController,
} from "@ionic/vue";
import { closeSharp } from "ionicons/icons";

export default {
  name: "InfoModal",
  components: {
    IonHeader,
    IonGrid,
    IonIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: "You have left a mandatory field empty. As your listing is live,\n" +
          "          we can not save this page in its current condition.\n" +
          "          Please complete all mandatory fields before saving.",
      required: true,
    },
  },
  data() {
    return {
      closeSharp,
    }
  },
  methods: {
    async closeModal() {
      await modalController.dismiss();
    },
    goToListing() {
      this.closeModal();
    },
  },

};
</script>
