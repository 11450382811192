<template>
  <ion-row class="collapsable-menu-custom">
    <ion-col>
      <ion-list>
        <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
          <ion-item
            id="TestA_plp_filter_payloadcapacity"
            class="filter-item-head"
            lines="none"
            :class="{ active: isShown }"
            @click="toggle()"
          >
            <div class="text-icon">
              <div class="ion-text-uppercase parent-text">
                {{ name }}
              </div>
              <div>
                <span
                  :class="!isShown ? 'arrow-drop  rotate-caret' : 'arrow-drop'"
                >
                  <img
                    alt="Trailers Near Me"
                    src="@/assets/icons/caretDownBlue.svg"
                  >
                </span>
              </div>
            </div>
          </ion-item>
          <div
            v-if="isShown"
            lines="none"
          >
            <range-filter
              :name="payloadInfo.name"
              :label="payloadInfo.label"
              :min="payloadInfo.min"
              :max="payloadInfo.max"
              :filter-class="WeightFilterModel"
              @submitted="handleValueSubmitted()"
            />
          </div>
        </div>
      </ion-list>
    </ion-col>
  </ion-row>
</template>

<script>
import { IonRow, IonCol, IonItem, IonList } from "@ionic/vue";
import { informationCircle } from "ionicons/icons";
import RangeFilter from "./RangeFilter.vue";
import WeightFilterModel from "../../models/filters/range/WeightFilter";
import { scrollPlpToTop } from "@/utils/ProductListingPageUtil";

export default {
  name: "PayloadCapacityFilter",
  components: {
    RangeFilter,
    IonCol,
    IonRow,
    IonList,
    IonItem,
  },
  data() {
    return {
      informationCircle,
      name: "Payload Capacity",
      isShown: false,
      WeightFilterModel,
      lower: 0,
      upper: 0,
    };
  },
  computed: {
    payloadInfo() {
      const {
        filterLimits,
        searchApiFields: { type_id: activeTypeId },
      } = this.$store.state;
      const min =
        (filterLimits?.payload_capacity &&
          filterLimits.payload_capacity[activeTypeId]?.min) ||
        0;
      const max =
        (filterLimits?.payload_capacity &&
          filterLimits.payload_capacity[activeTypeId]?.max) ||
        35000;
      return {
        name: "payload_capacity",
        label: "capacity",
        min,
        max,
      };
    },
  },
  methods: {
    toggle() {
      this.isShown = !this.isShown;
    },
    handleValueSubmitted() {
      scrollPlpToTop();
    },
  },
};
</script>

<style scoped></style>
