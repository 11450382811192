<template>
  <ion-menu
    class="filters-responsive-layout dealer-sales"
    menu-id="categoryContent"
    side="end"
    content-id="main-content"
  >
    <div class="responsive-bar">
      <span>
        <ion-button
          color="transparent"
          class="close-btn ion-no-padding"
          @click="closeFilter"
        ><ion-icon
          class="close-side-toggle"
          :icon="closeSharp"
          aria-label="close side toggle"
        /></ion-button>
      </span>
      <CategorySideBar
        :categories="categories"
        :category="category"
        :select-category="selectCategory"
      />
    </div>
  </ion-menu>
</template>

<script>
import { defineComponent } from "vue";
import { IonIcon, menuController, IonButton, IonMenu } from "@ionic/vue";
import { closeSharp, chevronForwardOutline } from "ionicons/icons";
import { MENU_CONTROLLER } from "@/consts/menus";
import { CategorySideBar } from "@/components";
export default defineComponent({
  name: "CategoryResponsiveSideBar",
  components: {
    CategorySideBar,
    IonIcon,
    IonButton,
    IonMenu,
  },
  props: ["categories", "category", "selectCategory"],
  data() {
    return {
      chevronForwardOutline,
      data: true,
      closeSharp,
    };
  },
  methods: {
    closeFilter() {
      menuController.close(MENU_CONTROLLER.CategoryContent);
    },
  },
});
</script>
