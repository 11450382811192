<template>
  <template v-if="realHref.length > 0">
    <router-link
      :to="realHref"
      :target="target"
    >
      <ion-img
        :src="currentSrc"
        :alt="alt"
        :title="title"
        @ionError="onImageLoadError"
      />
    </router-link>
  </template>
  <template v-else>
    <ion-img
      :src="currentSrc"
      :alt="alt"
      :title="title"
      @ionError="onImageLoadError"
    />
  </template>
</template>

<script>
import { IonImg } from "@ionic/vue";
import defaultLogo from "@/assets/defaultLogo.webp";
import { trimOverlay } from "@/utils/ImgSrcUtil";

export default {
  name: "NoOverlayIonImg",
  components: {
    IonImg,
  },
  props: {
    src: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    href: {
      type: String,
      default: "",
    },
    target: {
      type: String,
      default: "_blank",
    },
  },
  data() {
    return {
      currentSrc: trimOverlay(this.src),
    };
  },
  computed: {
    realHref() {
      let realHref = this.href.trim().length === 0
        ? ""
        : this.href.trim();

      // If we send absolute path in the prop, we'll set the href using only the path
      if (realHref.includes("http")) {
        realHref = new URL(realHref).pathname;
      }

      return realHref;
    },
  },
  methods: {
    onImageLoadError() {
      if (this.currentSrc !== this.src) {
        this.currentSrc = this.src;
      } else {
        this.currentSrc = defaultLogo;
      }
    },
  },
};
</script>

<style scoped>

</style>
