import http from "./http-client";
import { isSSR } from "@/utils/SSRUtil";
import { states } from "@/consts/states";

const reverseGeocode = (params) => {
  return http.get("/map_search/reverse", { params });
};

const autocomplete = (params) => {
  return http.get("/map_search/autocomplete", { params });
};
const geocode = (params) => {
  if (isSSR()) {
    return {};
  }

  if (params.q) {
    return http.get("/map_search/geocode", { params });
  }

  return {};
};

const ipToLocation = () => {
  return http.get("/ipinfo/city");
};

const convertLongRegionToShortRegion = (region) => {
  if (typeof region === "undefined") {
    return "";
  }

  region = region.trim();

  if (region.length === 2) {
    return region;
  }

  for (const key in states) {
    if (states[key].toUpperCase() === region) {
      return key;
    }
  }

  return region;
};

export { reverseGeocode, autocomplete, geocode, ipToLocation, convertLongRegionToShortRegion };
