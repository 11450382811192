<template>
  <!-- Start of ColorFilter Accordion Setup -->
  <ion-row
    class="collapsable-menu-custom"
  >
    <ion-col>
      <ion-list>
        <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
          <ion-item
            id="TestA_plp_filter_color"
            lines="none"
            class="filter-item-head"
            :class="{ active }"
            @click="handleClick"
          >
            <div class="text-icon">
              <div class="ion-text-uppercase parent-text">
                Color
              </div>
              <div class="d-flex">
                <span class="ion-text-uppercase selected-search ion-padding-end">
                  {{ selectedListString }}
                </span>
                <span :class="!active ? 'arrow-drop  rotate-caret' : 'arrow-drop'">
                  <img
                    alt="Trailers Near Me"
                    src="@/assets/icons/caretDownBlue.svg"
                  >
                </span>
              </div>
            </div>
          </ion-item>
          <template
            v-for="(item, index) in options"
            :key="index"
          >
            <ion-item
              v-if="active && (index < moreLimit || seeMore)"
              lines="none"
              class="filter-collapse"
              @click="toggleFilter($event, 'color', index)"
            >
              <ion-checkbox
                slot="start"
                ref="color"
                :value="item.key"
                :checked="!!hasFilter('color', item.key)"
                class="filter-checkbox"
              />
              <ion-label
                style="display: flex;"
                class="ion-text-uppercase child-text"
              >
                <div
                  class="filter-color-circle"
                  :style="getCircleStyle(item.key)"
                /> 
                {{ keyToLabel(item.key) }} 
              </ion-label>
            </ion-item>
          </template>
          <div
            v-if="active && options.length > moreLimit"
            class="see-more-less"
          >
            <div>
              <ion-label
                v-if="active && !seeMore"
                class="see-more ion-margin-top"
                @click="toggleSeeMore(true)"
              >
                See {{ options.length - moreLimit }} more
                <img
                  alt="caretRight"
                  class="caret-right"
                  src="@/assets/icons/caretRight.svg"
                >
              </ion-label>
            </div>
            <div class="see-less">
              <ion-label
                v-if="active && seeMore"
                class="see-more ion-margin-top"
                @click="toggleSeeMore(false)"
              >
                See less
                <img
                  alt="caretUp"
                  src="@/assets/icons/caretUp.svg"
                >
              </ion-label>
            </div>
          </div>
        </div>
      </ion-list>
    </ion-col>
  </ion-row>
</template>

<script>
import { mapState } from "vuex";
import { ellipsizeList } from "@/utils/FilterListUtil";
import { IonCheckbox, IonCol, IonItem, IonList, IonRow, IonLabel } from "@ionic/vue";
import { filterActions } from "@/mixins";

export default {
  name: "ColorFilter",
  components: {
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonCheckbox,
    IonLabel,
  },
  mixins: [filterActions],
  props: {
    active: Boolean,
  },
  emits: ["toggle"],
  data(){
    return {
      moreLimit: 6,
      seeMore: false,
    }
  },
  computed: {
    ...mapState({
      searchFilter: (state) => state.searchFilter,
      searchApiFields: (state) => state.searchApiFields,
      aggregations: (state) => state.categoryAggregations,
    }),
    options() {
      return this.aggregations?.filter_aggregations?.color?.buckets || []
    },
    selectedList() {
      return this.searchFilter
          .filter((item) => item.name === "color")
          .map((item) => item.label);
    },
    selectedListString() {
      return ellipsizeList(this.selectedList, 14);
    },
  },
  methods: {
    getCircleStyle(key) {
      const colorCodes = {
        aluminum: "#DFDFE1",
        beige: "#fff0db",
        bronze: "#CD7F32",
        black: "#000000",
        blue: "#0000FF",
        burgundy: "#800020",
        champagne: "#F7E7CE",
        charcoal: "#36454F",
        gold: "#FFD700",
        gray: "#808080",
        green: "#00FF00",
        matte_black: "#171717",
        metallic_gray: "#8E8E8E",
        metallic_mocha: "#835F58",
        mocha: "#967969",
        natural: "#E5D3BF",
        orange: "#FFA500",
        pewter: "#E9EAEC",
        pink: "#FFC0CB",
        purple: "#800080",
        red: "#FF0000",
        silver: "#C0C0C0",
        tan: "#D2B48C",
        two_tone: "half/half",
        white: "#FFFFFF",
        yellow: "#FFFF00",
        light_blue_metallic: "#63cbf7",
        royal_red_metallic: "#9b1c31",
        chestnut_metallic: "#954535",
        ingot_silver_metallic: "#aaa9ad",
        dark_blue_metallic: "#32527b",
        sterling_gray_metallic: "#b5b5ab",
        birch: "#f8dfa1",
      }

      if (key === "two_tone") {
        return { background: "linear-gradient(90deg, black 50%, white 50%)" };
      } else {
        return { backgroundColor: colorCodes[key] || key };
      }
    },
    toggleSeeMore(value) {
      this.seeMore = value;
    },
    handleClick() {
      this.$emit("toggle");
    },
    keyToLabel(key) {
      return key.replaceAll("_", " ")
    },
  },
};
</script>