export const DEFAULT_LOCATION_SORT = "in_distance";
export const DEFAULT_NO_LOCATION_SORT = "-createdAt";

export const NO_LOCATION_SORT_LIST = [
  { name: "Newest", apiVal: "-createdAt" },
  { name: "Most Features", apiVal: "-numFeatures" },
  { name: "Price Asc", apiVal: "price" },
  { name: "Price Desc", apiVal: "-price" },
  { name: "Payload Capacity Asc", apiVal: "payloadCapacity" },
  { name: "Payload Capacity Desc", apiVal: "-payloadCapacity" },
];

export const LOCATION_SORT_LIST = [
  { name: "Within range", apiVal: "in_distance" },
  { name: "Closest", apiVal: "distance" },
  { name: "Newest", apiVal: "-createdAt" },
  { name: "Most Features", apiVal: "-numFeatures" },
  { name: "Price Asc", apiVal: "price" },
  { name: "Price Desc", apiVal: "-price" },
  { name: "Payload Capacity Asc", apiVal: "payloadCapacity" },
  { name: "Payload Capacity Desc", apiVal: "-payloadCapacity" },
];
