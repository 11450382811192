import { isSSR } from "@/utils/SSRUtil";

export default {
  computed: {
    parentUrl() {
      if (!isSSR()) {
        return window.location.hostname;
      } else {
        return new URL(this.href).hostname;
      }
    },
    /**
     * The domainOrigin method returns the domain origin (schema + domain name)
     *
     * @returns {string}
     */
    domainOrigin() {
      if (!isSSR()) {
        return window.location.origin;
      } else {
        return new URL(this.href).origin;
      }
    },
  },
};
