import { parseBoolean, undefinedAsNull } from "@/config";
import { isSSR } from "@/utils/SSRUtil";
import LogRocket from "logrocket";

export const LogRocketService = {
  // Bootstrap stuff
  enabled: parseBoolean(import.meta.env.VITE_LOGROCKET_ENABLED),
  app_id: undefinedAsNull(import.meta.env.VITE_LOGROCKET_APP_ID),
  debug: parseBoolean(import.meta.env.VITE_LOGROCKET_DEBUG),
  booted: false,

  // Properties
  events: {
    // Systems
    initial_site_load: "Initial Site Load",

    // Call Seller
    call_seller_form_open: "Call Seller Form Open",
    call_seller_form_submitted_success: "Call Seller Form Submitted Success",
    call_seller_form_submitted_errors: "Call Seller Form Submitted Errors",

    // Contact Form
    contact_form_open: "Contact Form Open",
    contact_form_abandoned: "Contact Form Abandoned",
    contact_form_submitted_success: "Contact Form Submitted Success",
    contact_form_submitted_with_validation_errors: "Contact Form Submitted With Validation Errors",
    contact_form_submitted_errors: "Contact Form Submitted Errors",
    contact_form_partially_filled_in: "Contact Form Partially Filled In",

    // Sign In/Sign Up Modal
    sign_in_sign_up_model_open: "Sign In/Sign Up Modal Open",

    // Login
    login_submitted: "Login Submitted",
    login_success: "Login Success",
    login_needs_verify: "Login Needs Verify",
    login_failed_credentials: "Login Failed - Credentials",
    login_failed_recaptcha: "Login Failed - Recaptcha",
    login_google: "Login With Google",
    login_facebook: "Login With Facebook",

    // Register
    register_submitted: "Register Submitted",
    register_success: "Register Success",
    register_failed: "Register Failed",
    register_failed_duplicate_emails_with_tc: "Register Failed - Duplicate Email With TC",
    register_failed_account_exists_in_tt: "Register Failed - Account Exists in TT",

    // Logout
    logout_success: "Logout Success",

    // Listing a Trailer
    create_new_listing_clicked: "Create New Listing Clicked",
    edit_listing_clicked: "Edit Listing Clicked",
    renew_listing_clicked: "Renew Listing Clicked",
    open_trailer_page_clicked: "Open Trailer Page Clicked",
    listing_sold_modal_open: "Mark Listing As Sold Modal Open",
    listing_sold_modal_abandoned: "Mark Listing As Sold Modal Abandoned",
    listing_sold_success: "Mark Listing As Sold Success",
    pay_now_clicked: "Pay Now Clicked",
    payment_plan_changed: "Payment Plan Changed",
    photo_upload_submitted: "Upload Photo Submitted",
    photo_upload_success: "Upload Photo Success",
    photo_upload_failed: "Upload Photo Failed",
    photo_drag_dropped: "Photo Drag & Dropped",

    // Payment
    payment_success: "Payment Success",
    payment_canceled: "Payment Canceled",

    // Search
    homepage_search_enter_pressed: "Homepage Search - Enter Pressed",
    homepage_search_searching: "Homepage Search - Searching",
    homepage_search_success_with_results: "Homepage Search - Success With Results",
    homepage_search_success_empty: "Homepage Search - Success Empty",
    homepage_search_error: "Homepage Search - Error",
    homepage_search_item_selected: "Homepage Search - Item Selected",

    dw_navigation_modal_opened: "DW Navigation Modal Opened",
    dw_navigation_modal_abandoned: "DW Navigation Modal Abandoned",
    dw_navigation_modal_abandoned_filled: "DW Navigation Modal Abandoned - Filled",
    dw_navigation_modal_submitted: "DW Navigation Modal Submitted",
    dw_navigation_lead_success: "DW Navigation Lead Success",
    dw_navigation_lead_errors: "DW Navigation Lead Errors",

    share_modal_opened: "Share Modal Opened",
    share_modal_abandoned: "Share Modal Abandoned",
    share_modal_abandoned_filled: "Share Modal Abandoned - Filled",
    share_modal_submitted: "Share Modal Submitted",
    share_modal_success: "Share Modal Success",
    share_modal_errors: "Share Modal Errors",

    // Listing a Trailer - Methods
    nextStepClicked(currentTabName) {
      return `${currentTabName ?? 'Unknown Tab'} - Next Step Clicked`;
    },
  },
  init() {
    if (isSSR()) {
      return;
    }

    if (!this.enabled || this.app_id === null) {
      this.log(`LogRocket is being disabled in .env file or app_id is being null, we'll not start LogRocket session`);
      return;
    }

    LogRocket.init(this.app_id);

    this.booted = true;

    this.log(`LogRocket is initialized with app id ${this.app_id}`);
  },
  track(event, meta) {
    this.log(`Intercepting event "${event}" and parsing it along to LogRocket...`, meta);

    if (isSSR() || !this.booted) {
      this.log(`LogRocket is not initialized yet or running in SSR mode, we'll not send this tracking data to it`)
      return;
    }

    LogRocket.track(event, meta);

    this.log(`Event "${event}" logged to LogRocket service`, meta);
  },
  identify(userId, meta) {
    if (isSSR() || !this.booted) {
      this.log(`LogRocket is not initialized yet or running in SSR mode, we'll not execute this function call`)
      return;
    }

    if (!userId) {
      this.log(`Cannot identify the user because user ID is undefined or null`, meta);
      return;
    }

    LogRocket.identify(userId, meta);

    this.log(`LogRocket will identify this session with user id ${userId}`, meta);
  },
  log(message, meta) {
    if (!this.debug) {
      return;
    }

    if (meta !== undefined) {
      console.log(`LogRocket: ${message}`, meta);
    } else {
      console.log(`LogRocket: ${message}`);
    }
  },
};
