import http from "./http-client";

const registerUser = (payload) => {
  return http.post("/user/register", payload).then((res) => res.data);
};

const loginUser = (params) => {
  return http.get(`/user/auth`, params).then((res) => res);
};

const forgotPassword = (payload) => {
  return http.post("/user/forget-password", payload).then((res) => res);
};

const resetPassword = (payload) => {
  return http.post("/user/reset-password", payload).then((res) => res);
};

const resendEmail = (email) => {
  return http.get(`/user/email/verification-notification`, {params: {email: email}}).then((res) => res);
};

const userProfile = (token) => {
  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return http.get(`/user`, config);
};

const updateProfile = (payload, token) => {
  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return http.post(`/user`, payload, config);
};

const refreshToken = () => http.post(`/user/jwt/refresh`);
const logOut = () => http.post(`/user/jwt/logout`);

const getDealers = (params) => {
  return http.get("/dealers", { params }).then((res) => res.data.data);
};

const privateStats = (payload) => {
  return http.post("/user/stats", payload);
}

export {
  registerUser,
  loginUser,
  forgotPassword,
  resetPassword,
  resendEmail,
  userProfile,
  updateProfile,
  refreshToken,
  logOut,
  getDealers,
  privateStats,
};
