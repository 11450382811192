import RangeFilter from "./RangeFilter";

export default class WeightFilter extends RangeFilter {
  constructor({name, min, max}) {
    const label = WeightFilter.defaultLabel(name, min, max);
    const rangeLabels = WeightFilter.rangeLabelsList(name, min, max);
    super({name, min, max, label, rangeLabels});
  }
}

WeightFilter.valueFormatter = function (value) {
  if(value <= 9999) {
    return `${value} lbs`;
  } else {
    return `${(value / 1000).toFixed(1)} K lbs`;
  }
}

WeightFilter.defaultLabel = function (name, min, max) {
  const minFormatted = WeightFilter.valueFormatter(min);
  const maxFormatted = WeightFilter.valueFormatter(max);

  return `${minFormatted}<${name}<${maxFormatted}`;
}

WeightFilter.rangeLabelsList = function (name, min, max) {
  const ranges = [];
  if (min) {
    ranges.push({
      key: "min",
      value: `${name} > ${WeightFilter.valueFormatter(min)}`,
    });
  }
  if (max) {
    ranges.push({
      key: "max",
      value: `${name} < ${WeightFilter.valueFormatter(max)}`,
    });
  }

  return ranges;
};