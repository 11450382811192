<template>
  <ion-header class="ion-no-border">
    <ion-grid class="modal-header-logout-confirmation">
      <div class="logout-confirmation-modal">
        <div class="logout-confirmation-title ion-text-uppercase">
          Logout
        </div>
        <div class="logout-confirmation-description">
          Please confirm that you want to log out
        </div>
        <div class="logout-confirmation-button">
          <ion-col
            size-md="4"
            size="12"
          >
            <ion-button
              class="btn btn-submit custom-button"
              type="submit"
              @click="logout"
            >
              confirm
            </ion-button>
          </ion-col>
          <ion-col
            size-md="4"
            size="12"
          >
            <ion-button
              class="btn btn-cancel custom-button"
              @click="closeModal"
            >
              cancel
            </ion-button>
          </ion-col>
        </div>
      </div>
    </ion-grid>
  </ion-header>
</template>

<script>
import { IonHeader, IonGrid, modalController, IonCol, IonButton } from "@ionic/vue";
import { logOut } from "@/services/UserService";
import { mapState } from "vuex";
import { LogRocketService } from "@/services/LogRocketService";
export default {
  name: "LogoutConfirmation",
  components: {
    IonHeader,
    IonGrid,
    IonCol,
    IonButton,
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.isAuthenticated,
    }),
  },
  methods: {
    async closeModal() {
      await modalController.dismiss();
    },
    async logout() {
      await this.closeModal();
      const res = await logOut();

      if( typeof res === "object" && res?.status === 200){
        const userData = this.$store.state.userData;
        LogRocketService.track(LogRocketService.events.logout_success, {
          email: userData.email,
        });

        this.$store.commit("REMOVE_AUTH_TOKEN", "");
        this.$store.commit("SET_IS_AUTHENTICATED", false);
        this.$store.dispatch("setToastNotification", {
          open: true,
          text: "Logged Out Successfully",
          type: "success",
        });
        this.$router.push({
          name: "Home",
        });
      }
    },
  },
};
</script>
