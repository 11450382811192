<template>
  <ion-header class="ion-no-border">
    <ion-grid class="modal-header-call-seller">
      <div class="call-seller-modal">
        <div class="call-seller-header">
          <span class="close-call-seller-modal">
            <ion-icon
              :icon="closeSharp"
              aria-label="close modal"
              @click="closeModal"
            />
          </span>
        </div>
        <div class="call-seller-content">
          <div class="call-seller-logo">
            <img
              src="@/assets/Tel.webp"
              alt="@/assets/Tel.webp"
            >
          </div>
          <div class="call-seller-title ion-text-uppercase">
            Tell the seller who's calling
          </div>
          <div class="call-seller-form-container">
            <Form
              ref="saveSearchForm"
              v-slot="{ meta, values }"
              @submit="submitCallSellerForm"
            >
              <ion-col
                size-md="4"
                size="12"
              >
                <Field
                  v-slot="{ field, errors }"
                  name="name"
                  label="NAME"
                >
                  <ion-item
                    lines="none"
                    :class="errors.length !== 0 ? 'error-field' : ''"
                    class="location-input error-call-seller"
                  >
                    <IonLabel
                      class="location-label"
                      position="floating"
                    >
                      Name
                    </IonLabel>
                    <ion-input
                      name="name"
                      v-bind="field"
                      aria-label="Input Name"
                      @keyup="checkIfFill()"
                    />
                  </ion-item>
                </Field>
                <ErrorMessage name="name" />
              </ion-col>
              <ion-col
                size-md="4"
                size="12"
              >
                <Field
                  v-slot="{ field, errors }"
                  rules="phone_number"
                  name="phoneNumber"
                  label="PHONE NUMBER"
                >
                  <ion-item
                    lines="none"
                    :class="errors.length !== 0 ? 'error-field' : ''"
                    class="location-input error-call-seller"
                  >
                    <IonLabel
                      class="location-label"
                      position="floating"
                    >
                      phone number
                    </IonLabel>
                    <ion-input
                      name="phoneNumber"
                      v-bind="field"
                      aria-label="Input Phone Number"
                      @keyup="checkIfFill()"
                    />
                  </ion-item>
                </Field>
                <ErrorMessage name="phoneNumber" />
              </ion-col>
              <ion-col
                size-md="4"
                size="12"
              >
                <Field
                  v-slot="{ field, errors }"
                  rules="email"
                  name="email"
                  label="Email"
                >
                  <ion-item
                    lines="none"
                    :class="errors.length !== 0 ? 'error-field' : ''"
                    class="location-input error-call-seller"
                  >
                    <IonLabel
                      class="location-label"
                      position="floating"
                    >
                      Email
                    </IonLabel>
                    <ion-input
                      name="email"
                      v-bind="field"
                      aria-label="Input Email"
                      @keyup="checkIfFill()"
                    />
                  </ion-item>
                </Field>
                <ErrorMessage name="email" />
              </ion-col>
              <ion-col
                size-md="4"
                size="12"
              >
                <div class="description">
                  You should enter at least one of the following: <b>Phone Number</b> or <b>Email</b>.
                </div>
              </ion-col>
              <ion-col
                v-if="submitLeadErrorMessage.length > 0"
                size-md="4"
                size="12"
              >
                <div class="ion-color-danger">
                  <ion-text color="danger">
                    {{ submitLeadErrorMessage }}
                  </ion-text>
                </div>
              </ion-col>
              <ion-col
                size-md="4"
                size="12"
              >
                <ion-button
                  id="callseller_submit_email_pdp"
                  class="btn btn-submit call-seller"
                  type="submit"
                  :disabled="submitting || disableQRModal || !(meta.valid && meta.dirty) || !(values.phoneNumber || values.email)"
                >
                  <span v-if="submitting">Submitting...</span>
                  <span v-else>Submit</span>
                  <ion-icon :icon="chevronForwardOutline" />
                </ion-button>
              </ion-col>
              <RecaptchaRemark />
            </Form>
          </div>
        </div>
      </div>
    </ion-grid>
  </ion-header>
</template>

<script>
import {
  IonIcon,
  IonGrid,
  IonHeader,
  IonLabel,
  IonCol,
  IonInput,
  IonItem,
  IonButton,
  IonText,
  modalController,
} from "@ionic/vue";
import { Field, ErrorMessage, Form } from "vee-validate";
import { modal, handleImageError } from "@/mixins";
import { contactSeller } from "@/services/ProductsService";
import { closeSharp, chevronForwardOutline } from "ionicons/icons";
import RecaptchaRemark from "@/components/google/RecaptchaRemark.vue";
import { LogRocketService } from "@/services/LogRocketService";
import {
  defaultCallSellerLeadFirstName,
  defaultCallSellerLeadLastName,
  defaultCallSellerEmail,
  generateCallSellerLeadPayload,
} from "@/utils/LeadUtil";
import urlTransformations from "@/mixins/urlTransformations";
import { utagLink } from "@/services/TealiumService";

export default {
  components: {
    RecaptchaRemark,
    IonIcon,
    IonGrid,
    IonHeader,
    IonLabel,
    IonCol,
    IonInput,
    IonItem,
    IonButton,
    IonText,
    Field,
    Form,
    ErrorMessage,
  },
  mixins: [modal, handleImageError, urlTransformations],
  props: {
    dealerId: {
      type: Number,
      required: true,
    },
    dealerLocationId: {
      type: Number,
      required: true,
    },
    inventoryId: {
      type: Number,
      required: true,
    },
    inventoryTitle: {
      type: String,
      required: true,
    },
    dealerLocationPhone: {
      type: String,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
    stock: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      closeSharp,
      chevronForwardOutline,
      disableQRModal: false,
      formData: {},
      submitting: false,
      submitLeadErrorMessage: "",
    };
  },
  mounted() {
    LogRocketService.track(LogRocketService.events.call_seller_form_open);
  },
  methods: {
    async closeModal() {
      await modalController.dismiss();
    },
    async submitCallSellerForm(event) {
      this.submitting = true;
      this.disableQRModal = true;
      this.submitLeadErrorMessage = "";

      if (window.innerWidth < 767) {
        let telNo = this.dealerLocationPhone;

        if (telNo[0] !== "1") {
          telNo = `+1${telNo}`;
        }

        window.open(`tel:${telNo}`, "_self");
      }

      const pdpUrl = this.productionPDPUrl(this.inventoryId, this.inventoryTitle);

      // We'll use traditional Promise here to keep the lead submission logic running
      // in the background, while the modal can keep taking the user forward to reduce
      // friction
      const payload = await generateCallSellerLeadPayload({
        dealer_id: this.dealerId,
        first_name: event.name ?? defaultCallSellerLeadFirstName,
        last_name: defaultCallSellerLeadLastName,
        email_address: event.email ?? defaultCallSellerEmail,
        phone_number: event.phoneNumber,
        dealer_location_id: this.dealerLocationId,
        inventory: {
          inventory_id: this.inventoryId,
        },
        pdp_url: pdpUrl,
        title: this.inventoryTitle,
        stock: this.stock,
        referral: `/${this.productHrefPath(this.inventoryId, this.inventoryTitle)}`,
      });

      let contactSellerOk = false;

      try {
        await contactSeller(payload);
        contactSellerOk = true;

        utagLink(this.$route, {
          "tealium_event": "form_submit",
          "form_id": "call_seller_form",
          "form_submission_email": event.email ?? defaultCallSellerEmail,
          "form_submission_first_name": event.name ?? defaultCallSellerLeadFirstName,
          "form_submission_last_name": defaultCallSellerLeadLastName,
        });
      } catch(error) {
        // We log the error in case something goes wrong
        const errorData = error?.data;
        this.submitLeadErrorMessage = "Something is wrong, please try again.";

        if (typeof errorData !== "undefined") {
          this.submitLeadErrorMessage = `${errorData.message}: `;

          for (const key in errorData.errors ?? {}) {
            this.submitLeadErrorMessage += `${errorData.errors[key][0]}, `;
          }

          // Remove the last extra comma and add full stop to the sentence
          this.submitLeadErrorMessage = this.submitLeadErrorMessage.trim().slice(0, -1);
        }

        LogRocketService.track(LogRocketService.events.call_seller_form_submitted_errors, errorData ?? { error });
        console.log("Error when submitting the lead: ", {
          error,
        });
      }

      this.submitting = false;
      this.disableQRModal = false;

      if (!contactSellerOk) {
        return;
      }

      let telNo = this.dealerLocationPhone;

      if (telNo[0] !== "1") {
        telNo = `+1${telNo}`;
      }

      window.open(`tel:${telNo}`, "_self");

      LogRocketService.track(LogRocketService.events.call_seller_form_submitted_success, {
        ...payload,
        location: this.location,
      });

      await modalController.dismiss();
    },
    checkIfFill() {
      this.formPartiallyFilled = true;
    },
  },
};
</script>

<style scoped>
ion-button {
  margin: 5px -10px 0 !important;
}
.description {
  margin-bottom: 12px;
}
</style>
