// import { isPlatform } from "@ionic/vue";
import {isSSR} from "@/utils/SSRUtil";

// TODO: consider polyfill on server side
export default {
  computed: {
    isDesktop() {
      if(isSSR()) return true;
      return this.$windowWidth >= 1200;
    },
    isResponsive() {
      if(isSSR()) return false;
      return this.isMobile || this.isTablet
    },
    isTablet() {
      if(isSSR()) return false;
      return this.$windowWidth <= 1199 && this.$windowWidth >= 768;
    },
    isMobile() {
      if(isSSR()) return false;
      return this.$windowWidth < 768;
    },
  },
};
