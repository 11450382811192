<template>
  <!-- Start of CabToAxleLengthFilter Accordion Setup -->
  <ion-row
    class="collapsable-menu-custom"
  >
    <ion-col>
      <ion-list>
        <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
          <ion-item
            id="TestA_plp_filter_axles"
            lines="none"
            class="filter-item-head"
            :class="{ active }"
            @click="handleClick"
          >
            <div class="text-icon">
              <div class="ion-text-uppercase parent-text">
                Cab to axle length
              </div>
              <div class="d-flex">
                <span class="ion-text-uppercase selected-search ion-padding-end">
                  {{ selectedListString }}
                </span>
                <span :class="!active ? 'arrow-drop  rotate-caret' : 'arrow-drop'">
                  <img
                    alt="Trailers Near Me"
                    src="@/assets/icons/caretDownBlue.svg"
                  >
                </span>
              </div>
            </div>
          </ion-item>
          <template
            v-for="(item, index) in options"
            :key="index"
          >
            <ion-item
              v-if="active"
              lines="none"
              class="filter-collapse"
              @click="toggleSelected($event, item)"
            >
              <ion-checkbox
                slot="start"
                :ref="'cab_to_axle_length_' + item.value"
                :value="item.value"
                :checked="!!hasFilter('cab_to_axle_length', item.value)"
                class="filter-checkbox"
              />
              <ion-label class="ion-text-uppercase child-text">
                {{ getLabel(item.value) }}
              </ion-label>
            </ion-item>
          </template>
        </div>
      </ion-list>
    </ion-col>
  </ion-row>
</template>

<script>
import { mapState } from "vuex";
import { ellipsizeList } from "@/utils/FilterListUtil";
import { IonCheckbox, IonCol, IonItem, IonList, IonRow, IonLabel } from "@ionic/vue";
import { filterActions } from "@/mixins";
import {scrollPlpToTop} from "@/utils/ProductListingPageUtil";
import CabToAxleLengthItemFilter from "@/models/filters/group/CabToAxleLengthItemFilter";

export default {
  name: "CabToAxleLengthFilter",
  components: {
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonCheckbox,
    IonLabel,
  },
  mixins: [filterActions],
  props: {
    active: Boolean,
  },
  emits: ["toggle"],
  data() {
    return {
      groupName: 'cab_to_axle_length',
      options: [
        {value: '38'},
        {value: '40'},
        {value: '42'},
        {value: '56'},
        {value: '58'},
        {value: '60'},
        {value: '84'},
        {value: '108'},
        {value: '120'},
      ],
    }
  },

  computed: {
    ...mapState({
      searchFilter: (state) => state.searchFilter,
      searchApiFields: (state) => state.searchApiFields,
      aggregations: (state) => state.categoryAggregations,
    }),
    selectedList() {
      return this.searchFilter
          .filter((item) => item.name === "cab_to_axle_length")
          .map((item) => item.label);
    },
    selectedListString() {
      return ellipsizeList(this.selectedList, 14);
    },
  },
  methods: {
    getLabel: CabToAxleLengthItemFilter.defaultLabel,
    handleClick() {
      this.$emit("toggle");
    },
    toggleSelected($event, item) {
      $event.preventDefault();
      const {checked} = this.$refs['cab_to_axle_length_' + item.value][0];
      if (!checked) {
        this.addSearchFilter(new CabToAxleLengthItemFilter({value: item.value}));
      } else if (checked) {
        this.clearSearchFilter({
          name: this.groupName,
          value: item.value,
        });
      }

      scrollPlpToTop();
    },
  },
};
</script>
