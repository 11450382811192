import { isSSR } from "../../utils/SSRUtil";

const state = () => ({
  allManufacturers: {},
  selectedCategoryManufacturers: {},
  activeCategory: "",
  activeType: "",
  tcManufacturers: [], // This list of manufacturers comes from the TC API and not from ES
  tcBrands: [], // This list of brands comes from the TC API and not from ES
  comparisons: !isSSR() ? JSON.parse(window.localStorage.getItem('comparisons') || "[]") : [], // This list of trailers that user wants to compare
});

const mutations = {
  SET_ALL_MANUFACTURERS(state, value) {
    state.allManufacturers = value;
  },
  SET_CATEGORY_MANUFACTURERS(state, value) {
    state.selectedCategoryManufacturers = value;
  },
  SET_CATEGORY(state, value) {
    state.activeCategory = value;
  },
  SET_TYPE(state, value) {
    state.activeType = value;
  },
  SET_TC_MANUFACTURERS(state, value) {
    state.tcManufacturers = value;
  },
  SET_TC_BRANDS(state, value) {
    state.tcBrands = value;
  },
  SET_COMPARISONS(state, value) {
    state.comparisons = value;
    if (isSSR()) return;

    window.localStorage.setItem("comparisons", JSON.stringify(value));
  },
};

const actions = {
  setAllManufacturers({ commit }, payload) {
    commit("SET_ALL_MANUFACTURERS", payload);
  },
  setCategoryManufacturers({ commit }, payload) {
    commit("SET_CATEGORY_MANUFACTURERS", payload);
  },
  setCategory({ commit }, payload) {
    commit("SET_CATEGORY", payload);
  },
  setTcManufacturers({ commit }, payload) {
    commit("SET_TC_MANUFACTURERS", payload);
  },
  setTcBrands({ commit }, payload) {
    commit("SET_TC_BRANDS", payload);
  },
  setComparisons({ commit }, payload) {
    commit("SET_COMPARISONS", payload);
  },
  loadComparisonFromLocalStorage({ commit }) {
    if (isSSR()) return;

    commit("SET_COMPARISONS", JSON.parse(window.localStorage.getItem('comparisons') || "[]"));
  },
};

const getters = {
  getComparisons: (state) => {
    return state.comparisons;
  },
  comparisonsLength: (state) => {
    return state.comparisons.length;
  },
  checkIfExistInComparisons: (state) => (id) => {
    return state.comparisons.some((trailer) => trailer.id === id);
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
};
