<template>
  <ion-app>
    <Tracker />
    <TopBar />
    <Toast />
    <ion-router-outlet />
    <CompareBanner />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent, computed, watch } from "vue";
import { useHead } from "@vueuse/head";
import { TopBar } from "@/components";
import { isSSR } from "@/utils/SSRUtil";
import Toast from "@/components/Toast.vue";
import { getTypes } from "@/services/PartsService";
import { useStore } from "vuex";
import Tracker from "@/components/Tracker.vue";
import CompareBanner from "@/components/compare/CompareBanner.vue";
import {
  extractUsefulParams,
  fetchFilterParams,
  pathFromQueryParams,
  prependParams,
  queryParamsFromPath,
} from "@/utils/UrlParamUtil";
import { useRoute, useRouter } from "vue-router";
import { recaptchaSitekey } from "@/config";
import { LogRocketService } from "@/services/LogRocketService";
import { userProfile } from "@/services/UserService";
import { onLCP, onCLS, onTTFB, onFCP, onINP } from "web-vitals/attribution";
import { AppziService } from "@/services/AppziService";
import { chatbubblesOutline } from 'ionicons/icons';
import { sortOrder } from "./consts/type-dealers";
import { SEO_FRIENDLY_URLS_CATEGORIES } from "./consts/category";
import { FacebookService } from "@/services/FacebookService";

export default defineComponent({
  name: "App",
  components: {
    Tracker,
    IonApp,
    IonRouterOutlet,
    TopBar,
    Toast,
    CompareBanner,
  },
  setup() {
    const store = useStore();
    useHead(computed(() => store.state.seo.head));

    const route = useRoute();
    const router = useRouter();

    watch(
        () => [route.query, route.params],
        () => {
          if (!route.name.startsWith("ProductList")) return;
          let params = fetchFilterParams(route);
          const paramsLength = Object.keys(route.params)?.length;
          const queryLength = Object.keys(route.query)?.length;
          const isDefaultPage = !paramsLength && !queryLength;
          const { userGeoLocation, location, distance } = store.state;
          const { location_city: locationCity, location_region: locationRegion, lat, lon } = route.query;
          if (route.params.path) {
            params["fromQuery"] = {
              ...prependParams(queryParamsFromPath(route.params.path), params),
            };
          } else {
            params["fromQuery"] = {
              ...params,
              ...route.params,
              ...route.query,
              type_id: isDefaultPage ? "all" : route.query.type_id || route.params.type_id,
            };
          }
          if(location) {
            params["fromQuery"].location = location;
            params["fromQuery"].lat = userGeoLocation[0];
            params["fromQuery"].lon = userGeoLocation[1];
            params["fromQuery"].distance = distance;
          } else {
            if (locationCity && locationRegion) {
              params["fromQuery"].location = `${locationCity}, ${locationRegion}`;
              delete params["fromQuery"].location_city;
              delete params["fromQuery"].location_region;
              delete params["fromQuery"].location_type;
            }

            if (lat && lon) {
              params["fromQuery"].lat = parseFloat(lat);
              params["fromQuery"].lon = parseFloat(lon);
            }
          }

          params["fromQuery"] = extractUsefulParams(params["fromQuery"]);
          store.dispatch("loadApiFields", params);
        },
        { immediate: true },
    );

    watch(
      () => store.state.searchApiFields,
      (newVal) => {
        if (!route.name.startsWith("ProductList")) return;
        store.dispatch("setProductListPageHead");
        const { distance, location, userGeoLocation } = store.state;

        const newParams = {
          ...newVal,
          location: newVal.location || location,
          distance,
        };

        if (userGeoLocation && userGeoLocation.length > 0) {
          newParams.lat = userGeoLocation[0];
          newParams.lon = userGeoLocation[1];
        }
        delete newParams.location_type;
        delete newParams.location_city;
        delete newParams.location_region;
        let formattedPath = pathFromQueryParams(newParams);

      /**
       * Here we are checking if the page is in the list of SEO friendly pages or not. There are multiple considerations.
       * 1. If the page is SEO friendly, and a change in category happens, no need to change the URL.
       * 2. If the page is not SEO friendly, and a change in category happen, follow the usual behavior.
       */
        let isSeoFriendlyPage = false;
        let needsUrlChange = true;

        const currentPage = window.location.pathname;
        const currentCategoryPage = currentPage.split('/')[2] ?? "";
        isSeoFriendlyPage = SEO_FRIENDLY_URLS_CATEGORIES.some(
          (category) => currentCategoryPage.includes(category),
        );
        needsUrlChange = !isSeoFriendlyPage;

        if (!formattedPath) {
          router.replace({
            name: route.name,
            query: newParams,
          });
        } else if (!isSSR() && !isSeoFriendlyPage && needsUrlChange) {
          formattedPath = `/trailers-for-sale/${formattedPath}`;
          history.replaceState(
            {
              ...history.state,
              current: formattedPath,
            },
            "",
            `${window.location.origin}${formattedPath}`,
          );
        }
      },
    );

    return {
      chatbubblesOutline,
    };
  },
  async serverPrefetch() {
    try {
      let megaMenuList = await getTypes();
      megaMenuList.forEach(type => type.categories.sort((a, b) => sortOrder[type.id].findIndex(v => v === a.name) - sortOrder[type.id].findIndex(v => v === b.name)));
      await this.$store.commit("UPDATE_MEGAMENULIST", megaMenuList);
    } catch (err) {
      console.log(err);
    }
  },
  async mounted() {
    await this.$store.dispatch("loadConfig");
    await this.$store.dispatch("loadLocalStorage");
    await this.$store.dispatch("getAuthToken");
    await this.$store.dispatch("loadComparisonFromLocalStorage");

    LogRocketService.init();
    FacebookService.init();

    const { authToken } = this.$store.state;

    if (authToken !== null) {
      const { data } = await userProfile(authToken);
      this.$store.commit("SET_USER_DATA", data?.data || {});
      LogRocketService.identify(data?.data?.id, data?.data);
    }

    LogRocketService.track(LogRocketService.events.initial_site_load);

    if (this.$store.state?.authToken !== null && this.$store.state?.userData?.email_verified_at !== null) {
      this.$store.commit("SET_IS_AUTHENTICATED", true);
    }

    if (!isSSR()) {
      const printWebVitalsLog = localStorage.getItem("tt_print_web_vitals_log") === "1";

      if (printWebVitalsLog) {
        // @see https://operatebeyond.atlassian.net/browse/TR-653
        onLCP(console.log);
        onCLS(console.log);
        onFCP(console.log);
        onINP(console.log);
        onTTFB(console.log);
      }

      AppziService.init();
    }

    await this.handleAuthCallback();
  },
  methods: {
    async handleAuthCallback() {
      if (this.$route.query.token !== undefined && this.$route.name === 'Home') {
        this.$store.commit("SET_IS_AUTHENTICATED", true);
        this.$store.commit("SET_AUTH_TOKEN", this.$route.query.token);
        const { data } = await userProfile(this.$route.query.token);
        this.$store.commit("SET_USER_DATA", data?.data || {});

        // Remove the token from the param
        const query = { ...this.$route.query };
        delete query.token;
        this.$router.replace({ path: '/dashboard', query });
      }
    },
  },
});

if (!isSSR()) {
  // Insert Google recaptcha script
  let googleRecaptchaScript = document.createElement("script");
  googleRecaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaSitekey}`;
  googleRecaptchaScript.defer = true;
  googleRecaptchaScript.async = true;
  document.body.appendChild(googleRecaptchaScript);
}
</script>
