<template>
  <ion-button
    :id="id"
    :disabled="disabled || processing"
    @click="handleClick"
  >
    <slot />
  </ion-button>
</template>

<script>
import { modal } from "@/mixins";
import { IonButton } from "@ionic/vue";
import urlTransformations from "@/mixins/urlTransformations";
import CallSellerForm from "../productdetails/CallSellerForm.vue";

export default {
  components: {
    IonButton,
  },
  mixins: [modal, urlTransformations],
  props: {
    id: {
      required: false,
      default() {
        // Random ID from 0 to 100
        const randomId = Math.floor(Math.random() * 100);

        return `call-seller-${randomId}`;
      },
    },
    disabled: {
      default: false,
      required: false,
    },
    dealerId: {
      required: true,
    },
    dealerLocationId: {
      required: true,
    },
    inventoryId: {
      required: true,
    },
    inventoryTitle: {
      required: true,
    },
    dealerLocationPhone: {
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
    isDealerPrivate: {
      type: Boolean,
      required: true,
    },
    stock: {
      type:  String,
      required: true,
    },
  },
  data() {
    return {
      processing: false,
    };
  },
  methods: {
    async handleClick() {
      this.openModal(CallSellerForm, {
        cssClass: "modal-header-call-seller",
        componentProps: {
          dealerId: Number(this.dealerId),
          dealerLocationId: Number(this.dealerLocationId),
          dealerLocationPhone: this.dealerLocationPhone,
          inventoryId: Number(this.inventoryId),
          inventoryTitle: this.inventoryTitle,
          location: this.location,
          stock: this.stock,
        },
      });
    },
  },
};
</script>
