<template>
  <ion-header class="ion-no-border">
    <ion-grid class="modal-header-contact-seller">
      <div class="container-form">
        <div class="contact-seller-header">
          <span class="contact-header ion-text-uppercase">Contact Seller</span>
          <span class="close-contact-seller-form">
            <ion-icon
              :icon="closeSharp"
              aria-label="close dialog"
              @click="closeModal('contactSellerForm')"
            />
          </span>
        </div>
        <div class="form-detail-container">
          <ion-row class="limit-height">
            <!--Left side info-->
            <ion-col size-md="6">
              <div class="left-top-bottom-container">
                <div class="left-top-container">
                  <div class="image">
                    <!--  -->
                    <ion-img
                      :src="modalData?.primary_image?.url ? modalData?.primary_image?.url : defaultLogo"
                      :alt="modalData?.primary_image?.url || 'No Image'"
                      @ionError="onImageLoadError"
                    />
                  </div>
                  <span class="title">{{ modalData?.inventory_title }}</span>
                  <div class="price-deal">
                    <span class="listed-price">Listed Price</span>
                    <div class="price-deal">
                      <ion-text
                        v-if="showSalesPrice"
                        class="original-price-mobile call-seller-form"
                      >
                        {{ formatPrice(modalData.price, modalData?.currency) }}
                      </ion-text>
                      <span
                        v-if="priceFormatter !== 0"
                        class="price"
                      >{{ priceFormatter }}</span>
                      <span
                        v-else
                        class="product-contact-price"
                      > CONTACT SELLER FOR <b>PRICE</b> </span>
                      <span
                        v-if="showSalesPrice"
                        class="ion-text-uppercase deal"
                      >sale</span>
                    </div>
                  </div>
                </div>
                <div class="left-bottom-container">
                  <div class="address-container ion-margin-top">
                    <span class="ion-text-uppercase address-header">Address</span>
                    <span
                      class="address"
                    >
                      {{ modalData?.dealer_location?.city }}, {{ modalData?.dealer_location?.region }}, {{ modalData?.dealer_location?.postalcode }}
                    </span>
                  </div>
                  <!-- It will be uncommented in the future when we have this feature -->
                  <!-- <div class="business-hours-container">
                  <span class="ion-text-uppercase business-hours-header"
                    >business hours</span
                  >
                  <span class="timing"
                    >Monday - 9:00 a.m. - 8:00 p.m.
                    <span>
                      <img
                        class="img-caret"
                        alt="caretDown"
                        src="@/assets/icons/caretBlue.svg"
                      /> </span
                  ></span>
                </div> -->
                  <!-- It will be uncommented in the future when we have this feature -->
                </div>
              </div>
            </ion-col>
            
            <!--Right side form-->
            <ion-col
              size-md="6"
              class="email-seller-form"
            >
              <EmailSellerForm
                :location="location"
                :dealer-phone-number="modalData?.dealer_location?.phone ?? ''"
                :dealer-location-id="modalData?.dealer_location?.id ?? 0"
                :website-id="modalData?.dealer?.website?.id ?? ttWebsiteId"
                :dealer-id="modalData?.dealer?.id ?? 0"
                :inventory-id="modalData?.id ?? 0"
                :inventory-title="modalData?.inventory_title ?? modalData?.title ?? ''"
                :message="message"
                :price="installmentData?.price ?? '0'"
                :down-payment="installmentData?.downPayment ?? 0"
                :frequency="installmentData?.frequency ?? 'monthly'"
                :finance-term="installmentData?.financeTerm ?? '4'"
                :stock="modalData?.stock ?? ''"
              />
            </ion-col>
          </ion-row>
        </div>
      </div>
    </ion-grid>
  </ion-header>
</template>

<script>
import {
  IonIcon,
  IonGrid,
  IonHeader,
  IonCol,
  IonRow,
  IonImg,
  IonText,
} from "@ionic/vue";
import { closeSharp, chevronForwardOutline } from "ionicons/icons";
import { modal, handleImageError } from "../mixins";
import defaultLogo from "@/assets/defaultLogo.webp";
import { LogRocketService } from "@/services/LogRocketService";
import EmailSellerForm from "@/components/productdetails/EmailSellerForm.vue";
import { TRAILERTRADER_WEBSITE_ID } from "@/utils/TrailerTraderUtil";
import { formatPrice } from "@/utils/CurrencyUtil";

export default {
  name: "ContactSellerForm",
  components: {
    EmailSellerForm,
    IonGrid,
    IonHeader,
    IonIcon,
    IonCol,
    IonRow,
    IonImg,
    IonText,
  },
  mixins: [modal, handleImageError],
  props: {
    installmentData: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
    modalData: {
      type: Object,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      closeSharp,
      chevronForwardOutline,
      defaultLogo,
      contactingSeller: false,
      ttWebsiteId: TRAILERTRADER_WEBSITE_ID,
    };
  },
  computed: {
    message(){
      return this.location === 'PDP_INSTALLMENT' ? `Hi, I’m interested in buying your trailer with installments. Could you please contact me with a credit proposal ?` : `Hi, I'm interested in your trailer, could you please contact me?`;
    },
    priceFormatter() {
      return formatPrice(this.showPrice, this.modalData?.currency);
    },
    showPrice() {
      let price = "";
      if (parseInt(this.modalData?.website_price) === 0) {
        price = this.modalData?.price;
      } else {
        price = this.modalData?.website_price;
      }
      if (parseInt(this.modalData?.sales_price) < parseInt(price) && parseInt(this.modalData?.sales_price) !== 0) {
        price = this.modalData?.sales_price;
      } else {
        price = this.modalData?.price;
      }
      return price;
    },
    showSalesPrice() {
      if (parseFloat(this.modalData?.sales_price) !== 0) {
        return (
          parseFloat(this.modalData?.sales_price) < parseFloat(this.modalData?.website_price) ||
          parseFloat(this.modalData?.sales_price) < parseFloat(this.modalData?.price)
        );
      }
      return false;
    },
  },
  mounted() {
    LogRocketService.track(LogRocketService.events.contact_form_open);
  },
  methods: {
    formatPrice,
  },
};
</script>

<style scoped>
.email-seller-form {
  margin-top: 10px;
}
</style>
