<template>
  <div 
    v-show="notifications.open"
    :class="notifications.type" 
  >
    <span class="notification-text">{{ notifications.text }}</span>
    <span
      class="close-toast"
      @click="$store.dispatch('removeToastNotification')"
    >
      <ion-icon 
        :icon="closeSharp"
        aria-label="close toast"  
      />
    </span>
  </div>
</template>
<script>
import { closeSharp } from "ionicons/icons";
import { IonIcon } from "@ionic/vue";
export default {
  name: "Toast",
  components: {
    IonIcon,
  },
  data() {
    return {
      closeSharp,
    };
  },
  computed: {
    notifications() {
      return this.$store.state.notifications.toastMessage;
    },
  },
};
</script>
