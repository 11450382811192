<template>
  <div style="width: 100%; position: relative;">
    <div class="custom-skeleton-loader" />
  </div>
</template>

<script>
export default {
  name: "SkeletonLoader",
}
</script>
