<template>
  <ion-grid
    v-if="!fetchingProducts"
    class="product-list-content-block"
  >
    <ion-row>
      <ion-col
        class="no-padding"
      >
        <h2>{{ content?.headline || '' }}</h2>
        <template v-if="!showMore">
          <div v-html="content?.top_section || ''" />
        </template>
        <template v-if="showMore">
          <div v-html="content?.content || ''" />
        </template>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col
        class="no-padding"
        :class="{
          'floating-buttons buttonOffset': showMore && isVisible,
        }"
      >
        <p
          class="show-button"
          @click="showAction"
        >
          {{ showMore ? 'Show less' : 'Show more' }}
          <img
            alt="caret"
            :class="{
              'rotate': showMore,
            }"
            src="@/assets/icons/arrowDownBlue.svg"
          >
        </p>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>
<script>
import { IonGrid, IonCol, IonRow } from "@ionic/vue";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "ProductListContentBlock",
  components: {
    IonGrid,
    IonCol,
    IonRow,
},
  props: ["isVisible", "contentBlocks"],
  setup() {
    const store = useStore();

    const fetchingProducts = computed(() => store.state.fetchingProducts);
    const category = computed(() => store.state.searchApiFields?.category);
    const totalProducts = computed(() => store.state.totalProducts);

    return {
      fetchingProducts,
      category,
      totalProducts,
    };
  },
  data() {
    return {
      showMore: false,
      content: null,
    };
  },
  mounted() {
    this.content = this.contentBlocks.find(cb => this.category.includes(cb.category));
  },
  methods: {
    showAction(ev) {
      ev.preventDefault();
      if(this.showMore) {
        if(this.totalProducts > 0) {
          const el = document.querySelector('div[style*="z-index: 101"] .progress-bar-pagination') ||
          document.querySelector('div[style*="z-index: 99"] .progress-bar-pagination') || 
          document.querySelector('.progress-bar-pagination') ||
          document.querySelector('div[style*="z-index: 101"] .product-list-content-block') ||
          document.querySelector('div[style*="z-index: 99"] .product-list-content-block') || 
          document.querySelector('.product-list-content-block');
          el?.scrollIntoView();
        } else {
          const desktopContent = "div[style*='z-index: 101'] #productlist-header-desktop";
          const mobileContent = "div[style*='z-index: 101'] #productlist-header-mobile";
          document.querySelector(desktopContent)?.scrollIntoView();
          document.querySelector(mobileContent)?.scrollIntoView();
        }
      }
      this.showMore = !this.showMore;
    },
  },
};
</script>
