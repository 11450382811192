const state = () => ({
  //dealers
  dealerId: "",
  productDetails: {},
  geoCoordinates: {}
});

const mutations = {
  SET_DEALER_ID(state, value) {
    state.dealerId = value;
  },
  SET_PRODUCT_DETAIL(state, value){
    state.productDetails = value
  },
  SET_GEO_CODE(state, value){
    state.geoCoordinates = value
  }
};

const actions = {
  setDealerId({ commit }, payload) {
    commit("SET_DEALER_ID", payload);
  },
  setProductDetail({ commit }, payload){
    commit("SET_PRODUCT_DETAIL", payload)
  },
  setGeoCode({ commit }, payload){
    commit("SET_GEO_CODE", payload)
  }
};

export default {
  state,
  mutations,
  actions,
};
