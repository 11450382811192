import { isSSR } from "@/utils/SSRUtil";

const staticUtagData = {
    ga4_id: 19710795,
    site_id: 284,
    site_company_name: "trailer trader",
    site_platform: "Trailer Trader",
    site_internal_id: 'trailertrader',
}

const getUtag = () => {
    return isSSR() ? undefined : window.utag;
}

const utagView = (route, utagData = {}) => {
    const utag = getUtag();

    if (!utag || route.href.includes("token=")) {
        return;
    }

    if (route.name === "ProductDetails" && utagData.tealium_event !== 'product_detail_view') {
        // ProductDetails can call with more data. Generic call from router.afterEach can be ignored.
        return;
    }

    let page_title = route.meta.title ? route.meta.title : document.title;

    utagData = {
        tealium_event: "standard_view",
        page_title: page_title,
        page_title_count: page_title ? page_title.length : 0,
        site_section: route.name,
        ...staticUtagData,
        ...utagData,
    };

    // Track view event.
    utag.view(utagData);
};

const utagLink = (route, utagData = {}) => {
    const utag = getUtag();

    if (!utag) {
        return;
    }

    let page_title = route.meta.title ? route.meta.title : document.title;

    utagData = {
        page_title: page_title,
        page_title_count: page_title ? page_title.length : 0,
        ...staticUtagData,
        ...utagData,
    }

    if (utagData.tealium_event === 'form_submit') {
        utagData.form_type = 'trailertrader_form';
    }

    // Track link event.
    utag.link(utagData);
};

export { utagView, utagLink };