<template>
  <ion-grid class="modal-header-success-submission">
    <div class="forgot-password-modal">
      <div class="d-flex forgot-password-header">
        <span
          class="forgot-password-title ion-text-uppercase"
        >if your email exists in our system check your inbox, an email was
          sent to you for password recovery</span>
      </div>
      <div class="forgot-password-info">
        If your email address is registered with TrailerTrader, you will shortly
        receive an email with the instructions to recover your password.
      </div>
      <ion-col
        size-md="4"
        size="12"
      >
        <ion-button
          class="btn btn-submit custom-button"
          @click="closeModal"
        >
          cancel
        </ion-button>
      </ion-col>
    </div>
  </ion-grid>
</template>

<script>
import { IonGrid, IonCol, IonButton, modalController } from "@ionic/vue";
export default {
  components: {
    IonGrid,
    IonCol,
    IonButton,
  },
  methods: {
    async closeModal() {
      await modalController.dismiss();
    },
  },
};
</script>
