export const DISTANCE_LIST = [
    "50mi",
    "100mi",
    "150mi",
    "200mi",
    "300mi",
    "400mi",
    "500mi",
    "9999mi",
];
export const NATIONWIDE_DISTANCE = "9999mi";
export const NATIONWIDE = "Nationwide";