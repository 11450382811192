import createApp from './main'

const { app, router, store } = createApp();

(async (r, a, s) => {
  const storeInitialState = window.INITIAL_DATA;

  await r.isReady();
  r.beforeResolve((to, from, next) => {
    const matched = to.matched.flatMap(record =>
      Object.values(record.components)
    );
    const prevMatched = from.matched.flatMap(record =>
      Object.values(record.components)
    );
    let diffed = false
    const activated = matched.filter((c, i) => {
      return diffed || (diffed = (prevMatched[i] !== c))
    })
    if (!activated.length) {
      return next()
    }

    Promise.all(activated.map(c => {
      if (c.asyncData) {
        return c.asyncData({ store, route: to })
      }
    })).then(() => {
      next()
    }).catch(next)
  });

  if (storeInitialState) {
    s.replaceState(storeInitialState);
  }

  a.mount('#app', true);
})(router, app, store);