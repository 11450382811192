import { isSSR } from "../utils/SSRUtil";

export const FacebookService = {
  appId: import.meta.env.VITE_APP_FACEBOOK_APP_ID,
  init() {
    if (isSSR()) {
      return;
    }

    if (!this.appId) {
      this.log(`Facebook SDK is being disabled in .env file, we'll not start Facebook SDK`);
      return;
    }

    window.fbAsyncInit = function () {
      window?.FB.init({
          appId: this.appId,
          xfbml : true,
          version : 'v19.0',
      });
    };

      // load facebook sdk script
      (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) { return; }
          js = d.createElement(s); js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));  

    this.log(`Facebook SDK is initialized with app id ${this.appId}`);
  },

  log(message, meta) {
    if (meta !== undefined) {
      console.log(`Facebook: ${message}`, meta);
    } else {
      console.log(`Facebook: ${message}`);
    }
  },

  checkIfPostShared() {
    window?.FB.getLoginStatus(response => {
        if (response.status === 'connected') {
          window?.FB.api('/me/feed', { fields: 'id,message,created_time' }, feedResponse => {
            if (feedResponse && !feedResponse.error) {
                console.log('User feed:', feedResponse);
            }
          });
        } else {
            console.log('User is not authenticated');
        }
    });
},

  share: (url, title, city, state, primary_image) => {
    window?.FB.ui({
      method: 'feed',
      link: url,
      display       : 'iframe',
      name          : 'Trailer for sale.',
      picture       : primary_image?.original_url,
      caption       : 'Trailer Trader ',
      description   : `Check out my ${title} for sale in ${city}, ${state}`,
      access_token  : 'user access token',
    },response => {
        if (response && !response.error_message) {
            console.log('Post was shared');
            this.checkIfPostShared();

        } else {
            console.log('Post was not shared');
        }
    },
  );
  },
};
