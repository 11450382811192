<template>
  <div class="bricksbar ion-text-uppercase" v-if="showBanner">
    <template v-if="showLocationMessage">
      <div class="location-msg">{{ locationMessage }}</div>
    </template>
    <template v-else>
      <span>The list of results now sort results within range when you have set a location.</span>
      <a href="/trailers-for-sale?type_id=3&category=Camper+/+RV "
        >See how this works for Recreational Vehicles.
        <!-- <svg
          width="10"
          height="12"
          viewBox="0 0 6 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 7L4 4L1 1" stroke-width="2" />
        </svg> -->
      </a>
    </template>
    <ion-button
      @click="closeAnnouncementBar"
      color="transparent"
      class="close-btn ion-no-padding"
      ><ion-icon :icon="closeSharp"></ion-icon
    ></ion-button>
  </div>
</template>

<script>
import { IonIcon, IonButton } from "@ionic/vue";
import { closeSharp } from "ionicons/icons";
import { mapState } from "vuex";
export default {
  components: {
    IonIcon,
    IonButton,
  },
  data() {
    return {
      showBanner: null,
      closeSharp,
    };
  },
  computed: {
    ...mapState({
      showLocationMessage: (state) => state.showLocationMessage,
      locationMessage: (state) => state.locationMessage,
    }),
  },
  mounted() {
    this.showBanner = window.sessionStorage.getItem("showBanner")
      ? JSON.parse(window.sessionStorage.getItem("showBanner"))
      : true;
    window.sessionStorage.setItem("showBanner", this.showBanner);
  },
  methods: {
    closeAnnouncementBar() {
      this.showBanner = !this.showBanner;
      window.sessionStorage.setItem("showBanner", this.showBanner);
    },
  },
};
</script>