<template>
  <ion-row class="collapsable-menu-custom">
    <ion-col>
      <ion-list>
        <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
          <ion-item
            id="TestA_plp_filter_manufacturer"
            lines="none"
            class="filter-item-head"
            :class="{ active }"
            @click="handleClick"
          >
            <div class="text-icon">
              <div class="ion-text-uppercase parent-text">
                Manufacturer
              </div>
              <div class="d-flex">
                <span class="ion-text-uppercase selected-search ion-padding-end">{{ showManufacturer }}</span>
                <span :class="!active ? 'arrow-drop  rotate-caret' : 'arrow-drop'">
                  <img
                    alt="Trailers Near Me"
                    src="@/assets/icons/caretDownBlue.svg"
                  >
                </span>
              </div>
            </div>
          </ion-item>
          <!-- <div> -->
          <div
            v-if="active && manufacturers.length > moreLimit"
            class="filter-attribute"
          >
            <input
              v-model="searchManufacturer"
              type="text"
              class="form-textbox"
              placeholder="Search Manufacturer..."
              aria-label="Input manufacturer search keyword"
            >
          </div>
          <ion-content
            v-if="active"
            :class="manufacturerItems.length === 1 ? 'selected infinite-content' : 'infinite-content'"
          >
            <ion-list>
              <ion-item
                v-for="(item, index) in manufacturerItems"
                :key="index"
                :href="item.href"
                lines="none"
                class="filter-collapse-see-more"
                @click="toggleFilter($event, 'manufacturer', index)"
              >
                <ion-checkbox
                  slot="start"
                  ref="manufacturer"
                  :value="item.name"
                  :checked="!!hasFilter('manufacturer', item.name)"
                  class="filter-checkbox"
                />
                <ion-label class="ion-text-uppercase child-text">
                  {{ item.name }}
                </ion-label>
              </ion-item>
            </ion-list>
            <ion-infinite-scroll
              v-if="active && filteredManufacturers.length > moreLimit"
              @ionInfinite="loadData"
            >
              <ion-infinite-scroll-content
                loading-text="Please wait..."
                loading-spinner="bubbles"
              />
            </ion-infinite-scroll>
          </ion-content>

          <div
            v-if="active && filteredManufacturers.length === 0"
            class="see-more-less"
          >
            <ion-label class="see-more ion-margin-top">
              No result.
            </ion-label>
          </div>
        </div>
      </ion-list>
    </ion-col>
  </ion-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ellipsizeList } from "@/utils/FilterListUtil";
import { IonCheckbox, IonCol, IonItem, IonList, IonRow, IonLabel, IonInfiniteScroll, IonInfiniteScrollContent, IonContent } from "@ionic/vue";
import { filterActions } from "@/mixins";

export default {
  name: "YearFilter",
  components: {
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonCheckbox,
    IonLabel,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
  mixins: [filterActions],
  props: {
    active: Boolean,
  },
  emits: ["toggle"],
  data() {
    return {
      moreLimit: 20,
      seeMore: false,
      seeMoreManufacturer: false,
      searchManufacturer: "",
      manufacturerItems: [],
      index: 0,
    };
  },
  computed: {
    ...mapState({
      searchFilter: (state) => state.searchFilter,
      searchApiFields: (state) => state.searchApiFields,
      categoryAggregations: (state) => state.categoryAggregations,
      manufacturerList: (state) =>
        state.searchFilter
          .filter((item) => item.name === "manufacturer")
          .map((item) => item.value),
    }),
    showManufacturer() {
      return ellipsizeList(this.manufacturerList, 14);
    },
    manufacturers() {
      const selectedList = this.searchFilter
        .filter((item) => item.name === "manufacturer")
        .map((item) => item.value);

      let list = (this.categoryAggregations.filter_aggregations?.manufacturer?.buckets || []).map((bucket) => bucket.key);

      /** Add non-existing items from selected list to manufacturer list **/
      for (const item of selectedList) {
        if (!list.includes(item)) {
          list.push(item);
        }
      }
      return list.sort();
    },
    seeMoreManufacturerCount() {
      if (this.searchManufacturer.length === 0) {
        return this.manufacturers.length - this.moreLimit;
      }

      return this.filteredManufacturers.length - this.moreLimit;
    },
    filteredManufacturers() {
      return this.manufacturers
        .filter((post) => post.toLowerCase().includes(this.searchManufacturer.toLowerCase()))
        .map((manufacturer) => {
          const updatedPath = this.generateHrefFromField(manufacturer, "manufacturer");
          return { name: manufacturer, href: updatedPath };
        });
    },
  },
  watch: {
    "$store.state.categoryAggregations": function () {
      this.fillManufacturers();
    },
    searchManufacturer: function() {
      this.index = 0;
      this.manufacturerItems = this.filteredManufacturers.slice(0, 20);
    },
  },
  mounted() {
    this.fillManufacturers();
  },
  methods: {
    ...mapActions(["addSearchFilter", "clearSearchFilter"]),
    fillManufacturers() {
      if (this.shownGroup === null && this.manufacturerList.length === 0) {
        this.seeMoreManufacturer = false;
      }
      this.seeMoreManufacturer = this.manufacturerList.some(
        (manufacturer) => this.manufacturers.findIndex((e) => e === manufacturer) >= this.moreLimit,
      );
      this.index = 0;
      this.manufacturerItems = this.filteredManufacturers.slice(0, 20);
    },
    toggleManufacturers(value) {
      this.seeMoreManufacturer = value;
    },
    handleClick() {
      if (this.manufacturerList.length === 0) {
        this.seeMoreManufacturer = false;
      }
      this.$emit("toggle");
      this.index = 0;
      this.manufacturerItems = this.filteredManufacturers.slice(0, 20);
    },
    loadData(ev) {
      this.index += this.moreLimit;
      setTimeout(() => {
        for(let i = this.index; i < this.index + this.moreLimit; i++) {
          const manufacturer = this.filteredManufacturers[i];
          if(manufacturer) this.manufacturerItems.push(manufacturer);
        }
        ev.target.complete();
      }, 500);
    },
  },
};
</script>
