import GroupItemFilter from "@/models/filters/group/GroupItemFilter";

export default class SkirtedBedItemFilter extends GroupItemFilter {
    constructor({value}) {
        super({
            name: SkirtedBedItemFilter.NAME,
            label: SkirtedBedItemFilter.defaultLabel(value),
            value: value,
        });
    }
}

SkirtedBedItemFilter.defaultLabel = function (value) {
    if(value === '0') {
        return 'No';
    } else {
        return 'Yes';
    }
}
SkirtedBedItemFilter.fromApiField = GroupItemFilter.fromApiField;
SkirtedBedItemFilter.NAME = 'skirted_bed';