import { pathFromQueryParams } from "@/utils/UrlParamUtil";
import GroupItemFilter from "@/models/filters/group/GroupItemFilter";
import AxlesItemFilter from "@/models/filters/group/AxlesItemFilter";
import {mapActions, mapGetters} from "vuex";
import { scrollPlpToTop } from "@/utils/ProductListingPageUtil";
import SkirtedBedItemFilter from "@/models/filters/group/SkirtedBedItemFilter";

export default {
  computed: {
    ...mapGetters(['hasFilter']),
  },
  methods: {
    ...mapActions(["addSearchFilter", "clearSearchFilter"]),
    /**
     * Constructs a new path to be displayed using the filter value and its respective key name
     * which should be a valid key from the "searchApiFields" store property.
     * @param {String} value
     * @param {String} key
     * @returns {String}
     */
    generateHrefFromField(value, key) {
      let updatedField = value;
      if (value !== "") {
        if (this.searchApiFields?.[key]?.includes(value)) {
          updatedField = this.searchApiFields?.[key];
        } else if (this.searchApiFields?.[key]) {
          updatedField = [this.searchApiFields?.[key], value].join(";");
        }
        const updatedPath = pathFromQueryParams({
          ...this.searchApiFields,
          [key]: updatedField,
        });
        return `${this.domainOrigin}/trailers-for-sale/${updatedPath}`;
      }
    },
    toggleFilter($event, key, index) {
      $event.preventDefault();
      const { checked, value } = this.$refs[key][index];
      const filterExist = this.hasFilter(key, value);
      if (!checked && !filterExist) {
        const filterClasses = {
          'axles': AxlesItemFilter,
          'skirted_bed': SkirtedBedItemFilter,
        }
        const filterFields = {
          name: key,
          value,
        };
        if (filterClasses[key]) {
          this.addSearchFilter(
            new filterClasses[key]({
              value,
            }),
          );
        } else {
          this.addSearchFilter(new GroupItemFilter(filterFields));
        }
      } else if (checked && filterExist) {
        this.clearSearchFilter({
          name: key,
          value,
        });
      }

      scrollPlpToTop();
    },
    isFieldChecked(key, value) {
      return this.$refs[`${key}_${value}`]?.[0]?.checked;
    },
  },
};
