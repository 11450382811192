export const PRODUCT_LISTING_SEO = {
    first_part: "TrailerTrader, America's First Trailer and RV marketplace with the largest selection of",
    last_part: "for sale. TrailerTrader the marketplace, market, platform, online sales, classifieds for trailers and recreational vehicles near me.",
    category_specific_part: {
        "Cargo (Enclosed) Trailers": "enclosed trailers for sale, cargo trailers for sale, enclosed trailer, box trailers, used enclosed trailers, used cargo trailers, utilities trailers",
        "Equipment / Flatbed Trailers": "equipment trailers for sale, used equipment trailer, flatbed trailers, used equipment trailers, trailers with ramps",
        "Car Haulers / Racing Trailers": "car trailers, car haulers, car on trailers, trailers with car, car trailer for car, car carrier trailer, race trailers, race car trailers, old car trailer, used car trailer, enclosed car trailer, closed car trailer, used car hauler, used car trailer",
        "Tow Dollys": "tow dolly, vehical dolly, car dolly, dolly trailer car, dolly for RV, dolly for recreational vehicle, towing car, towing vehicle, car on dolly, auto car dolly, trailer dolly for cars",
        "Tilt Trailers": "tilt trailer, tilting trailer, trailer with tilt, tiltable trailer, magictilt, tilt deck trailer, tilt bed trailer, trailer with tilt bed, auto tilt trailer",
        "Motorcycle / Cycle Trailers": "motorcycle trailer, cycle trailer, motorbike trailer, motorcycle on trailer, bicycle trailer, bike trailer, bike on trailer",
        "ATV Trailers": "atv trailer, utv trailer, off-road vehicle trailer, 4 wheeler trailer, canam spyder trailer, side by side atv trailer, four wheeler trailer, canam trailer, rockymtnatv trailer",
        "Watercraft Trailers": "boat trailer, yacht trailer, watercraft trailer, catamaran trailer, sailboat trailer, fishing boat trailer, jetski trailer, jet ski trailer, personal watercraft trailer, seadoo trailer, waverunner trailer, zodiac trailer",
        "Snowmobile Trailers": "snowmobile trailer, ski doo trailer, skidoo trailer, sled trailer, yamaha sled trailer, arctic cat trailer, lynx trailer",
        "Utility Trailers": "utility trailer, small utility trailer, used utility trailer, lawn mover trailer, gardening trailer, tool box trailer, carryon trailer, lawn equipment trailer",
        "Dump Trailers": "dump trailer, dumpster trailer, used dump trailer, gooseneck dump trailer, rolloff trailer, small dump trailer",
        "Vending / Concession Trailers": "vending trailer, concession trailer, food trailer, used food trailer, buy food trailer, food truck trailer, concession stand trailer, small food trailer",
        "Office / Fiber Optic Trailers": "office trailer, used office trailer, fiber optic trailer, used fiber optic trailer, trailer with office, office on trailer, mobile office trailer, construction trailer, portable office trailer, construction site trailer, construction office trailer, job site trailer",
        "Other Trailers": "refrigerated trailer, cooling trailer, pipe trailer, pipe hauling trailer, tank trailer, trash trailer, inline hay trailer, welding trailer, bathroom trailer, bbq trailer, diesel tank trailer, petrol tank trailer, gravity feed trailer, real estate auctioneer trailer, restroom trailer, fuel tank trailer",
        "Horse Trailers": "horse trailer, used horse trailer, horse and trailer for sale, equine trailer, horse tr, horse trailering, horse trailers world, horse trailer with living quarter, living quarter horse trailer, sundown trailer, 4 horse trailer, four horse trailer, pre owned horse trailer, lq horse trailer",
        "Stock / Stock Combo Trailers": "livestock trailer, stock trailer, stock combo trailer, small livestock trailer, stock trailers for sale, cattle trailer",
        "Travel Trailers": "travel trailer, pop up trailer, pop up camper trailer, campers near me, campers for sale, pop out trailer, pop out travel trailer, travel trailers for sale",
        "Fifth Wheel Trailers": "5th wheel camper, 5th wheel motorhome, fifth wheel, 5th wheel, fifth wheel, fifth wheel trailer, used fifth wheel, fifth wheel for sale, fifth wheel rv for sale",
        "Toy Haulers": "toy haulers, toy hauler camper, toy hauler rv, toy hauler for sale, toy hauler travel trailer, shortest to hauler, used toy hauler, pre owned toy hauler",
        "Camper / RV": "rv for sale, recreational vehicle, rv camper, motorhome trader, camper trader, camping world, rv and campers for sale, rv, rec vehicles, rv motorhome, camper, camper for sale, rv near me, class a motorhome, c rv, class c rv, class c camper, class c motorhome, class b rv, class b, class a",
        "Truck Beds Category": "truck beds, truck beds for sale, utility truck bed, truck bed rack, sliding truck bed, flatbed truck beds, norstar truck beds, tonneau cover, truck bed covers, truck tool box, truck bed tool box, truck box covers, pickup box, pickup toppers, truck bed liner, c and m truck beds, extended truck bed",
        "Low Boy / Drop Deck Semi Trailers": "drop deck trailers, step deck semi trailer, semi step deck trailer, drop deck semi trailer, semi trailer drop deck",
        "Dry Van Semi Trailers": "dry van semi trailer, dry van trailer, 53 ft dry van, dryvan ",
        "Flatbed Semi Trailers": "flat bed semi trailer, flatbed semi trailer, flatbed tractor trailer, semi truck flatbed trailer, flatbed semi",
        "Grain Semi Trailers": "hopper for grain, grain semi trailer, semi with grain trailer, semi truch grain trailer, grain hopper for sale, tractor grain trailer",
        "Reefer Semi Trailers": "reefer semi trailer, refrigerated semi trailer, semi reefer trailers for sale, reefer semi, semi reefer",
        "Livestock Semi Trailers": "livestock semi trailer, semi cattle trailer, semi stock trailer, livestock trailer semi, straight deck cattle trailer",
        "Tank / Bulk Semi Trailers": "tank semi trailer, bulk semi trailer, cement bulk trailer, cement bulker trailer, used tanker semi trailer, semi water tanker, fuel tank semi trailer",
        "Dump Semi Trailers": "dump semi trailer, semi truck dump trailer, tractor trailer dump trailer, semi truck with dump trailer, tri axle semi",
        "Other Semi Trailers": "woodchip trailer, walking floor trailer ",
        "Truck Bed Campers": "truck bed camper, truck bed rv camper, camper on bed of truck, truck with camper bed, camper bed of truck, pickup truck camper shell, truck shell camper, tent for pickup truck bed, tent on truck bed, camper pickup shell, bed camper top, truck bed camper for sale, truck pop up camper",
        "Semi Car Hauler": "semi car trailers, semi car haulers, car on semitrailers, semi-trailers with car, car semi-trailer for car, car carrier semi-trailer,  used car semi-trailer",
        "Other Trucks": "specialised trucks",
        "Standard Trucks": "trucks",
    },
    hasCategory(category) {
        return Object.prototype.hasOwnProperty.call(this.category_specific_part, category);
    },
    listingDescription(category) {
        if (this.hasCategory(category)) {
            return [this.first_part, this.category_specific_part[category], this.last_part].join(' ');
        }

        return '';
    },
};
