<template>
  <div class="collapsable-filter-list">
    <!-- Start of Trailer Type Accordion Setup -->
    <ion-row class="collapsable-menu-custom">
      <ion-col>
        <ion-list>
          <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
            <ion-item
              lines="none"
              class="filter-item-head"
            >
              <div class="text-icon">
                <div class="ion-text-uppercase parent-text">
                  Types
                </div>
              </div>
            </ion-item>
            <div
              v-for="(item, index) in TrailerCategories"
              :key="item.type_id"
              class="left-nav-item"
              @click="goToSelectedType(index)"
            >
              {{ item.category }}
            </div>
          </div>
        </ion-list>
      </ion-col>
    </ion-row>
    <!-- End of Trailer Type Accordion Setup -->
  </div>
</template>

<script>
import { IonCol, IonItem, IonList, IonRow, menuController } from "@ionic/vue";
import { chevronForwardOutline, closeSharp } from "ionicons/icons";
import {mapActions, mapGetters, mapState} from "vuex";
import { ellipsizeList } from "@/utils/FilterListUtil";
import { getGlossary } from "@/services/ProductsService";
import SaveSearch from "./SaveSearch.vue";
import { modal } from "@/mixins";
import { MENU_CONTROLLER } from "@/consts/menus";
import { trailerTypes } from "@/consts";
export default {
  name: "SimpleFilterList",
  components: {
    IonCol,
    IonRow,
    IonList,
    IonItem,
  },
  mixins: [modal],
  data() {
    return {
      TrailerCategories: trailerTypes,
      moreLimit: 6,
      data: true,
      closeSharp,
      manufacturers: [],
      categories: [],
      seeMoreCategory: false,
      seeMoreManufacture: false,
      dealFilters: [
        {
          key: "sale",
        },
      ],
      shownGroup: null,
      chevronForwardOutline,
      glossaryArray: [],
    };
  },
  computed: {
    ...mapState({
      userLocation: (state) => state.userGeoLocation,
      totalProducts: (state) => state.totalProducts,
      breadCrumb: (state) => state.pageTitle,
      aggregations: (state) => state.typeAggregations,
      megaMenuList: (state) => state.megaMenuList,
      type_id: (state) => state.searchApiFields.type_id,
      searchFilter: (state) => state.searchFilter,
      categoryList: (state) =>
        state.searchFilter
          .filter((item) => item.name === "category")
          .map((item) => item.value),
      manufacturerList: (state) =>
        state.searchFilter
          .filter((item) => item.name === "manufacturer")
          .map((item) => item.value),
    }),
    ...mapGetters(['hasFilter']),
    isSearchFilterEmpty() {
      return this.searchFilter.length === 0;
    },
    showCategory() {
      return ellipsizeList(this.categoryList, 14);
    },
    showManufacturer() {
      return ellipsizeList(this.manufacturerList, 14);
    },
    showCondition() {
      const conditionList = this.$store.state.searchFilter
        .filter((item) => item.name === "condition")
        .map((item) => item.label);
      return ellipsizeList(conditionList, 14);
    },
  },
  watch: {
    "$store.state.typeAggregations": function () {
      this.fillCategories();
    },
  },
  mounted() {
    this.fillCategories();
    this.filterGlossary();
  },
  methods: {
    ...mapActions(["addSearchFilter", "clearSearchFilter", "clearAllSearchFilter"]),
    goToSelectedType(index, isRedirect = true) {
      const selectedType = this.TrailerCategories[index];
      menuController.close(MENU_CONTROLLER.FilterContent);
      if (isRedirect) {
        this.$router.push({
          name: "ProductListWithPath",
          params: {
            path: selectedType.categoryGroupPath,
          },
        });
      }
    },
    isChildShown(group) {
      return this.shownGroup === group;
    },
    initSeeMoreCategories() {
      if (this.shownGroup === null && this.TrailerCategories.length === 0) {
        this.seeMoreCategory = false;
      }
      this.seeMoreCategory = this.TrailerCategories.some(
        (category) => this.categories.findIndex((e) => e === category) >= this.moreLimit,
      );
    },
    fillCategories() {
      const { typeAggregations, megaMenuList, searchApiFields } = this.$store.state;
      const category = typeAggregations.category?.buckets || [];
      const item = megaMenuList.find((item) => +item.id === +searchApiFields.type_id);
      this.categories = item
        ? item.categories
            .map((item) => item.name)
            .sort((categoryA, categoryB) => {
              const docA = category.find((c) => c.key === categoryA)?.doc_count || 0;
              const docB = category.find((c) => c.key === categoryB)?.doc_count || 0;
              return docB - docA;
            })
        : [];
      this.initSeeMoreCategories();
    },
    async filterGlossary() {
      this.glossaryArray = await getGlossary();
    },
    async saveSearch() {
      await menuController.close(MENU_CONTROLLER.FilterContent);
      this.openModal(SaveSearch, {
        cssClass: "modal-header-save-search",
        backdropDismiss: true,
      });
    },
  },
};
</script>
