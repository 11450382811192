<template>
  <div v-if="!isDesktop">
    <div v-if="!isAuthenticated">
      <ion-item
        class="header-item item-stable"
        @click="openUserModal"
      >
        <ion-label class="ion-text-uppercase top-menu-header-text">
          Sign In / Register
        </ion-label>
        <span style="margin-top: 3px">
          <img
            alt="arrowIcon"
            src="@/assets/icons/arrowIconBlack.svg"
          >
        </span>
      </ion-item>
    </div>
    <div v-else>
      <ion-item
        class="header-item item-stable"
        @click="expandProfile"
      >
        <div class="d-flex ion-align-items-center w-100 ion-justify-content-between">
          <div class="d-flex ion-align-items-center">
            <img
              class="img-user"
              alt="userimg"
              src="@/assets/imgUser.webp"
            >
            <span class="ion-margin-start top-menu-header-text ion-text-uppercase">
              User Account
            </span>
          </div>
          <div>
            <img
              alt="arrowIcon"
              src="@/assets/icons/arrowIconBlack.svg"
            >
          </div>
        </div>
      </ion-item>
    </div>
  </div>
  <div
    v-if="!isAuthenticated"
    class="top-menu-item ion-hide-xl-down"
    @click="openUserModal"
  >
    Sign In / Register
  </div>
  <div
    v-else-if="isDesktop"
    class="user-profile"
  >
    <span @click="toggleProfile">
      <img
        class="img-user"
        alt="userimg"
        src="@/assets/imgUser.webp"
      >
    </span>
    <span
      :class="!showProfile ? 'top-menu-arrow' : 'top-menu-arrow rotate-caret'"
      @click="toggleProfile"
    >
      <img
        alt="arrow"
        src="@/assets/icons/caretDown.svg"
      >
    </span>
    <ion-list v-if="showProfile">
      <ion-item
        :href="`${domainOrigin}/dashboard`"
        class="item"
        lines="none"
        @click="goToDashboard($event)"
      >
        <ion-label>
          DashBoard
          <span>
            <img
              alt="arrowIcon"
              src="@/assets/arrowIcon.svg"
            >
          </span>
        </ion-label>
      </ion-item>
      <ion-item
        :href="`${domainOrigin}`"
        class="item"
        lines="none"
        @click="logout($event)"
      >
        <ion-label>
          Log Out
          <span>
            <img
              alt="arrowIcon"
              src="@/assets/arrowIcon.svg"
            >
          </span>
        </ion-label>
      </ion-item>
    </ion-list>
  </div>
</template>

<script>
import { IonList, IonItem, IonLabel } from "@ionic/vue";
import { defineComponent } from "vue";
import UserBaseForm from "@/components/user/UserBaseForm.vue";
import LogoutConfirmation from "@/components/user/LogoutConfirmation.vue";
import { mapState } from "vuex";
import { modal } from "@/mixins";
import { LogRocketService } from "@/services/LogRocketService";

export default defineComponent({
  name: "TopMenu",
  components: { IonList, IonItem, IonLabel },
  mixins: [ modal ],
  emits: [ "onExpandUserProfile", "onHideSideMenu" ],
  data() {
    return {
      UserBaseForm,
      LogoutConfirmation,
    };
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.isAuthenticated,
      showProfile: (state) => state.showProfile,
    }),
  },
  methods: {
    toggleProfile() {
      this.$store.commit("SET_SHOW_PROFILE", !this.showProfile);
      this.$store.commit("SET_PROFILE_OVERLAY", this.showProfile);
    },
    logout(ev) {
      if (ev) {
        ev.preventDefault();
      }
      this.$store.commit("SET_SHOW_PROFILE", false);
      this.$store.commit("SET_PROFILE_OVERLAY", false);
      this.openModal(LogoutConfirmation, {
        cssClass: "modal-header-logout-confirmation",
        backdropDismiss: true,
      });
    },
    goToDashboard(ev) {
      if (ev) {
        ev.preventDefault();
      }
      this.$store.commit("SET_SHOW_PROFILE", false);
      this.$store.commit("SET_PROFILE_OVERLAY", false);
      this.$router.push({
        path: "/dashboard",
      });
    },
    expandProfile() {
      this.$emit("onExpandUserProfile", false);
    },
    openUserModal() {
      LogRocketService.track(LogRocketService.events.sign_in_sign_up_model_open);
      this.$emit("onHideSideMenu", false);
      this.openModal(UserBaseForm, {
        cssClass: "modal-user-base-form",
        backdropDismiss: true,
      });
    },
  },
});
</script>
