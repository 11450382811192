<template>
  <ion-row>
    <ion-col>
      <ion-list>
        <ion-item
          :ref="`${name}_minField`"
          lines="none"
          class="d-block"
        >
          <ion-label
            class="label-text range-section"
            position="stacked"
          >
            Min {{ displayLabel }}
          </ion-label>
          <div class="d-flex w-100 ion-justify-content-between">
            <div
              class="d-flex"
              :class="{ 'flex-reverse': displayUnit === '$' }"
            >
              <ion-input
                inputmode="numeric"
                size="small"
                :class="inputClassList"
                :value="fieldFormatter(lower, displayUnit)"
                :maxlength="maxLength"
                :aria-label="`input min ${ariaLabelName}`"
                @ionInput="validate($event, `${name}_minField`, displayUnit)"
              />
              <span
                class="range-input-unit"
                :class="{ 'size-unit': isSizeFilter }"
              >{{ displayUnit }}</span>
            </div>
            <template v-if="sizeFields.includes(name)">
              <div class="d-flex">
                <ion-input
                  inputmode="numeric"
                  size="small"
                  :class="inputClassList"
                  :value="fieldFormatter(lower, 'in')"
                  :maxlength="2"
                  :aria-label="`input min ${ariaLabelName}`"
                  @ionInput="validate($event, `${name}_minField`, 'in')"
                />
                <span
                  class="range-input-unit"
                  :class="{ 'size-unit': isSizeFilter }"
                >in</span>
              </div>
            </template>
            <ion-button
              color="primary"
              class="range-input-submit"
              @click="updateValue('minField')"
            >
              Submit
            </ion-button>
          </div>
          <ion-note slot="error">
            {{ errorMsg }}
          </ion-note>
        </ion-item>
        <ion-item
          :ref="`${name}_maxField`"
          lines="none"
          class="d-block"
        >
          <ion-label
            class="label-text range-section"
            position="stacked"
          >
            Max {{ displayLabel }}
          </ion-label>
          <div class="d-flex w-100 ion-justify-content-between">
            <div
              class="d-flex"
              :class="{ 'flex-reverse': displayUnit === '$' }"
            >
              <ion-input
                inputmode="numeric"
                size="small"
                :class="inputClassList"
                :value="fieldFormatter(upper, displayUnit)"
                :maxlength="maxLength"
                :aria-label="`input max ${ariaLabelName}`"
                @ionInput="validate($event, `${name}_maxField`, displayUnit)"
              />
              <span
                class="range-input-unit"
                :class="{ 'size-unit': isSizeFilter }"
              >{{ displayUnit }}</span>
            </div>
            <template v-if="sizeFields.includes(name)">
              <div class="d-flex">
                <ion-input
                  inputmode="numeric"
                  size="small"
                  :class="inputClassList"
                  :value="fieldFormatter(upper, 'in')"
                  :maxlength="2"
                  :aria-label="`input max ${ariaLabelName}`"
                  @ionInput="validate($event, `${name}_maxField`, 'in')"
                />
                <span
                  class="range-input-unit"
                  :class="{ 'size-unit': isSizeFilter }"
                >in</span>
              </div>
            </template>
            <div>
              <ion-button
                class="range-input-submit"
                color="primary"
                @click="updateValue('maxField')"
              >
                Submit
              </ion-button>
            </div>
          </div>
          <ion-note slot="error">
            {{ errorMsg }}
          </ion-note>
        </ion-item>
      </ion-list>
    </ion-col>
  </ion-row>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {
  IonItem,
  IonInput,
  IonButton,
  IonNote,
  IonLabel,
  IonList,
  IonCol,
  IonRow,
} from "@ionic/vue";

export default {
  name: "RangeFilter",
  components: {
    IonItem,
    IonInput,
    IonButton,
    IonNote,
    IonLabel,
    IonList,
    IonCol,
    IonRow,
  },
  props: {
    isActive: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    filterClass: {
      type: Function,
    },
  },
  emits: ["add", "remove", "submitted"],
  data: function () {
    const { searchFilter } = this.$store.state;
    const filter = searchFilter.find((f) => f.name === this.name);
    return {
      lower: filter?.min ?? 0,
      upper: filter?.max ?? 0,
      sizeFields: ["length", "width", "height"],
      minFeet: 0,
      maxFeet: 0,
      minInches: 0,
      maxInches: 0,
      errorMsg: "",
    };
  },
  computed: {
    isSizeFilter() {
      return this.sizeFields.includes(this.name);
    },
    displayUnit() {
      if (this.name === "price") {
        return "$";
      }
      if (this.isSizeFilter) {
        return "ft";
      }
      return "lbs";
    },
    displayLabel() {
      return this.isSizeFilter ? this.name : "";
    },
    ariaLabelName() {
      return this.name.replaceAll('_', ' ');
    },
    maxLength() {
      return this.isSizeFilter ? 3 : 11;
    },
    inputClassList() {
      const classes = {
        "range-input": true,
        "left-padding": this.displayUnit === "$",
        "right-padding": this.displayUnit !== "$",
        "size-input": this.isSizeFilter,
      };
      return classes;
    },
    isValid() {
      return (
        (!!this.lower || !!this.upper) &&
        this.validateRange(String(this.lower), String(this.upper))
      );
    },
    ...mapState({
      searchFilter: (state) => state.searchFilter,
    }),
    ...mapGetters(['hasFilter', 'getFirstFilter']),
  },
  methods: {
    ...mapActions([
      "addSearchFilter",
      "clearSearchFilter",
      "clearAllSearchFilter",
    ]),
    appendSearchFilter(name, _label, valueObj) {
      this.addSearchFilter(
        new this.filterClass({
          name,
          ...valueObj,
        }),
      );
    },
    removeSearchFilter(key) {
      if (this.searchFilter.hasFilter(key)) {
        this.clearSearchFilter({
          name: key,
        });
      }
    },
    validate(ev, refString, filterType) {
      let value = this.extractValue(ev.target.value);
      const isValidNum = this.validateValue(value);
      const isMinField = refString.includes("minField");
      const ismaxField = refString.includes("maxField");

      // Get the native input element and caret position.
      const inputElement = ev.target.nativeInput;
      const caretPosition = inputElement.selectionStart;
      const valueLength = inputElement.value.length;

      this.$refs[refString].$el.classList.remove("ion-invalid");

      if (!isValidNum) {
        this.errorMsg = "Please only enter numbers";
        this.$refs[refString].$el.classList.add("ion-invalid");
        return;
      }
      let existingVal, updatedVal;
      updatedVal = Number(value);
      if (isMinField) {
        existingVal = Number(this.lower);
      }
      if (ismaxField) {
        existingVal = Number(this.upper);
      }

      if (["ft", "in"].includes(filterType)) {
        let feet, inches;

        if (filterType === "ft") {
          feet = updatedVal;
          inches = this.extractInches(existingVal);
        }
        if (filterType === "in") {
          feet = this.extractFeet(existingVal);
          inches = updatedVal > 11 ? 11 : updatedVal;
        }
        updatedVal = `${feet}.${inches}`;
      }
      if (isMinField) {
        this.lower = updatedVal;
      } else if (ismaxField) {
        this.upper = updatedVal;
      }

      // Restore the caret position.
      this.$nextTick(() => {
        const newLength = inputElement.value.length, changedLength = newLength - valueLength;
        const newPosition = Math.max(0, caretPosition + changedLength);
        inputElement.setSelectionRange(newPosition, newPosition);
      });
    },
    fieldFormatter(value, filterType) {
      if (filterType === "ft") {
        return this.extractFeet(value);
      } else if (filterType === "in") {
        return this.extractInches(value);
      }
      value = this.extractValue(value);
      const formatter = new Intl.NumberFormat("en-US", {
        // These options are needed to round to whole numbers.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      });
      return `${formatter.format(Number(value))}`;
    },
    validateValue(val) {
      return !isNaN(Number(val));
    },
    validateRange(min, max) {
      /* ------------------- maxFeet and minFeet are essentially single fields in case of non-size filters. 
      -----------------------Inches value will be undefined in such cases --------------*/
      const [maxFeet, maxInches] = max.split(".").map((n) => Number(n));
      const [minFeet, minInches] = min.split(".").map((n) => Number(n));

      this.$refs[`${this.name}_maxField`].$el.classList.remove("ion-invalid");

      const throwRangeValidationError = () => {
        this.errorMsg = "Min value should be less than Max value";
        this.$refs[`${this.name}_maxField`].$el.classList.add("ion-invalid");
      };

      /*---------Return all the false conditions----------*/
      if (!this.validateValue(min) || !this.validateValue(max)) {
        throwRangeValidationError();
        return false;
      }
      if (maxFeet && maxFeet < minFeet) {
        throwRangeValidationError();
        return false;
      }

      /*---------Return all true conditions---------------*/

      // If either of the fields are empty, return true
      if ((!maxFeet && !maxInches) || (!minFeet && !minInches)) {
        return true;
      }

      if (maxFeet > minFeet) {
        return true;
      }

      if (maxFeet === minFeet && maxInches > minInches) {
        return true;
      }

      throwRangeValidationError();
      return false;
    },
    extractValue(val) {
      return String(val).replaceAll(",", "");
    },
    extractInches(val) {
      return Number(String(val).split(".")?.[1] || 0);
    },
    extractFeet(val) {
      return Math.floor(Number(val));
    },
    updateValue(field) {
      const {getFirstFilter} = this.$store.getters;
      const extractedMinVal = this.extractValue(this.lower);
      const extractedMaxVal = this.extractValue(this.upper);

      const isValueEmpty =
        field === "minField"
          ? Number(extractedMinVal) === 0
          : Number(extractedMaxVal) === 0;
      if (isValueEmpty) {
        this.errorMsg = "Please enter values";
        this.$refs[`${this.name}_${field}`].$el.classList.add("ion-invalid");
        return;
      }

      const updatedFields = {
        min: getFirstFilter(this.name)?.min,
        max: getFirstFilter(this.name)?.max,
      };

      if (field === "minField") {
        updatedFields.min = this.isSizeFilter
          ? extractedMinVal
          : Math.round(extractedMinVal);
      }
      if (field === "maxField") {
        updatedFields.max = this.isSizeFilter
          ? extractedMaxVal
          : Math.round(extractedMaxVal);
      }
      if (this.validateRange(extractedMinVal, extractedMaxVal)) {
        this.appendSearchFilter(this.name, this.label, updatedFields);
      }

      this.$emit("submitted");
    },
  },
  watch: {
    "$store.state.searchFilter": function (newVal, oldVal) {
      const oldExist = !!oldVal.find((f) => f.name === this.name);
      const newExist = !!newVal.find((f) => f.name === this.name);
      if (oldExist && !newExist && this.isActive) {
        this.$emit("remove", {
          name: this.name,
          label: this.label,
        });
      }
    },
    "$store.state.searchApiFields": function (newVal, oldVal) {
      if (oldVal[`${this.name}_min`] && !newVal[`${this.name}_min`]) {
        this.lower = 0;
      } else if(newVal[`${this.name}_min`]) {
        this.lower = newVal[`${this.name}_min`];
      }
      if (oldVal[`${this.name}_max`] && !newVal[`${this.name}_max`]) {
        this.upper = 0;
      } else if(newVal[`${this.name}_max`]) {
        this.upper = newVal[`${this.name}_max`];
      }
    },
    isActive(newValue) {
      if (newValue === false && this.getFirstFilter(this.name)) {
        this.removeSearchFilter(this.name);
      }
    },
  },
};
</script>
