<!--Since we hide the reCaptcha banner from the website, we need to show this remark per Google's policy-->
<!--@see https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed -->

<template>
  <div class="container">
    This site is protected by reCAPTCHA and the Google
    <a
      href="https://policies.google.com/privacy"
      target="_blank"
    >Privacy Policy</a> and
    <a
      href="https://policies.google.com/terms"
      target="_blank"
    >Terms of Service</a> apply.
  </div>
</template>

<style scoped>
.container {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: normal;
  font-family: "Object Sans", sans-serif;
}
</style>
