const state = () => ({
  toastMessage: {
    open: false,
    text: "",
    type: "",
  },
});

const mutations = {
  SET_TOAST_MESSAGE(state, value) {
    state.toastMessage = value;
  },
};

const actions = {
  setToastNotification({ commit, dispatch }, payload) {
    commit("SET_TOAST_MESSAGE", payload);
    setTimeout(() => {
      dispatch("removeToastNotification");
    }, 8000);
  },
  removeToastNotification({ commit }) {
    commit("SET_TOAST_MESSAGE", { open: false, text: "", type: "" });
  },
};

export default {
  state,
  mutations,
  actions,
};
