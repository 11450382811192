<template>
  <!-- Start of Mobile and .Tablet View Your Search -->
  <ion-menu
    class="map-view-filter"
    menu-id="mapViewFilterContent"
    side="start"
    swipe-gesture="false"
    type="overlay"
    content-id="main-content"
    style="background: rgba(7, 50, 85, 0.5)"
  >
    <div>
      <span>
        <ion-button
          color="transparent"
          class="close-btn ion-no-padding"
          @click="closeFilter"
        ><ion-icon
          class="close-side-toggle"
          :icon="closeSharp"
        /></ion-button>
      </span>
      <ion-list>
        <ion-item lines="none">
          <ion-label class="ion-text-uppercase header-text">
            Filters
          </ion-label>
        </ion-item>
      </ion-list>
    </div>
    <FilterList />
    <ion-row
      class="results-button-row collapsable-menu-custom"
      :class="{ 'sticky-ad-inactive': isStickyFooterClosed }"
    >
      <ion-col>
        <div class="results-button-parent">
          <ion-button
            class="results-button btn-inactive"
            disabled
            @click="closeFilter"
          >
            Now listing {{ totalProducts > 0 ? totalProducts : "0" }} Results
            <ion-icon :icon="chevronForwardOutline" />
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-menu>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonLabel,
  IonIcon,
  menuController,
  IonButton,
  IonItem,
  IonList,
  IonMenu,
  IonRow,
  IonCol,
} from "@ionic/vue";
import { closeSharp, chevronForwardOutline } from "ionicons/icons";
import FilterList from "./FilterList.vue";
import { mapState } from "vuex";
import { MENU_CONTROLLER } from "@/consts/menus";
export default defineComponent({
  name: "FiltersResponsiveLayout",
  components: {
    FilterList,
    IonLabel,
    IonIcon,
    IonButton,
    IonMenu,
    IonItem,
    IonList,
    IonRow,
    IonCol,
  },
  data() {
    return {
      chevronForwardOutline,
      data: true,
      closeSharp,
    };
  },
  computed: {
    ...mapState({
      totalProducts: (state) => state.totalProducts,
      searchFilter: (state) => state.searchFilter,
      isStickyFooterClosed: (state) => state.ads.isStickyFooterClosed,
    }),
    isSearchFilterEmpty() {
      return this.searchFilter.length === 0;
    },
  },
  methods: {
    closeFilter() {
      menuController.close(MENU_CONTROLLER.MapViewFilterContent);
    },
  },
});
</script>
