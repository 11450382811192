<template>
  <ion-grid class="login-form">
    <Form
      ref="loginForm"
      v-slot="{ meta }"
      @submit="login"
    >
      <ion-col>
        <p>
          Private sellers register to create and manage your listings. Dealers please <span
            class="link"
            @click="goToPrivateSellersPage"
          >click here</span>.
        </p>
      </ion-col>
      <SocialAuth />
      <ion-col
        class="ion-padding"
        size-md="4"
        size="12"
      >
        <p>Or login with your email:</p>
        <Field
          v-slot="{ field, errors }"
          rules="required|email"
          name="email"
          label="EMAIL"
        >
          <ion-item
            :class="errors.length !== 0 ? 'error-field' : ''"
            class="search-trailer-location-input login error-save-search"
            lines="none"
          >
            <IonLabel
              class="location-label custom-color"
              position="floating"
            >
              Email Address
            </IonLabel>
            <ion-input
              name="email"
              v-bind="field"
              aria-label="Input Email Address"
              @keyup="checkIfFill()"
            />
          </ion-item>
        </Field>
        <ErrorMessage name="email" />
      </ion-col>
      <ion-col
        class="ion-padding"
        size-md="4"
        size="12"
      >
        <Field
          v-slot="{ field, errors }"
          rules="required"
          name="password"
          label="PASSWORD"
        >
          <ion-item
            :class="errors.length !== 0 ? 'error-field' : ''"
            class="search-trailer-location-input login error-save-search"
            lines="none"
          >
            <IonLabel
              class="location-label custom-color"
              position="floating"
            >
              Password
            </IonLabel>
            <ion-input
              type="password"
              name="password"
              v-bind="field"
              aria-label="Input Password"
              @keyup="checkIfFill"
            />
          </ion-item>
        </Field>
        <ErrorMessage name="password" />
      </ion-col>
      <div class="d-flex login-form-button">
        <ion-col
          size-md="6"
          size="12"
        >
          <ion-button
            class="btn btn-cancel custom-button"
            @click="closeModal"
          >
            Cancel
          </ion-button>
        </ion-col>
        <ion-col
          size-md="6"
          size="12"
        >
          <ion-button
            class="btn btn-submit custom-button"
            type="submit"
            :class="loggingIn || !(meta.valid && meta.dirty) ? 'btn-inactive' : ''"
            :disabled="loggingIn || !(meta.valid && meta.dirty)"
          >
            <span v-if="loggingIn">Signing In...</span>
            <span v-else>Sign In</span>
            <ion-icon
              style="margin-top: -2px"
              :icon="chevronForwardOutline"
            />
          </ion-button>
        </ion-col>
      </div>
      <RecaptchaRemark />
    </Form>
  </ion-grid>
  <div
    v-if="errorResponse"
    class="ion-padding forgot-password-acknowledgement-text"
  >
    <span
      class="ion-text-uppercase ion-text-center d-block"
    >If you have lost your password, use the following link to reset it.</span>
  </div>
  <div class="ion-padding forgot-password-link">
    <span
      class="ion-text-uppercase ion-text-center d-block"
      @click="openForgotPasswordModal"
    >Forgot Password?</span>
  </div>
</template>

<script>
import { IonGrid, IonCol, IonItem, IonButton, IonInput, IonLabel, modalController, IonIcon } from "@ionic/vue";
import { chevronForwardOutline } from "ionicons/icons";
import { Field, ErrorMessage, Form } from "vee-validate";
import ForgotPassword from "@/components/user/ForgotPassword.vue";
import SocialAuth from "@/components/user/SocialAuth.vue";
import { modal } from "@/mixins";
import { loginUser } from "@/services/UserService";
import WrongPasswordAcknowledge from "@/components/user/WrongPasswordAcknowledge.vue";
import { getRecaptchaToken } from "@/services/RecaptchaService";
import RecapchaFailed from "@/components/user/ReceptchaFailed.vue";
import RecaptchaRemark from "@/components/google/RecaptchaRemark.vue";
import { LogRocketService } from "@/services/LogRocketService";
import { utagLink } from "@/services/TealiumService";

export default {
  name: "LoginForm",
  components: {
    RecaptchaRemark,
    SocialAuth,
    Field,
    ErrorMessage,
    Form,
    IonGrid,
    IonCol,
    IonButton,
    IonItem,
    IonInput,
    IonLabel,
    IonIcon,
  },
  mixins: [ modal ],
  data() {
    return {
      chevronForwardOutline,
      errorResponse: false,
      loggingIn: false,
    };
  },

  methods: {
    checkIfFill() {
      this.formPartiallyFilled = true;
    },
    async closeModal() {
      await modalController.dismiss();
    },
    async goToPrivateSellersPage() {
      await this.closeModal();
      this.$router.push('/privatesellers');
    },
    async login(event) {
      this.loggingIn = true;

      const params = {
        email: event.email?.toLowerCase(),
        password: event.password,
        captcha: await getRecaptchaToken("sign_in"),
      };

      try {
        LogRocketService.track(LogRocketService.events.login_submitted, {
          email: params.email,
        });

        const { data } = await loginUser({
          params,
        });

        LogRocketService.identify(data?.user?.id, data?.user);

        this.$store.commit("SET_USER_DATA", data?.user || {});
        if (data !== undefined && data.user.email_verified_at !== null) {
          LogRocketService.track(LogRocketService.events.login_success, {
            email: params.email,
          });
          this.$store.commit("SET_IS_AUTHENTICATED", true);
          this.$store.commit("SET_AUTH_TOKEN", data.token);
          this.$store.dispatch("setToastNotification", {
            open: true,
            text: "Logged in successfully",
            type: "success",
          });
          await this.closeModal();
          this.loggingIn = false;
          const { redirectUrl } = this.$store.state;
          if(redirectUrl) {
            this.$store.commit("SET_REDIRECT_URL", null);
            if(redirectUrl !== "search") {
              await this.$router.push(redirectUrl);
            }
          } else {
            await this.$router.push({
              name: "Dashboard",
            });
          }
        } else {
          LogRocketService.track(LogRocketService.events.login_needs_verify, {
            email: params.email,
          });
          await this.closeModal();
          await this.$router.push({
            name: "ResendEmail",
            params: { email: data.user.email },
          });
          this.loggingIn = false;
        }
        
        utagLink(this.$route, {
          "tealium_event": "form_submit",
          "form_id": "login_form",
          "form_submission_email": event.email?.toLowerCase(),
        });
      } catch (e) {
        this.loggingIn = false;
        this.errorResponse = true;
        let loginFailedReason = "Wrong credentials.";
        let isWrongPasswordError = true;
        let logRocketLoginFailedEvent = LogRocketService.events.login_failed_credentials;

        if (e.data?.errors?.captcha !== undefined) {
          loginFailedReason = "Recaptcha validation failed.";
          isWrongPasswordError = false;
          logRocketLoginFailedEvent = LogRocketService.events.login_failed_recaptcha;
        }

        if (isWrongPasswordError) {
          await this.openModal(WrongPasswordAcknowledge, {
            cssClass: "modal-header-wrong-password",
            backdropDismiss: true,
          });
        } else {
          await this.openModal(RecapchaFailed, {
            cssClass: "modal-header-wrong-password",
            backdropDismiss: true,
          });
        }

        LogRocketService.track(logRocketLoginFailedEvent, {
          email: params.email,
          reason: loginFailedReason,
        });
      }
    },
    openForgotPasswordModal() {
      // this.closeModal()
      this.openModal(ForgotPassword, {
        cssClass: "modal-header-forgot-password",
        backdropDismiss: true,
      });
    },
  },
};
</script>
