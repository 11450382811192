import { isSSR } from "@/utils/SSRUtil";

export function appendSchema(schema, isProductList = false, isRemove = true) {
  if(!isSSR()) {
    if(isRemove) {
      [...document.querySelectorAll('[type="application/ld+json"][id="productlist"]')].map(el => el.remove());
    }
    const schemaElement = document.createElement('script');
    schemaElement.type = 'application/ld+json';
    if(isProductList) {
      schemaElement.id = 'productlist';
    }
    schemaElement.text = JSON.stringify(schema);
    document.querySelector('head').appendChild(schemaElement);
  }
}