<template>
  <ion-row class="collapsable-menu-custom">
    <ion-col>
      <ion-list>
        <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
          <ion-item
            id="TestA_plp_filter_price"
            class="filter-item-head"
            lines="none"
            :class="{ active: isShown }"
            @click="toggle()"
          >
            <div class="text-icon">
              <div class="ion-text-uppercase parent-text">
                {{ name }}
              </div>
              <div>
                <span
                  :class="!isShown ? 'arrow-drop  rotate-caret' : 'arrow-drop'"
                >
                  <img
                    alt="Trailers Near Me"
                    src="@/assets/icons/caretDownBlue.svg"
                  >
                </span>
              </div>
            </div>
          </ion-item>

          <div
            v-if="isShown"
            lines="none"
          >
            <range-filter
              :name="priceInfo.name"
              :label="priceInfo.label"
              :min="priceInfo.min"
              :max="priceInfo.max"
              :filter-class="PriceFilterModel"
              @submitted="handleValueSubmitted()"
            />
          </div>
        </div>
      </ion-list>
    </ion-col>
  </ion-row>
</template>

<script>
import { IonRow, IonCol, IonItem, IonList } from "@ionic/vue";
import RangeFilter from "./RangeFilter.vue";
import PriceFilterModel from "../../models/filters/range/PriceFilter";
import { scrollPlpToTop } from "@/utils/ProductListingPageUtil";

export default {
  name: "PriceFilter",
  components: {
    IonCol,
    IonRow,
    IonList,
    IonItem,
    RangeFilter,
  },
  data: function () {
    return {
      name: "PRICE",
      isShown: false,
      activeFilters: "",
      PriceFilterModel,
    };
  },
  computed: {
    priceInfo() {
      const {
        filterLimits,
        searchApiFields: { type_id: activeTypeId },
      } = this.$store.state;
      const min =
        (filterLimits?.price && filterLimits.price[activeTypeId]?.min) || 0;
      const max =
        (filterLimits?.price && filterLimits.price[activeTypeId]?.max) || 1000;
      return {
        name: "price",
        label: "PRICE",
        min,
        max,
        lower: min,
        upper: max,
      };
    },
  },
  methods: {
    toggle() {
      this.isShown = !this.isShown;
    },
    handleValueSubmitted() {
      scrollPlpToTop();
    },
  },
};
</script>
