import domain from "./domain.js";
import { productionUrl } from "@/config";
export default {
  mixins: [domain],
  methods: {
    superSanitize(string = "") {
      string = string.replace(/\s+/g, "-");
      string = string.replaceAll("'", "");
      string = string.replaceAll("/", "-");
      string = string.replaceAll(";", "-");
      string = string.replaceAll("|", "-");
      string = string.replaceAll(".", "-");

      const specialCharacters = ["#", "$", "%", "&", "@", ".", "€", "+", "=", "§", "\\"];

      specialCharacters.forEach((character) => {
        string = string.replaceAll(character, "");
      });

      string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      string = string.normalize("NFD").replace(/\p{Diacritic}/gu, "");

      string = string.replace(/[^a-zA-Z0-9-]/g, "-");
      string = string.replace(/^[-]+/g, "-");
      string = string.replace(/[-]+$/g, "-");
      string = string.replace(/[-]{2,}/g, "-");
      string = string.toLowerCase();
      string = string.replaceAll("ø", "o");
      string = string.replace(/-$/, "");

      return string;
    },
    shortenNumber(number) {
      let index = "abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let base = index.length;
      let out = "";

      for (let i = Math.floor(Math.log(number) / Math.log(base)); i >= 0; i--) {
        let bcp = Math.pow(base, i);
        let a = Math.floor(number / bcp) % base;
        out = out + index.substr(a, 1);
        number = number - a * bcp;
      }
      out = out.split("").reverse().join("");

      return out;
    },
    unshortenNumber(text) {
      let index = "abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let base = index.length;
      let out = 0;
      text = text.split("").reverse().join("");
      let len = text.length - 1;
      for (let i = 0; i <= len; i++) {
        let bcpow = Math.pow(base, len - i);
        out = out + index.indexOf(text.substr(i, 1)) * bcpow;
      }
      return out;
    },
    productHref(productId, productTitle) {
      const path = this.productHrefPath(productId, productTitle);

      return `${this.domainOrigin}/${path}`;
    },
    productHrefPath(productId, productTitle) {
      let path = this.superSanitize(productTitle);

      path = path + "-" + this.shortenNumber(productId) + ".html";
      path = path?.replace(/\//g, "%2F");

      return path;
    },
    productionPDPUrl(productId, productTitle) {
      const path = this.productHrefPath(productId, productTitle);

      return `${productionUrl}/${path}`;
    },
    manufacturerHref(manufacturer) {
      manufacturer = manufacturer?.toLowerCase();
      const containTrailerSuffix =
        manufacturer?.split(" ")?.includes("trailer") || manufacturer?.split(" ")?.includes("trailers");
      if (containTrailerSuffix) {
        return `${this.domainOrigin}/${manufacturer}-for-sale`;
      }
      return `${this.domainOrigin}/${manufacturer}-trailers-for-sale`;
    },
    arrayValuesToString(arr, separator = " ") {
      return arr.filter(v => typeof v === "string" && v.trim().length > 0).join(separator);
    },
    altText(product) {
      let filename = 'defaultLogo';
      if(product?.image) {
        const url = product.image;
        filename = url?.slice(url?.lastIndexOf('/') + 1) || '';
        const extentionPos = filename.indexOf('.');
        filename = filename.slice(0, extentionPos);
      }
      
      const prefix = this.arrayValuesToString([product?.condition, product?.cagetory, product?.manufacturer, product?.model]);
      const suffix = this.arrayValuesToString([product?.location?.city, product?.location?.region], ', ');

      return `${prefix} for sale, in ${suffix}-${filename}`;
    },
  },
};
