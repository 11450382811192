<template>
  <!-- Start of ConstructionFilter Accordion Setup -->
  <ion-row
    v-if="(constructions || []).length > 0"
    class="collapsable-menu-custom"
  >
    <ion-col>
      <ion-list>
        <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
          <ion-item
            id="TestA_plp_filter_construction"
            lines="none"
            class="filter-item-head"
            :class="{ active }"
            @click="handleClick"
          >
            <div class="text-icon">
              <div class="ion-text-uppercase parent-text">
                Construction
              </div>
              <div class="d-flex">
                <span class="ion-text-uppercase selected-search ion-padding-end">
                  {{ selectedListString }}
                </span>
                <span :class="!active ? 'arrow-drop  rotate-caret' : 'arrow-drop'">
                  <img
                    alt="Trailers Near Me"
                    src="@/assets/icons/caretDownBlue.svg"
                  >
                </span>
              </div>
            </div>
          </ion-item>
          <template
            v-for="(item, index) in constructions"
            :key="item.key"
          >
            <ion-item
              v-if="active"
              lines="none"
              :href="item.href"
              class="filter-collapse"
              @click="toggleSelected($event, index)"
            >
              <ion-checkbox
                slot="start"
                ref="construction"
                :value="item.key"
                :checked="!!hasFilter('construction', item.key)"
                class="filter-checkbox"
              />
              <ion-label class="ion-text-uppercase child-text">
                {{ toItemLabel(item.key) }}
              </ion-label>
            </ion-item>
          </template>
        </div>
      </ion-list>
    </ion-col>
  </ion-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ellipsizeList } from "../../utils/FilterListUtil";
import { IonCheckbox, IonCol, IonItem, IonList, IonRow, IonLabel } from "@ionic/vue";
import ConstructionItemFilter from "@/models/filters/group/ConstructionItemFilter";
import { filterActions } from "@/mixins";
import { scrollPlpToTop } from "@/utils/ProductListingPageUtil";

export default {
  name: "ConstructionFilter",
  components: {
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonCheckbox,
    IonLabel,
  },
  mixins: [filterActions],
  props: {
    active: Boolean,
  },
  emits: ["toggle"],
  computed: {
    ...mapState({
      searchFilter: (state) => state.searchFilter,
      searchApiFields: (state) => state.searchApiFields,
      aggregations: (state) => state.categoryAggregations,
    }),
    selectedList() {
      return this.searchFilter
        .filter((item) => item.name === "construction")
        .map((item) => item.value);
    },
    selectedListString() {
      return ellipsizeList(this.selectedList, 14);
    },
    constructions() {
      return this.aggregations?.filter_aggregations?.construction?.buckets?.map((construction) => {
        return {
          ...construction,
          href: this.generateHrefFromField(construction.key, "construction"),
        };
      });
    },
  },
  methods: {
    ...mapActions(["addSearchFilter", "clearSearchFilter"]),
    toItemLabel: ConstructionItemFilter.defaultLabel,
    toggleSelected($event, index) {
      $event.preventDefault();
      const { checked, value } = this.$refs.construction[index];
      if (!checked) {
        this.addSearchFilter(
          new ConstructionItemFilter({
            value,
          }),
        );
      } else if (checked) {
        this.clearSearchFilter({
          name: 'construction',
          value,
        });
      }

      scrollPlpToTop();
    },
    handleClick() {
      this.$emit("toggle");
    },
  },
};
</script>
