import { isSSR } from "../../utils/SSRUtil";

const state = () => ({
  isStickyFooterClosed: true,
});

const mutations = {
  SET_STICKY_FOOTER_FLAG(state, value) {
    state.isStickyFooterClosed = value;
  },
};

const actions = {
  toggleStickyFooterFlag({ commit }, payload = true) {
    commit("SET_STICKY_FOOTER_FLAG", payload);
  },
};

const getters = {
  stickyFooterOffsetHeight() {
    if (!isSSR()) {
      return (
        document.getElementById("trailertrader.com_stickyfooter")
          ?.offsetHeight || 0
      );
    }
    return 0;
  },
  filterOverlayOffset(state, getters) {
    if (state.isStickyFooterClosed) {
      return "bottom: 5px";
    }
    return `bottom: ${getters.stickyFooterOffsetHeight}px`;
  },
  footerPadding() {
    return "margin-bottom: 0";
  },
  pdpOverlayScrolled(state, getters) {
    if (state.isStickyFooterClosed) {
      return "padding-bottom: 0px";
    }
    return `padding-bottom: ${getters.stickyFooterOffsetHeight}px`;
  },
  resultsButtonOffset(state, getters) {
    if (state.isStickyFooterClosed) {
      return "padding-bottom: 0px";
    }
    return `padding-bottom: ${getters.stickyFooterOffsetHeight + 10}px`;
  },
  loadMoreButtonOffset(state, getters) {
    if (state.isStickyFooterClosed) {
      return "bottom: 50px";
    }
    return `bottom: ${getters.stickyFooterOffsetHeight + 50}px`;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
