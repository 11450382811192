export const parseBoolean = (value) => {
  return value === "1" || value === "true";
}

export const undefinedAsNull = (value) => {
  if (value === undefined) {
    return null;
  }

  return value;
}

export const baseUrl = import.meta.env.BASE_URL;
export const mapsApiKey = import.meta.env.VITE_APP_MAPS_API_KEY;
export const appBaseUrl = import.meta.env.VITE_APP_API_BASE_URL;
export const recaptchaSitekey = import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY;
export const tcApiUrl = import.meta.env.VITE_APP_TC_API_URL;
export const tmpToken = import.meta.env.VITE_APP_TC_TEMP_TOKEN;
export const productionUrl = import.meta.env.VITE_APP_PRODUCTION_WEBSITE_URL;
export const websiteBaseUrl = import.meta.env.VITE_APP_WEBSITE_BASE_URL;
