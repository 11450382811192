<template>
  <div class="collapsable-filter-list">
    <!-- Start of Your Search When No Search Category is Selected -->
    <ion-row
      v-if="isSearchFilterEmpty"
      class="search-box"
    >
      <div>
        <p class="ion-text-uppercase search-heading">
          Your Search
        </p>
        <p class="search-text">
          Select filters to narrow your results.
        </p>
      </div>
    </ion-row>
    <!-- End of Your Search When No Search Category is Selected -->

    <!-- Start of Your Search When Search Category is Selected -->
    <ion-row
      v-if="!isSearchFilterEmpty"
      id="TestA_plp_searchbox"
      class="search-box"
    >
      <div>
        <div class="ion-text-uppercase search-heading">
          <span>Your Search</span>
          <a
            :href="clearAllHref()"
            class="reset"
            @click="handleClearAll"
          >
            <span
              id="TestA_plp_clearAll"
              class="ion-text-uppercase clear-all"
            >
              <img
                class="reset-icon"
                alt="resetIcon"
                src="@/assets/icons/resetIcon.svg"
              >Clear All
            </span>
          </a>
        </div>

        <div class="search-chip-container">
          <template
            v-for="search in searchFilter"
            :key="search.name + search.value"
          >
            <template v-if="search.rangeLabels">
              <template
                v-for="(rangeType, subKey) in search.rangeLabels"
                :key="`${search.name}_${subKey}`"
              >
                <ion-chip class="search-chip">
                  <ion-label class="ion-text-wrap">
                    {{ rangeType.value }}
                  </ion-label>
                  <ion-icon
                    class="ion-icon-test"
                    :icon="closeSharp"
                    @click="clearSearchFilter({name: search.name + '_' + rangeType.key})"
                  />
                </ion-chip>
              </template>
            </template>
            <template v-else>
              <ion-chip class="search-chip">
                <ion-label class="ion-text-wrap">
                  {{ searchLabel(search) }}
                </ion-label>
                <ion-icon
                  class="ion-icon-test"
                  :icon="closeSharp"
                  @click="clearSearchFilter({name: search.name, value: search.value})"
                />
              </ion-chip>
            </template>
          </template>
        </div>
        <p
          id="savesearch_flf"
          class="ion-text-uppercase save-search"
          @click="saveSearch"
        >
          save this search
          <img
            class="caret-right"
            alt="caretRight"
            src="@/assets/arrowIcon.svg"
          >
        </p>
      </div>
    </ion-row>
    <!-- End of Your Search When Search Category is Selected -->
    <ion-row class="filter-keyword">
      <ion-row>
        <ion-col>
          <div class="ion-text-uppercase parent-text">
            Keywords
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="search-input">
        <ion-col size="1">
          <ion-icon
            class="search-icon"
            :icon="searchOutline"
          />
        </ion-col>
        <ion-col size="11">
          <ion-item
            class="search-item"
            lines="none"
          >
            <ion-input
              v-model="searchKeyword"
              placeholder="Enter keyword"
              :clear-input="true"
              aria-label="input keyword for search"
              @keyup.enter="enterKeyword"
            />
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-row>
    <!-- Start of Trailer Type Accordion Setup -->
    <slot name="types" />
    <ion-row
      v-if="!isAllTypes"
      class="collapsable-menu-custom"
    >
      <ion-col>
        <ion-list>
          <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
            <ion-item
              id="TestA_plp_filter_trailercategory"
              lines="none"
              class="filter-item-head"
              :class="{ active: isChildShown('categories') }"
              @click="toggleParent('categories')"
            >
              <div class="text-icon">
                <div class="ion-text-uppercase parent-text">
                  Category
                </div>
                <div class="d-flex">
                  <span class="ion-text-uppercase selected-search ion-padding-end">
                    {{ showCategory }}
                  </span>
                  <span :class="!isChildShown('categories') ? 'arrow-drop  rotate-caret' : 'arrow-drop'">
                    <img
                      alt="Trailers Near Me"
                      src="@/assets/icons/caretDownBlue.svg"
                    >
                  </span>
                </div>
              </div>
            </ion-item>
            <template
              v-for="(item, index) in categories"
              :key="index"
            >
              <ion-item
                v-if="isChildShown('categories') && (index < moreLimit || (seeMoreCategory && index >= moreLimit))"
                :href="item.href"
                lines="none"
                class="filter-collapse-see-more"
                @click="toggleFilter($event, 'category', index)"
              >
                <ion-checkbox
                  slot="start"
                  ref="category"
                  :value="item.name"
                  :checked="!!hasFilter('category', item.name)"
                  class="filter-checkbox"
                />
                <ion-label class="ion-text-uppercase child-text">
                  {{ item.name }}
                </ion-label>
              </ion-item>
            </template>
            <div
              v-if="isChildShown('categories') && categories.length > moreLimit"
              class="see-more-less"
            >
              <div>
                <ion-label
                  v-if="isChildShown('categories') && !seeMoreCategory"
                  class="see-more ion-margin-top"
                  @click="toggleCategories(true)"
                >
                  See {{ categories.length - moreLimit }} more
                  <img
                    alt="caretRight"
                    class="caret-right"
                    src="@/assets/icons/caretRight.svg"
                  >
                </ion-label>
              </div>
              <div class="see-less">
                <ion-label
                  v-if="isChildShown('categories') && seeMoreCategory"
                  class="see-more ion-margin-top"
                  @click="toggleCategories(false)"
                >
                  See less
                  <img
                    alt="caretUp"
                    src="@/assets/icons/caretUp.svg"
                  >
                </ion-label>
              </div>
            </div>
          </div>
        </ion-list>
      </ion-col>
    </ion-row>
    <!-- End of Trailer Type Accordion Setup -->

    <ColorFilter
      v-if="totalProducts > 0 && hasFilterAggregations('color')"
      :active="isChildShown('color')"
      @toggle="toggleParent('color')"
    />

    <PullType
      v-if="!isTruckBed"
      :active="isChildShown('pull_type')"
      @toggle="toggleParent('pull_type')"
    />
    <!-- Start of Manufacturer Accordion Setup -->
    <ManufacturerFilter 
      :active="isChildShown('skirted_bed')"
      @toggle="toggleParent('skirted_bed')"
    />
    <!-- End of Manufacturer Accordion Setup -->

    <AxlesFilter
      v-if="!isAllTypes && !isTruckBed"
      :active="isChildShown('axles')"
      @toggle="toggleParent('axles')"
    />

    <!-- Start of Payload Capacity Accordion Setup -->
    <payload-capacity-filter v-if="!isAllTypes && !isTruckBed" />
    <!-- End of Payload Capacity Accordion Setup -->

    <!-- Start of GVWR Accordion Setup -->
    <gvwr-filter
      v-if="!isAllTypes && !isTruckBed"
      :glossary-data="glossaryArray"
    />
    <!-- End of GVWR Accordion Setup -->

    <size-filter v-if="!isTruckBed" />

    <BodyTypeFilter
      v-if="isTruckBed"
      :active="isChildShown('body_type')"
      @toggle="toggleParent('body_type')"
    />

    <CabToAxleLengthFilter
      v-if="isTruckBed"
      :active="isChildShown('truck_bed')"
      @toggle="toggleParent('truck_bed')"
    />

    <NumberOfTruckAxlesFilter
      v-if="isTruckBed"
      :active="isChildShown('number_of_truck_axles')"
      @toggle="toggleParent('number_of_truck_axles')"
    />

    <SkirtedBedFilter
      v-if="isTruckBed"
      :active="isChildShown('skirted_bed')"
      @toggle="toggleParent('skirted_bed')"
    />

    <YearFilter
      v-if="!isAllTypes"
      :active="isChildShown('year')"
      @toggle="toggleParent('year')"
    />

    <ConstructionFilter
      v-if="showConstructionFilter && !isAllTypes && !isTruckBed"
      :active="isChildShown('construction')"
      @toggle="toggleParent('construction')"
    />

    <!-- Start of Price Accordion Setup -->
    <price-filter />
    <!-- End of Price Accordion Setup -->

    <!-- Start of Deal Accordion Setup -->
    <ion-row
      v-if="categoryAggregations?.count_of_sales > 0"
      class="collapsable-menu-custom"
    >
      <ion-col>
        <ion-list>
          <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
            <ion-item
              id="TestA_plp_filter_deal"
              class="ion-item-test filter-item-head"
              lines="none"
              :class="{ active: isChildShown('deal') }"
              @click="toggleParent('deal')"
            >
              <div class="text-icon">
                <div class="ion-text-uppercase parent-text">
                  Deal
                </div>
                <div class="d-flex">
                  <span class="ion-text-uppercase selected-search ion-padding-end">{{
                    hasFilter("sale", '1') ? "sale" : ""
                  }}</span>
                  <span :class="!isChildShown('deal') ? 'arrow-drop  rotate-caret' : 'arrow-drop'">
                    <img
                      alt="Trailers Near Me"
                      src="@/assets/icons/caretDownBlue.svg"
                    >
                  </span>
                </div>
              </div>
            </ion-item>

            <ion-item
              v-for="(item, index) in deals"
              v-show="isChildShown('deal')"
              :key="item"
              :href="item.href"
              lines="none"
              class="filter-collapse"
              @click="toggleSelectedDeal($event, index)"
            >
              <ion-checkbox
                slot="start"
                ref="deal"
                :value="item.key"
                :checked="!!hasFilter(item.key, '1')"
                class="filter-checkbox"
              />
              <ion-label class="ion-text-uppercase child-text">
                {{ item.key }}
              </ion-label>
            </ion-item>
          </div>
        </ion-list>
      </ion-col>
    </ion-row>
    <!-- End of Deal Accordion Setup -->

    <StatusFilter
      :active="isChildShown('availability')"
      @toggle="toggleParent('availability')"
    />

    <CountryFilter
      :active="isChildShown('location_country')"
      @toggle="toggleParent('location_country')"
    />

    <!-- Start of Condition Accordion Setup -->
    <ion-row class="collapsable-menu-custom">
      <ion-col>
        <ion-list>
          <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
            <ion-item
              lines="none"
              class="filter-item-head"
              :class="{ active: isChildShown(aggregations?.condition) }"
              @click="toggleParent(aggregations?.condition)"
            >
              <div class="text-icon">
                <div class="ion-text-uppercase parent-text">
                  Condition
                </div>
                <div class="d-flex">
                  <span class="ion-text-uppercase selected-search ion-padding-end">
                    {{ showCondition }}
                  </span>
                  <span :class="!isChildShown(aggregations?.condition) ? 'arrow-drop  rotate-caret' : 'arrow-drop'">
                    <img
                      alt="locationIcon"
                      src="@/assets/icons/caretDownBlue.svg"
                    >
                  </span>
                </div>
              </div>
            </ion-item>
            <template
              v-for="(item, index) in conditions"
              :key="index"
            >
              <ion-item
                v-if="isChildShown(aggregations?.condition)"
                :href="item.href"
                lines="none"
                class="filter-collapse"
                @click="toggleFilter($event, 'condition', index)"
              >
                <ion-checkbox
                  slot="start"
                  ref="condition"
                  :value="item.key"
                  :checked="!!hasFilter('condition', item.key)"
                  class="filter-checkbox"
                />
                <ion-label class="ion-text-uppercase child-text">
                  {{ item.key }}
                </ion-label>
              </ion-item>
            </template>
          </div>
        </ion-list>
      </ion-col>
    </ion-row>
    <!-- End of Condition Accordion Setup -->
  </div>
</template>

<script>
import SizeFilter from "./SizeFilter.vue";
import GvwrFilter from "./GvwrFilter.vue";
import PriceFilter from "./PriceFilter.vue";
import YearFilter from "./YearFilter.vue";
import AxlesFilter from "./AxlesFilter.vue";
import PayloadCapacityFilter from "./PayloadCapacityFilter.vue";
import ConstructionFilter from "./ConstructionFilter.vue";
import ValueFilter from "../../models/filters/value/ValueFilter";
import StatusFilter from "./StatusFilter.vue";
import BodyTypeFilter from "./BodyTypeFilter.vue";
import CabToAxleLengthFilter from "./CabToAxleLengthFilter.vue";
import NumberOfTruckAxlesFilter from "./NumberOfTruckAxlesFilter.vue";
import SkirtedBedFilter from "./SkirtedBedFilter.vue";
import PullType from "./PullType.vue";
import CountryFilter from "./CountryFilter.vue";
import { IonCheckbox, IonChip, IonCol, IonIcon, IonItem, IonLabel, IonList, IonRow, IonInput, menuController } from "@ionic/vue";
import { chevronForwardOutline, closeSharp, searchOutline } from "ionicons/icons";
import { mapActions, mapState } from "vuex";
import { ellipsizeList } from "@/utils/FilterListUtil";
import { getGlossary } from "@/services/ProductsService";
import SaveSearch from "./SaveSearch.vue";
import { modal, filterActions, domain } from "@/mixins";
import { MENU_CONTROLLER } from "@/consts/menus";
import { pathFromQueryParams } from "@/utils/UrlParamUtil";
import { scrollPlpToTop } from "@/utils/ProductListingPageUtil";
import { tcCategoryMapping } from "@/consts";
import ManufacturerFilter from "./ManufacturerFilter.vue";
import ColorFilter from "./ColorFilter.vue";

export default {
  name: "FilterList",
  components: {
    ColorFilter,
    ManufacturerFilter,
    SkirtedBedFilter,
    NumberOfTruckAxlesFilter,
    CabToAxleLengthFilter,
    BodyTypeFilter,
    AxlesFilter,
    ConstructionFilter,
    PullType,
    PayloadCapacityFilter,
    SizeFilter,
    GvwrFilter,
    PriceFilter,
    YearFilter,
    StatusFilter,
    CountryFilter,
    IonCol,
    IonRow,
    IonChip,
    IonLabel,
    IonIcon,
    IonList,
    IonCheckbox,
    IonItem,
    IonInput,
  },
  mixins: [modal, filterActions, domain],
  data() {
    return {
      moreLimit: 6,
      data: true,
      closeSharp,
      seeMoreCategory: false,
      shownGroup: null,
      chevronForwardOutline,
      glossaryArray: [],
      searchOutline,
      searchKeyword: "",
    };
  },
  computed: {
    ...mapState({
      searchApiFields: (state) => state.searchApiFields,
      totalProducts: (state) => state.totalProducts,
      breadCrumb: (state) => state.pageTitle,
      aggregations: (state) => state.typeAggregations,
      categoryAggregations: (state) => state.categoryAggregations,
      megaMenuList: (state) => state.megaMenuList,
      type_id: (state) => state.searchApiFields.type_id,
      searchFilter: (state) => state.searchFilter,
      categoryList: (state) =>
        state.searchFilter
          .filter((item) => item.name === "category")
          .map((item) => item.value),
    }),
    isSearchFilterEmpty() {
      return this.searchFilter.length === 0;
    },
    showCategory() {
      return ellipsizeList(this.categoryList, 14);
    },
    showCondition() {
      const conditionList = this.$store.state.searchFilter
        .filter((item) => item.name === "condition")
        .map((item) => item.label);
      return ellipsizeList(conditionList, 14);
    },
    showConstructionFilter() {
      return Boolean(this.aggregations?.construction?.buckets?.length);
    },
    isAllTypes() {
      return this.type_id === "all";
    },
    isTruckBed() {
      return this.type_id === 5;
    },
    categories() {
      const { categoryAggregations, megaMenuList, searchApiFields } = this.$store.state;
      const categories = categoryAggregations.filter_aggregations?.category?.buckets || [];
      const categoryNames = categories.map((cat) => tcCategoryMapping[cat.key]?.category || '');
      const item = megaMenuList.find((item) => +item.id === +searchApiFields.type_id);
      return item
        ? item.categories
            .map((item) => item.name)
            .filter((category) => categoryNames.includes(category))
            .map((category) => {
              const updatedPath = this.generateHrefFromField(category, "category");
              return { name: category, href: updatedPath };
            })
        : [];
    },
    deals() {
      return [
        {
          key: "sale",
          href: this.generateHrefFromField('1', "sale"),
        },
      ];
    },
    conditions() {
      return this.categoryAggregations?.filter_aggregations?.condition?.buckets?.map(({ key }) => {
        return {
          key,
          href: this.generateHrefFromField(key, "condition"),
        };
      });
    },
  },
  watch: {
    "$store.state.typeAggregations": function () {
      this.fillCategories();
    },
  },
  mounted() {
    this.fillCategories();
    this.filterGlossary();
  },
  methods: {
    ...mapActions(["addSearchFilter", "clearSearchFilter", "clearAllSearchFilter"]),
    isChildShown(group) {
      return this.shownGroup === group;
    },
    toggleParent(group) {
      if (this.isChildShown(group)) {
        this.shownGroup = null;
      } else {
        this.shownGroup = group;
      }
      if (this.shownGroup === null && this.categoryList.length === 0) {
        this.seeMoreCategory = false;
      }
    },
    toggleCategories(value) {
      this.seeMoreCategory = value;
    },
    toggleSelectedDeal($event, index) {
      $event.preventDefault();
      const checked = this.$refs.deal[index].checked;
      if (!checked) {
        this.addSearchFilter(
          new ValueFilter({
            name: 'sale',
            value: '1',
            label: 'sale',
          }),
        );
      } else if (checked) {
        this.clearSearchFilter({name: 'sale', value: '1'});
      }

      scrollPlpToTop();
    },
    fillCategories() {
      if (this.shownGroup === null && this.categoryList.length === 0) {
        this.seeMoreCategory = false;
      }
      this.seeMoreCategory = this.categoryList.some(
        (category) => this.categories.findIndex((e) => e === category) >= this.moreLimit,
      );
    },
    async filterGlossary() {
      this.glossaryArray = await getGlossary();
    },
    async saveSearch() {
      await menuController.close(MENU_CONTROLLER.FilterContent);
      await this.openModal(SaveSearch, {
        cssClass: "modal-header-save-search",
        backdropDismiss: true,
      });
    },
    clearAllHref() {
      let params = {
        type_id: this.$store.state.searchApiFields.type_id,
        location: this.$store.state.searchApiFields.location,
        lat: this.$store.state.searchApiFields.lat,
        lon: this.$store.state.searchApiFields.lon,
      };
      let formattedPath = pathFromQueryParams(params);
      return `${this.domainOrigin}/trailers-for-sale/${formattedPath}`;
    },
    preventLoad(ev) {
      if (ev) {
        ev.preventDefault();
      }
    },
    enterKeyword() {
      if(this.searchKeyword.length > 2) {
        this.clearSearchFilter({ name: "search", value: this.$store.state.searchApiFields?.search});
        this.addSearchFilter(
          new ValueFilter({
            name: "search",
            value: this.searchKeyword,
            label: `Keyword - ${this.searchKeyword}`,
          }),
        );
        this.searchKeyword = "";

        scrollPlpToTop();
      }
    },
    handleClearAll(event) {
      this.preventLoad(event);

      this.clearAllSearchFilter();

      scrollPlpToTop();
    },
    searchLabel(search) {
      switch (search.name) {
          // Add more group matching here if we want to customize other fields
        case "axles":
          return `${search.label} axle(s)`;
        case "skirted_bed":
          return search.value === '0' ? 'No Skirted Bed' : 'Has Skirted Bed';
        case 'cab_to_axle_length':
          return `${search.label} Cab To Axle`;
        case 'number_of_truck_axles':
          return `${search.label} axle(s)`;
      }
      return search.label;
    },
    hasFilterAggregations(filter) {
      return !!this.categoryAggregations?.filter_aggregations?.[filter]?.buckets?.length;
    },
  },
};
</script>
