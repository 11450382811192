import { geocode } from "@/services/LocationService";

export default {
  data() {
    return {
      address: "",
      dealerAddress: "",
    };
  },
  methods: {
    getAddress(data) {
      this.address = `${data?.city}, ${data?.region}`;
    },
    getDealerAddress(data) {
      this.dealerAddress = `${data?.city} ${data?.region}`;
    },
    async getSellerGeoCode(address = this.address) {
      if (address) {
        const getGeoCode = await geocode({
          q: address,
        });
        if (getGeoCode.data?.data?.[0]) {
          return {
            lat: parseFloat(getGeoCode.data.data[0].position.lat),
            lng: parseFloat(getGeoCode.data.data[0].position.lng),
          };
        }
        return {
          lat: "",
          lng: "",
        };
      }
    },
  },
};
