<template>
  <!-- Start of Side Menu Navigation -->
  <teleport to="head">
    <!-- eslint-disable -->
    <component :is="'script'" type="application/ld+json" v-html="JSON.stringify(breadCrumbStructuredSchema)"></component>
  </teleport>
  <ion-menu
    v-if="activeMenu === 'navigation'"
    swipe-gesture="false"
    side="end"
    menu-id="appContent"
    content-id="main-content"
    class="sideBar-toggle"
  >
    <ion-toolbar
      transparent
      class="ios-css"
    >
      <ion-title>
        <img
          v-if="expandedMenu"
          style="margin-top: 14px"
          alt="arrowIconBack"
          src="@/assets/icons/arrowBack.svg"
          width="16"
          height="16"
          @click="hideMegaMenu"
        >
        <ion-button
          color="transparent"
          class="close-btn ion-no-padding"
          @click="closeMenu"
        >
          <ion-icon
            class="close-side-toggle"
            aria-label="close side toggle"
            :icon="closeSharp"
          />
        </ion-button>
      </ion-title>
    </ion-toolbar>
    <ion-content>
      <ion-list>
        <ion-label
          style="margin-left: 16px"
          class="ion-text-uppercase header-text"
        >
          {{ expandedMenu }}
        </ion-label>

        <template v-if="!expandedMenu">
          <top-menu
            @onHideSideMenu="hideSideMenu()"
            @onExpandUserProfile="selectMenu($event, 'User Account')"
          />
          <ion-item
            id="buytrailers_top_menu"
            class="header-item item-stable"
            :class="{ active: expandedMenu === 'Buy Trailers' }"
            @click="selectMenu($event, 'Buy Trailers')"
          >
            <ion-label
              id="buytrailers_top_menu"
              class="ion-text-uppercase header-text"
            >
              Buy
            </ion-label>
            <span>
              <img
                alt="arrowIcon"
                src="@/assets/icons/arrowIconBlack.svg"
                width="16"
                height="16"
              >
            </span>
          </ion-item>
          <ion-item
            v-if="isAuthenticated"
            id="selltrailers_top_menu"
            class="header-item item-stable"
            href="/dashboard"
            @click="goToDashboard($event)"
          >
            <ion-label class="ion-text-uppercase header-text">
              SELL on TRAILERTRADER
            </ion-label>
            <span>
              <img
                alt="arrowIcon"
                src="@/assets/icons/arrowIconBlack.svg"
                width="16"
                height="16"
              >
            </span>
          </ion-item>
          <ion-item
            v-if="!isAuthenticated"
            id="selltrailers_top_menu"
            class="header-item item-stable"
            :class="{ active: expandedMenu === 'SELL ON TRAILERTRADER' }"
            @click="selectMenu($event, 'SELL ON TRAILERTRADER')"
          >
            <ion-label
              id="selltrailers_top_menu"
              class="ion-text-uppercase header-text"
            >
              SELL on TRAILERTRADER
            </ion-label>
            <span>
              <img
                alt="arrowIcon"
                src="@/assets/icons/arrowIconBlack.svg"
                width="16"
                height="16"
              >
            </span>
          </ion-item>
          <ion-item
            id="resources_top_menu"
            class="header-item item-stable"
            :class="{ active: expandedMenu === 'Help' }"
            @click="selectMenu($event, 'Help')"
          >
            <ion-label
              id="resources_top_menu"
              class="ion-text-uppercase header-text"
            >
              Help
            </ion-label>
            <span>
              <img
                alt="arrowIcon"
                src="@/assets/icons/arrowIconBlack.svg"
                width="16"
                height="16"
              >
            </span>
          </ion-item>
        </template>
        <!-- Start of Accordion Setup -->
        <ion-row
          v-if="expandedMenu && expandedMenu === 'Buy Trailers'"
          id="buytrailers_top_menu"
          class="collapsable-menu-custom mega-menu"
        >
          <ion-col size="12">
            <div
              v-for="menu in megaMenuList"
              :id="megaMenuType(menu)"
              :key="menu.name"
            >
              <ion-item
                :href="`${domainOrigin}/trailers-for-sale/${menu.href}`"
                :class="{ active: activeType === menu.name }"
                @click="selectType($event, menu, true)"
              >
                <div class="text-icon">
                  <div class="ion-text-uppercase parent-text">
                    {{ menu.name }}
                  </div>
                  <span :class="activeType === menu.name ? 'arrow-drop' : 'arrow-drop rotate-caret'">
                    <img
                      alt="Trailers Near Me"
                      src="@/assets/icons/caretDownBlue.svg"
                      width="16"
                      height="16"
                    >
                  </span>
                </div>
              </ion-item>
              <ion-item
                v-for="category in categoriesList"
                v-if="activeType === menu.name"
                :id="categoryType(category)"
                :key="category"
                :href="category.href"
                @click="goToSelectedCategory($event, category.name)"
              >
                <div class="menu-image-mobile-parent">
                  <img
                    :src="category.image.image_url"
                    class="menu-image-mobile"
                    :alt="category.name"
                    width="68"
                    height="45"
                  >
                </div>
                <ion-label class="menu-type-mobile">
                  {{ category.name }}
                </ion-label>
              </ion-item>
            </div>
          </ion-col>
          <ion-col style="margin-top: 18px">
            <div
              class="type-info"
              :style="{
                'background-image': !!activeCategory ? 'unset' : `url(${typePlaceholder})`,
              }"
              :class="{
                'type-selected': !!activeCategory,
                inactive: !activeCategory,
              }"
            >
              <div class="type-selected-box-image">
                <img
                  v-if="!!activeCategory"
                  class="type-image"
                  :src="activeCategoryDetails.image ? activeCategoryDetails.image.image_url : ''"
                  :alt="activeCategoryDetails.id"
                  width="68"
                  height="45"
                >
              </div>
              <div :class="{ 'default-info': !activeCategory }">
                <p class="type-title ion-text-uppercase">
                  {{ activeCategoryDetails.name }}
                </p>
                <p
                  v-if="activeCategory"
                  class="type-description ion-no-margin"
                >
                  {{ activeCategoryDetails.description || "Description for Trailer" }}
                </p>
                <a
                  v-else
                  href="javascript:void(0)"
                  class="ion-no-margin ion-text-uppercase h3"
                >
                  <span />
                </a>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row
          v-if="expandedMenu && expandedMenu === 'SELL ON TRAILERTRADER'"
          id="selltrailers_top_menu"
          class="collapsable-menu-custom mega-menu"
        >
          <ion-col size="12">
            <ion-item
              id="privateseller_sell_top_menu"
              @click="goToPage('/privatesellers', $event)"
            >
              <div class="ion-text-uppercase parent-text">
                <a :href="`${domainOrigin}/privatesellers`">as a PRIVATE SELLER</a>
              </div>
            </ion-item>
            <ion-item
              id="dealers_sell_top_menu"
              @click="goToPage('/dealers', $event)"
            >
              <div class="ion-text-uppercase parent-text">
                <a :href="`${domainOrigin}/dealers`">as a DEALER</a>
              </div>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row
          v-if="expandedMenu && expandedMenu === 'Help'"
          id="resources_top_menu"
          class="collapsable-menu-custom mega-menu"
        >
          <ion-col size="12">
            <ion-item
              id="faq_resources_top_menu"
              @click="goToPage('/faq', $event)"
            >
              <div class="ion-text-uppercase parent-text">
                <a :href="`${domainOrigin}/faq`">FAQ</a>
              </div>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item
              id="guide_resources_top_menu"
              @click="goToPage('/articles/what-features-should-you-consider-when-buying-a-trailer', $event)"
            >
              <div class="ion-text-uppercase parent-text">
                <a :href="`${domainOrigin}/articles/what-features-should-you-consider-when-buying-a-trailer`">BUYER'S GUIDE</a>
              </div>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item
              id="guide_for_used_trailers_resources_top_menu"
              @click="goToPage('/articles/buyers-guide-for-used-trailers', $event)"
            >
              <div class="ion-text-uppercase parent-text">
                <a :href="`${domainOrigin}/articles/buyers-guide-for-used-trailers`">Used Trailers</a>
              </div>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item
              id="tow_guide_top_menu"
              @click="goToPage('/articles/tow-guide', $event)"
            >
              <div class="ion-text-uppercase parent-text">
                <a :href="`${domainOrigin}/articles/tow-guide`">Tow Guide</a>
              </div>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item
              id="vin_check_top_menu"
              @click="goToPage('/articles/ask-for-vin-and-check', $event)"
            >
              <div class="ion-text-uppercase parent-text">
                <a :href="`${domainOrigin}/articles/ask-for-vin-and-check`">VIN CHECK</a>
              </div>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item
              id="abouttt_resources_top_menu"
              @click="goToPage('/about', $event)"
            >
              <div class="ion-text-uppercase parent-text">
                <a :href="`${domainOrigin}/about`">ABOUT TRAILERTRADER</a>
              </div>
            </ion-item>
          </ion-col>
        </ion-row>

        <!-- User Dashboard -->
        <ion-row
          v-if="expandedMenu && expandedMenu === 'User Account'"
          id="resources_top_menu"
          class="collapsable-menu-custom mega-menu"
        >
          <ion-col size="12">
            <ion-item
              id="faq_resources_top_menu"
              :href="`${domainOrigin}/dashboard`"
              @click="goToDashboard($event)"
            >
              <div class="ion-text-uppercase parent-text">
                DashBoard
              </div>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item
              id="faq_resources_top_menu"
              :href="`${domainOrigin}/saved-searches`"
              @click="goToSavedSearches($event)"
            >
              <div class="ion-text-uppercase parent-text">
                Save Searches
              </div>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item
              id="faq_resources_top_menu"
              :href="`${domainOrigin}/settings`"
              @click="goToSettings($event)"
            >
              <div class="ion-text-uppercase parent-text">
                Settings
              </div>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item
              id="abouttt_resources_top_menu"
              @click="logout"
            >
              <div class="ion-text-uppercase parent-text">
                LogOut
              </div>
            </ion-item>
          </ion-col>
        </ion-row>
        <!-- End of Accordion Setup -->
        &nbsp;
        <ion-row style="height: 15px" />
        <template v-if="!expandedMenu">
          <ion-item
            lines="none"
            class="mobileMenuHelp"
            @click="goToPage('/about', $event)"
          >
            <ion-label class="ion-text-uppercase contact-text">
              <a :href="`${domainOrigin}/about`">Submit Feedback</a>
            </ion-label>
          </ion-item>
        </template>
      </ion-list>
    </ion-content>
    <!-- Start LocationBarForm popup section -->
    <ion-row
      v-show="showForm"
      class="LocationBarForm-popup"
    >
      <LocationBarForm
        v-if="showForm"
        @is-open="openCloseForm"
      />
    </ion-row>
    <!-- End LocationBarForm popup section -->
  </ion-menu>
  <template v-else-if="activeMenu === 'filters'">
    <FiltersResponsiveLayout />
  </template>
  <template v-if="activeMenu === 'mapViewFilters'">
    <FiltersMapView />
  </template>
  <!-- End of Side Menu Navigation -->
  <ion-header
    class="ion-no-border header-background ion-no-padding"
    :translucent="true"
  >
    <ion-grid
      v-if="isDesktop"
      class="grid-desktop topBar"
    >
      <ion-row class="content-container lr-padding">
        <!-- begin logo image -->
        <ion-col
          size="auto"
          class="ion-float-left"
          style="margin-right: 60px; padding-left: 0"
        >
          <a :href="`${domainOrigin}/home`">
            <img
              id="trailertrader_logo"
              alt="TrailerTraderLogo"
              class="img-logo-Desktop"
              src="@/assets/TrailerTraderLogo.webp"
              width="189"
              height="16"
              @click="goToHome($event)"
            >
          </a>
        </ion-col>
        <!-- Begin Middle Navigation -->
        <ion-col
          id="buytrailers_top_menu"
          size="auto"
          class="ion-text-start menu-item ion-float-left"
          :class="{
            active: expandedMenu === 'BUY TRAILERS',
            'active-hover': expandedHoverMenu === 'BUY TRAILERS',
          }"
          @mouseenter="selectMenuHover('BUY TRAILERS')"
          @mouseleave="hideSelectMenuHover('BUY TRAILERS')"
          @click="selectMenu($event, 'BUY TRAILERS')"
        >
          <div
            id="buytrailers_top_menu"
            class="nav-item"
          >
            BUY
            <span>
              <img
                class="img-icon-nav"
                alt="caretDown"
                src="@/assets/icons/caretDown.svg"
                width="16"
                height="16"
              >
            </span>
          </div>
        </ion-col>
        <ion-col
          v-if="isAuthenticated"
          id="selltrailers_top_menu"
          size="auto"
          class="ion-text-center menu-item ion-float-left"
          :class="{
            'active-hover': hoveringMenu === 'SELL ON TRAILERTRADER',
          }"
          @mouseover="hoveringOverMenu('SELL ON TRAILERTRADER')"
          @mouseout="hoveringOverMenu(null)"
          @click="goToDashboard($event)"
        >
          <div
            id="selltrailers_top_menu"
            class="nav-item"
          >
            SELL on TRAILERTRADER
            <span>
              <img
                class="img-icon-nav"
                alt="caretDown"
                src="@/assets/icons/caretDown.svg"
                width="16"
                height="16"
              >
            </span>
          </div>
        </ion-col>
        <ion-col
          v-if="!isAuthenticated"
          id="selltrailers_top_menu"
          size="auto"
          class="ion-text-center menu-item ion-float-left"
          :class="{
            active: expandedMenu === 'SELL ON TRAILERTRADER',
            'active-hover': expandedHoverMenu === 'SELL ON TRAILERTRADER',
          }"
          @mouseenter="selectMenuHover('SELL ON TRAILERTRADER')"
          @mouseleave="hideSelectMenuHover('SELL ON TRAILERTRADER')"
          @click="selectMenu($event, 'SELL ON TRAILERTRADER')"
        >
          <div
            id="selltrailers_top_menu"
            class="nav-item"
          >
            SELL on TRAILERTRADER
            <span>
              <img
                class="img-icon-nav"
                alt="caretDown"
                src="@/assets/icons/caretDown.svg"
                width="16"
                height="16"
              >
            </span>
          </div>
        </ion-col>
        <ion-col
          id="resources_top_menu"
          size="auto"
          class="ion-text-start menu-item ion-float-left"
          :class="{
            active: expandedMenu === 'Help',
            'active-hover': expandedHoverMenu === 'Help',
          }"
          @mouseenter="selectMenuHover('Help')"
          @mouseleave="hideSelectMenuHover('Help')"
          @click="selectMenu($event, 'Help')"
        >
          <div class="nav-item">
            Help
            <span>
              <img
                class="img-icon-nav"
                alt="caretDown"
                src="@/assets/icons/caretDown.svg"
                width="16"
                height="16"
              >
            </span>
          </div>
        </ion-col>
        <!-- Begin Right Navigation -->
        <ion-col class="ion-text-end ion-float-right ion-no-padding">
          <ion-row class="ion-justify-content-end">
            <ion-col
              size="auto"
              class="search-icon"
            >
              <div class="location-box">
                <div class="nav-item">
                  <span>
                    <img
                      alt="search-icon"
                      class="img-icon-nav"
                      src="@/assets/icons/search.svg"
                      width="16"
                      height="16"
                    >
                  </span>
                </div>
              </div>
            </ion-col>
            <ion-col size="auto">
              <div
                id="selectlocation_top_menu"
                class="location-box"
                @click="openCloseFormDesktop"
              >
                <div class="nav-item">
                  <span>
                    <img
                      class="img-icon-nav"
                      alt="Trailers Near Me"
                      src="@/assets/icons/location.svg"
                      width="16"
                      height="16"
                    >
                  </span>
                  {{ location }}
                  <span :class="!showFormDesktop ? '' : 'rotate-caret'">
                    <img
                      class="img-icon-nav"
                      alt="caretDown"
                      src="@/assets/icons/caretDown.svg"
                      width="16"
                      height="16"
                    >
                  </span>
                  <div class="distance">
                    {{ distance }}
                  </div>
                </div>
              </div>
            </ion-col>
            <ion-col
              v-click-outside="hideProfile"
              size="auto"
            >
              <top-menu />
            </ion-col>
          </ion-row>
        </ion-col>
        <!-- End Right Navigation -->
      </ion-row>
    </ion-grid>
    <ion-grid
      v-if="!isDesktop"
      id="mobile-topBar"
      class="grid-mobile topBar"
    >
      <ion-row class="ion-border">
        <!-- begin logo image -->
        <ion-col size="6">
          <a :href="`${domainOrigin}/home`">
            <img
              alt="TrailerTraderLogo"
              class="img-logo-mobile"
              src="@/assets/TrailerTraderLogo.webp"
              width="189"
              height="16"
              @click="goToHome($event)"
            >
          </a>
        </ion-col>
        <!-- End logo Image -->
        <ion-col size="4" />
        <ion-col
          size="1"
          class="ion-text-end"
        >
          <img
            alt="search-icon"
            class="img-nav-mobile search-icon-img"
            src="@/assets/icons/search.svg"
            width="16"
            height="16"
          >
        </ion-col>
        <!-- Start of Code Clicking on Hamburger Opens SideMenu -->
        <ion-col
          id="main-content"
          size="1"
          class="ion-text-start"
          @click="openMenu"
        >
          <img
            alt="search-icon"
            class="img-nav-mobile"
            src="@/assets/icons/menuList.svg"
            width="16"
            height="16"
          >
        </ion-col>
        <!-- End of Code for Clicking on Hamburger Opens SideMenu -->
      </ion-row>
      <ion-row class="ion-text-nowrap">
        <!-- begin mobile bottom nav -->
        <ion-col
          size="auto"
          class=""
        >
          <span>
            <img
              class="img-nav-location-mobile"
              alt="Trailers Near Me"
              src="@/assets/icons/location.svg"
              width="16"
              height="16"
            >
          </span>
        </ion-col>
        <ion-col
          size="6"
          class=""
          @click="openCloseFormMobile"
        >
          <div
            id="selectlocation_top_menu"
            class="ion-text-start nav-item-mobile"
          >
            {{ location }}
          </div>
        </ion-col>
        <!-- End -->
        <ion-col @click="openCloseFormMobile">
          <div class="ion-text-end nav-item-distance-mobile">
            {{ distance }}
          </div>
        </ion-col>
        <ion-col
          size="1"
          style="margin-top: 3px"
          class="ion-text-start"
          @click="openCloseFormMobile"
        >
          <span :class="!showFormMobile ? '' : 'rotate-caret'">
            <img
              class="img-nav-caret-mobile"
              alt="Trailers Near Me"
              src="@/assets/icons/caretDown.svg"
              width="16"
              height="16"
            >
          </span>
        </ion-col>
        <ion-col size="12">
          <LocationBarForm
            v-if="showFormMobile"
            @is-open="openCloseFormMobile"
          />
        </ion-col>
      </ion-row>
    </ion-grid>
    <!-- Desktop Megamenu -->
    <template v-if="isDesktop">
      <ion-grid
        v-if="showMenus && expandedMenu === 'BUY TRAILERS'"
        id="buytrailers_top_menu"
        v-click-outside="hideMegaMenu"
        fixed
        class="w-100 ion-no-padding mega-menu"
      >
        <ion-row
          v-if="showMenus"
          class="ion-no-padding mega-menu-row"
        >
          <ion-col
            size="2"
            class="ion-align-self-start mega-menu-categories"
          >
            <a
              v-for="menu in megaMenuList"
              :id="megaMenuType(menu)"
              :key="menu.name"
              expand="full"
              :class="{ active: activeType === menu.name }"
              class="no-ripple ion-no-margin menu ionic-button-custom"
              fill="clear"
              :href="`${domainOrigin}/trailers-for-sale/${menu.href}`"
              @mouseenter="selectType($event, menu, false)"
              @click="goToSelectedType($event, '')"
            >
              <span
                slot="start"
                name="star"
              >{{ menu.name }}</span>
            </a>
          </ion-col>
          <ion-col size="7">
            <div class="types-menu">
              <a
                v-for="category in categoriesList"
                :id="categoryType(category)"
                :key="category.id"
                :href="category.href"
                class="ion-text-no-underline type-name"
                @mouseenter="activeCategory = category.name"
                @mouseleave="activeCategory = ''"
                @click="goToSelectedCategory($event, category.name)"
              >
                {{ category.name }}
                <span><img
                  alt="arrowIcon"
                  src="@/assets/arrowIcon.svg"
                  width="16"
                  height="16"
                > </span>
              </a>
            </div>
          </ion-col>
          <ion-col size="3">
            <div
              class="type-info"
              :style="{
                'background-image': !!activeCategory ? 'unset' : `url(${typePlaceholder})`,
              }"
              :class="{
                'type-selected': !!activeCategory,
                inactive: !activeCategory,
              }"
            >
              <div class="type-selected-box-image">
                <img
                  v-if="!!activeCategory"
                  class="type-image"
                  :src="activeCategoryDetails.image ? activeCategoryDetails.image.image_url : ''"
                  :alt="activeCategoryDetails.id"
                  width="68"
                  height="45"
                >
              </div>
              <div :class="{ 'default-info': !activeCategory }">
                <p class="type-title">
                  {{ activeCategoryDetails.name }}
                </p>
                <p
                  v-if="activeCategory"
                  class="type-description ion-no-margin"
                >
                  {{ activeCategoryDetails.description || "Description for Trailer" }}
                </p>
                <a
                  v-else
                  href="javascript:void(0)"
                  class="ion-no-margin ion-text-uppercase h3"
                >
                  <span />
                </a>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid
        v-if="showMenus && expandedMenu === 'SELL ON TRAILERTRADER'"
        id="selltrailers_top_menu"
        v-click-outside="hideMegaMenu"
        fixed
        :style="{ left: `${selectedMenuLeftDistance - 17}px` }"
        class="w-100 ion-no-padding mega-menu sell-trailers"
      >
        <ion-row class="bg-white row-sell-trailers md hydrated">
          <ion-toolbar class="md in-toolbar hydrated">
            <a
              id="privateseller_sell_top_menu"
              :href="`${domainOrigin}/privatesellers`"
              class="ion-text-start sub-header"
              @click="goToPage('/privatesellers', $event)"
            >
              as a PRIVATE SELLER
              <span>
                <img
                  alt="arrowIcon"
                  src="@/assets/arrowIcon.svg"
                  width="16"
                  height="16"
                >
              </span>
            </a>
            <a
              id="dealers_sell_top_menu"
              :href="`${domainOrigin}/dealers`"
              class="ion-text-start sub-header"
              @click="goToPage('/dealers', $event)"
            >
              as a DEALER
              <span>
                <img
                  alt="arrowIcon"
                  src="@/assets/arrowIcon.svg"
                  width="16"
                  height="16"
                >
              </span>
            </a>
          </ion-toolbar>
        </ion-row>
      </ion-grid>
      <ion-grid
        v-if="showMenus && expandedMenu === 'Help'"
        id="resources_top_menu"
        v-click-outside="hideMegaMenu"
        fixed
        class="w-100 ion-no-padding mega-menu resources"
      >
        <ion-row
          class="bg-white row-resources md hydrated"
          :style="{ left: `${selectedMenuLeftDistance - 40}px` }"
        >
          <ion-toolbar class="md in-toolbar hydrated">
            <a
              id="faq_resources_top_menu"
              :href="`${domainOrigin}/faq`"
              class="ion-text-start sub-header"
              @click="goToPage('/faq', $event)"
            >
              FAQ
              <span>
                <img
                  alt="arrowIcon"
                  src="@/assets/arrowIcon.svg"
                  width="16"
                  height="16"
                >
              </span>
            </a>
            <a
              id="guide_resources_top_menu"
              :href="`${domainOrigin}/articles/what-features-should-you-consider-when-buying-a-trailer`"
              class="ion-text-start sub-header"
              @click="goToPage('/articles/what-features-should-you-consider-when-buying-a-trailer', $event)"
            >
              BUYER'S GUIDE
              <span>
                <img
                  alt="arrowIcon"
                  src="@/assets/arrowIcon.svg"
                >
              </span>
            </a>
            <a
              id="guide_for_used_trailers_resources_top_menu"
              :href="`${domainOrigin}/articles/buyers-guide-for-used-trailers`"
              class="ion-text-start sub-header"
              @click="goToPage('/articles/buyers-guide-for-used-trailers', $event)"
            >
              USED TRAILERS
              <span>
                <img
                  alt="arrowIcon"
                  src="@/assets/arrowIcon.svg"
                >
              </span>
            </a>
            <a
              id="tow_guide_top_menu"
              :href="`${domainOrigin}/articles/tow-guide`"
              class="ion-text-start sub-header"
              @click="goToPage('/articles/tow-guide', $event)"
            >
              TOW GUIDE
              <span>
                <img
                  alt="arrowIcon"
                  src="@/assets/arrowIcon.svg"
                >
              </span>
            </a>
            <a
              id="vin_check_top_menu"
              :href="`${domainOrigin}/articles/ask-for-vin-and-check`"
              class="ion-text-start sub-header"
              @click="goToPage('/articles/ask-for-vin-and-check', $event)"
            >
              VIN CHECK
              <span>
                <img
                  alt="arrowIcon"
                  src="@/assets/arrowIcon.svg"
                >
              </span>
            </a>
            <a
              id="abouttt_resources_top_menu"
              :href="`${domainOrigin}/about`"
              class="ion-text-start sub-header"
              @click="goToPage('/about', $event)"
            >
              ABOUT TRAILERTRADER
              <span>
                <img
                  alt="arrowIcon"
                  src="@/assets/arrowIcon.svg"
                  width="16"
                  height="16"
                >
              </span>
            </a>
          </ion-toolbar>
        </ion-row>
      </ion-grid>
    </template>
    <!-- Location Bar Form -->
    <div
      v-show="showFormDesktop"
      class="ion-hide-lg-down"
    >
      <LocationBarForm
        v-if="showFormDesktop"
        @is-open="openCloseFormDesktop"
      />
    </div>
  </ion-header>
  <!-- Start of Link for Category -->
  <ion-row
    v-if="isProductListing"
    id="TesA_plp_breadcrumb"
    :style="$store.state.toggleView ? 'margin-left: 15px' : ''"
    class="heading content-container"
  >
    <div>
      <a
        :href="`${domainOrigin}/home`"
        class="homepage text-no-decoration"
        @click="goToHome($event)"
      >Homepage</a>
      <a
        :href="`${domainOrigin}/trailers-for-sale/all-trailers-for-sale`"
        class="homepage text-no-decoration"
        @click="goToAllCategoriesPage($event)"
      >
        <img
          class="link-arrow-breadCrumb"
          alt="arrowIcon"
          src="@/assets/icons/arrowIconBlack.svg"
          width="16"
          height="16"
        >
        All Trailers
      </a>
      <a
        v-if="isAllTrailers !== 'all'"
        :href="typeIdHref"
        class="homepage text-no-decoration"
        @click="goToSelectedType($event)"
      >
        <img
          class="link-arrow-breadCrumb"
          alt="arrowIcon"
          src="@/assets/icons/arrowIconBlack.svg"
          width="16"
          height="16"
        >{{ breadCrumb }}</a>
      <span v-if="categoryName === undefined || categoryName === ''" />
      <a
        v-else-if="categoryName"
        class="homepage text-no-decoration"
        :href="categoryHref"
        @click="$event.preventDefault()"
      ><img
        class="link-arrow"
        alt="arrowIcon"
        src="@/assets/icons/arrowIconBlack.svg"
        width="16"
        height="16"
      > {{ categoryName }}</a>
    </div>
  </ion-row>
  <!-- End of Link for Category -->
  <!-- Start of BreadCrumb on Affiliates/All Manufacturers -->
  <ion-row
    v-if="isAffiliates || isAllManufacturers || isFindTypeNearYou"
    id="TesA_plp_affiliates"
    :style="$store.state.toggleView ? 'margin-left: 15px' : ''"
    class="heading content-container"
  >
    <span
      class="homepage"
      :class="{ 'ion-text-uppercase': isAllManufacturers }"
      @click="goToHome"
    >Home</span>
    <span v-if="isAffiliates">
      <img
        class="link-arrow-breadCrumb"
        alt="arrowIcon"
        src="@/assets/icons/arrowIconBlack.svg"
        width="16"
        height="16"
      >
      Trailer Trader Websites
    </span>
    <span v-if="isAllManufacturers">
      <img
        class="link-arrow-breadCrumb"
        alt="arrowIcon"
        src="@/assets/icons/arrowIconBlack.svg"
        width="16"
        height="16"
      >
      MANUFACTURERS ON TRAILERTRADER
    </span>
    <span v-if="isFindTypeNearYou">
      <img
        class="link-arrow-breadCrumb"
        alt="arrowIcon"
        src="@/assets/icons/arrowIconBlack.svg"
      >
      Find A {{ typeTitle }} Near You
    </span>
  </ion-row>
  <!-- End of BreadCrumb on Affiliates/All Manufacturers -->
</template>

<script>
import {
  IonHeader,
  IonGrid,
  IonRow,
  IonCol,
  menuController,
  IonIcon,
  IonButton,
  IonTitle,
  IonToolbar,
  IonItem,
  IonList,
  IonMenu,
  IonLabel,
  IonContent,
} from "@ionic/vue";
import { defineComponent } from "vue";
import LocationBarForm from "./LocationBarForm.vue";

import FiltersResponsiveLayout from "./filters/FiltersResponsiveLayout.vue";
import typePlaceholder from "@/assets/type-placeholder.webp";

import { reverseGeocode, ipToLocation, autocomplete } from "@/services/LocationService";
import { mapActions, mapState } from "vuex";
import { formatDistance } from "@/utils/DistanceUtil";
import {getTypeIdFromName, pathFromQueryParams} from "@/utils/UrlParamUtil";
import { NATIONWIDE_DISTANCE } from "@/consts/distance";
import FiltersMapView from "./filters/FiltersMapView.vue";
import { ACTIVE_MENU, MENU_CONTROLLER } from "@/consts/menus";
import LogoutConfirmation from "./user/LogoutConfirmation.vue";
import { modal, domain } from "../mixins";
import TopMenu from "@/components/user/TopMenu.vue";
import { closeSharp } from "ionicons/icons";
import GroupItemFilter from "../models/filters/group/GroupItemFilter";
import { isSSR } from "@/utils/SSRUtil";
import {TYPE_TITLES} from "@/consts/category";
import { SEO_FRIENDLY_URLS_CATEGORIES } from "../consts/category";

export default defineComponent({
  name: "Topbar",
  components: {
    IonIcon,
    IonHeader,
    IonCol,
    IonGrid,
    IonRow,
    IonButton,
    IonToolbar,
    IonItem,
    IonTitle,
    IonList,
    IonMenu,
    IonLabel,
    IonContent,
    LocationBarForm,
    FiltersResponsiveLayout,
    FiltersMapView,
    TopMenu,
  },
  mixins: [modal, domain],
  data() {
    return {
      typePlaceholder,
      closeSharp,
      showMenus: false,
      showForm: false,
      searchFilter: [],
      activeType: "",
      activeTypeId: null,
      activeCategory: "",
      expandedMenu: "",
      expandedHoverMenu: "",
      isPromptShown: false,
      selectedMenuLeftDistance: 0,
      hoveringMenu: null,
      breadCrumbStructuredSchema: {},
    };
  },
  computed: {
    isAllTrailers() {
      return this.typeId === "all" ? "all" : "";
    },
    megaMenuList() {
      return this.$store.state.megaMenuList.map((m, index) => {
        return {
          ...m,
          href: pathFromQueryParams({
            type_id: index + 1,
          }),
        };
      });
    },
    isProductListing() {
      return ["ProductListWithPath", "ProductList"].includes(this.$route.name);
    },
    isAffiliates() {
      return ["affiliates"].includes(this.$route.name);
    },
    isAllManufacturers() {
      return ["Manufacturers"].includes(this.$route.name);
    },
    isFindTypeNearYou() {
      return ["FindTypeNearYou"].includes(this.$route.name);
    },
    typeTitle() {
      const typeName = this.$route.params.type;
      return TYPE_TITLES[getTypeIdFromName(typeName)];
    },
    breadCrumb() {
      return this.$store.state.pageTitle;
    },
    categoryName() {
      return this.$store.state.searchApiFields.category?.split(";")[0] || undefined;
    },
    location() {
      return this.$store.state.location || "Select Location";
    },
    distance() {
      if (this.$store.state.distance === NATIONWIDE_DISTANCE) {
        return "Nationwide";
      } else {
        return `Within ${formatDistance(this.$store.state?.distance)}`;
      }
    },
    categoriesList() {
      return (
        this.megaMenuList
          .find((type) => type.name === this.activeType)
          ?.categories?.map((category) => {
          const categoryParams = {
            type_id: this.activeTypeId,
            category: category.name,
          };
          let categoryPath = pathFromQueryParams(categoryParams);
          return {
            ...category,
            href: `${this.domainOrigin}/trailers-for-sale/${categoryPath}`,
          };
        }) || []
      );
    },
    activeCategoryDetails() {
      const active = this.categoriesList?.find((category) => category.name === this.activeCategory) || {};
      return active;
    },
    typeIdHref() {
      if (this.typeId) {
        const typeIdParams = {
          type_id: String(this.typeId),
        };
        let typeIdPath = pathFromQueryParams(typeIdParams);
        return `${this.domainOrigin}/trailers-for-sale/${typeIdPath}`;
      }
      return "";
    },
    categoryHref() {
      const categoryParams = {
        type_id: String(this.typeId),
        category: this.categoryName,
      };
      let categoryPath = pathFromQueryParams(categoryParams);
      return `${this.domainOrigin}/trailers-for-sale/${categoryPath}`;
    },
    ...mapState({
      activeMenu: (state) => state.activeMenu,
      showOverlay: (state) => state.showOverlay,
      showFormDesktop: (state) => state.showLocationDesktop,
      showFormMobile: (state) => state.showLocationMobile,
      showProfile: (state) => state.showProfile,
      typeId: (state) => state.searchApiFields?.type_id,
      isAuthenticated: (state) => state.isAuthenticated,
    }),
  },
  watch: {
    showMenus(newVal) {
      this.$store.commit("SET_MEGAMENU_OVERLAY", newVal);
    },
    showFormDesktop(newVal) {
      this.$store.commit("SET_OVERLAY", newVal);
    },
    showFormMobile(newVal) {
      this.$store.commit("SET_OVERLAY", newVal);
    },
  },
  mounted() {
    const { location } = this.$store.state;

    if (!isSSR()) {
      const localStorageLocation = localStorage.getItem("location");

      if (
        location?.toLowerCase().trim().includes("undefined") ||
        localStorageLocation?.toLowerCase().trim().includes("undefined")
      ) {
        this.$store.dispatch("setLocation", "");
      }
    }

    this.getBrowserLocation();
    this.setBreadCrumbStructuredSchema();
  },
  methods: {
    ...mapActions(["clearAllSearchFilter"]),
    async getBrowserLocation() {
      const { location, userGeoLocation } = this.$store.state;

      if (!navigator.geolocation || location || userGeoLocation?.length !== 0) {
        return;
      }

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { data: geocodeResponse } = await reverseGeocode({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          const geocodeLocation = geocodeResponse.data[0];

          const countryCode = geocodeLocation?.address?.countryCode?.trim();

          if (!["US", "CA"].includes(countryCode)) {
            return;
          }

          const postalCode = geocodeLocation?.address?.postalCode;

          if (postalCode !== null) {
            const { data: autocompleteResponse } = await autocomplete({
              q: postalCode,
            });

            // Do nothing if the autocomplete response is empty
            if (autocompleteResponse.data.length === 0) {
              return;
            }

            const address = autocompleteResponse.data[0].address;

            const location = `${address.city.trim()}, ${address.state.trim()}`;
            const geoLatLng = [address.latitude, address.longitude];

            this.$store.dispatch("setLocation", location);
            this.$store.dispatch("setUserGeolocation", geoLatLng);
            this.$store.commit("SET_SHOWLOCATIONMESSAGE", false);
            this.$store.commit("PATCH_SEARCH_API_FIELDS", {
              location,
              lat: geoLatLng[0],
              lon: geoLatLng[1],
              page: 1,
            });
          } else {
            const location = `${geocodeResponse.address.city.trim()}, ${geocodeResponse.address.stateCode}`;
            const geoPos = geocodeResponse.position;

            this.$store.dispatch("setLocation", location);
            this.$store.dispatch("setUserGeolocation", [geoPos.lat, geoPos.lng]);
            this.$store.commit("SET_SHOWLOCATIONMESSAGE", false);
            this.$store.commit("PATCH_SEARCH_API_FIELDS", {
              location,
              lat: geoPos.lat,
              lon: geoPos.lng,
              page: 1,
            });
          }
        },
        async () => {
          // Use ip to convert to location
          let ipLocationData;
          let countryCode;

          try {
            const { data: ipToLocationResponse } = await ipToLocation();

            ipLocationData = ipToLocationResponse.data;
            countryCode = ipLocationData.countryISO.trim();

            if (!["US", "CA"].includes(countryCode)) {
              return;
            }
          } catch (error) {
            console.log(error);
            return;
          }

          const postalCode = ipLocationData.postalcode.trim();

          if (postalCode !== null) {
            const { data: autocompleteResponse } = await autocomplete({
              q: postalCode,
            });

            // Do nothing if the autocomplete response is empty
            if (autocompleteResponse.data.length === 0) {
              return;
            }

            const address = autocompleteResponse.data[0].address;

            const location = `${address.city.trim()}, ${address.state.trim()}`;
            const geoLatLng = [address.latitude, address.longitude];

            this.$store.dispatch("setLocation", location);
            this.$store.dispatch("setUserGeolocation", geoLatLng);
            this.$store.commit("PATCH_SEARCH_API_FIELDS", {
              location,
              lat: geoLatLng[0],
              lon: geoLatLng[1],
              page: 1,
            });
          } else {
            const location = `${ipLocationData.city.trim()}, ${ipLocationData.stateISO}`;
            this.$store.dispatch("setLocation", location);
            this.$store.commit("SET_USER_GEOLOCATION", [ipLocationData.latitude, ipLocationData.longitude]);
            this.$store.commit("PATCH_SEARCH_API_FIELDS", {
              location,
              lat: ipLocationData.latitude,
              lon: ipLocationData.longitude,
              page: 1,
            });
          }
        },
      );
    },
    hideMegaMenu(event) {
      const isTrailerElem = event?.target?.classList?.contains("menu-item") ?? false;
      if (!isTrailerElem) {
        this.showMenus = false;
        this.expandedMenu = "";
      }
      this.$store.commit("SET_MEGAMENU_OVERLAY", false);
      this.showMenus = false;
    },
    selectType(ev, type = { id: null, name: null }, toggle = false) {
      if (ev) {
        ev.preventDefault();
      }
      const { id: typeId, name: typeName } = type;
      // Toggle condition is used by mobile view components
      this.activeTypeId = typeId;
      if (toggle) {
        this.activeType = this.activeType === typeName ? "" : typeName;
      } else {
        this.activeType = typeName;
      }
      this.showMenus =
        String(this.expandedMenu).toLowerCase() === "buy trailers" ||
        String(this.expandedMenu).toLowerCase() === "sell on trailertrader" ||
        String(this.expandedMenu).toLowerCase() === "help";
    },
    routeFormation(categoryName) {
      let path;
      if (SEO_FRIENDLY_URLS_CATEGORIES.includes(categoryName)) {
        path = pathFromQueryParams({
          category: categoryName,
          type_id: this.activeTypeId,
        });
      } else {
        path = pathFromQueryParams({
          category: categoryName,
          type_id: this.activeTypeId,
          sort: this.$store.getters.sortOption,
          location: this.$store.state.location,
        });
      }

      this.$router.push({
        path: `/trailers-for-sale/${path}`,
      });
    },
    goToSelectedCategory(event, categoryName) {
      if (event) {
        event.preventDefault();
      }
      if (this.$route.name !== "ProductList" && this.$route.query.type_id === "all") {
        let newSearchFilter;
        this.routeFormation(categoryName);
        newSearchFilter = {
          [categoryName]: new GroupItemFilter({
            name: categoryName,
            group: "category",
            label: categoryName,
          }),
        };
        this.$store.commit("SET_SEO_SEARCH_FILTER", newSearchFilter);
        this.$store.commit("SHOW_SELECTED_FILTER", false);
        this.$store.commit("UPDATE_REMOVED_SEO_FILTER", []);
      } else {
        this.routeFormation(categoryName);
      }
      this.closeMenu();
      this.hideMegaMenu();
    },
    selectMenu(ev, menuName) {
      if (ev) {
        ev.preventDefault();
      }

      this.selectedMenuLeftDistance = ev?.target?.getBoundingClientRect().left || 0;

      if (this.expandedMenu === menuName) {
        this.hideMegaMenu();
      } else {
        this.expandedMenu = menuName;
        this.selectType(ev, this.megaMenuList[0]);
        // hide location form
        this.$store.commit("SET_SHOW_LOCATION_DESKTOP", false);
      }
    },
    selectMenuHover(menuName) {
      this.expandedHoverMenu = menuName;
      if (this.expandedMenu !== menuName) {
        this.hideMegaMenu();
      }
    },
    hideSelectMenuHover(menuName) {
      if (this.expandedMenu === menuName) {
        this.expandedHoverMenu = menuName;
      } else {
        this.hideMegaMenu();
      }
    },
    hoveringOverMenu(menu) {
      this.hoveringMenu = menu;
      this.expandedHoverMenu = "";
    },
    closeMenu() {
      menuController.close(MENU_CONTROLLER.AppContent);
      this.$store.commit("SET_OVERLAY", false);
    },
    openMenu() {
      this.$store.commit("SET_ACTIVE_MENU", ACTIVE_MENU.Navigation);
      menuController.enable(true, MENU_CONTROLLER.AppContent);
      menuController.open(MENU_CONTROLLER.AppContent);
    },
    openCloseForm() {
      this.showForm = !this.showForm;
    },
    openCloseFormMobile() {
      this.$store.commit("SET_SHOW_LOCATION_MOBILE", !this.showFormMobile);
    },
    openCloseFormDesktop() {
      this.$store.commit("SET_SHOW_LOCATION_DESKTOP", !this.showFormDesktop);
    },
    goToPage(url, $event) {
      $event.preventDefault();
      this.closeMenu();
      this.hideMegaMenu();
      if (this.$route.path !== url) {
        this.$router.push({
          path: url,
        });
      }

      this.hideMegaMenu();
    },
    goToHome(ev) {
      if (ev) {
        ev.preventDefault();
      }
      this.closeMenu();
      this.hideMegaMenu();
      window?.dispatchEvent(new Event("scrollToTop"));
      this.$router.push({
        name: "Home",
      });
    },
    megaMenuType(menu) {
      return `type_` + menu.name.toLowerCase().split(" ").join("_") + `_top_menu`;
    },
    categoryType(category) {
      if (this.activeTypeId === 4) {
        return `cat_semi_` + category.name.toLowerCase().split(" ").join("_") + `_top_menu`;
      }
      return `cat_` + category.name.toLowerCase().split(" ").join("_") + `_top_menu`;
    },
    hideSideMenu() {
      this.closeMenu();
    },
    goToSelectedType($event, typeId = "all", isRedirect = true) {
      if ($event) {
        $event.preventDefault();
      }
      if (isRedirect) {
        let path = pathFromQueryParams({
          type_id: typeId === "all" ? this.$store.state.searchApiFields.type_id : this.activeTypeId,
          sort: this.$store.getters.sortOption,
        });
        this.$router.push({
          path: `/trailers-for-sale/${path}`,
        });
      }
      this.hideMegaMenu();
    },
    goToAllCategoriesPage($event) {
      if ($event) {
        $event.preventDefault();
      }
      this.$router.push({
        name: "ProductList",
        query: {
          type_id: "all",
          sort: this.$store.getters.sortOption,
        },
      });
      this.hideMegaMenu();
    },
    hideProfile() {
      this.$store.commit("SET_SHOW_PROFILE", false);
      this.$store.commit("SET_PROFILE_OVERLAY", false);
    },
    //User Profile methods
    goToDashboard(ev) {
      if (ev) {
        ev.preventDefault();
      }
      this.closeMenu();
      this.hideMegaMenu();
      this.$router.push({
        path: "/dashboard",
      });
    },
    goToSavedSearches(ev) {
      if (ev) {
        ev.preventDefault();
      }
      this.closeMenu();
      this.hideMegaMenu();
      this.$router.push({
        path: "/saved-searches",
      });
    },
    goToSettings(ev) {
      if (ev) {
        ev.preventDefault();
      }
      this.closeMenu();
      this.hideMegaMenu();
      this.$router.push({
        path: "/settings",
      });
    },
    logout(e) {
      e.preventDefault();

      this.closeMenu();
      this.hideMegaMenu();
      this.openModal(LogoutConfirmation, {
        cssClass: "modal-header-logout-confirmation",
        backdropDismiss: true,
      });
    },
    setBreadCrumbStructuredSchema() {
      if (this.isProductListing) {
        let breadCrumbItems = [
          {
              "@type": "ListItem",
              "position": 1,
              "name": "Homepage",
              "item": "https://example.com/books",
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "All Trailers",
              "item": "https://example.com/books",
            },
        ];

        if (this.isAllTrailers !== "all") {
          breadCrumbItems = [
            ...breadCrumbItems,
            {
              "@type": "ListItem",
              "position": breadCrumbItems.length + 1,
              "name": this.breadCrumb,
              "item": this.typeIdHref,
            },
          ];
        }

        if (this.categoryName) {
          breadCrumbItems = [
            ...breadCrumbItems,
            {
              "@type": "ListItem",
              "position": breadCrumbItems.length + 1,
              "name": this.categoryName,
              "item": this.categoryHref,
            },
          ];
        }
        
        const breadCrumbStructuredSchema = {
          "@context": "https://www.schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": breadCrumbItems,
        };

        this.breadCrumbStructuredSchema = breadCrumbStructuredSchema;
      }
    },
  },
});
</script>

<style scoped>
ion-header {
  background: white;
  position: relative;
}

ion-menu .menu-inner {
  background: #fff;
}

.ion-border {
  border-bottom: 1px solid #e2ebf5;
}

ion-img::part(image) {
  margin-top: 20px;
  margin-left: 20px;
  width: 80%;
}

ion-item {
  font-size: 19px;
  font-weight: bold;
  border: 0px solid white;
}

ion-item .sub-text {
  text-align: center;
  font-size: 18px;
  font-weight: bolder;
  color: grey;
}

ion-button.menu {
  --box-shadow: none;
  color: #344854;
  right: 20px;
  top: -15px;
  height: auto;
  z-index: 1;
}

/* ion-button .close-btn{
  
} */
ion-menu {
  --min-width: 100%;
  background: #fff;
}

.header-text {
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px;
  text-align: left;
  color: #344854;
}

.contact-text {
  font-family: Object Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 13px;
  color: #74869a;
  font-display: swap;
}

#TesA_plp_breadcrumb {
  display: grid;
  padding-left: 45px;
  padding-right: 45px;
  width: 100%;
  margin-top: 10px;
}

#TesA_plp_affiliates {
  display: flex;
  padding-left: 45px;
  padding-right: 45px;
  width: 100%;
  margin-top: 10px;
}
</style>
