<template>
  <!-- Start of NumberOfTruckAxlesFilter Accordion Setup -->
  <ion-row
    class="collapsable-menu-custom"
  >
    <ion-col>
      <ion-list>
        <div style="border-bottom: 1px solid #d5dee9; margin-top: -18px">
          <ion-item
            id="TestA_plp_filter_axles"
            lines="none"
            class="filter-item-head"
            :class="{ active }"
            @click="handleClick"
          >
            <div class="text-icon">
              <div class="ion-text-uppercase parent-text">
                Number of truck axles
              </div>
              <div class="d-flex">
                <span class="ion-text-uppercase selected-search ion-padding-end">
                  {{ selectedListString }}
                </span>
                <span :class="!active ? 'arrow-drop  rotate-caret' : 'arrow-drop'">
                  <img
                    alt="Trailers Near Me"
                    src="@/assets/icons/caretDownBlue.svg"
                  >
                </span>
              </div>
            </div>
          </ion-item>
          <template
            v-for="(item, index) in options"
            :key="index"
          >
            <ion-item
              v-if="active"
              lines="none"
              :href="item.href"
              class="filter-collapse"
              @click="toggleFilter($event, 'number_of_truck_axles', index)"
            >
              <ion-checkbox
                slot="start"
                ref="number_of_truck_axles"
                :value="item.value"
                :checked="!!hasFilter('number_of_truck_axles', item.value)"
                class="filter-checkbox"
              />
              <ion-label class="ion-text-uppercase child-text">
                {{ item.label }}
              </ion-label>
            </ion-item>
          </template>
        </div>
      </ion-list>
    </ion-col>
  </ion-row>
</template>

<script>
import { mapState } from "vuex";
import { ellipsizeList } from "@/utils/FilterListUtil";
import { IonCheckbox, IonCol, IonItem, IonList, IonRow, IonLabel } from "@ionic/vue";
import { filterActions } from "@/mixins";

export default {
  name: "NumberOfTruckAxlesFilter",
  components: {
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonCheckbox,
    IonLabel,
  },
  mixins: [filterActions],
  props: {
    active: Boolean,
  },
  emits: ["toggle"],
  data() {
    return {
      options: [
        {value: '1', label: '1'},
        {value: '2', label: '2'},
      ],
    }
  },

  computed: {
    ...mapState({
      searchFilter: (state) => state.searchFilter,
      searchApiFields: (state) => state.searchApiFields,
      aggregations: (state) => state.categoryAggregations,
    }),
    selectedList() {
      return this.searchFilter
          .filter((item) => item.name === "number_of_truck_axles")
          .map((item) => item.label);
    },
    selectedListString() {
      return ellipsizeList(this.selectedList, 14);
    },
  },
  methods: {
    handleClick() {
      this.$emit("toggle");
    },
  },
};
</script>