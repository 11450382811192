import { createRouter, createWebHistory, createMemoryHistory } from "@ionic/vue-router";

import { isSSR } from "@/utils/SSRUtil";
import { baseUrl } from "@/config";
import headForRoute from "./seo";
import { auth } from "@/middleware/";
import authPipeline from "./authPipeline";
import { dealerRoutes, dealerListRoutes } from "@/consts";
import { utagView } from "@/services/TealiumService";

export default function ({ store }) {
  const requiredParams = (to) => {
    if (Object.keys(to.query).length) return true;
    else return { path: "/404" };
  };

  const routes = [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      name: "Home",
      component: () => import("../views/Home.vue"),
      meta: {
        gtm: "Trailer Classifieds on TrailerTrader | Near Me",
        title: "Largest selection of trailers and recreational vehicles in America | TrailerTrader | Select from 100,000s of new and used listings.",
      },
    },
    ...dealerRoutes(),
    ...dealerListRoutes(),
    {
      path: "/trailers-for-sale",
      name: "ProductList",
      component: () => import("../views/ProductList.vue"),
      meta: { gtm: "Trailers For Sale on Trailertrader" },
    },
    {
      path: "/compare",
      name: "Compare",
      component: () => import("../views/Compare.vue"),
      meta: {
        gtm: "Compare Trailers before buying on Trailertrader",
        title: "TrailerTrader | Compare Trailers",
      },
    },
    {
      path: "/trailers-for-sale/:path(.*)",
      name: "ProductListWithPath",
      component: () => import("../views/ProductList.vue"),
      meta: { gtm: "Trailers For Sale on Trailertrader" },
    },
    {
      path: "/find-:type-near-you",
      name: "FindTypeNearYou",
      component: () => import("../views/FindTypeNearYou.vue"),
      meta: { gtm: "Find trailers near you on TrailerTrader" },
    },
    {
      path: "/:url",
      name: "ProductDetails",
      component: () => import("../views/ProductDetails.vue"),
      meta: {
        gtm: "Trailers For Sale on TrailerTrader. Shop trailers for sale near me",
      },
      beforeEnter: (to) => {
        // reject the navigation
        return String(to.path).includes(".html") || !String(to.path).includes(".");
      },
    },
    {
      path: "/:manufacturer-for-sale",
      name: "MoreFromManufacturer",
      component: () => import("../views/MoreFromManufacturers.vue"),
      meta: {
        title: "TrailerTrader | More from Manufacturer",
        gtm: "Trailer & RV manufacturers on TrailerTrader",
      },
    },
    {
      path: "/manufacturers-on-trailertrader",
      name: "Manufacturers",
      component: () => import("../views/Manufacturers.vue"),
      meta: {
        title: "TrailerTrader | Manufacturers on TrailerTrader",
        gtm: "All manufacturers on TrailerTrader",
      },
    },
    {
      path: "/privacypolicy",
      name: "PrivacyPolicy",
      component: () => import("../views/PrivacyPolicy.vue"),
      meta: {
        title: "TrailerTrader | Privacy Policy",
      },
    },
    {
      path: "/ccpa",
      name: "CaliforniaPrivacyPolicy",
      component: () => import("../views/CaliforniaPrivacyPolicy.vue"),
      meta: {
        title: "TrailerTrader | California Privacy Policy",
      },
    },
    {
      path: "/termsofuse",
      name: "TermsOfUse",
      component: () => import("../views/TermsOfUse.vue"),
      meta: {
        title: "TrailerTrader | Terms Of Use",
      },
    },
    {
      path: "/about",
      name: "About",
      component: () => import("../views/About.vue"),
      meta: {
        title: "TrailerTrader | About",
      },
    },
    {
      path: "/careers",
      name: "Careers",
      component: () => import("../views/Careers.vue"),
      meta: {
        title: "TrailerTrader | Careers",
      },
    },
    {
      path: "/help",
      redirect: "/faq",
    },
    {
      path: "/faq",
      name: "Faq",
      component: () => import("../views/Faq.vue"),
      meta: {
        title: "TrailerTrader | FAQ",
      },
    },
    {
      path: "/articles/what-features-should-you-consider-when-buying-a-trailer",
      name: "Guide",
      component: () => import("../views/articles/Guide.vue"),
      meta: {
        title: "Understanding Trailer Features: An In-depth Guide for New Buyers - TrailerTrader",
      },
    },
    {
      path: "/articles/ask-for-vin-and-check",
      name: "VIN Check",
      component: () => import("../views/articles/VinCheck.vue"),
      meta: {
        title: "Ask for VIN and check - TrailerTrader",
      },
    },
    {
      path: "/articles/tow-guide",
      name: "Tow Guide",
      component: () => import("../views/articles/TowGuide.vue"),
      meta: {
        title: "Tow Guide - TrailerTrader",
      },
    },
    {
      path: "/articles/buyers-guide-for-used-trailers",
      name: "GuideForUsedTrailers",
      component: () => import("../views/articles/GuideForUsedTrailers.vue"),
      meta: {
        title: "Buyer’s Guide for Used Trailers",
      },
    },
    {
      path: "/dealers",
      name: "Dealers",
      component: () => import("../views/Dealers.vue"),
      meta: {
        title: "TrailerTrader | Dealers",
      },
    },
    {
      path: "/listingconfirmation",
      name: "ListingConfirmation",
      component: () => import("../views/ListingConfirmation.vue"),
      meta: {
        title: "TrailerTrader | Listing Confirmation",
      },
    },
    {
      path: "/privatesellers",
      name: "PrivateSellers",
      component: () => import("../views/PrivateSellers.vue"),
      meta: {
        title: "TrailerTrader | Private Sellers",
      },
    },
    {
      path: "/404",
      name: "NotFound",
      component: () => import("../views/404.vue"),
      meta: {
        title: "TrailerTrader | America’s online Trailer and RV marketplace",
      },
    },
    {
      path: "/affiliates",
      name: "affiliates",
      component: () => import("../views/Affiliates.vue"),
      meta: {
        title: "TrailerTrader | Affiliates",
      },
    },
    {
      path: "/resetpassword",
      name: "ResetPassword",
      component: () => import("../views/ResetPassword.vue"),
      beforeEnter: [requiredParams],
      meta: {
        title: "TrailerTrader | Reset Password",
      },
    },
    {
      path: "/registrationconfirmation",
      name: "RegistrationConfirmation",
      component: () => import("../views/RegistrationConfirmation.vue"),
      meta: {
        title: "TrailerTrader | Registration Confirmation",
      },
    },
    {
      path: "/resendemail/:email",
      name: "ResendEmail",
      component: () => import("../views/ResendEmail.vue"),
      props: true,
      meta: {
        title: "TrailerTrader | Resend Email",
      },
    },
    {
      path: "/trailers/add",
      name: "AddTrailers",
      component: () => import("../views/trailers/index.vue"),
      meta: {
        title: "TrailerTrader | Add Trailers",
        middleware: [auth],
      },
    },
    {
      path: "/trailers/:id",
      name: "EditTrailers",
      component: () => import("../views/trailers/index.vue"),
      meta: {
        title: "TrailerTrader | Edit Trailers",
        middleware: [auth],
      },
    },
    {
      path: "/emailconfirmation",
      name: "EmailConfirmation",
      component: () => import("../views/EmailConfirmation.vue"),
      meta: {
        title: "TrailerTrader | Email Confirmation",
      },
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: () => import("../views/dashboard/index.vue"),
      meta: {
        title: "TrailerTrader | Dashboard",
        middleware: [auth],
      },
    },
    {
      path: "/settings",
      name: "UserInfo",
      component: () => import("../views/dashboard/index.vue"),
      meta: {
        title: "TrailerTrader | UserInfo",
        middleware: [auth],
      },
    },
    {
      path: "/saved-searches",
      name: "SavedSearches",
      component: () => import("../views/dashboard/index.vue"),
      meta: {
        title: "TrailerTrader | Saved Searches",
        middleware: [auth],
      },
    },
    {
      path: "/thank-you-dealer-signup",
      name: "ThankYou",
      component: () => import("../views/ThankYou.vue"),
      meta: {
        title: "TrailerTrader | Thank You For Dealer SignUp",
      },
    },
    {
      path: "/cmpromotion",
      name: "Promotion",
      component: () => import("../views/Promotion.vue"),
      meta: {
        title: "TrailerTrader | CM Promotion",
      },
    },
    {
      path: "/find-in-state",
      name: "FindInState",
      component: () => import("../views/FindInState.vue"),
      meta: {
        title: "TrailerTrader | Find In State",
      },
    },
    {
      path: "/find-in-state/:state/:city?",
      name: "FindInCity",
      component: () => import("../views/FindInCity.vue"),
      meta: {
        title: "TrailerTrader | Find In City",
      },
    },
  ];

  const router = createRouter({
    history: isSSR() ? createMemoryHistory(baseUrl) : createWebHistory(baseUrl),
    routes,
  });

  router.beforeEach((to, from, next) => {
    const head = headForRoute(to);
    if (head) {
      store.commit("SET_SEO_HEAD", head);
    }

    if(to.query.reminder) {
      store.commit("SET_REDIRECT_URL", to.href);
    }

    if (!to.meta.middleware) {
      return next();
    }
    const middleware = to.meta.middleware;
    store.commit("SET_AUTHENTICATED_ROUTE", true);
    const context = {
      to,
      from,
      next,
      store,
    };

    return middleware[0]({
      ...context,
      next: authPipeline(context, middleware, 1),
    });
  });

  router.afterEach((to) => {
    const head = headForRoute(to);
    if (head) {
      store.commit("SET_SEO_HEAD", head);
    }

    //Send tealium event.
    utagView(to);
  });
  return router;
}
