<template>
  <Form
    ref="form"
    v-slot="{ meta, values }"
    class="email-seller-form"
    @submit="submit"
  >
    <div>
      <!--Name-->
      <Field
        v-slot="{ field, errors }"
        rules="required"
        name="name"
        label="NAME"
        model-value=""
      >
        <ion-item
          lines="none"
          :class="generateClasses(field, errors)"
          class="location-input"
        >
          <IonLabel
            class="location-label"
            position="floating"
          >
            NAME <span style="color: red">*</span>
          </IonLabel>
          <ion-input
            id="pdp_contact_seller_name_input"
            ref="nameInput"
            name="name"
            v-bind="field"
            aria-label="input name"
          />
        </ion-item>
      </Field>
      <ErrorMessage name="name" />

      <!--Phone-->
      <Field
        v-slot="{ field, errors }"
        rules="phone_number"
        name="phone"
        label="PHONE"
        model-value=""
      >
        <ion-item
          lines="none"
          :class="generateClasses(field, errors)"
          class="location-input"
        >
          <IonLabel
            class="location-label"
            position="floating"
          >
            PHONE NUMBER
          </IonLabel>
          <ion-input
            name="phone"
            v-bind="field"
            aria-label="input phone"
          />
        </ion-item>
      </Field>
      <ErrorMessage name="phone" />

      <!--Email-->
      <Field
        v-slot="{ field, errors }"
        rules="email"
        name="email"
        label="Email"
        model-value=""
      >
        <ion-item
          lines="none"
          :class="errors.length !== 0 ? 'error-field' : ''"
          class="location-input"
        >
          <IonLabel
            class="location-label"
            position="floating"
          >
            Email
          </IonLabel>
          <ion-input
            name="email"
            v-bind="field"
            aria-label="input email"
          />
        </ion-item>
      </Field>
      <ErrorMessage name="email" />

      <!--Message-->
      <Field
        v-slot="{ field, errors }"
        rules="required"
        name="message"
        label="MESSAGE"
        model-value="message"
      >
        <ion-item
          lines="none"
          :class="errors.length !== 0 ? 'error-field' : ''"
          class="location-input"
        >
          <IonLabel
            class="location-label"
            position="floating"
          >
            MESSAGE <span style="color: red">*</span>
          </IonLabel>
          <ion-textarea
            name="message"
            v-bind="field"
            maxlength="1500"
            rows="3"
            aria-label="Input message"
          />
        </ion-item>
      </Field>
      <ErrorMessage name="message" />
    </div>

    <div class="description">
      You should enter at least one of the following: <b>Phone Number</b> or <b>Email</b>.
    </div>

    <!--Error message in case of backend issues-->
    <div v-if="errorMessage.trim() !== ''">
      <div class="ion-color-danger ion-padding-bottom">
        <ion-text color="danger">
          {{ errorMessage }}
        </ion-text>
      </div>
    </div>

    <!--Email Seller Button-->
    <div>
      <ion-button
        id="contactseller_pdp"
        type="submit"
        :disabled="submitting || !(meta.valid && meta.dirty) || !(values.phone || values.email)"
        class="btn btn-email"
        style="margin-top: 0"
      >
        Email Seller
        <ion-icon
          class="email"
          :icon="chevronForwardOutline()"
        />
      </ion-button>
    </div>

    <RecaptchaRemark />
  </Form>
</template>

<script>
import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonTextarea,
  modalController,
  IonText,
} from "@ionic/vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { chevronForwardOutline } from "ionicons/icons";
import RecaptchaRemark from "@/components/google/RecaptchaRemark.vue";
import { getRecaptchaToken } from "@/services/RecaptchaService";
import { defaultContactSellerLeadFirstName, defaultContactSellerLeadLastName } from "@/utils/LeadUtil";
import { contactSeller } from "@/services/ProductsService";
import { LogRocketService } from "@/services/LogRocketService";
import ResponseMessage from "@/components/ResponseMessage.vue";
import { modal } from "@/mixins";
import urlTransformations from "@/mixins/urlTransformations";
import { utagLink } from "@/services/TealiumService";

export default {
  name: "EmailSellerForm",
  components: {
    RecaptchaRemark,
    IonItem,
    IonLabel,
    IonInput,
    IonTextarea,
    IonIcon,
    IonButton,
    Form,
    ErrorMessage,
    Field,
    IonText,
  },
  mixins: [modal, urlTransformations],
  props: {
    inventoryId: {
      type: Number,
      required: true,
    },
    dealerId: {
      type: Number,
      required: true,
    },
    websiteId: {
      type: Number,
      required: true,
    },
    dealerLocationId: {
      type: Number,
      required: true,
    },
    dealerPhoneNumber: {
      type: String,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
    inventoryTitle: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    price: {
      type: [String, Number],
      required: false,
      default() {
        return 0;
      },
    },
    downPayment: {
      type: [String, Number],
      required: false,
      default() {
        return 0;
      },
    },
    frequency: {
      type: [String, Number],
      required: false,
      default() {
        return 'monthly';
      },
    },
    financeTerm: {
      type: [String, Number],
      required: false,
      default() {
        return 4;
      },
    },
    stock: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      errorMessage: "",
      email: "this_is_a_contact_lead@trailertrader.com",
    };
  },
  mounted() {
    window.addEventListener("goToContactSellerForm", (event) => {
      const nodeList = document.querySelectorAll("ion-router-outlet .ion-page");

      if (nodeList.length === 0) {
        return;
      }

      const page = nodeList[nodeList.length - 1];

      const anchor = page.querySelector("#pdp_contact_seller_form_anchor");

      if (anchor === null) {
        return;
      }

      anchor.scrollIntoView({ behavior: "smooth" });

      setTimeout(() => {
        page.querySelector("#pdp_contact_seller_name_input > input")?.focus();
      }, event.detail?.duration ?? 1000);
    });

    const userData = JSON.parse(localStorage.getItem("user")) || this.$store?.state?.userData;

    if (userData?.phone_number) {
      this.$refs.form.setValues({
        name: `${userData.first_name} ${userData.last_name}`,
        phone: `${userData.phone_number}`,
      });
      this.$refs.form.validate();
    }

    this.$refs.form.setValues({
      message : `${this.message}`, 
    });

    if (userData?.email) {
      this.email = userData.email;
    }
  },
  methods: {
    chevronForwardOutline() {
      return chevronForwardOutline;
    },
    async submit(values) {
      this.submitting = true;

      const payload = await this.getLeadPayload(values);

      await this.createLead(payload);

      this.submitting = false;

      utagLink(this.$route, {
        "tealium_event": "form_submit",
        "form_id": "email_seller_form",
        "form_submission_email": payload.email_address,
        "form_submission_first_name": payload.first_name,
        "form_submission_last_name": payload.last_name,
      });
    },
    async getLeadPayload(values) {
      let firstName = "";
      let lastName = "";
      let phone = values.phone.trim();

      if (values.name.trim().length === 0) {
        firstName = defaultContactSellerLeadFirstName;
        lastName = defaultContactSellerLeadLastName;
      }

      const [tempFirstName, ...tempLastName] = values.name.split(" ");

      if (tempFirstName.length > 0) {
        firstName = tempFirstName;
      }

      if (tempLastName.length > 0) {
        lastName = tempLastName.join("");
      }

      let price;
      let downPayment;
      let frequency;
      let financeTerm;
      let isInstallmentInquiry = false;
      let comments = values.message;

      if(this.location === 'PDP_INSTALLMENT'){
        price = this.price;
        downPayment = this.downPayment;
        frequency = this.frequency;
        financeTerm = this.financeTerm;
        isInstallmentInquiry = true;
        comments += `
          Price : ${price}
          Down Payment : ${downPayment || 0}
          Frequency : ${frequency}
          Finance Term : ${financeTerm} year(s)
        `;
      }

      return {
        dealer_id: this.dealerId,
        first_name: firstName,
        last_name: lastName,
        comments: comments,
        email_address: values.email || this.email,
        phone_number: phone,
        zip: "",
        website_id: this.websiteId,
        inquiry_type: "inventory",
        is_installment_inquiry: isInstallmentInquiry,
        dealer_location_id: this.dealerLocationId,
        lead_types: {
          status: "inventory",
        },
        inventory: {
          inventory_id: String(this.inventoryId),
        },
        installment_info :{
          price,
          downPayment,
          frequency,
          financeTerm,
        },
        stock: this.stock,
        captcha: await getRecaptchaToken("contact_seller"),
        title: this.inventoryTitle,
        referral: `/${this.productHrefPath(this.inventoryId, this.inventoryTitle)}`,
      };
    },
    async createLead(payload) {
      this.errorMessage = "";
      let contactSellerOk = false;

      try {
        await contactSeller(payload);
        contactSellerOk = true;
      } catch (error) {
        const errorData = error?.data;

        this.errorMessage = "Something is wrong while we're contacting the seller, please try again.";

        if (typeof errorData !== "undefined") {
          this.errorMessage = `${errorData.message}: `;

          for (const key in errorData.errors ?? {}) {
            this.errorMessage += `${errorData.errors[key][0]}, `;
          }

          this.errorMessage = this.errorMessage.trim().slice(0, -1);
        }

        LogRocketService.track(LogRocketService.events.contact_form_submitted_errors, errorData ?? { error });
      }

      if (contactSellerOk) {
        LogRocketService.track(LogRocketService.events.contact_form_submitted_success, {
          ...payload,
          location: this.location,
        });

        try {
          await modalController.dismiss();
        } catch (error) {
          // Do nothing in case of error
        }

        await this.openModal(ResponseMessage, {
          cssClass: "modal-header-response-message",
          componentProps: {
            phoneNumber: this.dealerPhoneNumber,
          },
          backdropDismiss: true,
        });
      }
    },
    generateClasses(field, errors) {
      let classes = '';
      if(field.value !== '') {
        classes += 'item-has-value ';
      }
      if(errors.length !== 0) {
        classes += 'error-field';
      }
      return classes;
    },
  },
};
</script>

<style scoped>
.location-input {
  width: 100%;
}

.description {
  margin-bottom: 12px;
}

@media (max-width: 767px) {
  .email-seller-form {
    padding: 0 10px 36px;
  }
}
</style>
