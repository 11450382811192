<template>
  <div class="hide-tablet-mobile filter-side-bar">
    <FilterList />
    <ion-row
      class="results-button-row collapsable-menu-custom sticky-bottom"
      :class="{ 'sticky-ad-inactive': isStickyFooterClosed }"
    >
      <ion-col>
        <div class="results-button-parent">
          <ion-button
            class="results-button btn-inactive"
            disabled
          >
            Now listing {{ totalProducts > 0 ? totalProducts : "0" }} Results
            <ion-icon :icon="chevronForwardOutline" />
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { IonButton, IonCol, IonRow, IonIcon } from "@ionic/vue";
import { chevronForwardOutline } from "ionicons/icons";
import FilterList from "./FilterList.vue";
import { mapState } from "vuex";
export default defineComponent({
  name: "FilterSideBar",
  components: {
    FilterList,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
  },
  data() {
    return {
      chevronForwardOutline,
    };
  },
  computed: {
    ...mapState({
      totalProducts: (state) => state.totalProducts,
      isStickyFooterClosed: (state) => state.ads.isStickyFooterClosed,
    }),
  },
});
</script>
